import React from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { TextField } from 'edenred-ui';

import {
  createRules,
  FormFields,
  useOnBlurTrim,
  useAsyncValidatorController
} from '@epi-forms/helpers';
import { required, maxLength } from '@epi-helpers/formHelpers/validators';
import { OperatorFieldProps } from '@epi-models/forms/Controls';

import { useEInvoiceContext } from '../useEInvoiceContext';

export function OperatorField({ readOnly }: OperatorFieldProps) {
  const name = FormFields.Operator;
  const {
    control,
    formState: { errors },
    getValues
  } = useFormContext();
  const { t } = useTranslation();
  const validators = [required, maxLength(50)];
  const rules = createRules(validators);
  const onBlurTrim = useOnBlurTrim(name);

  const {
    field: { ref, value, onChange: setValue },
    fieldState: { error }
  } = useController({
    name,
    control,
    rules
  });

  const { validateEInvoice } = useEInvoiceContext();

  const { isError, inputProps, setAsyncUnverified, clearAsyncUnverified } =
    useAsyncValidatorController({
      value,
      error,
      name: FormFields.EInvoice,
      isStatusSuccess: isDefaultSuccess =>
        isDefaultSuccess &&
        getValues(FormFields.EInvoiceAddress) &&
        !errors[FormFields.EInvoiceAddress]
    });

  const onChange = event => {
    setAsyncUnverified();
    setValue(event);
  };

  const onBlur = async () => {
    onBlurTrim();
    await validateEInvoice();
    clearAsyncUnverified();
  };

  return (
    <TextField
      label={t('controls.operator_label')}
      required
      readOnly={readOnly}
      value={value}
      fullWidth
      id={name}
      error={isError}
      InputProps={readOnly ? undefined : inputProps}
      helperText={error?.message}
      inputRef={ref}
      onBlur={onBlur}
      onChange={onChange}
    />
  );
}
