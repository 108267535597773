import React, { useEffect } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useTranslation } from 'react-i18next';
import { FormProvider } from 'react-hook-form';
import { BackIcon } from 'edenred-ui';
import { isEmpty } from 'lodash';

import { getRecaptchaToken } from '@epi-helpers/ReCaptchaHelper';
import { FormFields } from '@epi-forms/helpers';
import { registeredVoucherOrderAction } from '@epi-store/registeredVoucherOrder/registeredVoucherOrder.slice';
import {
  getOrderPage,
  getOrderData
} from '@epi-store/registeredVoucherOrder/registeredVoucherOrder.selectors';
import { getApplicationLocale } from '@epi-store/application/application.selectors';

import { replaceParams } from '../../helpers/UrlHelpers';
import { OrderType } from '../../constants/orderType';
import { orderSummary as orderSummarySelector } from '../../selectors/voucherOrder';
import {
  getUserCompany as getUserCompanySelector,
  getAvailableVouchersByType,
  arePaymentMethodsLoaded as arePaymentMethodsLoadedSelector
} from '../../selectors/api';
import {
  countries as countriesLoadingSelector,
  orderData as orderDataLoadingSelector,
  createVoucherOrder as createVoucherOrderLoadingSelector,
  companyInfo as companyInfoLoadingSelector,
  disablePayment as disablePaymentLoadingSelector
} from '../../selectors/loading';
import {
  getMaxOrderTotalAmount,
  getVoucherMinimumQuantity,
  getReCaptchaSiteKey
} from '../../selectors/settings';
import { ApiConstants } from '../../constants/actions';
import { PaymentMethods } from '../../constants/paymentMethods';
import { VoucherTypes } from '../../constants/voucherTypes';
import { scrollToInvalidField } from '../../helpers/DocumentHelper';
import { Steps } from '../../components/Steps/Steps';
import { ErrorPanel } from '../../components/ErrorPanel/ErrorPanel';
import { DeliveryAndPayment } from '../../components/DeliveryAndPayment/DeliveryAndPayment';
import { RoundButton } from '../../components/RoundButton/RoundButton';
import { Quantity } from '../../components/Quantity/Quantity';
import { OrderButton } from '../../components/OrderButton/OrderButton';
import { routerPaths } from '../../constants/routerPaths';
import * as loginActions from '../../actions/login';
import * as apiActions from '../../actions/api';
import * as reduxFormActions from '../../actions/reduxForm';
import * as navigationActions from '../../actions/navigation';

import { useVoucherOrder } from './hooks/useVoucherOrder';
import './VoucherOrder.scss';
import { IRegisteredUser } from '@epi-models/containers/VoucherReOrder';

const totalPages = 2;
const voucherQuantityPage = 1;
const paymentMethodPage = 2;

function RegisteredUser({
  loadVouchers,
  getUserCompany,
  loadOrderFees,
  voucherTypeKey,
  createVoucherOrder,
  locale,
  vouchers,
  isLoadingCompany,
  useCalculator = false,
  isLoading,
  isCreatingVoucherOrder,
  userCompany,
  apiPaymentMethods,
  disablePayment = false,
  maxOrderTotalAmount,
  voucherMinimumQuantity,
  loadPaymentMethods,
  arePaymentMethodsLoaded,
  reCaptchaSiteKey
}: IRegisteredUser) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const order = useSelector(getOrderData);
  const page = useSelector(getOrderPage);
  const { voucherOrderForm, composeVoucherOrder, useFormUpdater } =
    useVoucherOrder();

  const {
    formState: { isValid, errors },
    trigger,
    handleSubmit,
    getValues,
    setValue
  } = voucherOrderForm;

  const voucherQuantityValue = getValues(FormFields.VoucherQuantity);
  const voucherWorthValue = getValues(FormFields.VoucherWorth);
  const paymentMethodValue = getValues(FormFields.PaymentMethod);
  const voucherIdValue = getValues(FormFields.VoucherId);
  const orderSummary = useSelector(
    orderSummarySelector(
      paymentMethodValue,
      voucherQuantityValue,
      voucherWorthValue
    )
  );

  useFormUpdater(order, setValue);

  const goToPage = pageNumber => {
    dispatch(registeredVoucherOrderAction.changeOrderPage(pageNumber));
  };

  const nextPageSubmit = async values => {
    trigger();

    if (!isValid) {
      return;
    }
    if (totalPages === page) {
      const reCaptchaToken = await getRecaptchaToken(
        reCaptchaSiteKey,
        'VOUCHER_ORDER_FORM'
      );

      const payload = composeVoucherOrder({
        values,
        reCaptchaToken
      });
      createVoucherOrder(payload);
    } else {
      dispatchQuantity();
      goToPage(page + 1);
    }
  };

  const isStepClickable = (activePageIndex, index) => activePageIndex !== index;

  const dispatchQuantity = () => {
    if (page === voucherQuantityPage) {
      const quantityInfo = {
        [FormFields.VoucherQuantity]: voucherQuantityValue,
        [FormFields.VoucherWorth]: voucherWorthValue,
        [FormFields.VoucherId]: voucherIdValue
      };
      dispatch(registeredVoucherOrderAction.addOrderInformation(quantityInfo));
    }
  };

  const navigationClick = (activePage, nextPage) => {
    if (!isStepClickable(activePage, nextPage)) {
      return;
    }
    dispatchQuantity();
    goToPage(nextPage + 1);
  };

  const submitCompleteForm = () => {
    nextPageSubmit(getValues());
  };

  const handleLoadingPaymentMethods = () => {
    const totalAmount = orderSummary.sumVouchers + orderSummary.handlingFee;
    if (
      page === totalPages &&
      orderSummary.sumVouchers > 0 &&
      totalAmount > 0 &&
      arePaymentMethodsLoaded &&
      !arePaymentMethodsLoaded(locale, totalAmount)
    ) {
      loadPaymentMethods(OrderType.voucher, totalAmount, locale);
    }
  };

  const isPaidOnline =
    [PaymentMethods.CreditCard, PaymentMethods.Online].includes(
      paymentMethodValue
    ) && page === paymentMethodPage;

  useEffect(() => {
    loadVouchers();
    getUserCompany();
    loadOrderFees();
    if (page === paymentMethodPage && orderSummary.sumVouchers !== 0) {
      handleLoadingPaymentMethods();
    }
  }, []);

  useEffect(() => {
    handleLoadingPaymentMethods();
  });

  return (
    <div className="VoucherOrder order-registered">
      <Steps
        pages={[
          { id: 1, name: t('containers.voucher_order.amount_step') },
          {
            id: 2,
            name: t('containers.voucher_order.delivery_and_payment_step')
          }
        ]}
        activePageId={page}
        navigationClick={navigationClick}
        isStepClickable={isStepClickable}
      />
      <FormProvider {...voucherOrderForm}>
        <form noValidate onSubmit={handleSubmit(nextPageSubmit)}>
          {page === voucherQuantityPage && (
            <div>
              <Quantity
                voucherTypeKey={voucherTypeKey}
                vouchers={vouchers}
                orderSummary={orderSummary}
                useCalculator={useCalculator}
                locale={locale}
                maxOrderTotalAmount={maxOrderTotalAmount}
                voucherMinimumQuantity={voucherMinimumQuantity}
                header={t(`containers.voucher_order.header`)}
              />
            </div>
          )}
          {page === paymentMethodPage && (
            <div>
              <DeliveryAndPayment
                voucherTypeKey={voucherTypeKey}
                isLoading={isLoadingCompany}
                orderSummary={orderSummary}
                apiPaymentMethods={apiPaymentMethods}
                onClickPaymentMethod={submitCompleteForm}
                userCompany={userCompany}
                paymentMethod={paymentMethodValue}
                disabled={disablePayment}
                goToEditPage={() => goToPage(voucherQuantityPage)}
                editDeliveryPage={replaceParams(
                  routerPaths.voucherOrderEditDelivery,
                  {
                    voucherType: VoucherTypes[voucherTypeKey]
                  }
                )}
                editCompanyPage={replaceParams(
                  routerPaths.voucherOrderEditCompany,
                  {
                    voucherType: VoucherTypes[voucherTypeKey]
                  }
                )}
              />
            </div>
          )}
          <ErrorPanel failedActionName={ApiConstants.CREATE_VOUCHER_ORDER} />
          {page > voucherQuantityPage && (
            <RoundButton
              id="voucher-order-go-back"
              icon={<BackIcon />}
              onClick={() => goToPage(page - 1)}
            />
          )}
          {!isPaidOnline && (
            <OrderButton
              disabled={!isValid || !isEmpty(errors)}
              currentPage={page}
              totalPages={totalPages}
              isLoading={isCreatingVoucherOrder}
              onClick={() => {
                if (!isValid) {
                  scrollToInvalidField();
                }
              }}
            />
          )}
        </form>
      </FormProvider>
    </div>
  );
}

const mapStateToProps = (state, props) => ({
  arePaymentMethodsLoaded: (locale, totalAmount) =>
    arePaymentMethodsLoadedSelector(locale, totalAmount)(state),
  reCaptchaSiteKey: getReCaptchaSiteKey(state),
  apiPaymentMethods: state.apiPaymentMethods,
  locale: getApplicationLocale(state),
  vouchers: getAvailableVouchersByType(props.voucherTypeKey)(state),
  isLoadingCountries: countriesLoadingSelector(state),
  userCompany: getUserCompanySelector(state),
  isLoading: orderDataLoadingSelector(state),
  isCreatingVoucherOrder: createVoucherOrderLoadingSelector(state),
  isLoadingCompany: companyInfoLoadingSelector(state),
  disablePayment: disablePaymentLoadingSelector(state),
  maxOrderTotalAmount: getMaxOrderTotalAmount(state),
  voucherMinimumQuantity: getVoucherMinimumQuantity(state)
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...loginActions,
      ...apiActions,
      ...navigationActions,
      ...reduxFormActions
    },
    dispatch
  );

const ConnectedRegisteredUser = connect(
  mapStateToProps,
  mapDispatchToProps
)(RegisteredUser);

export { ConnectedRegisteredUser as RegisteredUser };
