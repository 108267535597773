import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Link, TitleText, Text } from 'edenred-ui';
import { useTheme } from 'styled-components';

import { socialsArr } from '@epi-constants/support';

export const SupportSocials = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Box
      mt={8}
      pt={8}
      mx={20}
      mb={2}
      sx={{ borderTop: `1px solid ${theme.gray10}` }}
    >
      <Box id="support-page-social-title">
        <TitleText>{t('containers.help.social.title')}</TitleText>
      </Box>
      <Text paragraph id="support-page-social-subtitle">
        {t('containers.help.social.content')}
      </Text>
      <Box display="flex" pt={2}>
        {socialsArr.map(({ key, link, img, alt }) => (
          <Link key={key} href={link} target="_blank">
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              width={48}
              height={48}
              bgcolor={theme.primaryBackColorLight10}
              borderRadius="100%"
              mx="10px"
              id={`support-page-social-icon-${key}`}
            >
              <img src={img} alt={alt} width={24} height={24} />
            </Box>
          </Link>
        ))}
      </Box>
    </Box>
  );
};
