import React, { useEffect } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import { TextField } from 'edenred-ui';

import { getTranslatedCountries } from '@epi-selectors/api';
import { loadCountries } from '@epi-actions/api';
import { createRules, FormFields } from '@epi-forms/helpers';
import {
  required as requiredValidation,
  requiredCountry
} from '@epi-helpers/formHelpers/validators';
import { CountryFieldProps } from '@epi-models/forms/Controls';
function CountryField({
  disabled = false,
  countries,
  readOnly = false,
  name = FormFields.Country,
  required = true
}: CountryFieldProps) {
  const dispatch = useDispatch();
  const { control, unregister } = useFormContext();
  const { t } = useTranslation();
  const validators = [requiredValidation, requiredCountry];
  const rules = disabled || readOnly ? undefined : createRules(validators);

  const {
    field: { ref, value, onChange: setValue },
    fieldState: { error }
  } = useController({
    name,
    control,
    rules
  });

  useEffect(() => {
    dispatch(loadCountries());
  }, []);

  useEffect(() => {
    if (readOnly) {
      unregister(name);
    }
  }, [readOnly]);

  return (
    <TextField
      label={t('controls.country_label')}
      select
      required={required}
      value={value}
      disabled={disabled}
      readOnly={readOnly}
      id={name}
      error={!!error}
      helperText={error?.message}
      inputRef={ref}
      onChange={setValue}
      sx={{ '& .MuiSelect-select:focus': { backgroundColor: 'transparent' } }}
    >
      {(countries.length > 0 ? countries : [{ Code: value, Name: '' }]).map(
        country => (
          <TextField.Item key={country.Code} value={country.Code}>
            {country.Name}
          </TextField.Item>
        )
      )}
    </TextField>
  );
}

const mapStateToProps = state => ({
  countries: getTranslatedCountries(state)
});

const ConnectedCountryField = connect(mapStateToProps)(CountryField);

export { ConnectedCountryField as CountryField };
