import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getTopupEmployeesRequest } from '@epi-api/topupLoad';
import { clearFee, setFeePercentage } from '@epi-actions/api';
import { employeesPerPage } from '@epi-constants/topupEmployees';
import { BenefitType, ON_HOLD } from '@epi-constants/benefitTypes';
import { getCompanyMetadata } from '@epi-store/companyMetadata/companyMetadata.selectors';

import { useSortEmployees } from './useSortEmployees';

export const useTopupEmployees = (
  page,
  benefitType,
  showTable,
  setShowTable
) => {
  const dispatch = useDispatch();
  const [checkableEmployees, setCheckableEmployees] = useState([]);
  const [employeesToTopup, setEmployeesToTopup] = useState([]);
  const [employeesOnHold, setEmployeesOnHold] = useState([]);
  const [visibleEmployees, setVisibileEmployees] = useState([]);
  const [isToggle, setIsToggle] = useState(true);
  const [forceUpdate, setForceUpdate] = useState(false);
  const [togglerFromBE, setTogglerFromBE] = useState(true);
  const companyMetadata = useSelector(getCompanyMetadata);

  const checkedCount = checkableEmployees.filter(e => e.isChecked).length;
  const lowestAvailable =
    showTable && employeesToTopup.length
      ? Math.min(
          ...employeesToTopup.map(employee => employee.availableToTopUpAmount)
        )
      : Math.min(
          ...checkableEmployees
            .filter(employee => employee.availableToTopUpAmount > 0)
            .map(employee => employee.availableToTopUpAmount)
        );

  const topupBeneficiariesIds =
    showTable && employeesToTopup.length
      ? employeesToTopup.map(employee => employee.beneficiaryId)
      : checkableEmployees
          .filter(employee => employee.availableToTopUpAmount > 0)
          .filter(
            employee =>
              employee.employmentType !== ON_HOLD || employee.isChecked
          )
          .map(employee => employee.beneficiaryId);
  const employeesCount = topupBeneficiariesIds.length;

  const isOnHoldEmployee = !!employeesOnHold.find(
    employee => employee.employmentType === ON_HOLD
  );

  const isAllEmployeeChecked = !checkableEmployees.find(
    emp => !emp.isChecked && !emp.disabled
  );

  const toggleEmployee = employee => {
    setCheckableEmployees(
      checkableEmployees.map(emp =>
        emp.beneficiaryId === employee.beneficiaryId
          ? { ...employee, isChecked: !employee.isChecked }
          : emp
      )
    );
  };

  const toggleAllEmployee = isChecked => {
    setCheckableEmployees(prevState =>
      prevState.map(emp => ({
        ...emp,
        isChecked:
          !emp.disabled && emp.availableToTopUpAmount > 0 ? isChecked : false
      }))
    );
  };

  const loadEmployeesToTopup = async () => {
    const response = await getTopupEmployeesRequest(benefitType);
    dispatch(
      setFeePercentage(response.availableBenefitSettings[0]?.feePercentage)
    );
    setCheckableEmployees(
      response.beneficiaries.map(employee => {
        const isOnHold = employee.employmentType === ON_HOLD;
        const disabled =
          employee.availableToTopUpAmount <= 0 ||
          ((benefitType === BenefitType.Transport ||
            benefitType === BenefitType.Lunch) &&
            isOnHold);
        const isCheckedOnHold = isOnHold ? togglerFromBE : !disabled;

        return {
          ...employee,
          availableToTopUpAmount: employee.availableToTopUpAmount / 100,
          isChecked: disabled ? false : isCheckedOnHold,
          onHold: isOnHold,
          disabled
        };
      })
    );
  };

  const { handleSort, descending, sortBy } = useSortEmployees(
    checkableEmployees,
    setCheckableEmployees
  );

  const paginationCount = Math.ceil(
    checkableEmployees.length / employeesPerPage
  );

  const handleToggler = () => {
    setCheckableEmployees(prevState =>
      prevState.map(elem =>
        elem.onHold ? { ...elem, isChecked: !isToggle } : { ...elem }
      )
    );

    setIsToggle(prevState => !prevState);
  };

  useEffect(() => {
    setEmployeesToTopup(
      checkableEmployees.filter(employee => employee.isChecked)
    );
  }, [checkableEmployees]);

  useEffect(() => {
    setVisibileEmployees(
      checkableEmployees.slice(
        page * employeesPerPage - employeesPerPage,
        page * employeesPerPage
      )
    );
  }, [checkableEmployees, page]);

  useEffect(() => {
    return () => dispatch(clearFee());
  }, []);

  useEffect(() => {
    if (checkableEmployees.find(emp => emp.onHold)) {
      setEmployeesOnHold(
        checkableEmployees.filter(employee => employee.onHold && employee)
      );
    }
  }, [checkableEmployees]);

  useEffect(() => {
    const atLastOneChecked = employeesOnHold.filter(emp => emp.isChecked);
    if (employeesOnHold.length) {
      if (atLastOneChecked.length) {
        setIsToggle(true);
      } else {
        setIsToggle(false);
      }
    } else {
      setIsToggle(false);
    }
  }, [employeesOnHold]);

  useEffect(() => {
    if (companyMetadata?.data) {
      setIsToggle(companyMetadata.data?.showOnHoldBeneficiaries);
      setTogglerFromBE(companyMetadata.data?.showOnHoldBeneficiaries);
    }
  }, [companyMetadata]);

  useEffect(() => {
    if (benefitType) {
      setForceUpdate(false);
      loadEmployeesToTopup();
    } else {
      setCheckableEmployees([]);
      setEmployeesOnHold([]);
      setShowTable(false);
    }
  }, [benefitType, forceUpdate]);

  return {
    paginationCount,
    toggleEmployee,
    visibleEmployees,
    handleSort,
    descending,
    sortBy,
    checkedCount,
    employeesPerPage,
    employeesToTopup,
    employeesCount,
    lowestAvailable,
    topupBeneficiariesIds,
    isToggle,
    handleToggler,
    toggleAllEmployee,
    isOnHoldEmployee,
    isAllEmployeeChecked,
    setForceUpdate
  };
};
