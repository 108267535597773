import { createSelector } from 'reselect';
import { orderBy } from 'lodash';

import { getContacts } from './api';

export const getShowModal = state => state.apiCompany.showModal;

export const getShowSlideout = state => state.companyInfo.showSlideout;

export const getSlideoutEditMode = state => state.companyInfo.slideoutEditMode;

export const getEditMode = state => state.companyInfo.editMode;

export const getLastCompanyInformation = state =>
  state.apiCompany.lastCompanyInformation;

export const getContactsOrder = state => state.companyInfo.contactsOrder;

export const getOrderedContacts = createSelector(
  getContacts,
  getContactsOrder,
  (contacts, order) => {
    if (!order) {
      return contacts;
    }
    const sortedContacts = orderBy(
      contacts,
      item => `${item[order.sortingBy]}`.toLowerCase(),
      order.ascending ? 'asc' : 'desc'
    );
    return sortedContacts;
  }
);
