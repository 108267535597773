import React, { useEffect } from 'react';
import { useFormContext, useController } from 'react-hook-form';
import { Box, InputAdornment, TextField } from 'edenred-ui';
import { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';

import { createRules, FormFields } from '@epi-forms/helpers';
import {
  numberBetween,
  number,
  required as requiredValidation
} from '@epi-helpers/formHelpers/validators';
import { IContractPercentageField } from '@epi-models/pages/Beneficiaries';
import { EmployeeTypeModes } from '@epi-constants/beneficiary';

export const ContractPercentageField = ({
  disabled = false,
  isAddMode = false,
  isPartTime = false
}: IContractPercentageField) => {
  const { control, getValues, watch, setValue, trigger } = useFormContext();
  const { sizeMedium } = useTheme();
  const { t } = useTranslation();
  const betweenNumber = isPartTime
    ? numberBetween(1, 99)
    : numberBetween(1, 100);
  const rules = createRules([requiredValidation, betweenNumber, number]);
  const name = FormFields.EmployeePercentage;
  const isEditMode = getValues(FormFields.EditMode);
  const employmentType = watch(FormFields.EmployeeType);
  const wasTypeFieldChange = watch(FormFields.EditingType);

  const {
    field: { onChange, value },
    fieldState: { error }
  } = useController({
    name,
    control,
    rules
  });

  const setValues = () => {
    if (employmentType === EmployeeTypeModes.FullTime) return '100';
    if (
      employmentType === EmployeeTypeModes.Onhold ||
      employmentType === EmployeeTypeModes.Dropped
    )
      return value;
    if (value) return value;
    if (isAddMode || isEditMode) return '';
    return '100';
  };

  useEffect(() => {
    if (employmentType === EmployeeTypeModes.FullTime)
      return setValue(name, '100');
    if (
      employmentType === EmployeeTypeModes.Onhold ||
      employmentType === EmployeeTypeModes.Dropped
    )
      return setValue(name, value);
    if (
      employmentType === EmployeeTypeModes.PartTime ||
      employmentType === EmployeeTypeModes.TemporaryEmployed
    ) {
      if (wasTypeFieldChange) {
        setValue(name, '');
        trigger(name, { shouldFocus: true });
      } else {
        setValue(name, value);
      }
      return;
    }
    return setValue(name, '');
  }, [employmentType]);

  return (
    <TextField
      disabled={disabled}
      id={name}
      label={t('containers.beneficiaries.detail.working_hours_as_percentage')}
      value={setValues()}
      onChange={onChange}
      readOnly={false}
      error={!!error}
      helperText={error?.message}
      required
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <Box fontSize={sizeMedium}>%</Box>
          </InputAdornment>
        )
      }}
    />
  );
};
