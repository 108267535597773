import React from 'react';
import { Col } from 'react-bootstrap';
import { PagePanel } from 'edenred-ui';

import { QuantityProps } from '@epi-models/components/Quantity';

import { VoucherQuantity } from '../../forms/VoucherQuantity/VoucherQuantity';
import { BenefitSummaryContent } from '../BenefitSummaryContent/BenefitSummaryContent';

export function Quantity({
  voucherTypeKey,
  vouchers,
  orderSummary,
  useCalculator = false,
  locale,
  header,
  maxOrderTotalAmount,
  voucherMinimumQuantity
}: QuantityProps) {
  return (
    <div className="page1">
      <PagePanel title={header}>
        <Col md={12} className="order-page-voucher-quantity">
          <VoucherQuantity
            voucherTypeKey={voucherTypeKey}
            vouchers={vouchers}
            useCalculator={useCalculator}
            locale={locale}
            maxOrderTotalAmount={maxOrderTotalAmount}
            voucherMinimumQuantity={voucherMinimumQuantity}
          />
        </Col>
        <Col md={12}>
          <hr />
        </Col>
        <Col md={12} className="mb-10 order-page-benefit-summary">
          <BenefitSummaryContent
            voucherTypeKey={voucherTypeKey}
            orderSummary={orderSummary}
          />
        </Col>
      </PagePanel>
    </div>
  );
}
