import React from 'react';
import { Box, Link } from 'edenred-ui';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

export const FloatingBorder = styled.div`
  position: relative;
  width: 32px;
  margin-right: 32px;
  border-right: ${({ theme }) => `1px solid ${theme.altGray1}`};
`;

export const SupportTileLink = () => {
  const { t } = useTranslation();

  return (
    <Box display="flex">
      <Link
        target="_blank"
        href={t('home.support.guides.employer_guide.link_url')}
        key={t('home.support.guides.title')}
        isSizeMedium
        isFontRegularBold
        id="home-support-link-guide-employer"
      >
        {t('home.support.guides.employer_guide.link_text')}
      </Link>
      <FloatingBorder />
      <Link
        target="_blank"
        href={t('home.support.guides.beneficairy_guide.link_url')}
        key={t('home.support.help.title')}
        isSizeMedium
        isFontRegularBold
        id="home-support-link-guide-beneficiary"
      >
        {t('home.support.guides.beneficairy_guide.link_text')}
      </Link>
    </Box>
  );
};
