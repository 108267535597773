import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  PagePanel,
  Tabs,
  Button,
  ButtonMode,
  IPagePanelFooter
} from 'edenred-ui';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { openSlideout, enableSlideoutEditMode } from '@epi-actions/company';
import { ICompanyInfo } from '@epi-models/pages/CompanyInfo';
import { useMenuDimensions } from '@epi-shared/hooks';

import { CompanyData } from './components/CompanyData';
import { ContactsData } from './components/ContactsData';

const AddContactButton = styled(Button)`
  visibility: ${props => (props.isContactsTabActive ? 'visible' : 'hidden')};
`;

function CompanyInfo({ autoRedirect = false }: ICompanyInfo) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isMobile } = useMenuDimensions();
  const [isContactsTabActive, setIsContactsTabActive] = useState(false);
  const [footer, setFooter] = useState<IPagePanelFooter | undefined>(undefined);

  const updateFooter = (newFooter: IPagePanelFooter) => {
    setFooter(newFooter);
  };

  const handleButtonVisibility = (tabName: string) => {
    setIsContactsTabActive(tabName === 'contactsDataTab');
  };

  const handleOpenSlideout = () => {
    dispatch(openSlideout());
    dispatch(enableSlideoutEditMode());
  };

  const tabs = [
    {
      name: 'companyDataTab',
      label: t('company_info.company_info_tab'),
      id: 'tab-company-info',
      body: (
        <CompanyData autoRedirect={autoRedirect} updateFooter={updateFooter} />
      )
    },
    {
      name: 'contactsDataTab',
      label: t('company_info.contact_persons_tab'),
      id: 'tab-contact-persons',
      body: <ContactsData updateFooter={updateFooter} />
    }
  ];

  return (
    <PagePanel
      title={t('company_info.title')}
      footer={footer}
      headerContent={
        !isMobile && (
          <AddContactButton
            isContactsTabActive={isContactsTabActive}
            mode={ButtonMode.Secondary}
            onClick={handleOpenSlideout}
            id="company-info_add-contact"
          >
            {t('company_info.add_contact')}
          </AddContactButton>
        )
      }
    >
      <Tabs tabs={tabs} onTabChange={handleButtonVisibility} />
    </PagePanel>
  );
}

export default CompanyInfo;
