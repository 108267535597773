import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Box } from 'edenred-ui';
import { useFormContext } from 'react-hook-form';

import {
  FirstNameField,
  LastNameField,
  EmailAddressField
} from '@epi-forms/controls';
import {
  DaysForLunariField,
  EntitledDataField,
  PersonalDataField,
  ContractPercentageField
} from '@epi-pages/Beneficiaries/components/BeneficiaryDetail/components';
import { TerminationDate } from '@epi-pages/Beneficiaries/components/TerminationDate';
import { FormFields } from '@epi-forms/helpers';
import {
  maxLength,
  socialSecurityNumber,
  required as requiredValidation
} from '@epi-helpers/formHelpers/validators';
import { EmployeeTypeDataField } from '@epi-pages/Beneficiaries/components/BeneficiaryDetail/components/EmployeeTypeDataField';
import { EmployeeTypeModes } from '@epi-constants/beneficiary';

export const AddPersonalData = () => {
  const { t } = useTranslation();
  const { watch } = useFormContext();
  const employeeType = watch(FormFields.EmployeeType);
  const EoB = watch(FormFields.EndOfBenefit);
  const isPartTimeOrTemporaryType =
    employeeType === EmployeeTypeModes.PartTime ||
    employeeType === EmployeeTypeModes.TemporaryEmployed;
  const isOnHold = employeeType === EmployeeTypeModes.Onhold;
  const isPartTime = employeeType === EmployeeTypeModes.PartTime;
  const isLunchBenefitActive = watch(FormFields.LunchBenefitActive);
  const minHeight = 1000 + (isLunchBenefitActive ? 150 : 0) + (EoB ? 150 : 0);

  return (
    <Box
      px={10}
      pt={5}
      gap={3}
      display="flex"
      flexDirection="column"
      minHeight={minHeight}
      id="employee-benefit-add-personal-data-tab"
    >
      <FirstNameField />
      <LastNameField />
      <PersonalDataField
        label={t('containers.beneficiaries.detail.personal_id')}
        name={FormFields.PersonalID}
        validate={[requiredValidation, maxLength(50), socialSecurityNumber]}
        isAddMode
        required
      />
      <EmailAddressField label={t('containers.beneficiaries.table.email')} />
      <Box fontSize={12} fontWeight={400}>
        <EmployeeTypeDataField isAddMode />
        {isOnHold && (
          <Box id="add_beneficiary_employee_type_subtext">
            {t('containers.beneficiaries.detail.employee_type_subtext')}
          </Box>
        )}
      </Box>
      <Box fontSize={12} fontWeight={400}>
        <ContractPercentageField
          disabled={!isPartTimeOrTemporaryType}
          isPartTime={isPartTime}
          isAddMode
        />
        <Box id="add_beneficiary_working_hours_subtext">
          {t('containers.beneficiaries.detail.working_hours_subtext')}
        </Box>
      </Box>
      {isLunchBenefitActive && (
        <Box fontSize={12} fontWeight={400}>
          <DaysForLunariField isAddMode />
          <Box id="add_beneficiary_lunch_benefit_per_month_subtext">
            <Trans
              defaults={t(
                'containers.beneficiaries.detail.lunch_benefit_per_month_subtext'
              )}
              components={{
                b: <b />
              }}
            />
          </Box>
        </Box>
      )}
      <PersonalDataField
        label={t('containers.beneficiaries.detail.employee_number')}
        name={FormFields.EmployeeNumber}
        validate={[maxLength(20)]}
        isAddMode
      />
      <PersonalDataField
        label={t('containers.beneficiaries.detail.cost_center_number')}
        name={FormFields.CostCenterNumber}
        validate={[maxLength(50)]}
        isAddMode
      />

      <EntitledDataField isAddMode />
      <TerminationDate showTerminationButton />
    </Box>
  );
};
