import React from 'react';
import { useFormContext, useController } from 'react-hook-form';
import { Button, ButtonMode, CardIcon, TextField } from 'edenred-ui';
import { useTranslation } from 'react-i18next';

import { createRules, FormFields } from '@epi-forms/helpers';
import { useModal } from '@epi-shared/hooks';
import { required as requiredValidation } from '@epi-helpers/formHelpers/validators';
import { IEntitledDataField } from '@epi-models/pages/Beneficiaries';

import { EntitledDataDialog } from '../EntitledDataDialog';

const name = FormFields.Entitled;

export const EntitledDataField = ({
  isAddMode = false,
  isRemoved = false
}: IEntitledDataField) => {
  const { t } = useTranslation();
  const { control } = useFormContext();
  const { isModalOpen, openModal, closeModal } = useModal();
  const validators = [requiredValidation];
  const rules = createRules(validators);

  const {
    field: { onChange, value },
    fieldState: { error }
  } = useController({
    name,
    control,
    rules
  });

  const {
    field: { value: isEditMode }
  } = useController({
    name: FormFields.EditMode,
    control
  });

  const setValues = () => {
    if (value) return value;
    if (isAddMode || isEditMode) return '';
    return 'None';
  };

  return (
    <>
      <TextField
        id={name}
        label={t('download_reports.lunch_deduction.list.entitled')}
        value={setValues()}
        onChange={onChange}
        readOnly
        required
        error={!!error}
        helperText={error?.message}
        InputProps={
          !isRemoved
            ? {
                endAdornment: (
                  <Button
                    id="beneficiaries-detail-entitled-date-open-modal-button"
                    mode={ButtonMode.Link}
                    onClick={openModal}
                  >
                    <CardIcon />
                  </Button>
                )
              }
            : undefined
        }
      />
      <EntitledDataDialog isOpen={isModalOpen} handleClose={closeModal} />
    </>
  );
};
