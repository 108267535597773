import React, { useState } from 'react';
import { useFormContext, useController } from 'react-hook-form';

import { FormFields } from '@epi-forms/helpers';
import { dateToApiFormat } from '@epi-helpers/dateHelper';
import { disableSaveButtonForBeneficiariesDataModal } from '@epi-helpers/BeneficiaryHelper';

export const useEntitledDataDialog = (handleClose: () => void) => {
  const { control } = useFormContext();
  const [date, setDate] = useState<null | Date>(null);

  const {
    field: { onChange, value: dayValue }
  } = useController({
    name: FormFields.Entitled,
    control
  });

  const handleSave = () => {
    onChange(dateToApiFormat(date));
    handleClose();
  };

  const handleSetDate = (value: Date | null) => setDate(value);
  const disableSave = disableSaveButtonForBeneficiariesDataModal(date, true);
  const isError = date === null ? false : !date || disableSave;

  return {
    handleSetDate,
    disableSave,
    isError,
    handleSave,
    dayValue
  };
};
