import React from 'react';
import { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import {
  Box,
  TransferIcon,
  Button,
  ButtonMode,
  ChevronRightIcon
} from 'edenred-ui';

import { getMainAvailableBalance } from '@epi-selectors/companyAccounts';
import { formatCurrency } from '@epi-helpers/numeral';
import { goToPage } from '@epi-actions/navigation';
import { setGoToPageLink } from '@epi-actions/beneficiary';
import { routerPaths } from '@epi-constants/routerPaths';
import { selectIsOrderInProcess } from '@epi-selectors/benefitGroupSettings';

export const BalanceTile = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const amount = useSelector(getMainAvailableBalance);
  const isOrderInProgress = useSelector(selectIsOrderInProcess);
  const dispatch = useDispatch();

  const goToTransferMoney = () => {
    if (isOrderInProgress) {
      dispatch(setGoToPageLink(routerPaths.loadCompanyAccount));
    } else {
      dispatch(goToPage(routerPaths.loadCompanyAccount));
    }
  };

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      height={120}
      p={3}
      bgcolor={theme.primaryFrontColor}
      minWidth={800}
      id="home-balance"
    >
      <Box
        display="flex"
        alignItems="center"
        color={theme.gray0}
        fontSize={28}
        id="home-balance-amount"
      >
        <Box fontWeight={theme.fontRegularBold} mr={1}>
          {t('home.account.balance_title')}
        </Box>
        <Box fontWeight={theme.fontSemiBold}>{formatCurrency(amount)}</Box>
      </Box>

      <Button
        id="go-to-transfer-money-btn"
        minWidth={355}
        mode={ButtonMode.Block}
        onClick={goToTransferMoney}
      >
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box display="flex" alignItems="center">
            <TransferIcon />
            <Box ml={2}>{t('home.account.balance_go_to_transfer_money')}</Box>
          </Box>
          <ChevronRightIcon />
        </Box>
      </Button>
    </Box>
  );
};
