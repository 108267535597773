import React from 'react';
import {
  Box,
  Notification,
  NotificationType,
  Button,
  ButtonMode,
  ButtonSize
} from 'edenred-ui';

import { IInfoNotification } from '@epi-models/containers/ManageBenefitGroups';

export const InfoNotification = ({
  text,
  onClose,
  handleFirstBtn,
  textForFirstBtn,
  handleSecondBtn,
  textForSecondBtn,
  textBetweenBtns
}: IInfoNotification) => (
  <Box mb={5}>
    <Notification
      type={NotificationType.Info}
      text={
        <Box display="flex" alignItems="center">
          <Box flexGrow={1} mr={1}>
            {text}
          </Box>
          <Box mr={1}>
            <Button
              id="go-to-beneficiaries-btn"
              mode={ButtonMode.Link}
              size={ButtonSize.Medium}
              onClick={handleFirstBtn}
            >
              {textForFirstBtn}
            </Button>
          </Box>
          <Box flexGrow={1} mr={1}>
            {textBetweenBtns}
          </Box>
          {handleSecondBtn && (
            <Box>
              <Button
                id="go-to-beneficiaries-btn"
                mode={ButtonMode.Link}
                size={ButtonSize.Medium}
                onClick={handleSecondBtn}
              >
                {textForSecondBtn}
              </Button>
            </Box>
          )}
        </Box>
      }
      onClose={onClose}
    />
  </Box>
);
