import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ButtonMode,
  SlideoutPanelComponent as Slideout,
  Box,
  SimpleButton,
  DeleteIcon,
  EditIcon
} from 'edenred-ui';
import { useFormContext } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import {
  FirstNameField,
  LastNameField,
  EmailAddressField,
  PhoneNumberField
} from '@epi-forms/controls';
import { isEmptyObj } from '@epi-helpers/isEmptyObj';
import { fetchPost as Post, fetchPut as Put } from '@epi-helpers/FetchHelpers';
import { contactsApiUrl } from '@epi-repositories/ContactsRepository';
import { showNotification } from '@epi-actions/overlays';
import { getSlideoutEditMode, getEditMode } from '@epi-selectors/company';
import { loadContacts } from '@epi-actions/api';
import {
  enableSlideoutEditMode,
  enableEditMode,
  disableEditMode
} from '@epi-actions/company';
import { useMenuDimensions } from '@epi-shared/hooks';
import { IContactPersonSlideout } from '@epi-models/pages/CompanyInfo';

import {
  FieldContainer,
  ButtonsContainer,
  StyledButton
} from './ContactPersonSlideoutStyles';

export function ContactPersonSlideout({
  isSlideoutExpanded,
  closeSlideout,
  handleDeleteContact
}: IContactPersonSlideout) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isMobile } = useMenuDimensions();
  const editMode = useSelector(getSlideoutEditMode);
  const [isAddContactProcessing, setIsAddContactProcessing] = useState(false);
  const isEditMode = useSelector(getEditMode);

  const {
    trigger,
    getValues,
    formState: { isValid, errors },
    reset
  } = useFormContext();
  const isActiveContact = getValues('personId');

  const saveContactPerson = () => {
    setIsAddContactProcessing(true);

    const onSuccess = () => {
      dispatch(
        showNotification(
          { message: 'messages.company_info_updated' },
          'success'
        )
      );
      closeSlideout();
      reset();
      dispatch(loadContacts());
      setIsAddContactProcessing(false);
    };
    const onFailure = () => {
      dispatch(showNotification({ message: 'messages.error' }, 'error'));
      setIsAddContactProcessing(false);
    };
    if (isActiveContact)
      return Put(contactsApiUrl, getValues(), onSuccess, onFailure);
    return Post(contactsApiUrl, getValues(), onSuccess, onFailure);
  };

  const getSlideoutTitle = () => {
    if (editMode && isActiveContact) return t('company_info.edit_contact');
    if (editMode) return t('company_info.add_contact');
    return t('company_info.preview_contact');
  };

  const handleDeleteAction = () => {
    const values = getValues();
    handleDeleteContact({
      firstName: values.firstName,
      lastName: values.lastName,
      userName: values.userName,
      personId: values.personId,
      dataContactId: values.dataContactId,
      phoneNumber: values.phoneNumber,
      emailAddress: values.emailAddress,
      active: isActiveContact
    });
  };

  const handleCloseSlideout = () => {
    dispatch(disableEditMode());
    closeSlideout();
  };

  const enableEdit = () => {
    dispatch(enableSlideoutEditMode());
    dispatch(enableEditMode());
  };

  useEffect(() => {
    if (isEditMode) {
      trigger();
    }
  }, [isEditMode]);

  return (
    <Slideout
      width={isMobile ? '100vw' : '668px'}
      title={getSlideoutTitle()}
      expanded={isSlideoutExpanded}
      onClose={handleCloseSlideout}
      actions={
        editMode && (
          <ButtonsContainer>
            <StyledButton
              id="contact-person-close-btn"
              mode={ButtonMode.Link}
              onClick={handleCloseSlideout}
            >
              {t('company_info.back_btn')}
            </StyledButton>
            <StyledButton
              id="contact-person-save-btn"
              disabled={
                !isValid || !isEmptyObj(errors) || isAddContactProcessing
              }
              onClick={saveContactPerson}
            >
              {t('company_info.save_btn')}
            </StyledButton>
          </ButtonsContainer>
        )
      }
    >
      <Box
        py={isMobile ? '0' : '30px'}
        px={isMobile ? '0' : '60px'}
        display="flex"
        flexDirection={isMobile ? 'column-reverse' : 'row'}
      >
        <Box
          flex="1"
          sx={{
            '& input.Mui-disabled': {
              '-webkit-text-fill-color': '#252525'
            }
          }}
        >
          <FieldContainer>
            <FirstNameField readOnly={!editMode} />
          </FieldContainer>
          <FieldContainer>
            <LastNameField readOnly={!editMode} />
          </FieldContainer>
          <FieldContainer>
            <EmailAddressField
              readOnly={!editMode}
              label={t('controls.email_address_label')}
            />
          </FieldContainer>
          <FieldContainer>
            <PhoneNumberField readOnly={!editMode} />
          </FieldContainer>
        </Box>
        {!editMode && (
          <Box display={isMobile ? 'flex' : ''} justifyContent="end">
            <Box
              display="flex"
              width="185px"
              justifyContent={isActiveContact ? 'space-between' : 'end'}
            >
              <SimpleButton
                onClick={handleDeleteAction}
                icon={<DeleteIcon />}
                text={t('company_info.contacts.actions.delete')}
              />
              {isActiveContact && (
                <SimpleButton
                  onClick={enableEdit}
                  icon={<EditIcon />}
                  text={t('company_info.contacts.actions.edit')}
                />
              )}
            </Box>
          </Box>
        )}
      </Box>
    </Slideout>
  );
}
