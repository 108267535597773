import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { Box, Button, ButtonMode, IconTooltip, InfoIcon } from 'edenred-ui';

import { FormFields } from '@epi-forms/helpers';
import { MoneyAmountField } from '@epi-forms/controls/MoneyAmountField/MoneyAmountField';
import { BenefitSelect } from '@epi-forms/controls/BenefitSelect/BenefitSelect';
import { SortingIcon } from '@epi-shared/components';
import { BenefitType } from '@epi-constants/benefitTypes';
import {
  useTopupRequest,
  useTopupEmployees
} from '@epi-pages/TransferPage/hooks';
import { getNumber, formatCurrencyDecimalConvert } from '@epi-helpers/numeral';
import {
  required,
  yearlyLimit,
  minAmountMoreAccurate
} from '@epi-helpers/formHelpers/validators';
import {
  selectActiveBenefits,
  getBenefitSelected
} from '@epi-selectors/benefitGroupSettings';

import { SimpleFormStep } from '../SimpleFormStep';
import { TopupSummary } from '../TopupSummary/TopupSummary';
import { useTopupSummary } from '../TopupSummary/useTopupSummary';
import { FormStepStyles } from '../FormStep/FormStep.styles';
import { FormStep, FormStepMode } from '../FormStep';
import { FormStepLabel } from '../FormStepLabel';
import { Switch } from './Switch';

import { EmployeesTable } from './EmployeesTable';
import { TopupInProgress } from './TopupInProgress';
import { TopupConfirmationModal } from './TopupConfirmationModal';

const StepField = styled.div`
  width: 200px;
`;

const FieldInfo = styled.span`
  margin-top: 4px;

  font-size: ${props => props.theme.sizeExtraSmall};
  color: ${props => props.theme.gray85};
`;

export const TopupForm = ({ updateFooter }) => {
  const { t } = useTranslation();
  const [showTable, setShowTable] = useState(false);
  const [showOnHoldBtn, setShowOnHoldBtn] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [page, setPage] = useState(1);
  const [showTopupForm, setShowTopupForm] = useState(true);

  const loadableBenefits = useSelector(selectActiveBenefits).filter(
    loadableBenefit => loadableBenefit !== BenefitType.Lunch
  );
  const form = useForm({
    mode: 'onChange',
    defaultValues: {
      [FormFields.MoneyAmount]: '',
      [FormFields.Benefit]: ''
    }
  });
  const {
    watch,
    handleSubmit,
    setValue,
    resetField,
    formState: { isValid, errors }
  } = form;
  const benefitType = watch(FormFields.Benefit);
  const topupPerPerson = watch(FormFields.MoneyAmount);
  const benefitSettings = useSelector(state =>
    getBenefitSelected(state, benefitType)
  );
  const {
    paginationCount,
    toggleEmployee,
    visibleEmployees,
    handleSort,
    descending,
    sortBy,
    checkedCount,
    employeesPerPage,
    employeesToTopup,
    employeesCount,
    lowestAvailable,
    topupBeneficiariesIds,
    isOnHoldEmployee,
    isToggle,
    handleToggler,
    toggleAllEmployee,
    isAllEmployeeChecked,
    setForceUpdate
  } = useTopupEmployees(page, benefitType, showTable, setShowTable);
  const { postTopupRequest, isTopupPending, isTopupProcessing, summaryId } =
    useTopupRequest(setShowConfirmationModal, isToggle, showOnHoldBtn);
  const { remainingBalance, formattedTopupWithFees } = useTopupSummary(
    employeesCount,
    topupPerPerson,
    employeesToTopup,
    showTable
  );

  const onSubmit = () => setShowConfirmationModal(true);
  const handleCloseModal = () => setShowConfirmationModal(false);

  const handleTopupRequest = () => {
    const payload = {
      amount: (getNumber(topupPerPerson) * 100).toFixed(0),
      benefitType,
      beneficiariesIds: topupBeneficiariesIds
    };
    postTopupRequest(payload);
    resetField(FormFields.MoneyAmount);
    resetField(FormFields.Benefit);
  };

  const handleChangePage = newPage => {
    setPage(newPage);
  };

  useEffect(() => {
    if (loadableBenefits?.length === 1) {
      setValue(FormFields.Benefit, loadableBenefits[0]);
    }
  }, [summaryId]);

  useEffect(() => {
    if (isTopupProcessing) {
      setShowTopupForm(false);
    }
  }, [isTopupProcessing]);

  useEffect(() => {
    if (
      (benefitType === BenefitType.Massage ||
        benefitType === BenefitType.Wellbeing ||
        benefitType === BenefitType.Recreational) &&
      isOnHoldEmployee &&
      !!paginationCount
    ) {
      setShowOnHoldBtn(true);
    } else {
      setShowOnHoldBtn(false);
    }
  }, [benefitType, isOnHoldEmployee]);

  useEffect(() => {
    if (isOnHoldEmployee) {
      setShowTable(true);
    }
  }, [isOnHoldEmployee, visibleEmployees]);

  useEffect(() => {
    if (showTopupForm) {
      updateFooter({
        submitText: t('containers.deposit_money.topup_tab.footer_button'),
        onSubmit: handleSubmit(onSubmit),
        disableSubmit:
          !isValid || remainingBalance < 0 || !topupBeneficiariesIds.length
      });
    }
    if (!showTopupForm) {
      updateFooter(null);
    }
  });

  return (
    <FormProvider {...form} onSubmit={handleSubmit(onSubmit)}>
      {!showTopupForm ? (
        <TopupInProgress
          setShowTopupForm={setShowTopupForm}
          isTopupProcessing={isTopupProcessing}
          summaryId={summaryId}
          loadableBenefits={loadableBenefits}
          setForceUpdate={setForceUpdate}
        />
      ) : (
        <>
          <SimpleFormStep
            mode={FormStepMode.Outlined}
            stepNumber={1}
            stepTitle={t(
              'containers.deposit_money.topup_tab.form.benefit.title'
            )}
          >
            <StepField>
              <BenefitSelect options={loadableBenefits} />
              <FieldInfo id="deposit_money_topup_step_one_info">
                {t('containers.deposit_money.topup_tab.form.benefit.info')}
              </FieldInfo>
            </StepField>
          </SimpleFormStep>
          <SimpleFormStep
            id="deposit_money_topup_step_two"
            mode={FormStepMode.Contained}
            stepNumber={2}
            stepTitle={t(
              'containers.deposit_money.topup_tab.form.amount.title'
            )}
          >
            <StepField>
              <MoneyAmountField
                validators={[
                  required,
                  yearlyLimit(lowestAvailable),
                  minAmountMoreAccurate(1)
                ]}
                labelKey={
                  'containers.deposit_money.topup_tab.form.amount.input'
                }
              />
              {!errors[FormFields.MoneyAmount] && (
                <FieldInfo id="deposit_money_topup_step_two_info">
                  {t('containers.deposit_money.topup_tab.form.amount.max', {
                    maxTopup: formatCurrencyDecimalConvert(
                      benefitSettings?.maxAmountPerPeriod
                    )
                  })}
                </FieldInfo>
              )}
            </StepField>
          </SimpleFormStep>
          <FormStep mode={FormStepMode.Outlined} stepNumber={3}>
            <Box flex="1" width="100%">
              <FormStepStyles.Content>
                <Box
                  display="flex"
                  flexDirection="column"
                  alignSelf="stretch"
                  justifyContent="space-between"
                >
                  <FormStepLabel
                    mode={FormStepMode.Outlined}
                    stepNumber={3}
                    stepTitle={t(
                      'containers.deposit_money.topup_tab.form.employees.title',
                      {
                        employeesNumber: topupBeneficiariesIds.length
                      }
                    )}
                  >
                    {!!paginationCount && (
                      <Box
                        marginLeft="56px"
                        marginBottom="15px"
                        id="deposit_money_topup_step_three_show_table_button"
                      >
                        <Button
                          mode={ButtonMode.Link}
                          onClick={() => setShowTable(!showTable)}
                        >
                          <Box
                            display="flex"
                            alignItems={showTable ? 'inherit' : 'flex-end'}
                          >
                            {t(
                              'containers.deposit_money.topup_tab.form.employees.select_employees'
                            )}

                            <SortingIcon isAscending={showTable} />
                          </Box>
                        </Button>
                      </Box>
                    )}
                  </FormStepLabel>
                </Box>
                <TopupSummary
                  employeesCount={employeesCount}
                  employeesToTopup={employeesToTopup}
                  showTable={showTable}
                />
              </FormStepStyles.Content>
              {showOnHoldBtn && (
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  alignItems="center"
                  gap={2}
                  mt={7}
                >
                  <Switch
                    checked={isToggle}
                    onChange={handleToggler}
                    id="switch-topup-form"
                  />
                  <div id="switch-topup-form-text">
                    {t(
                      'containers.deposit_money.topup_tab.form.employees.on_hold_employees'
                    )}
                  </div>
                  <div id="switch-topup-form-tooltip">
                    <IconTooltip
                      title={t(
                        isToggle
                          ? 'containers.deposit_money.topup_tab.form.employees.notification_on'
                          : 'containers.deposit_money.topup_tab.form.employees.notification_off'
                      )}
                      placement="top"
                    >
                      <InfoIcon />
                    </IconTooltip>
                  </div>
                </Box>
              )}
              {showTable && (
                <EmployeesTable
                  toggleAllEmployee={toggleAllEmployee}
                  handleChangePage={handleChangePage}
                  page={page}
                  paginationCount={paginationCount}
                  toggleEmployee={toggleEmployee}
                  visibleEmployees={visibleEmployees}
                  employeesPerPage={employeesPerPage}
                  handleSort={handleSort}
                  descending={descending}
                  sortBy={sortBy}
                  checkedCount={checkedCount}
                  topupPerPerson={topupPerPerson}
                  isAllEmployeeChecked={isAllEmployeeChecked}
                />
              )}
            </Box>
          </FormStep>
          <TopupConfirmationModal
            showModal={showConfirmationModal}
            employeesCount={employeesCount}
            handleCloseModal={handleCloseModal}
            handleTopupRequest={handleTopupRequest}
            totalAmount={formattedTopupWithFees}
            isTopupPending={isTopupPending}
            benefitType={benefitType}
            showOnHoldText={isToggle}
          />
        </>
      )}
    </FormProvider>
  );
};

TopupForm.propTypes = {
  updateFooter: PropTypes.func.isRequired
};
