import React from 'react';
import PropTypes from 'prop-types';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormControlLabel, Radio, RadioGroup } from 'edenred-ui';

import { createRules, FormFields, InvoicingMethod } from '@epi-forms/helpers';
import { required } from '@epi-helpers/formHelpers/validators';
import { InvoicingMethodRadioProps } from '@epi-models/forms/Controls';

export function InvoicingMethodRadio({
  readOnly = false,
  onChange
}: InvoicingMethodRadioProps) {
  const name = FormFields.InvoicingMethod;
  const { control } = useFormContext();
  const { t } = useTranslation();
  const validators = [required];
  const rules = createRules(validators);

  const {
    field: { ref, value, onChange: setValue }
  } = useController({
    name,
    control,
    rules
  });

  const onRadioChange = event => {
    setValue(event);

    if (onChange) {
      onChange(event.currentTarget.value);
    }
  };

  return (
    <RadioGroup ref={ref} value={value} onChange={onRadioChange} id={name}>
      <FormControlLabel
        value={InvoicingMethod.Email}
        control={<Radio />}
        label={t('controls.email_radio_label')}
        readOnly={readOnly}
        id="invoicing-method_email"
      />
      <FormControlLabel
        value={InvoicingMethod.EInvoice}
        control={<Radio />}
        label={t('controls.einvoice_radio_label')}
        readOnly={readOnly}
        id="invoicing-method_einvoice"
      />
      <FormControlLabel
        value={InvoicingMethod.Paper}
        control={<Radio />}
        label={t('controls.paper_radio_label')}
        readOnly={readOnly}
        id="invoicing-method_paper"
      />
    </RadioGroup>
  );
}
