import memoize from 'memoize-one';
import { createSelector } from 'reselect';
import { get } from 'lodash';
import { PaymentMethods } from '../constants/paymentMethods';
import { getOrderFees } from './api';
import { getCreditCardPercentFee } from './settings';

export const isSubmittingForm = state => state.voucherOrder.isSubmittingForm;

export const isUsernameAvailabilityLoading = state =>
  state.usernameAvailability.isLoading;

export const getVoucher = voucherId => state =>
  (get(state, 'apiVouchers.vouchers') || []).find(v => v.id === voucherId);

export const getHandlingFee = (sumVouchers, type) =>
  createSelector(
    state => (getOrderFees(state) || {}).voucherOrderFees || [],
    voucherOrderFees => {
      const handlingFeesItem = voucherOrderFees.find(
        fee =>
          fee.minValue <= sumVouchers &&
          sumVouchers <= fee.maxValue &&
          fee.type === type
      );
      if (handlingFeesItem) {
        return handlingFeesItem.fee || null;
      }
      const maxFee =
        voucherOrderFees.length > 0 &&
        voucherOrderFees
          .filter(fee => fee.type === type)
          .reduce((p, v) => (p.maxValue > v.maxValue ? p : v));

      return (maxFee || {}).fee || null;
    }
  );

export const getOrderData = (quantity, voucherId) =>
  createSelector(
    state => state,
    state => {
      const faceValue = (getVoucher(voucherId)(state) || {}).value || 0;
      return { faceValue, quantity: quantity || 0, voucherId };
    }
  );

export const commonOrderSummary = (paymentMethod, voucherQuantity, voucherId) =>
  createSelector(
    () => paymentMethod === PaymentMethods.CreditCard,
    getCreditCardPercentFee,
    getOrderFees,
    state => state,
    (isCreditCardPayment, creditCardFeePercent, orderFees, state) => {
      const sumVouchers = voucherQuantity * voucherId || 0;
      const handlingFee = getHandlingFee(sumVouchers, 'with_reference')(state);
      const creditCardFee = isCreditCardPayment
        ? ((sumVouchers + handlingFee) * creditCardFeePercent) / 100
        : 0;

      const { vatFeePercent } = orderFees || {};
      const vatFeeAmount = (handlingFee * vatFeePercent) / 100 || 0;
      const orderTotalWithoutVat = sumVouchers + handlingFee + creditCardFee;
      const totalAmountWithFees = orderTotalWithoutVat + vatFeeAmount;
      return {
        quantity: voucherQuantity,
        faceValue: voucherId,
        sumVouchers,
        handlingFee,
        creditCardFee,
        creditCardFeePercent: creditCardFee,
        vatFeePercent,
        vatFeeAmount,
        orderTotalWithoutVat,
        totalAmountWithFees
      };
    }
  );

export const orderSummary = memoize(
  (paymentMethod, voucherQuantity, voucherId) =>
    commonOrderSummary(paymentMethod, voucherQuantity, voucherId)
);
