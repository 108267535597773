import React from 'react';
import PropTypes from 'prop-types';

import { FormStepMode } from '../FormStep';

import { FormStepStyles } from './FormStepLabel.styles';

export const FormStepLabel = ({ mode, stepNumber, stepTitle, children }) => {
  return (
    <>
      <FormStepStyles.Name id={`deposit_money_topup_step_${stepNumber}_title`}>
        <FormStepStyles.Number mode={mode}>{stepNumber}</FormStepStyles.Number>
        {stepTitle}
      </FormStepStyles.Name>
      {children}
    </>
  );
};

FormStepLabel.propTypes = {
  stepNumber: PropTypes.number.isRequired,
  stepTitle: PropTypes.string.isRequired,
  children: PropTypes.node,
  mode: PropTypes.string.isRequired
};

FormStepLabel.defaultProps = {
  mode: FormStepMode.Contained
};
