import React from 'react';
import { FormProvider } from 'react-hook-form';

import { FormProps } from '@epi-models/components/Form';

export function Form({ form, onSubmit, children, id }: FormProps) {
  const { handleSubmit, getValues } = form;

  const submitValues = async () => {
    const formValues = getValues();

    await onSubmit(formValues);
  };

  return (
    <FormProvider {...form}>
      <form id={id} noValidate onSubmit={handleSubmit(submitValues)}>
        {children}
      </form>
    </FormProvider>
  );
}
