import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { Box, Grid, LoadingSpinner, SimpleButton, EditIcon } from 'edenred-ui';

import {
  dismissApiErrors,
  getUserCompany,
  updateUserCompany
} from '@epi-actions/api';
import {
  getUserCompany as getUserCompanySelector,
  isCompanyLoaded as isCompanyLoadedSelector,
  isProcessingCompanyUpdateSelector
} from '@epi-selectors/api';
import { ApiConstants } from '@epi-constants/actions';
import { routerPaths } from '@epi-constants/routerPaths';
import {
  AddressField,
  BusinessIdField,
  CityField,
  CompanyNameField,
  CountryField,
  CustomerNumberField,
  NumberOfEmployeesField,
  ZipCodeField
} from '@epi-forms/controls';
import { isSignatorySelector, getLoginState } from '@epi-selectors/login';
import { isEmptyObj } from '@epi-helpers/isEmptyObj';
import {
  addressLine,
  maxLength,
  required,
  poBoxNotAllowed
} from '@epi-helpers/formHelpers/validators';
import {
  CompanyDataFormValues,
  ICompanyData
} from '@epi-models/pages/CompanyInfo';

import { ErrorPanel } from '../../../../components/ErrorPanel/ErrorPanel';
import { getCompanyDataFormValues, mapFormToCompanyData } from '../../helpers';
import { CompanyDataInvoicing } from '../CompanyDataInvoicing';

export function CompanyData({ autoRedirect, updateFooter }: ICompanyData) {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const isSignatory = useSelector(isSignatorySelector);
  const isCompanyLoaded = useSelector(isCompanyLoadedSelector);
  const userCompany = useSelector(getUserCompanySelector);
  const isProcessingCompanyUpdate = useSelector(
    isProcessingCompanyUpdateSelector
  );
  const [editMode, setEditMode] = useState(false);
  const { isUserImpersonate } = useSelector(getLoginState);
  const propagateAddress =
    pathname.includes(routerPaths.voucherOrderUrlPrefix) ||
    pathname.includes(routerPaths.voucherReorderUrlPrefix);

  const form = useForm<CompanyDataFormValues>({
    mode: 'onChange',
    shouldUnregister: true,
    defaultValues: getCompanyDataFormValues(isSignatory)
  });

  const {
    reset,
    setValue,
    handleSubmit,
    formState: { errors }
  } = form;

  function resetCompany() {
    if (isCompanyLoaded) {
      reset();

      setEditMode(false);
      Object.entries(
        getCompanyDataFormValues(isSignatory, userCompany)
      ).forEach(([field, value]) =>
        setValue(field as keyof CompanyDataFormValues, value)
      );
    }
  }

  useEffect(() => {
    dispatch(dismissApiErrors());
    dispatch(getUserCompany());
  }, []);

  useEffect(() => resetCompany(), [isCompanyLoaded, userCompany]);

  const onSubmit = (values: CompanyDataFormValues) => {
    const companyData = mapFormToCompanyData(values, isSignatory, userCompany);
    dispatch(
      updateUserCompany(
        companyData,
        autoRedirect,
        propagateAddress,
        isUserImpersonate
      )
    );
  };

  useEffect(() => {
    updateFooter({
      hidden: !editMode,
      submitText: t('company_info.save_btn'),
      onSubmit: handleSubmit(onSubmit),
      disableSubmit: !isEmptyObj(errors) || isProcessingCompanyUpdate,
      cancelText: t('company_info.cancel_btn'),
      onCancel: () => resetCompany()
    });
  });

  useEffect(() => {
    return () =>
      updateFooter({
        hidden: true
      });
  }, []);

  return (
    <FormProvider {...form}>
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Box
          display="flex"
          justifyContent="flex-end"
          my={3}
          visibility={editMode ? 'hidden' : 'visible'}
        >
          <SimpleButton
            id="company-info-edit-btn"
            icon={<EditIcon />}
            text={t('company_info.edit_btn')}
            onClick={() => setEditMode(true)}
          />
        </Box>
        <Box
          maxWidth={936}
          minHeight={680}
          mx="auto"
          sx={{
            '& .MuiInputBase-input.Mui-disabled': {
              WebkitTextFillColor: 'rgb(37, 37, 37)'
            }
          }}
        >
          {!isCompanyLoaded && <LoadingSpinner />}
          <ErrorPanel failedActionName={ApiConstants.UPDATE_USER_COMPANY} />
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <CompanyNameField disabled required={false} />
            </Grid>
            <Grid item xs={8} md={4}>
              <Grid container spacing={3}>
                <Grid item xs={6} md={5}>
                  <BusinessIdField disabled required={false} />
                </Grid>
                <Grid item xs={6} md={7}>
                  <CustomerNumberField disabled required={false} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <NumberOfEmployeesField
                disabled={!editMode}
                required={editMode}
              />
            </Grid>
            <Grid item xs={8} md={4}>
              <AddressField
                disabled={!editMode}
                required={editMode}
                validate={[
                  required,
                  maxLength(50),
                  addressLine,
                  poBoxNotAllowed
                ]}
              />
            </Grid>
            <Grid item xs={8} md={4}>
              <Grid container spacing={3}>
                <Grid item xs={6} md={5}>
                  <ZipCodeField disabled={!editMode} required={editMode} />
                </Grid>
                <Grid item xs={6} md={7}>
                  <CityField disabled={!editMode} required={editMode} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4} md={4}>
              <CountryField disabled required={false} />
            </Grid>
          </Grid>
          {isSignatory && <CompanyDataInvoicing editMode={editMode} />}
        </Box>
      </form>
    </FormProvider>
  );
}
