import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { DATE, TODAY, DownloadType } from '@epi-constants/downloadReports';
import { IUseTimePeriodDialog } from '@epi-models/pages/DownloadReports';
import { FormFields } from '@epi-forms/helpers';
import { getPayroll } from '../helpers';

export const useTimePeriodDialog = ({
  closeAction,
  type,
  getFieldName
}: IUseTimePeriodDialog) => {
  const { t } = useTranslation();
  const { setValue, setError, clearErrors } = useFormContext();

  const [from, setFrom] = useState<Date>();
  const [to, setTo] = useState<Date>();

  const handleSave = () => {
    const isPayroll =
      type === DownloadType.payroll && from && DATE.getTime() > from.getTime();
    const isOther =
      type === DownloadType.other &&
      to &&
      to.getTime() > TODAY.setHours(23, 59, 59, 0);

    clearErrors(getPayroll(FormFields.StartDate));
    isPayroll && setErrorOnView();
    isOther && setErrorOnView();
    closeAction();
  };

  const setErrorOnView = () => {
    setError(getFieldName(FormFields.StartDate), {
      type: 'custom',
      message: t(
        `download_reports.lunch_deduction.time_period.error_message_${type}`
      )
    });
  };

  const handleCancel = () => {
    setFrom(undefined);
    setTo(undefined);
    closeAction();
  };

  useEffect(() => {
    if (from && to) {
      setValue(getFieldName(FormFields.StartDate), from);
      setValue(getFieldName(FormFields.EndDate), to);
    }
  }, [from, to]);

  return {
    from,
    to,
    setFrom,
    setTo,
    handleCancel,
    handleSave
  };
};
