import React from 'react';
import { LoadingSpinner, PagePanel, Tabs } from 'edenred-ui';
import { useTranslation } from 'react-i18next';

import {
  usePayrollTabFooter,
  useSetTabs,
  useReportsTabFooter,
  useIncomeTabFooter
} from './hooks';

import { TabName } from '@epi-constants/downloadReports';

export const DownloadReportsTab = () => {
  const { t } = useTranslation();
  const { payrollFooter } = usePayrollTabFooter();
  const { otherReportsFooter } = useReportsTabFooter();
  const { incomeFooter } = useIncomeTabFooter();

  const { tabs, handleOnTabChange, activeTab, isLoading } = useSetTabs();

  const footer = () => {
    switch (activeTab) {
      case TabName.Lunch:
        return payrollFooter;
      case TabName.Other:
        return otherReportsFooter;
      case TabName.Income:
        return incomeFooter;
      default:
        return otherReportsFooter;
    }
  };

  return (
    <PagePanel title={t('download_reports.title')} footer={footer()}>
      {!isLoading && tabs.length ? (
        <Tabs tabs={tabs} onTabChange={handleOnTabChange} />
      ) : (
        <LoadingSpinner />
      )}
    </PagePanel>
  );
};
