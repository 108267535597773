import { useContext } from 'react';

import { EInvoiceContext } from './EInvoiceContext';

export const useEInvoiceContext = () => {
  const context = useContext(EInvoiceContext);

  if (context === null) {
    throw new Error('useEInvoiceContext is not accessible');
  }
  return context;
};
