import { BenefitType } from '@epi-constants/benefitTypes';

export function getBenefitSelected(state, benefitTypeId) {
  return state.apiBenefitGroupSettings.benefitGroupSettings?.find(
    b => b.benefitType.toLowerCase() === benefitTypeId
  );
}

export function isBenefitDefined(state, benefitTypeId) {
  return state.apiBenefitGroupSettings.benefitGroupSettings.some(
    b => b.benefitType.toLowerCase() === benefitTypeId
  );
}

export const isAnyBenefitGroupSettingsDefined = state =>
  state.apiBenefitGroupSettings.benefitGroupSettings.length > 0;

export const getDefinedBenefitGroupValues = state => {
  const cardLunch = getBenefitSelected(state, BenefitType.Lunch);
  const cardRecreational = getBenefitSelected(state, BenefitType.Recreational);
  const cardTransport = getBenefitSelected(state, BenefitType.Transport);
  const cardMassage = getBenefitSelected(state, BenefitType.Massage);
  const cardWellbeing = getBenefitSelected(state, BenefitType.Wellbeing);

  return {
    cardLunch: cardLunch ? cardLunch.maxAmountPerPeriod : undefined,
    cardRecreational: cardRecreational
      ? cardRecreational.maxAmountPerPeriod
      : undefined,
    cardTransport: cardTransport ? cardTransport.maxAmountPerPeriod : undefined,
    cardMassage: cardMassage ? cardMassage.maxAmountPerPeriod : undefined,
    cardWellbeing: cardWellbeing ? cardWellbeing.maxAmountPerPeriod : undefined
  };
};

export const selectActiveBenefits = state =>
  state.apiBenefitGroupSettings.activeBenefits;

export const selectIsActiveBenefitsLoaded = state =>
  state.apiBenefitGroupSettings.isActiveBenefitsLoaded;

export const selectIsBenefitGroupSettingsLoaded = state =>
  state.apiBenefitGroupSettings.isLoaded;

export const selectIsOrderInProcess = state =>
  state.apiBenefitGroupSettings.isOrderInProgress;

export const selectGoToPageLink = state =>
  state.apiBenefitGroupSettings.goToPageLink;
