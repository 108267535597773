import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { createBenefitGroup, updateBenefitGroup } from '@epi-actions/api';
import { IDetail } from '@epi-models/containers/ManageBenefitGroups';
import { slideoutMode } from '@epi-constants/slideoutMode';

import { createModeInitialState, viewModeInitialState } from '../helpers';

export const useManageBenefitGroupsModal = () => {
  const [detail, setDetail] = useState<IDetail>(viewModeInitialState);
  const dispatch = useDispatch();
  const { benefitGroup } = useParams<{ benefitGroup: string }>();

  const handleViewDetail = id => {
    setDetail({
      ...detail,
      mode: slideoutMode.view,
      expanded: true,
      currentGroupId: id
    });
  };

  const handleCloseDetail = () => {
    setDetail(viewModeInitialState);
  };

  const handleEditDetail = id => {
    setDetail({
      ...detail,
      mode: slideoutMode.edit,
      expanded: true,
      currentGroupId: id
    });
  };

  const handleUpdateBenefitGroup = groupData => {
    setDetail({
      ...detail,
      mode: slideoutMode.view,
      expanded: false,
      currentGroupId: null
    });
    dispatch(updateBenefitGroup(groupData, benefitGroup));
  };

  const handleOpenCreateDatail = () => {
    setDetail(createModeInitialState);
  };

  const handleCreateBenefitGroup = groupData => {
    setDetail({
      ...detail,
      mode: slideoutMode.view,
      expanded: false,
      currentGroupId: null,
      createdGroupNotification: true,
      createdGroupSuggestion: true
    });
    dispatch(createBenefitGroup(groupData, benefitGroup));
  };

  const handleCancelDetail = () => {
    setDetail({
      ...detail,
      mode: slideoutMode.view,
      expanded: false,
      currentGroupData: null
    });
  };

  return {
    detail,
    setDetail,
    handleViewDetail,
    handleCloseDetail,
    handleEditDetail,
    handleUpdateBenefitGroup,
    handleOpenCreateDatail,
    handleCreateBenefitGroup,
    handleCancelDetail
  };
};
