import { FieldValues } from 'react-hook-form';

export function createRules(
  validators: Array<((value?: string) => unknown) | string>
) {
  const validate = validators.reduce((acc, validator, index) => {
    const functionName = `AnonymousFunction${index}`;
    return { ...acc, [functionName]: validator };
  }, {});

  return { validate } as FieldValues;
}
