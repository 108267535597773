import React, { useState } from 'react';
import {
  Box,
  Notification,
  NotificationType,
  Pagination,
  SelectComponent
} from 'edenred-ui';
import { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';

import { TODAY } from '@epi-constants/downloadReports';

import { BalanceCorrectionsTable } from './BalanceCorrectionsTable';
import { BalanceCorrectionsDateRange } from './BalanceCorrectionsDateRange';
import { useBalanceCorrections } from './hooks/useBalanceCorrections';

export const BalanceCorrections = () => {
  const { t } = useTranslation();
  const { gray10 } = useTheme();
  const [showNotification, setShowNotification] = useState(true);
  const {
    reports,
    page,
    totalPages,
    isLoading,
    timePeriodDialog,
    timePeriodType,
    options,
    handleChangePage,
    handleTimePeriodChange,
    setTimePeriodDialog,
    from,
    to,
    setFrom,
    setTo
  } = useBalanceCorrections();

  const closeNotification = () => {
    setShowNotification(false);
  };

  const closeTimePeriod = () => {
    setTimePeriodDialog(false);
  };

  return (
    <Box marginTop="40px">
      {showNotification && (
        <Notification
          text={t('containers.deposit_money.corrections_tab.info')}
          type={NotificationType.Info}
          onClose={closeNotification}
          id="balance-corrections-info"
        />
      )}
      <Box pb={5} borderBottom={`1px solid ${gray10}`} my={5}>
        <Box ml={-1}>
          <SelectComponent
            label=""
            placeholder={t(
              'containers.deposit_money.corrections_tab.placeholder'
            )}
            id="report-date-range-select"
            selectedId={timePeriodType}
            options={options}
            onChange={handleTimePeriodChange}
          />
        </Box>
      </Box>
      {timePeriodDialog && (
        <BalanceCorrectionsDateRange
          isOpen={timePeriodDialog}
          closeAction={closeTimePeriod}
          disabledCustomDates={{ after: TODAY }}
          from={from}
          to={to}
          setFrom={setFrom}
          setTo={setTo}
        />
      )}
      <BalanceCorrectionsTable
        correctionReports={reports}
        isLoading={isLoading}
      />
      <Box mt={5}>
        <Pagination
          prevBtnText={t('shared.pagination.previous')}
          nextBtnText={t('shared.pagination.next')}
          page={page}
          count={totalPages}
          onChangePage={handleChangePage}
        />
      </Box>
    </Box>
  );
};
