import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Table, Checkbox, SortDownIcon, SortUpIcon } from 'edenred-ui';
import { useFormContext } from 'react-hook-form';
import { useTheme } from 'styled-components';

import { Pagination } from '@epi-shared/components';
import { TopupSorting } from '@epi-constants/topupSorting';
import { formatCurrencyDecimal } from '@epi-helpers/numeral';

import { HeaderButton, Summary } from './EmployeesTable.styles';

export const EmployeesTable = ({
  visibleEmployees,
  toggleEmployee,
  handleChangePage,
  paginationCount,
  page,
  employeesPerPage,
  handleSort,
  descending,
  sortBy,
  checkedCount,
  toggleAllEmployee,
  isAllEmployeeChecked
}) => {
  const {
    t,
    i18n: { language }
  } = useTranslation();
  const { trigger } = useFormContext();
  const { gray50, fontSemiBold, gray10, gray85 } = useTheme();

  const sortingIcon = descending ? <SortDownIcon /> : <SortUpIcon />;
  const minWidth = language === 'en' ? 66 : 140;

  const handleToggleEmployee = employee => {
    toggleEmployee(employee);
    trigger();
  };

  const handleToggleAllEmployee = () => {
    toggleAllEmployee(!isAllEmployeeChecked);
    trigger();
  };

  return (
    <Box marginTop="48px">
      <Table id="employee-table">
        <Table.Head>
          <Table.Row>
            <Table.Cell width="35%">
              <Box display="flex" alignItems="center" gap={2}>
                <Checkbox
                  id="employee-table-checkbox"
                  noPadding
                  onClick={handleToggleAllEmployee}
                  checked={isAllEmployeeChecked}
                />
                <HeaderButton
                  onClick={() => handleSort(TopupSorting.ByFirstName)}
                  id="employee-table-name"
                >
                  {t('containers.deposit_money.topup_tab.form.table.name')}
                  {sortBy === TopupSorting.ByFirstName && sortingIcon}
                </HeaderButton>
              </Box>
            </Table.Cell>
            <Table.Cell width="25%" id="employee-table-email">
              {t('containers.deposit_money.topup_tab.form.table.email')}
            </Table.Cell>
            <Table.Cell width="25%" id="employee-table-employee-type">
              {t('containers.deposit_money.topup_tab.form.table.employee_type')}
            </Table.Cell>
            <Table.Cell
              width="15%"
              align="right"
              id="employee-table-topup-left"
            >
              <HeaderButton
                onClick={() =>
                  handleSort(TopupSorting.ByAvailableToTopupAmount)
                }
              >
                {sortBy === TopupSorting.ByAvailableToTopupAmount &&
                  sortingIcon}

                {t('containers.deposit_money.topup_tab.form.table.topup_left')}
              </HeaderButton>
            </Table.Cell>
          </Table.Row>
        </Table.Head>
        <Table.Body>
          {visibleEmployees.map(employee => (
            <Table.Row
              key={employee.beneficiaryId}
              disabled={employee.disabled}
              id={`employee-table-row-${employee.personalId}`}
              onHold={employee.onHold}
            >
              <Table.Cell>
                <Box display="flex" alignItems="center">
                  <Box paddingRight="16px">
                    <Checkbox
                      id={`employee-table-checkbox-${employee.personalId}`}
                      noPadding
                      checked={employee.isChecked}
                      onChange={() => handleToggleEmployee(employee)}
                      disabled={employee.disabled}
                    />
                  </Box>
                  <div id={`employee-table-name-${employee.personalId}`}>
                    {employee.firstName} {employee.lastName}
                  </div>
                  {employee.onHold && (
                    <Box
                      id={`employee-table-onhold-label-${employee.personalId}`}
                      ml={3}
                      display="flex"
                      minWidth={minWidth}
                      height={16}
                      alignItems="center"
                      justifyContent="center"
                      color={gray85}
                      fontSize={11.5}
                      borderRadius={1.25}
                      textTransform="uppercase"
                      fontWeight={fontSemiBold}
                      bgcolor={gray10}
                      border="1px solid"
                      borderColor={gray50}
                    >
                      {t(
                        'containers.deposit_money.topup_tab.form.table.on_hold_label'
                      )}
                    </Box>
                  )}
                </Box>
              </Table.Cell>
              <Table.Cell>{employee.emailAddress}</Table.Cell>
              <Table.Cell>
                {t(
                  `containers.deposit_money.topup_tab.form.table.${employee.employmentType}`,
                  { percentage: employee.benefitPercentage }
                )}
              </Table.Cell>
              <Table.Cell align="right">
                {formatCurrencyDecimal(employee.availableToTopUpAmount)}
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
      <Summary id="employee-table-summary">
        <span>
          {t('containers.deposit_money.topup_tab.form.table.selected')}
        </span>
        <span>{checkedCount}</span>
      </Summary>
      <Box mt={3}>
        <Pagination
          page={page}
          count={paginationCount}
          defaultRowsPerPage={employeesPerPage}
          onChangePage={handleChangePage}
        />
      </Box>
    </Box>
  );
};

EmployeesTable.propTypes = {
  visibleEmployees: PropTypes.array.isRequired,
  toggleEmployee: PropTypes.func.isRequired,
  handleChangePage: PropTypes.func.isRequired,
  paginationCount: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  employeesPerPage: PropTypes.number.isRequired,
  handleSort: PropTypes.func.isRequired,
  descending: PropTypes.bool.isRequired,
  sortBy: PropTypes.string.isRequired,
  checkedCount: PropTypes.number.isRequired,
  toggleAllEmployee: PropTypes.func.isRequired,
  isAllEmployeeChecked: PropTypes.bool.isRequired
};
