import React, { ReactElement, ReactNode } from 'react';

import { ICreatedGroupNotifications } from '@epi-models/containers/ManageBenefitGroups';
import { InfoNotification } from './InfoNotification';
import { SuccessNotification } from './SuccessNotification';
import { useCreatedGroupNotifications } from '@epi-containers/ManageBenefitGroups/hooks';

export function CreatedGroupNotifications({
  detail,
  isBenefitGroupsError,
  setDetail
}: ICreatedGroupNotifications): any {
  const { isLoading, notificationGroupArr } = useCreatedGroupNotifications();

  return notificationGroupArr.map(
    ({ showNotification, successNotification, infoNotification, id }) => {
      return (
        showNotification &&
        !isLoading && (
          <div key={id}>
            {detail.createdGroupSuggestion && (
              <SuccessNotification
                isBenefitGroupsError={isBenefitGroupsError}
                text={successNotification.text}
                onClose={() =>
                  setDetail({ ...detail, createdGroupSuggestion: false })
                }
                handleFirstBtn={successNotification?.handleFirstBtn}
                textForFirstBtn={successNotification?.textForFirstBtn}
                handleSecondBtn={successNotification?.handleSecondBtn}
                textForSecondBtn={successNotification?.textForSecondBtn}
              />
            )}
            {infoNotification && detail.createdGroupNotification && (
              <InfoNotification
                text={infoNotification.text}
                onClose={() =>
                  setDetail({ ...detail, createdGroupNotification: false })
                }
                handleFirstBtn={infoNotification.handleFirstBtn}
                textForFirstBtn={infoNotification.textForFirstBtn}
                handleSecondBtn={infoNotification.handleSecondBtn}
                textForSecondBtn={infoNotification.textForSecondBtn}
                textBetweenBtns={infoNotification.textBetweenBtns}
              />
            )}
          </div>
        )
      );
    }
  );
}
