import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { showNotification } from '@epi-actions/overlays';
import { getDetails } from '@epi-api/user';
import { ICustomerData } from '@epi-models/pages/Home';

export const useGetDetails = () => {
  const [customerData, setCustomerData] = useState<ICustomerData>();
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    let isApiSubscribed = true;
    getDetails()
      .then(res => {
        if (isApiSubscribed) {
          setCustomerData(res);
          setIsLoading(false);
        }
      })
      .catch(() => {
        dispatch(showNotification({ message: 'messages.error' }, 'error'));
        setIsLoading(false);
      });
    return () => {
      isApiSubscribed = false;
    };
  }, []);

  return { isLoading, customerData };
};
