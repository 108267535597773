import React, { StrictMode } from 'react';

import { StrictModeProps } from '@epi-models/components/StrictMode';

const StrictModeContainer = ({ children }: StrictModeProps) => {
  if (process.env.NODE_ENV === 'development') {
    return <StrictMode>{children}</StrictMode>;
  }
  return children;
};

export { StrictModeContainer as StrictMode };
