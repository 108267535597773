import config from '@epi-config';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { PagePanel, Box, Text } from 'edenred-ui';
import { useParams } from 'react-router-dom';

import { InfoBar } from '@epi-shared/components';
import { isProductionConfig } from '@epi-selectors/environment';

import { getDailyLimitSelectFieldOptions } from './helpers';
import {
  AddBenefitGroupTile,
  BenefitGroupDetail,
  CreatedGroupNotifications,
  BenefitGroupTiles
} from './components';

import {
  useGetAmounts,
  useManageBenefitGroups,
  useManageBenefitGroupsModal
} from './hooks';

export const ManageBenefitGroups = () => {
  const { t } = useTranslation();
  const { benefitGroup } = useParams<{ benefitGroup: string }>();
  const {
    benefitGroups,
    isBenefitGroupsLoaded,
    isBenefitGroupsError,
    isMassageOrWellbeing,
    isBenefitAvailable,
    shouldShowAddBenefitTile
  } = useManageBenefitGroups();
  const {
    detail,
    setDetail,
    handleViewDetail,
    handleCloseDetail,
    handleEditDetail,
    handleUpdateBenefitGroup,
    handleOpenCreateDatail,
    handleCreateBenefitGroup,
    handleCancelDetail
  } = useManageBenefitGroupsModal();
  const amount = useGetAmounts();

  const currentBenefitGroup = useMemo(
    () => benefitGroups.find(group => group.groupId === detail.currentGroupId),
    [detail.currentGroupId, benefitGroups]
  );

  const dailyLimitOptionsIncludingCurrentlySelected = useMemo(() => {
    return getDailyLimitSelectFieldOptions(
      amount,
      currentBenefitGroup?.maxAmountPerPeriod
    );
  }, [currentBenefitGroup]);

  return (
    <PagePanel
      title={t(`components.manage_benefits.new.benefits.${benefitGroup}`)}
      backIcon
      backIconLink={t('components.manage_benefit_lunch.back_link')}
    >
      {(detail.createdGroupSuggestion || detail.createdGroupNotification) && (
        <CreatedGroupNotifications
          detail={detail}
          isBenefitGroupsError={isBenefitGroupsError}
          setDetail={setDetail}
        />
      )}
      {isMassageOrWellbeing && (
        <Box mb={3}>
          <InfoBar
            text={t(`components.manage_benefit_massage.info_box_text`)}
            href={t(
              `components.manage_benefit_${benefitGroup}.info_box_link_${
                isProductionConfig() ? 'prod' : 'uat'
              }`
            )}
          />
        </Box>
      )}
      {!(detail.createdGroupNotification || detail.createdGroupSuggestion) && (
        <Box maxWidth={860} pb={5}>
          <Text light>{t('components.manage_benefit_lunch.subtitle')}</Text>
        </Box>
      )}
      {isBenefitGroupsLoaded && (
        <Box
          display="flex"
          flexWrap="wrap"
          id={`employee-benefits-${benefitGroup}-group-tiles`}
        >
          <BenefitGroupTiles
            benefitGroups={benefitGroups}
            handleViewDetail={handleViewDetail}
            handleEditDetail={handleEditDetail}
          />
          {shouldShowAddBenefitTile && (
            <AddBenefitGroupTile onClick={handleOpenCreateDatail} />
          )}
        </Box>
      )}
      {isBenefitAvailable && (
        <BenefitGroupDetail
          detail={detail}
          onClose={handleCloseDetail}
          onSave={handleUpdateBenefitGroup}
          onCancel={handleCancelDetail}
          onCreate={handleCreateBenefitGroup}
          onEdit={handleEditDetail}
          benefitGroup={currentBenefitGroup}
          benefitType={benefitGroup}
          dailyLimitOptions={dailyLimitOptionsIncludingCurrentlySelected}
          key={currentBenefitGroup?.groupId}
        />
      )}
    </PagePanel>
  );
};
