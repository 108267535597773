import React from 'react';
import { Box } from 'edenred-ui';

import { useOrderSummaryTerms } from '@epi-pages/Home/hooks';

import { useBenefits } from '../../hooks';
import { OrderSummary } from '../OrderSummary';
import { ActiveBenefits } from './ActiveBenefits';
import { ReadyToActiveBenefits } from './ReadyToActiveBenefits';

export const Benefits = () => {
  const {
    activeBenefits,
    readyToActiveBenefits,
    showOrderSummary,
    benefitsForOrder,
    handleOrderAllBenefits,
    handleOrderBenefit,
    getBenefits
  } = useBenefits();
  const { isSign, isTerms, handleIsSingIn, handleIsTerms } =
    useOrderSummaryTerms(showOrderSummary);

  return (
    <Box mt={8}>
      <ActiveBenefits benefits={activeBenefits} />
      <ReadyToActiveBenefits
        benefits={readyToActiveBenefits}
        handleOrderBenefit={handleOrderBenefit}
        handleOrderAllBenefits={handleOrderAllBenefits}
      />
      {showOrderSummary && (
        <OrderSummary
          benefits={benefitsForOrder}
          isSign={isSign}
          isTerms={isTerms}
          handleIsSingIn={handleIsSingIn}
          handleIsTerms={handleIsTerms}
          getBenefits={getBenefits}
        />
      )}
    </Box>
  );
};
