import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FormFields } from '@epi-forms/helpers';
import { benefitsDataMapper } from '@epi-helpers/BeneficiaryHelper';
import { isEmptyObj } from '@epi-helpers/isEmptyObj';
import { getBeneficiaryPayload } from '@epi-pages/Beneficiaries/helpers/getBeneficiaryPayload';
import { IBeneficiaryDetailSlideout } from '@epi-models/pages/Beneficiaries';
import { EmployeeTypeModes, UNIQUE } from '@epi-constants/beneficiary';

export const useBeneficiaryDetailSlideout = ({
  beneficiary,
  editingMode,
  setExpanded,
  setEditingMode,
  editEmployeeAction,
  isLunchBenefitActive,
  expanded
}: IBeneficiaryDetailSlideout) => {
  const { t } = useTranslation();
  const [showCloseConfirmation, setShowCloseConfirmation] = useState(false);

  const form = useForm({
    mode: 'onChange',
    defaultValues: benefitsDataMapper(
      beneficiary,
      editingMode,
      isLunchBenefitActive
    )
  });

  const {
    getValues,
    setValue,
    reset,
    setError,
    watch,
    formState: { errors, dirtyFields }
  } = form;

  const employeeType = watch(FormFields.EmployeeType);
  const daysForLunari = watch(FormFields.DaysForLunari);
  const isOnHoldTypeOrFullTimeoRDropped =
    employeeType === EmployeeTypeModes.Onhold ||
    employeeType === EmployeeTypeModes.Dropped ||
    employeeType === EmployeeTypeModes.FullTime;

  const disableSubmit = () => {
    const {
      [FormFields.EmployeePercentage]: EmployeePercentage,
      [FormFields.DaysForLunari]: DaysForLunari,
      ...rest
    } = errors;
    const specificErrors = isOnHoldTypeOrFullTimeoRDropped ? rest : errors;

    if (!isEmptyObj(specificErrors)) return true;
    if (!watch(FormFields.EmployeePercentage)) return true;
    if (daysForLunari && (daysForLunari < 1 || daysForLunari > 23)) return true;
    if (isEmptyObj(dirtyFields)) return true;
    if (
      watch(FormFields.EndOfBenefit) !== null &&
      !watch(FormFields.EndOfBenefit)
    )
      return false;
    return false;
  };

  const handleClose = () => {
    if ((isEmptyObj(errors) && !editingMode) || isEmptyObj(dirtyFields)) {
      setExpanded(false);
      setEditingMode(false);
    } else {
      setShowCloseConfirmation(true);
    }
  };

  const handleSubmitFormExecute = () => {
    editEmployeeAction(getBeneficiaryPayload(getValues()))
      .then(() => {
        setEditingMode(false);
        setExpanded(false);
      })
      .catch(err => {
        const uniqueEmployerNumber =
          err.content && err.content.errors[0].message === UNIQUE;
        if (uniqueEmployerNumber) {
          setError(FormFields.EmployeeNumber, {
            type: 'custom',
            message: t('messages.employee_number')
          });
        }
      });
  };

  const confirmCloseWithoutSaving = () => {
    setShowCloseConfirmation(false);
    setEditingMode(false);
    setExpanded(false);
  };

  const confirmSaving = () => {
    setShowCloseConfirmation(false);
    handleSubmitFormExecute();
  };

  const handleCloseConfirmation = () => setShowCloseConfirmation(false);

  useEffect(() => {
    setValue(FormFields.EditMode, editingMode);
  }, [editingMode]);

  useEffect(() => {
    reset(benefitsDataMapper(beneficiary, editingMode));
  }, [beneficiary, editingMode, expanded]);

  useEffect(() => {
    if (isLunchBenefitActive) {
      setValue(FormFields.LunchBenefitActive, isLunchBenefitActive);
    }
  }, [isLunchBenefitActive, expanded]);

  return {
    confirmCloseWithoutSaving,
    confirmSaving,
    handleClose,
    handleSubmitFormExecute,
    form,
    showCloseConfirmation,
    handleCloseConfirmation,
    disableSubmit
  };
};
