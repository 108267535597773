import React from 'react';
import { Box } from 'edenred-ui';
import { useTranslation, Trans } from 'react-i18next';
import { useFormContext } from 'react-hook-form';

import { FormFields } from '@epi-forms/helpers';
import { maxLength } from '@epi-helpers/formHelpers/validators';
import { TerminationDate } from '@epi-pages/Beneficiaries/components/TerminationDate';
import {
  FirstNameField,
  LastNameField,
  EmailAddressField
} from '@epi-forms/controls';
import { EmployeeTypeModes } from '@epi-constants/beneficiary';

import { PersonalDataField } from '../PersonalDataField';
import { EntitledDataField } from '../EntitledDataField';
import { EmployeeTypeDataField } from '../EmployeeTypeDataField';
import { ContractPercentageField } from '../ContractPercentageField';
import { DaysForLunariField } from '../DaysForLunariField';

export const PersonalData = () => {
  const { t } = useTranslation();
  const { getValues, watch } = useFormContext();
  const isEditMode = getValues(FormFields.EditMode);
  const EoF = watch(FormFields.EndOfFunds);
  const employeeType = watch(FormFields.EmployeeType);
  const showTerminationButton = !EoF || isEditMode;
  const isPartTimeOrTemporaryType =
    employeeType === EmployeeTypeModes.PartTime ||
    employeeType === EmployeeTypeModes.TemporaryEmployed;
  const isOnHold = employeeType === EmployeeTypeModes.Onhold;
  const isDropped = employeeType === EmployeeTypeModes.Dropped;
  const isLunchBenefitActive = watch(FormFields.LunchBenefitActive);
  const minHeight =
    1100 +
    (isLunchBenefitActive ? 150 : 0) +
    (EoF && showTerminationButton ? 150 : 0);

  return (
    <Box
      px={10}
      pt={5}
      gap={3}
      display="flex"
      flexDirection="column"
      minHeight={minHeight}
      id="employee-benefit-personal-data-tab"
    >
      <FirstNameField readOnly={!isEditMode} />
      <LastNameField readOnly={!isEditMode} />
      <PersonalDataField
        label={t('containers.beneficiaries.detail.personal_id')}
        name={FormFields.PersonalID}
        disabled
      />
      <EmailAddressField
        label={t('containers.beneficiaries.table.email')}
        readOnly={!isEditMode}
      />
      <Box fontSize={12} fontWeight={400}>
        <EmployeeTypeDataField />
        {isOnHold && (
          <Box id="edit_beneficiary_employee_type_subtext">
            {t('containers.beneficiaries.detail.employee_type_subtext')}
          </Box>
        )}
      </Box>
      <Box fontSize={12} fontWeight={400}>
        <ContractPercentageField
          disabled={!isPartTimeOrTemporaryType}
          isAddMode
        />
        <Box id="edit_beneficiary_working_hours_subtext">
          {t('containers.beneficiaries.detail.working_hours_subtext')}
        </Box>
      </Box>
      {isLunchBenefitActive && (
        <Box fontSize={12} fontWeight={400}>
          <DaysForLunariField disabled={isOnHold || isDropped} />
          <Box id="edit_beneficiary_lunch_benefit_per_month_subtext">
            <Trans
              defaults={t(
                'containers.beneficiaries.detail.lunch_benefit_per_month_subtext'
              )}
              components={{
                b: <b />
              }}
            />
          </Box>
        </Box>
      )}
      <PersonalDataField
        label={t('containers.beneficiaries.detail.employee_number')}
        name={FormFields.EmployeeNumber}
        validate={[maxLength(20)]}
      />
      <PersonalDataField
        label={t('containers.beneficiaries.detail.cost_center_number')}
        name={FormFields.CostCenterNumber}
        validate={[maxLength(50)]}
      />
      <EntitledDataField isRemoved={EoF} />
      <PersonalDataField
        label={t('containers.beneficiaries.detail.create_data')}
        name={FormFields.CreatedAt}
        disabled
      />
      <TerminationDate showTerminationButton={showTerminationButton} />
    </Box>
  );
};
