import React from 'react';
import {
  TitleText,
  Box,
  Checkbox,
  FormControlLabel,
  Button,
  ButtonMode,
  Tooltip
} from 'edenred-ui';
import { useTranslation, Trans } from 'react-i18next';
import { useTheme } from 'styled-components';

import { IOrderSummaryProps } from '@epi-models/pages/Home';
import { useOrderSummary, useOrderSummaryCancel } from '@epi-pages/Home/hooks';

import { OrderSummarySuccess } from '../OrderSummarySuccess';
import { OrderSummaryCancel } from '../OrderSummaryCancel';

export const OrderSummary = ({
  benefits,
  isSign,
  isTerms,
  handleIsSingIn,
  handleIsTerms,
  getBenefits
}: IOrderSummaryProps) => {
  const { t } = useTranslation();
  const { primaryFrontColor, gray70, fontSemiBold, sizeSmall } = useTheme();
  const { handleClose, handleOrderBenefits, isOpen } = useOrderSummary(
    benefits,
    getBenefits
  );
  const { isOpenLeave, handleCloseLeaveModal, handleGoTo } =
    useOrderSummaryCancel();

  return (
    <Box
      border={1}
      borderColor="black"
      mt={3}
      py={3}
      id="home-order-summary"
      minWidth={1290}
    >
      <Box pl={3}>
        <TitleText>{t('home.benefits_tile.summary_title')}</TitleText>
      </Box>
      <Box display="flex" gap={3} mb={4} px={3}>
        <Box pt={0.25}>
          <TitleText fontSize="16px" fontWeight={fontSemiBold} noMargin>
            {t('home.benefits_tile.summary_order')}
          </TitleText>
        </Box>
        {benefits.map(benefit => (
          <Box
            key={benefit.id}
            fontSize={sizeSmall}
            fontWeight={fontSemiBold}
            border={1}
            borderRadius={1}
            borderColor="black"
            px={1.5}
            py={1}
            alignItems="center"
            display="flex"
            maxHeight={24}
            color={primaryFrontColor}
          >
            {benefit.title}
          </Box>
        ))}
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        pt={3}
        borderTop={1}
        borderColor={gray70}
        px={3}
      >
        <Box
          display="flex"
          alignItems="center"
          sx={{
            '& .MuiFormControlLabel-root': {
              marginBottom: '0 !important',
              marginTop: 2
            }
          }}
        >
          <FormControlLabel
            control={<Checkbox checked={isSign} />}
            label={
              <Box overflow="hidden" whiteSpace="nowrap" display="inline-block">
                {t('home.benefits_tile.summary_first_checkbox')}
              </Box>
            }
            onChange={handleIsSingIn}
          />
          <FormControlLabel
            control={<Checkbox checked={isTerms} />}
            label={
              <Box overflow="hidden" whiteSpace="nowrap" display="inline-block">
                <Trans
                  defaults={t('home.benefits_tile.summary_second_checkbox')}
                  components={{
                    a: (
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={t(
                          'home.benefits_tile.summary_second_checkbox_first_link'
                        )}
                      />
                    ),
                    b: (
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={t(
                          'home.benefits_tile.summary_second_checkbox_second_link'
                        )}
                      />
                    )
                  }}
                />
              </Box>
            }
            onChange={handleIsTerms}
          />
        </Box>
        <Tooltip
          arrow
          placement="top"
          disableHoverListener={isSign && isTerms}
          title={
            <Box maxWidth={130}>{t('home.benefits_tile.summary_tooltip')}</Box>
          }
        >
          <Box>
            <Button
              id="button"
              mode={ButtonMode.Primary}
              onClick={handleOrderBenefits}
              disabled={!isSign || !isTerms}
            >
              {t('home.benefits_tile.summary_button')}
            </Button>
          </Box>
        </Tooltip>
      </Box>
      <OrderSummarySuccess isOpen={isOpen} handleClose={handleClose} />
      <OrderSummaryCancel
        isOpen={isOpenLeave}
        handleClose={handleCloseLeaveModal}
        handleGoTo={handleGoTo}
      />
    </Box>
  );
};
