import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled, { useTheme } from 'styled-components';
import { connect, useSelector } from 'react-redux';
import { Box, AvatarIcon, AvatarOutlineIcon, TitleText } from 'edenred-ui';
import { useTranslation } from 'react-i18next';

import { getIsAuthenticated, getLoginState } from '@epi-selectors/login';
import { getApplicationLanguageId } from '@epi-store/application/application.selectors';
import { useMenuDimensions } from '@epi-shared/hooks';

import { NavbarLanguageFlag } from '../NavbarLanguageFlag';
import { NavbarLanguageLabelWithChevron } from '../NavbarLanguageLabel';

const DropdownButton = styled.button.attrs({ type: 'button' })`
  display: flex;
  padding: 20px;
  background: none;
  border: none;
`;

function NavbarDropdownButton({
  isAuthenticated,
  languageId,
  isDropdownOpen,
  onClick
}) {
  const [hoverActive, setHoverActive] = useState(false);
  const { isMobile } = useMenuDimensions();
  const { sizeMedium, gray85 } = useTheme();
  const { accessToken, isUserImpersonate } = useSelector(getLoginState);
  const { t } = useTranslation();

  const getButtonContent = () => {
    if (!isAuthenticated) {
      return isMobile ? (
        <NavbarLanguageFlag languageId={languageId} />
      ) : (
        <Box mr={3}>
          <NavbarLanguageLabelWithChevron
            languageId={languageId}
            open={isDropdownOpen}
          />
        </Box>
      );
    }

    const showFilledIcon = isDropdownOpen || hoverActive;
    const avatarIconSize = isMobile ? 24 : 40;

    const avatarIcon = showFilledIcon ? (
      <AvatarIcon width={avatarIconSize} />
    ) : (
      <AvatarOutlineIcon width={avatarIconSize} />
    );

    return (
      <Box display="flex" mr={isMobile ? 0 : 2}>
        {avatarIcon}
      </Box>
    );
  };

  const onMouseEnter = () => setHoverActive(true);
  const onMouseLeave = () => setHoverActive(false);

  const buttonContent = getButtonContent();

  return (
    <Box display="flex" alignItems="center">
      {accessToken && isUserImpersonate && (
        <TitleText fontSize={sizeMedium} color={gray85} noMargin>
          {t('navbar.impersonationSession')}
        </TitleText>
      )}
      <DropdownButton
        id="navbar-dropdown-button"
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onClick={onClick}
      >
        {buttonContent}
      </DropdownButton>
    </Box>
  );
}

NavbarDropdownButton.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  isDropdownOpen: PropTypes.bool.isRequired,
  languageId: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  isAuthenticated: getIsAuthenticated(state),
  languageId: getApplicationLanguageId(state)
});

const ConnectedNavbarDropdownButton =
  connect(mapStateToProps)(NavbarDropdownButton);

export { ConnectedNavbarDropdownButton as NavbarDropdownButton };
