import { REHYDRATE } from 'redux-persist/constants';
import createReducer from '../helpers/CreateReducer';
import { NavigationConstants } from '../constants/actions';

export const getInitialState = () => ({
  locationHistory: [],
  navigationContext: ''
});

export default createReducer(getInitialState, {
  [NavigationConstants.ADD_LOCATION_HISTORY]: (state, { path }) => ({
    locationHistory: [path, ...state.locationHistory].slice(0, 15)
  }),

  [NavigationConstants.DELETE_LAST_LOCATION_HISTORY]: (state, { entries }) => ({
    locationHistory: [...state.locationHistory].slice(entries)
  }),

  [`${REHYDRATE}`]: (state, { payload }) => ({
    ...state,
    ...payload.navigation,
    rehydrated: true
  })
});
