import React from 'react';
import { Table, LoadingSpinner, Box } from 'edenred-ui';
import { useTranslation } from 'react-i18next';

import { IBeneficiariesTable } from '@epi-models/pages/Beneficiaries';

import { BeneficiariesRow } from './BeneficiariesRow';

export const BeneficiariesTable = ({
  beneficiaries,
  showDetail,
  isLoading,
  setBeneficiaryEditingMode,
  contractedBenefits
}: IBeneficiariesTable) => {
  const { t } = useTranslation();

  return (
    <Box pt={2} pb={6}>
      <Table id="manage-employees-table">
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <>
            <Table.Head>
              <Table.Row>
                <Table.Cell
                  width="30%"
                  id="manage-employees-table-employee-title"
                >
                  {t('containers.beneficiaries.table.employee')}
                </Table.Cell>
                <Table.Cell width="40%" id="manage-employees-table-email-title">
                  {t('containers.beneficiaries.table.email')}
                </Table.Cell>
                <Table.Cell
                  width="25%"
                  id="manage-employees-table-benefit-title"
                >
                  {t('containers.beneficiaries.table.benefit_type')}
                </Table.Cell>
                <Table.Cell
                  width="5%"
                  id="manage-employees-table-actions-title"
                >
                  {t('containers.beneficiaries.table.actions')}
                </Table.Cell>
              </Table.Row>
            </Table.Head>
            <Table.Body>
              {beneficiaries.map(beneficiary => (
                <BeneficiariesRow
                  key={beneficiary.personId}
                  beneficiary={beneficiary}
                  showDetail={showDetail}
                  setBeneficiaryEditingMode={setBeneficiaryEditingMode}
                  contractedBenefits={contractedBenefits}
                />
              ))}
            </Table.Body>
          </>
        )}
      </Table>
    </Box>
  );
};
