import React from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { TextField } from 'edenred-ui';

import {
  createRules,
  FormFields,
  withFormNameContext
} from '@epi-forms/helpers';
import {
  phone,
  required,
  maxLength
} from '@epi-helpers/formHelpers/validators';
import { normalizePhoneNumber } from '@epi-helpers/formHelpers/normalizers';
import { PhoneNumberFieldProps } from '@epi-models/forms/Controls';

export function PhoneNumberField({
  context = '',
  readOnly = false
}: PhoneNumberFieldProps) {
  const name = withFormNameContext(context, FormFields.PhoneNumber);
  const { control } = useFormContext();
  const { t } = useTranslation();
  const validators = [maxLength(20), required, phone];
  const rules = createRules(validators);

  const {
    field: { ref, value, onChange: setValue },
    fieldState: { error }
  } = useController({
    name,
    control,
    rules
  });

  const onChange = event =>
    setValue(normalizePhoneNumber(event.currentTarget.value));

  return (
    <TextField
      label={t('controls.phone_number_label')}
      required
      value={value}
      fullWidth
      id={name}
      error={!!error}
      helperText={error?.message}
      inputRef={ref}
      onChange={onChange}
      readOnly={readOnly}
    />
  );
}
