import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useFormContext } from 'react-hook-form';

import {
  Benefit,
  genericErrorMessage,
  TabName
} from '@epi-constants/downloadReports';
import { goToPage } from '@epi-actions/navigation';
import {
  REPORTS_QUERY_NAME,
  REPORTS_SEARCH_NAME
} from '@epi-constants/externalReport';
import { showNotification } from '@epi-actions/overlays';
import { FormFields } from '@epi-forms/helpers';
import { fetchGet } from '@epi-helpers/FetchHelpers';
import { companyBenefitGroupSettingsActiveBenefitsUrl } from '@epi-repositories/BenefitGroupSettingsRepository';
import { ITabs } from '@epi-models/pages/DownloadReports';

import { LunchDeduction, IncomeRegisterTab, ReportsTab } from '../components';
import { getOther, setActiveBenefits } from '../helpers';

export const useSetTabs = () => {
  const { t } = useTranslation();
  const { search, pathname } = useLocation();
  const dispatch = useDispatch();
  const { watch, setValue } = useFormContext();
  const [tabs, setTabs] = useState<ITabs[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleURLChange = tabName =>
    dispatch(goToPage(pathname + REPORTS_QUERY_NAME + tabName));

  const handleOnTabChange = tabName => {
    handleURLChange(tabName);
  };

  const urlParams = new URLSearchParams(search);
  const activeTab = TabName[urlParams.get(REPORTS_SEARCH_NAME) ?? 'Other'];

  const isTransportBenefitActive = !!watch(
    getOther(FormFields.ActiveBenefits)
  )?.find(elem => elem.name === Benefit.Transport);

  const isLounariBenefitActive = !!watch(
    getOther(FormFields.ActiveBenefits)
  )?.find(elem => elem.name === Benefit.Lounari);

  const luchTab = {
    name: TabName.Lunch,
    label: t('download_reports.lunch_tab_name'),
    id: 'tab-lunch-deduction',
    body: <LunchDeduction />
  };

  const incomeTab = {
    name: TabName.Income,
    label: t('download_reports.income_tab_name'),
    id: 'tab-income-register-reports',
    body: <IncomeRegisterTab />
  };

  const otherTab = {
    name: TabName.Other,
    label: t('download_reports.other_tab_name'),
    id: 'tab-other-reports',
    body: <ReportsTab />
  };

  useEffect(() => {
    setIsLoading(true);
    const onSuccess = res => {
      setValue(getOther(FormFields.ActiveBenefits), setActiveBenefits(res));
      setIsLoading(false);
    };

    const onFailure = () => {
      dispatch(showNotification({ message: genericErrorMessage }, 'error'));
      setIsLoading(false);
    };

    fetchGet(
      companyBenefitGroupSettingsActiveBenefitsUrl(),
      {},
      onSuccess,
      onFailure
    );
  }, []);

  useEffect(() => {
    setTabs([otherTab]);
    if (isTransportBenefitActive) {
      setTabs(prevState => [incomeTab, ...prevState]);
    }
    if (isLounariBenefitActive) {
      setTabs(prevState => [luchTab, ...prevState]);
    }
  }, [isTransportBenefitActive, isLounariBenefitActive]);

  useEffect(() => {
    if (tabs.length) handleOnTabChange(tabs[0].name);
  }, [tabs]);

  return {
    tabs,
    handleOnTabChange,
    activeTab,
    isLoading
  };
};
