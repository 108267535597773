import React from 'react';
import { useTheme } from 'styled-components';
import { Box, RadioGroup, FormControlLabel, Radio } from 'edenred-ui';
import { useFormContext, useController } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';

import { IFundsLoadingTypeField } from '@epi-models/containers/ManageBenefitGroups';
import { FundsLoadingTypes } from '@epi-constants/benefitTypes';

export const FundsLoadingTypeField = ({
  name,
  disabled,
  benefitType
}: IFundsLoadingTypeField) => {
  const { sizeMedium, fontSemiBold, gray10, primaryBackColor } = useTheme();
  const { control } = useFormContext();
  const { t } = useTranslation();

  const {
    field: { value, onChange }
  } = useController({
    name,
    control,
    rules: {
      required: true
    }
  });

  return (
    <Box mt={3}>
      <Box
        fontSize={sizeMedium}
        fontWeight={fontSemiBold}
        mb={3}
        id={`${name}-loading-type-title`}
      >
        {t('components.manage_benefits.loading_type_field.title')}
      </Box>
      <RadioGroup
        onChange={onChange}
        value={value}
        id={`${name}-loading-type-radio`}
      >
        <Box display="flex" mb={2}>
          <FormControlLabel
            value={FundsLoadingTypes.TopUpRequest}
            control={<Radio />}
            label={t(
              'components.manage_benefits.loading_type_field.labels.top_up_request'
            )}
            id="choose-topup-requests-radio"
            disabled={disabled}
          />
          <FormControlLabel
            value={FundsLoadingTypes.DirectLoad}
            control={<Radio />}
            label={t(
              'components.manage_benefits.loading_type_field.labels.direct_load'
            )}
            id="choose-direct-load-radio"
            disabled={disabled}
          />
        </Box>
      </RadioGroup>
      <Box
        py={1.5}
        px={2}
        boxShadow={`0px 2px 12px 0px ${gray10}`}
        borderBottom={`1px solid ${primaryBackColor}`}
        mb={5}
        id={`${name}-loading-type-info`}
      >
        <Trans
          i18nKey={`components.manage_benefits.loading_type_field.${benefitType}_info`}
        />
      </Box>
    </Box>
  );
};
