import React from 'react';
import { Box, TitleText, BenefitTile, BenefitType } from 'edenred-ui';
import { useTheme } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { goToPage } from '@epi-actions/navigation';
import { routerPaths } from '@epi-constants/routerPaths';
import { IAvailableBenefits } from '@epi-models/pages/Home';
import { setGoToPageLink } from '@epi-actions/beneficiary';
import { selectIsOrderInProcess } from '@epi-selectors/benefitGroupSettings';

export const ActiveBenefits = ({ benefits }: IAvailableBenefits) => {
  const { gray85, sizeExtraExtraLarge, fontSemiBold } = useTheme();
  const dispatch = useDispatch();
  const isOrderInProgress = useSelector(selectIsOrderInProcess);
  const { t } = useTranslation();

  const handleGoToManageBenefits = (benefit: string) => {
    if (isOrderInProgress) {
      dispatch(setGoToPageLink(routerPaths.manageBenefits + '/' + benefit));
    } else {
      dispatch(goToPage(routerPaths.manageBenefits + '/' + benefit));
    }
  };

  return benefits.length ? (
    <Box mb={6}>
      <Box mb={3}>
        <TitleText
          fontSize={sizeExtraExtraLarge}
          fontWeight={fontSemiBold}
          noMargin
          color={gray85}
        >
          {t('home.benefits_tile.active_title')}
        </TitleText>
      </Box>
      <Box
        display="grid"
        gridTemplateColumns="1fr 1fr"
        rowGap={3}
        columnGap={3}
      >
        {benefits.map(elem => (
          <BenefitTile
            key={elem.id}
            title={elem.title}
            status={elem.status}
            benefitType={BenefitType[elem.type.toLowerCase()]}
            description={elem.description}
            buttonContent={elem.buttonContent}
            onClick={() => handleGoToManageBenefits(elem.type.toLowerCase())}
            labelText={elem.labelText}
          />
        ))}
      </Box>
    </Box>
  ) : null;
};
