import React from 'react';
import { PanelComponentProps } from '@epi-models/components/PanelComponent';

function PanelComponent({
  title,
  subtitle,
  children,
  ...otherProps
}: PanelComponentProps) {
  return (
    <div {...otherProps} className="px-10 panel panel-default">
      {!title && !subtitle ? null : (
        <div className="panel-heading">
          <span className="panel-title">
            {title && <h2>{title}</h2>}
            {subtitle && <h2 className="small">{subtitle}</h2>}
          </span>
        </div>
      )}
      <div className="panel-body">{children}</div>
    </div>
  );
}

export default PanelComponent;
