import { createSelector } from 'reselect';

export const OnlinePaymentMinValue = 0.51;

export const GeneralPaymentMinValue = 0.01;

export const getSettings = state => state.apiSettings || {};

export const getCreditCardPercentFee = createSelector(
  getSettings,
  settings => settings.creditCardFee || 0
);

export const getVoucherMinimumQuantity = createSelector(
  getSettings,
  settings => settings.voucherMinimumQuantity || 0
);

export const getMaxOrderTotalAmount = createSelector(
  getSettings,
  settings => settings.voucherOrderMaxAmount || 0
);

export const getLunchAmounts = createSelector(getSettings, settings => [
  settings.lunchMaxAmount,
  ...settings.lunchMidAmounts,
  settings.lunchMinAmount
]);

export const getRecreationalAmounts = createSelector(getSettings, settings => [
  settings.recreationalMaxAmount,
  settings.recreationalMinAmount
]);

export const getMassageAmounts = createSelector(getSettings, settings => [
  settings.massageMaxAmount,
  settings.massageMinAmount
]);

export const getTransportAmounts = createSelector(getSettings, settings => [
  settings.transportMaxAmount,
  settings.transportMinAmount
]);

export const getWellbeingAmounts = createSelector(getSettings, settings => [
  settings.wellbeingMaxAmount,
  settings.wellbeingMinAmount
]);

export const getReCaptchaSiteKey = createSelector(
  getSettings,
  settings => settings.reCaptchaSiteKey || ''
);

export const getOnlinePaymentMinAmount = createSelector(
  getSettings,
  settings => settings.onlinePaymentMinAmount || OnlinePaymentMinValue
);

export const getOfflinePaymentMinAmount = createSelector(
  getSettings,
  settings => settings.offlinePaymentMinAmount || GeneralPaymentMinValue
);

export const getFeesVatPercentage = createSelector(
  getSettings,
  settings => settings.feesVatPercentage || 0
);

export const getFeePercentage = createSelector(
  getSettings,
  settings => settings.feePercentage || 0
);
