import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  ButtonMode,
  Notification,
  NotificationType,
  ButtonSize
} from 'edenred-ui';

import { routerPaths } from '@epi-constants/routerPaths';
import { goToPage } from '@epi-actions/navigation';
import { IBeneficiariesTopSection } from '@epi-models/pages/Beneficiaries';

import { TopSection } from '../../BeneficiariesListStyles';

export const BeneficiariesTopSection = ({
  showBeneficiaryAddedNotification,
  setShowBeneficiaryAddedNotification
}: IBeneficiariesTopSection) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  return (
    <TopSection>
      <div className="employee-added-notification">
        {showBeneficiaryAddedNotification && (
          <Notification
            type={NotificationType.Success}
            text={
              <Box display="flex" alignItems="center">
                <Box flexGrow={1} mr={1}>
                  {t('employees.employee_added_notification')}
                </Box>
                <Box>
                  <Button
                    id="go-to-transfer-money-btn"
                    mode={ButtonMode.Secondary}
                    size={ButtonSize.Medium}
                    onClick={() => {
                      dispatch(goToPage(routerPaths.loadCompanyAccount));
                    }}
                  >
                    {t('employees.employee_added_go_to_transfer_money_btn')}
                  </Button>
                </Box>
              </Box>
            }
            onClose={() => setShowBeneficiaryAddedNotification(false)}
          />
        )}
      </div>
    </TopSection>
  );
};
