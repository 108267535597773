import React from 'react';
import { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Box, Button, ButtonMode } from 'edenred-ui';
import { useDispatch, useSelector } from 'react-redux';

import supportImg from '@epi-assets/images/SupportImage.png';
import { routerPaths } from '@epi-constants/routerPaths';
import { goToPage } from '@epi-actions/navigation';
import { setGoToPageLink } from '@epi-actions/beneficiary';
import { selectIsOrderInProcess } from '@epi-selectors/benefitGroupSettings';

import { SupportTileLink } from './SupportTileLink';

export const SupportTile = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useDispatch();
  const isOrderInProgress = useSelector(selectIsOrderInProcess);

  const handleGoToHelpPage = () => {
    if (isOrderInProgress) {
      dispatch(setGoToPageLink(routerPaths.help));
    } else {
      dispatch(goToPage(routerPaths.help));
    }
  };

  return (
    <Box
      mt={2}
      flex={1}
      height={284}
      p={3}
      bgcolor={theme.primaryBackColorLight10}
      display="flex"
      justifyContent="space-between"
      minWidth={800}
      id="home-support"
    >
      <Box>
        <Box
          fontSize={theme.sizeLarge}
          fontWeight={theme.fontSemiBold}
          mb={3}
          id="home-support-title"
        >
          {t('home.support.guides.title')}
        </Box>
        <SupportTileLink />
        <div id="home-support-help-box">
          <Box
            fontSize={theme.sizeLarge}
            fontWeight={theme.fontSemiBold}
            mt={5}
            mb={1}
          >
            {t('home.support.help.title')}
          </Box>
          <Box fontSize={theme.sizeMedium} mb={2}>
            {t('home.support.help.text')}
          </Box>
        </div>

        <Button
          mode={ButtonMode.Secondary}
          onClick={handleGoToHelpPage}
          id="support_link"
        >
          {t('home.support.help.button.text')}
        </Button>
      </Box>
      <Box pr={3} id="home-support-image">
        <img src={supportImg} />
      </Box>
    </Box>
  );
};
