import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Notification,
  NotificationType,
  Button,
  ButtonMode,
  ButtonSize
} from 'edenred-ui';

import { ISuccessNotification } from '@epi-models/containers/ManageBenefitGroups';

export const SuccessNotification = ({
  isBenefitGroupsError,
  text,
  onClose,
  handleFirstBtn,
  textForFirstBtn,
  handleSecondBtn,
  textForSecondBtn
}: ISuccessNotification) => {
  const { t } = useTranslation();
  return (
    <Box mb={1}>
      <Notification
        type={
          isBenefitGroupsError
            ? NotificationType.Error
            : NotificationType.Success
        }
        text={
          <Box display="flex" alignItems="center">
            {isBenefitGroupsError ? (
              <Box flexGrow={1} mr={2}>
                {t('messages.error')}
              </Box>
            ) : (
              <>
                <Box flexGrow={1} mr={1}>
                  {text}
                </Box>
                {handleFirstBtn && (
                  <Box mr={1}>
                    <Button
                      id="go-to-beneficiaries-btn"
                      mode={ButtonMode.Link}
                      size={ButtonSize.Medium}
                      onClick={handleFirstBtn}
                    >
                      {textForFirstBtn}
                    </Button>
                  </Box>
                )}
                {handleSecondBtn && (
                  <Box mr={1} display="flex" alignItems="center">
                    <Box mr={1}>
                      {t('components.manage_benefits.notification.or')}
                    </Box>
                    <Button
                      id="go-to-beneficiaries-btn"
                      mode={ButtonMode.Link}
                      size={ButtonSize.Medium}
                      onClick={handleSecondBtn}
                    >
                      {textForSecondBtn}
                    </Button>
                  </Box>
                )}
              </>
            )}
          </Box>
        }
        onClose={onClose}
      />
    </Box>
  );
};
