import { useEffect, useState } from 'react';
import { useFormContext, useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { IPillData } from '@epi-models/pages/DownloadReports';
import {
  WAGE_TYPE,
  setIncomePillsData,
  setPayrollPillsData
} from '@epi-constants/downloadReports';
import { FormFields } from '@epi-forms/helpers';

import { getIncomesRegister, getPayroll } from '../helpers';

export const useColumnSection = (
  pillsData: IPillData[],
  isIncomeTab: boolean
) => {
  const [pills, setPills] = useState(pillsData);
  const { t } = useTranslation();
  const { control } = useFormContext();
  const name = isIncomeTab
    ? getIncomesRegister(FormFields.Columns)
    : getPayroll(FormFields.Columns);

  const wageType = pills.find(item => item.type === WAGE_TYPE);

  const {
    field: { onChange }
  } = useController({
    name,
    control
  });

  const togglePill = (id: number) => {
    const updatedPills = pills.map(item =>
      item.id === id ? { ...item, isActive: !item.isActive } : item
    );
    setPills(updatedPills);
    onChange(updatedPills);
  };

  useEffect(() => {
    const pillsWithNewLang = isIncomeTab
      ? setIncomePillsData(t)
      : setPayrollPillsData(t);

    const updatedPills = pills.map(item => ({
      ...item,
      name: pillsWithNewLang[item.id].name
    }));

    setPills(updatedPills);
  }, [t]);

  return { pills, togglePill, wageType };
};
