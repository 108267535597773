import React from 'react';
import { ChevronIcon } from 'edenred-ui';

import { ISortingIcon } from '@epi-models/shared';

import { SortingIconBox } from './SortingIcon.styled';

export const SortingIcon = ({ isAscending }: ISortingIcon) => (
  <SortingIconBox isAscending={isAscending}>
    <ChevronIcon />
  </SortingIconBox>
);
