import { orderBy } from 'lodash';

// Scroll to the first element with the specified classname
export const scrollTo = className => {
  const elements = document.getElementsByClassName(className);

  if (elements.length > 0) {
    orderBy(elements, 'scrollTop', 'asc')[0].scrollIntoView({
      behavior: 'smooth',
      block: 'start'
    });
  }
};

export const scrollToInvalidField = () => {
  setTimeout(() => scrollTo('has-error'), 0); // Run after rendering
};
