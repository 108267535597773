import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Pill,
  PillType,
  Checkbox,
  Box,
  TitleText,
  TextField,
  Tooltip,
  Text
} from 'edenred-ui';
import { useTheme } from 'styled-components';
import { useFormContext, useController } from 'react-hook-form';

import { IColumnSection } from '@epi-models/pages/DownloadReports';
import { useColumnSection } from '@epi-pages/DownloadReports/hooks';
import { createRules, FormFields } from '@epi-forms/helpers';
import { maxLength, required } from '@epi-helpers/formHelpers/validators';
import {
  getIncomesRegister,
  getPayroll
} from '@epi-pages/DownloadReports/helpers';

export const ColumnSection = ({
  pillsData,
  isIncomeTab = false
}: IColumnSection) => {
  const { t } = useTranslation();
  const { sizeMedium, fontSemiBold, gray85, gray10 } = useTheme();
  const { pills, togglePill, wageType } = useColumnSection(
    pillsData,
    isIncomeTab
  );
  const { control, trigger, clearErrors } = useFormContext();
  const validators = [required, maxLength(50)];
  const rules = createRules(validators);
  const name = isIncomeTab
    ? getIncomesRegister(FormFields.WageType)
    : getPayroll(FormFields.WageType);

  const {
    field: { value, onChange, ref },
    fieldState: { error }
  } = useController({
    name,
    control,
    rules
  });

  useEffect(() => {
    if (wageType?.isActive) {
      trigger(name, { shouldFocus: true });
    } else {
      clearErrors(name);
    }
  }, [wageType?.isActive]);

  return (
    <Box mt={5} pb={5} borderBottom={1} borderColor={gray10}>
      <TitleText
        fontSize={sizeMedium}
        fontWeight={fontSemiBold}
        color={gray85}
        noMargin
      >
        {t('download_reports.lunch_deduction.list_title')}
      </TitleText>
      <Box
        display="flex"
        gap={2}
        pt={2.5}
        pb={3}
        flexWrap="wrap"
        data-test="payroll-report-options"
      >
        {pills.map(({ id, isActive, name, disabled }) => (
          <Tooltip
            disableHoverListener={!disabled}
            arrow
            key={id}
            placement="top"
            title={
              <Box maxWidth={230}>
                <Text small>
                  {t('download_reports.lunch_deduction.pill_tooltip_text')}
                </Text>
              </Box>
            }
          >
            <Box>
              <Pill
                type={PillType.Basic}
                id={`column-element-payroll-${name}`}
                label={name}
                isActive={isActive}
                action={() => (!disabled ? togglePill(id) : null)}
                icon={
                  <Checkbox disabled={disabled} noPadding checked={isActive} />
                }
                disabled={disabled}
              />
            </Box>
          </Tooltip>
        ))}
      </Box>
      {wageType?.isActive && (
        <Box width={345} pb={2.5}>
          <TextField
            ref={ref}
            data-test="payroll-report-wage-type"
            value={value}
            onChange={onChange}
            label={wageType.name}
            error={!!error}
            helperText={error?.message}
            placeholder={t(
              'download_reports.lunch_deduction.wage_type_placeholder'
            )}
          />
        </Box>
      )}
    </Box>
  );
};
