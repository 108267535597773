import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LoadingSpinner } from 'edenred-ui';
import PropTypes from 'prop-types';

import { getActiveBenefits } from '@epi-actions/api';
import { BenefitType } from '@epi-constants/benefitTypes';
import {
  selectActiveBenefits,
  selectIsActiveBenefitsLoaded
} from '@epi-selectors/benefitGroupSettings';

import { TopupUnavailable } from './TopupUnavailable';
import { TopupForm } from './TopupForm';

export const Topup = ({ updateFooter }) => {
  const dispatch = useDispatch();
  const [hasLoadableBenefit, setHasLoadableBenefit] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const activeBenefits = useSelector(selectActiveBenefits);
  const isActiveBenefitsLoaded = useSelector(selectIsActiveBenefitsLoaded);

  useEffect(() => {
    dispatch(getActiveBenefits());
  }, []);

  useEffect(() => {
    setHasLoadableBenefit(
      activeBenefits.some(benefit => benefit !== BenefitType.Lunch)
    );
    setIsLoading(false);
  }, [activeBenefits]);

  if (!isActiveBenefitsLoaded || isLoading) return <LoadingSpinner />;

  if (hasLoadableBenefit) return <TopupForm updateFooter={updateFooter} />;

  return <TopupUnavailable />;
};

Topup.propTypes = {
  updateFooter: PropTypes.func.isRequired
};
