import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';

import { InfoBar } from '@epi-shared/components';
import { FormFields } from '@epi-forms/helpers';
import {
  setIncomePillsData,
  DownloadType
} from '@epi-constants/downloadReports';
import { getIncomesRegister } from '@epi-pages/DownloadReports/helpers';

import { BenefitReportsGroup } from '../BenefitReportsGroup';
import { ColumnSection } from '../ColumnSection';
import { TimePeriod } from '../TimePeriod';
import { DownloadAs } from '../DownloadAs';

export const IncomeRegisterTab = () => {
  const { t } = useTranslation();

  const { getValues } = useFormContext();

  const pillsData =
    getValues(FormFields.IncomeRegister).columns || setIncomePillsData(t);

  return (
    <>
      <InfoBar
        text={t('download_reports.income_register.info_bar_text')}
        href={t('download_reports.income_register.info_bar_link')}
      />
      <BenefitReportsGroup isIncomeTab />
      <ColumnSection pillsData={pillsData} isIncomeTab />
      <TimePeriod
        getFieldName={getIncomesRegister}
        type={DownloadType.income}
      />
      <DownloadAs name={getIncomesRegister(FormFields.DownloadAs)} />
    </>
  );
};
