import React from 'react';
import { useTheme } from 'styled-components';

import { BenefitType } from '@epi-constants/benefitTypes';
import { IBenefitTypeIcon } from '@epi-models/containers/ManageBenefits';
import {
  WellbeingIcon,
  MassageIcon,
  TransportIcon,
  LunchIcon,
  RecreationalIcon
} from 'edenred-ui';

export const BenefitTypeIcon = ({ benefit }: IBenefitTypeIcon) => {
  const { primaryBackColorLight } = useTheme();

  switch (benefit) {
    case BenefitType.Lunch:
      return <LunchIcon width={82} color={primaryBackColorLight} />;
    case BenefitType.Recreational:
      return <RecreationalIcon width={82} color={primaryBackColorLight} />;
    case BenefitType.Transport:
      return <TransportIcon width={82} color={primaryBackColorLight} />;
    case BenefitType.Massage:
      return <MassageIcon width={82} color={primaryBackColorLight} />;
    case BenefitType.Wellbeing:
      return <WellbeingIcon width={82} color={primaryBackColorLight} />;
    default:
      return null;
  }
};
