import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { useTranslation } from 'react-i18next';
import { PagePanel } from 'edenred-ui';

import { getIsUserImpersonate, getProfile } from '@epi-selectors/login';

import { ApiConstants } from '../../constants/actions';
import { changeSelectedProfileTab } from '../../actions/profile';
import { ErrorPanel } from '../../components/ErrorPanel/ErrorPanel';
import { LoadingContainer } from '../../components/LoadingContainer/LoadingContainer';

import { UserInformation as UserInformationForm } from './UserInformation';
import { ChangePassword as ChangePasswordForm } from './ChangePassword';
import './Profile.scss';

export const Profile = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const profile = useSelector(getProfile);
  const isUserImpersonate = useSelector(getIsUserImpersonate);

  return (
    <PagePanel title={t('profile.title')}>
      <ErrorPanel failedActionName={ApiConstants.UPDATE_USER_INFORMATION} />
      <Tabs
        className="Profile"
        defaultIndex={profile.selectedProfileIndex}
        onSelect={tabIndex => dispatch(changeSelectedProfileTab(tabIndex))}
      >
        <TabList>
          <Tab>{t('containers.user_profile.user_info_tab')}</Tab>
          {!isUserImpersonate && (
            <Tab>{t('containers.user_profile.change_password_tab')}</Tab>
          )}
        </TabList>
        <div className="col-lg-8 col-md-10 col-xs-12">
          <TabPanel>
            <LoadingContainer isLoading={[ApiConstants.GET_USER_INFORMATION]}>
              <UserInformationForm />
            </LoadingContainer>
          </TabPanel>
          {!isUserImpersonate && (
            <TabPanel>
              <ChangePasswordForm />
            </TabPanel>
          )}
        </div>
      </Tabs>
    </PagePanel>
  );
};
