import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  PagePanel,
  Button,
  ButtonMode,
  Box,
  InProgressIcon,
  CircleSuccessIcon
} from 'edenred-ui';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { EmptyState } from '@epi-shared/components';
import { goToPage } from '@epi-actions/navigation';
import { routerPaths } from '@epi-constants/routerPaths';
import {
  clearFee,
  loadCompanyAccounts,
  loadCompanyMetadata
} from '@epi-actions/api';

export const TopupInProgress = ({
  setShowTopupForm,
  isTopupProcessing,
  summaryId,
  loadableBenefits,
  setForceUpdate
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const goToReports = () => {
    dispatch(
      goToPage(
        summaryId
          ? `${routerPaths.reportsTopups}/${summaryId}`
          : routerPaths.reports
      )
    );
  };

  const handleAnotherTopup = () => {
    dispatch(loadCompanyMetadata());
    if (loadableBenefits?.length > 1) dispatch(clearFee());
    setShowTopupForm(true);
    setForceUpdate(true);
  };

  useEffect(() => {
    if (!isTopupProcessing) {
      dispatch(loadCompanyAccounts());
    }
  }, [isTopupProcessing]);

  useEffect(() => {
    dispatch(loadCompanyMetadata());
  }, []);

  return (
    <PagePanel>
      <Box
        mt={6}
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <EmptyState
          icon={isTopupProcessing ? <InProgressIcon /> : <CircleSuccessIcon />}
          title={
            isTopupProcessing
              ? t(
                  'containers.deposit_money.topup_tab.topup_in_progress.in_progress'
                )
              : t(
                  'containers.deposit_money.topup_tab.topup_in_progress.completed'
                )
          }
          btnText={t(
            'containers.deposit_money.topup_tab.topup_in_progress.add_another'
          )}
          btnOnClick={handleAnotherTopup}
          btnDisabled={isTopupProcessing}
        />
        <Box mt={4}>
          <Button
            mode={ButtonMode.Link}
            onClick={goToReports}
            id="topup_report"
          >
            {t(
              'containers.deposit_money.topup_tab.topup_in_progress.see_report'
            )}
          </Button>
        </Box>
      </Box>
    </PagePanel>
  );
};

TopupInProgress.propTypes = {
  setShowTopupForm: PropTypes.func.isRequired,
  isTopupProcessing: PropTypes.bool.isRequired,
  summaryId: PropTypes.string,
  loadableBenefits: PropTypes.array,
  setForceUpdate: PropTypes.func.isRequired
};
