import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { getBalanceCorrectionsRequest } from '@epi-api/balanceCorrections';
import { dateRangeModes } from '@epi-constants/downloadReports';
import { apiToDefaultDateFormat } from '@epi-helpers/dateHelper';
import { setStartDate, setEndDate } from '@epi-helpers/reportsHelper';
import { CorrectionDetails } from '@epi-models/pages/Reports';

export const useBalanceCorrections = () => {
  const { t } = useTranslation();
  const [reports, setReports] = useState<CorrectionDetails[]>([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [timePeriodDialog, setTimePeriodDialog] = useState(false);
  const [timePeriodType, setTimePeriodType] = useState();
  const [from, setFrom] = useState<string | Date | null>(null);
  const [to, setTo] = useState<string | Date | null>(null);

  const handleChangePage = (newPage: number) => {
    setPage(newPage);
  };

  const getReports = async () => {
    const formattedFrom =
      typeof from === 'object' ? from?.toLocaleDateString('sv-SE') : from;
    const formattedTo =
      typeof to === 'object' ? to?.toLocaleDateString('sv-SE') : to;
    const response = await getBalanceCorrectionsRequest(
      page,
      from ? `${formattedFrom}T00:00:00.000Z` : '',
      to ? `${formattedTo}T23:59:00.000Z` : ''
    );
    setReports(response.transactions);
    setTotalPages(response.totalPages);
    setIsLoading(false);
  };

  useEffect(() => {
    setIsLoading(true);
    getReports();
  }, [page, from, to]);

  const datePicker =
    from && to && timePeriodType === dateRangeModes.datePicker
      ? `${apiToDefaultDateFormat(from)} - ${apiToDefaultDateFormat(to)}`
      : t('download_reports.lunch_deduction.time_period.select_dates');

  const options = [
    {
      display: t('download_reports.lunch_deduction.time_period.previous_month'),
      id: dateRangeModes.lastMonth
    },
    {
      display: t('download_reports.lunch_deduction.time_period.current_month'),
      id: dateRangeModes.currentMonth
    },
    {
      display: datePicker,
      id: dateRangeModes.datePicker
    }
  ];

  const handleTimePeriodChange = value => {
    setTimePeriodType(value.id);
    if (value.id === dateRangeModes.datePicker) {
      setFrom(null);
      setTo(null);
      setTimePeriodDialog(true);
    } else {
      setFrom(setStartDate(value.id));
      setTo(setEndDate(value.id));
    }
    setPage(1);
  };

  return {
    reports,
    page,
    totalPages,
    isLoading,
    timePeriodDialog,
    timePeriodType,
    options,
    handleChangePage,
    handleTimePeriodChange,
    setTimePeriodDialog,
    from,
    to,
    setFrom,
    setTo
  };
};
