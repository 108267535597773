import React from 'react';
import { Button, ButtonSize } from 'edenred-ui';
import { useTranslation } from 'react-i18next';

import { OrderButtonProps } from '@epi-models/components/OrderButton';

const OrderButton = ({
  isLoading,
  currentPage,
  totalPages,
  onClick,
  disabled
}: OrderButtonProps) => {
  const { t } = useTranslation();

  return (
    <Button
      id="order-btn"
      className="pull-right"
      type="submit"
      aria-hidden
      disabled={isLoading || disabled}
      onClick={() => onClick()}
      size={ButtonSize.Medium}
    >
      {currentPage < totalPages
        ? t('containers.voucher_order.continue')
        : t('containers.voucher_order.confirm')}
    </Button>
  );
};

OrderButton.defaultProps = {
  isLoading: false,
  onClick: () => {}
};

const OrderButtonWthI18n = OrderButton;

export { OrderButtonWthI18n as OrderButton };
