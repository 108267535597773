import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import {
  getLunchAmounts,
  getRecreationalAmounts,
  getMassageAmounts,
  getTransportAmounts,
  getWellbeingAmounts
} from '@epi-selectors/settings';

export const useGetAmounts = () => {
  const { benefitGroup } = useParams<{ benefitGroup: string }>();

  if (benefitGroup === 'lunch') return useSelector(getLunchAmounts);
  if (benefitGroup === 'recreational')
    return useSelector(getRecreationalAmounts);
  if (benefitGroup === 'massage') return useSelector(getMassageAmounts);
  if (benefitGroup === 'wellbeing') return useSelector(getWellbeingAmounts);
  if (benefitGroup === 'transport') return useSelector(getTransportAmounts);
  return [];
};
