import React from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Card, Col } from 'react-bootstrap';
import { Button, ButtonSize, SuccessCheckIcon } from 'edenred-ui';

import { IVoucherSuccessfulScreen } from '@epi-models/containers/OrderStatus';

import { routerPaths } from '../../constants/routerPaths';
import { PaymentMethods } from '../../constants/paymentMethods';
import { DownloadInvoiceButton } from '../../components/DownloadInvoiceButton/DownloadInvoiceButton';

export function VoucherSuccessfulScreen({
  isPaymentSuccess,
  getTranslationPath,
  paymentMethod,
  forwardEmail,
  isReceiptOnly,
  paymentId,
  isNewCustomer,
  paymentOnlyFlow,
  goToPage
}: IVoucherSuccessfulScreen) {
  const { t } = useTranslation();

  return (
    <Card>
      <Card.Body>
        <Card.Title>
          <Row className="no-wrap">
            <Col xs={9} sm={10}>
              <h1 className="text">
                {isPaymentSuccess
                  ? t(getTranslationPath('title_success_payment'))
                  : t(getTranslationPath('title_success'))}
              </h1>
            </Col>
            <Col xs={3} sm={2} className="d-lg-none">
              <h1 className="icon">
                <SuccessCheckIcon />
              </h1>
            </Col>
          </Row>
        </Card.Title>
        <Row className="pl-15 mb-10">
          <Col xs={11}>
            <h2 className="small">{t(getTranslationPath('body_success'))}</h2>
            {paymentMethod === PaymentMethods.PrintInvoicePdf && (
              <h2 className="small">
                {t('containers.order_status.body_reference_number_info')}
              </h2>
            )}
            {paymentMethod === PaymentMethods.LinkToOtherPerson ? (
              <h2 className="payment-link small">
                {t('containers.order_status.payment_link')} {forwardEmail}
              </h2>
            ) : (
              <div>
                <h2 className="small">
                  {isReceiptOnly
                    ? t('containers.order_status.receipt')
                    : t('containers.order_status.invoice')}
                </h2>
                <DownloadInvoiceButton
                  id={paymentId}
                  idType="payment"
                  className="btn-download"
                >
                  <span>
                    {isReceiptOnly
                      ? t('containers.order_status.download_receipt')
                      : t('containers.order_status.download_invoice')}
                  </span>
                </DownloadInvoiceButton>
              </div>
            )}
            {isNewCustomer && !paymentOnlyFlow ? (
              <div className="pt-20">
                <h2 className="small">
                  {t('containers.order_status.new_customer')}
                </h2>
              </div>
            ) : (
              ''
            )}
          </Col>
        </Row>
        <Row>
          <Col xs={{ span: 11, offset: 1 }}>
            <Button
              id="voucher-success-confirm-btn"
              size={ButtonSize.Medium}
              className="pull-right"
              onClick={() =>
                paymentOnlyFlow
                  ? goToPage(`${routerPaths.home}`)
                  : goToPage(`${routerPaths.orderHistory}`)
              }
            >
              {paymentOnlyFlow
                ? t('containers.order_status.return_to_home')
                : t(getTranslationPath('button_success'))}
            </Button>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}
