import React, { useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import MomentLocaleUtils from 'react-day-picker/moment';
import { isAfter, isBefore, addDays, subDays } from 'date-fns';
import 'react-day-picker/lib/style.css';
import 'moment/locale/en-gb';
import 'moment/locale/fi';

import { DateRangePickerProps } from '@epi-models/components/DateRangePicker';

import { datePickerDefaultFormat } from '../../constants/dateFormats';

import { DateRangePickerStyles } from './DateRangePicker.styles';

const DateRangePicker = ({
  from,
  setFrom,
  to,
  setTo,
  validateFrom = null,
  validateTo = null,
  disableFutureDates = null,
  disabledCustomDates
}: DateRangePickerProps) => {
  const { t } = useTranslation();
  const today = new Date();
  const fromElement = useRef<DayPickerInput>(null);
  const toElement = useRef<DayPickerInput>(null);
  const handleFromChange = fromValue => {
    if (fromValue) {
      const fromValueIsValid =
        validateFrom === null ? true : validateFrom(fromValue);
      if (fromValueIsValid) {
        setFrom(fromValue);
        if (to && isAfter(fromValue, to)) {
          const newToDate = addDays(fromValue, 1);
          if (!isAfter(newToDate, today)) {
            setTo(addDays(fromValue, 1));
          } else {
            setTo(fromValue);
          }
        }
      }
    }
  };

  const handleToChange = toValue => {
    const toValueIsValid = validateTo === null ? true : validateTo(toValue);
    if (toValueIsValid) {
      setTo(toValue);
      if (from && isBefore(toValue, from)) {
        const newFromDate = subDays(toValue, 1);
        setFrom(newFromDate);
      }
    }
  };

  useEffect(() => {
    if (fromElement && fromElement.current) {
      fromElement.current.getInput().focus();
    }
  }, []);
  const modifiers = { start: from, end: to };
  const disabledDays =
    disabledCustomDates || (disableFutureDates ? { after: today } : undefined);

  return (
    <DateRangePickerStyles>
      <div className="InputFromTo">
        <div className="input-label-group" id="date-from">
          <label>{t('components.date_range_dialog.from')}</label>
          <DayPickerInput
            ref={fromElement}
            value={from}
            format={datePickerDefaultFormat}
            placeholder={datePickerDefaultFormat}
            formatDate={MomentLocaleUtils.formatDate}
            parseDate={MomentLocaleUtils.parseDate}
            dayPickerProps={{
              selectedDays: [from, { from, to }],
              disabledDays,
              toMonth: to,
              modifiers,
              numberOfMonths: 1,
              onDayClick: () => toElement?.current?.getInput().focus(),
              localeUtils: MomentLocaleUtils
            }}
            onDayChange={handleFromChange}
            showOverlay
          />
        </div>
        <div className="InputFromTo-to input-label-group" id="date-to">
          <label>{t('components.date_range_dialog.to')}</label>
          <DayPickerInput
            ref={toElement}
            value={to}
            format={datePickerDefaultFormat}
            placeholder={datePickerDefaultFormat}
            formatDate={MomentLocaleUtils.formatDate}
            parseDate={MomentLocaleUtils.parseDate}
            dayPickerProps={{
              selectedDays: [from, { from, to }],
              disabledDays,
              modifiers,
              month: from,
              fromMonth: from,
              numberOfMonths: 1,
              localeUtils: MomentLocaleUtils
            }}
            onDayChange={handleToChange}
            showOverlay={false}
            hideOnDayClick={false}
          />
        </div>
      </div>
    </DateRangePickerStyles>
  );
};

export default DateRangePicker;
