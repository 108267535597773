import { useEffect, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { topupLoadApiUrl, getTopupInProgressRequest } from '@epi-api/topupLoad';
import { fetchPost as Post } from '@epi-helpers/FetchHelpers';
import { showNotification } from '@epi-actions/overlays';
import { getBenefitGroupSettings } from '@epi-actions/api';

export const useTopupRequest = (
  setShowConfirmationModal,
  isToggle,
  showOnHoldBtn
) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [isTopupPending, setIsTopupPending] = useState(false);
  const [isTopupProcessing, setIsTopupProcessing] = useState(false);
  const [summaryId, setSummaryId] = useState();
  const timerRef = useRef(null);

  const getTopupInProgress = async () => {
    const response = await getTopupInProgressRequest();
    setIsTopupProcessing(response.isTopUpLoadInProgress);

    if (response.isTopUpLoadInProgress) {
      timerRef.current = setTimeout(() => {
        getTopupInProgress();
      }, 3000);
    }
  };

  const postTopupRequest = payload => {
    setIsTopupPending(true);

    const onSuccess = response => {
      setSummaryId(response.topUpLoadId);
      setIsTopupProcessing(true);
      setIsTopupPending(false);
      setShowConfirmationModal(false);
    };
    const onFailure = error => {
      if (t(`messages.${error.content.errors[0].code}`)) {
        dispatch(
          showNotification(
            {
              message: `messages.${error.content.errors[0].code}`
            },
            'error'
          )
        );
      } else {
        dispatch(showNotification({ message: 'messages.error' }, 'error'));
      }
      setIsTopupPending(false);
      setShowConfirmationModal(false);
    };
    return Post(
      topupLoadApiUrl,
      { ...payload, showOnHoldBeneficiaries: showOnHoldBtn ? isToggle : null },
      onSuccess
    ).catch(onFailure);
  };

  useEffect(() => {
    dispatch(getBenefitGroupSettings());
  }, []);

  useEffect(() => {
    getTopupInProgress();
  }, [isTopupProcessing]);

  useEffect(() => {
    return () => {
      clearTimeout(timerRef.current);
    };
  }, []);

  return {
    postTopupRequest,
    isTopupPending,
    isTopupProcessing,
    summaryId
  };
};
