import { availableLanguages } from '../constants/languages';

export const getDefaultLanguage = () => {
  const result = availableLanguages.filter(lg => lg.default);
  return result.length >= 1 ? result[0] : availableLanguages[0];
};

export const findLanguageById = languageId => {
  const result = availableLanguages.filter(lg => lg.id === languageId);
  return result.length >= 1 ? result[0] : availableLanguages[0];
};
