import React from 'react';
import { bindActionCreators } from 'redux';
import { LoadingSpinner } from 'edenred-ui';
import { connect } from 'react-redux';

import { LoadingContainerProps } from '@epi-models/components/LoadingContainer';

import { isLoading as isLoadingSelector } from '../../selectors/saga';
import * as navigationActions from '../../actions/navigation';

import './LoadingContainer.scss';

const LoadingContainer = ({ children, isLoading }: LoadingContainerProps) => (
  <div className="LoadingContainer">
    {isLoading && <LoadingSpinner />}
    <div className={`content ${isLoading ? 'loading' : ''}`}>{children}</div>
  </div>
);

const mapStateToProps = (state, props) => ({
  isLoading: Array.isArray(props.isLoading)
    ? isLoadingSelector(props.isLoading)(state)
    : props.isLoading
});
const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...navigationActions }, dispatch);

const ConnectedLoadingContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(LoadingContainer);

export { ConnectedLoadingContainer as LoadingContainer };
