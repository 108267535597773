import React, { useEffect, useState } from 'react';
import { Box } from 'edenred-ui';

import { getTopupReportsRequest } from '@epi-api/topupLoad';
import { Pagination } from '@epi-shared/components';

import { TopupReportsTable } from './TopupReportsTable';
import { ReportDetails } from '@epi-models/pages/Reports';

const reportsPerPage = 9;

export const TopupReports = () => {
  const [reports, setReports] = useState<ReportDetails[]>([]);
  const [page, setPage] = useState(1);
  const [visibleReports, setVisibleReports] = useState<ReportDetails[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const paginationCount = Math.ceil(reports.length / reportsPerPage);

  const handleChangePage = (newPage: number) => {
    setPage(newPage);
  };

  const getReports = async () => {
    const response = await getTopupReportsRequest();
    setReports(response.topUpLoads);
    setIsLoading(false);
  };

  useEffect(() => {
    getReports();
  }, []);

  useEffect(() => {
    setVisibleReports(
      reports.slice(
        page * reportsPerPage - reportsPerPage,
        page * reportsPerPage
      )
    );
  }, [reports, page]);

  return (
    <Box marginTop="40px">
      <TopupReportsTable
        visibleReports={visibleReports}
        isLoading={isLoading}
      />
      <Box mt={visibleReports.length ? 3 : 30}>
        {visibleReports.length ? (
          <Pagination
            page={page}
            count={paginationCount}
            defaultRowsPerPage={reportsPerPage}
            onChangePage={handleChangePage}
          />
        ) : null}
      </Box>
    </Box>
  );
};
