import React from 'react';
import { Box, Table } from 'edenred-ui';
import { useTranslation } from 'react-i18next';

import { currencyCommaConvert } from '@epi-helpers/numeral';
import { TopupReportsItemsTableProps } from '@epi-models/pages/Reports';

import { StatusDot } from '../../TopupReports.styles';
import { reportStatusesColors } from '../../../utils';

export const TopupReportsItemsTable = ({
  visibleReportItems
}: TopupReportsItemsTableProps) => {
  const { t } = useTranslation();

  return (
    <Table id="topup-reports-single-table">
      <Table.Head>
        <Table.Row>
          <Table.Cell width="16%" id="topup-reports-single-table-name">
            {t('containers.deposit_money.reports_tab.name')}
          </Table.Cell>
          <Table.Cell width="25%" id="topup-reports-single-table-personal_id">
            {t('containers.deposit_money.reports_tab.personal_id')}
          </Table.Cell>
          <Table.Cell width="15%" id="topup-reports-single-table-status">
            {t('containers.deposit_money.reports_tab.status')}
          </Table.Cell>
          <Table.Cell
            width="10%"
            align="right"
            id="topup-reports-single-table-load"
          >
            {t('containers.deposit_money.reports_tab.load')}
          </Table.Cell>
        </Table.Row>
      </Table.Head>
      <Table.Body>
        {visibleReportItems?.map((item, i) => (
          <Table.Row key={item.personalId + i}>
            <Table.Cell width="47%" id="topup-reports-single-table-cell-name">
              {item.firstName} {item.lastName}
            </Table.Cell>
            <Table.Cell
              width="20%"
              id="topup-reports-single-table-cell-personal_id"
            >
              {item.personalId}
            </Table.Cell>
            <Table.Cell width="22%" id="topup-reports-single-table-cell-status">
              <Box display="flex" alignItems="center">
                <StatusDot $dotColor={reportStatusesColors[item.status]} />
                {t(
                  `containers.deposit_money.reports_tab.statuses.${item.status}`
                )}
              </Box>
            </Table.Cell>
            <Table.Cell width="11%" align="right">
              {currencyCommaConvert(item.amount)}
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
};
