import React, { useEffect } from 'react';
import { Row, Card, Col } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import { connect, useSelector, useDispatch } from 'react-redux';
import { Route, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, ButtonSize } from 'edenred-ui';

import { OrderType } from '@epi-constants/orderType';
import { getCompanyInvoicePFD } from '@epi-selectors/companyAccounts';
import { goToPage } from '@epi-actions/navigation';
import { IOrderStatus } from '@epi-models/containers/OrderStatus';

import {
  getApiPaymentStatus,
  isNewCustomer as isNewCustomerSelector
} from '../../selectors/api';
import { routerPaths } from '../../constants/routerPaths';
import { PaymentStatuses } from '../../constants/paymentStatuses';
import { PaymentMethods } from '../../constants/paymentMethods';
import { NotFound } from '../NotFound/NotFound';
import { LoadingContainer } from '../../components/LoadingContainer/LoadingContainer';
import * as apiActions from '../../actions/api';
import * as navigationActions from '../../actions/navigation';
import './OrderStatus.scss';
import { CardOrderSuccessfulScreen } from './CardOrderSuccessfulScreen';
import { VoucherSuccessfulScreen } from './VoucherSuccessfulScreen';
import { PDFInvoiceSuccess } from './PDFInvoiceSuccess';

function OrderStatus({
  isNewCustomer,
  paymentOnlyFlow = false,
  orderType: propsOrderType = '',
  isDeposit = false,
  apiPaymentStatus,
  getPaymentStatus
}: IOrderStatus) {
  const {
    status,
    forwardEmail,
    paymentMethod,
    isLoading,
    isUnauthorized,
    orderType: apiOrderType
  } = apiPaymentStatus;
  const { t } = useTranslation();
  const { paymentId } = useParams<{ paymentId: string }>();
  const dispatch = useDispatch();
  const orderType = apiOrderType || propsOrderType;
  const isPrintInvoicePdfForTransferMoney = useSelector(getCompanyInvoicePFD);

  useEffect(() => {
    getPaymentStatus(paymentId);
  }, []);

  const redirectionUrl = () => {
    if (orderType === OrderType.digital) {
      return isDeposit
        ? routerPaths.loadCompanyAccount
        : routerPaths.cardOrderPayment;
    }
    return routerPaths.voucherOrderPayment;
  };

  function getTranslationPath(value, paid = false) {
    let after = value ? `.${value}` : '';
    if (orderType === OrderType.digital) {
      const digiOrderType = isDeposit ? 'deposit_order' : 'card_order';
      after = paid && !isDeposit ? `${after}_payment` : after;
      const result = `containers.order_status.${orderType}.${digiOrderType}${after}`;
      return result;
    }
    return `containers.order_status.${orderType}${after}`;
  }

  const isPaidOnline = [
    PaymentMethods.CreditCard,
    PaymentMethods.Online
  ].includes(paymentMethod);

  const isPaymentSuccess =
    isPaidOnline &&
    [
      PaymentStatuses.Pending,
      PaymentStatuses.Paid,
      PaymentStatuses.Confirmed
    ].includes(status);

  const isSuccessful = !isPaidOnline || isPaymentSuccess;

  const isReceiptOnly = isPaidOnline && OrderType.voucher !== orderType;

  const handleClickGoToPage = (url: string) => {
    dispatch(
      goToPage(url, {
        params: { paymentId },
        target: null
      })
    );
  };

  const successTemplate = () => {
    if (isPrintInvoicePdfForTransferMoney) {
      return <PDFInvoiceSuccess paymentId={paymentId} />;
    }

    if (orderType === OrderType.digital) {
      return (
        <CardOrderSuccessfulScreen
          isPaymentSuccess={isPaymentSuccess}
          getTranslationPath={getTranslationPath}
          paymentMethod={paymentMethod}
          paymentId={paymentId}
          isReceiptOnly={isReceiptOnly}
          goToPage={handleClickGoToPage}
        />
      );
    }
    return (
      <VoucherSuccessfulScreen
        isPaymentSuccess={isPaymentSuccess}
        getTranslationPath={getTranslationPath}
        paymentMethod={paymentMethod}
        forwardEmail={forwardEmail}
        isReceiptOnly={isReceiptOnly}
        paymentId={paymentId}
        isNewCustomer={isNewCustomer}
        paymentOnlyFlow={paymentOnlyFlow}
        goToPage={handleClickGoToPage}
      />
    );
  };

  const canceledTemplate = () => {
    const buttonText = orderType === OrderType.digital ? 'digital' : 'voucher';
    return (
      <Card>
        <Card.Body>
          <Card.Title as="span">
            <h1 className="text">{t(getTranslationPath('title_failed'))}</h1>
          </Card.Title>
          <Row>
            <Col xs={11}>
              <h2 className="small">{t(getTranslationPath('body_failed'))}</h2>
              {isNewCustomer && !paymentOnlyFlow ? (
                <div>
                  <h2 className="small">
                    {t('containers.order_status.new_customer_failed')}
                  </h2>
                </div>
              ) : (
                ''
              )}
            </Col>
          </Row>
          <Row>
            <Col xs={{ span: 1, offset: 11 }}>
              <Button
                id="back-to-payment-method-btn"
                size={ButtonSize.Medium}
                className="float-right"
                onClick={() => handleClickGoToPage(redirectionUrl())}
              >
                {t(`containers.order_status.button_failed_${buttonText}`)}
              </Button>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    );
  };

  return (
    <div className="OrderStatus">
      {isUnauthorized ? (
        <Route component={NotFound} />
      ) : (
        <div>
          <LoadingContainer isLoading={isLoading}>
            {isSuccessful && successTemplate()}
            {!isSuccessful && canceledTemplate()}
          </LoadingContainer>
        </div>
      )}
    </div>
  );
}

const mapStateToProps = state => ({
  apiPaymentStatus: getApiPaymentStatus(state),
  isNewCustomer: isNewCustomerSelector(state)
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...apiActions, ...navigationActions }, dispatch);

const ConnectedOrderStatus = connect(
  mapStateToProps,
  mapDispatchToProps
)(OrderStatus);

export { ConnectedOrderStatus as OrderStatus };
