import styled from 'styled-components';

export const BenefitTypeTileContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  width: 296px;
  margin-bottom: 24px;
  margin-right: 24px;
  padding: 24px;
  background-color: ${props =>
    props.active ? props.theme.primaryBackColorLight10 : props.theme.gray0};
  border: 1px solid;
  border-color: ${props => props.theme.altGray2};

  &:last-child {
    margin-right: 0;
  }

  &:hover {
    background-color: ${props =>
      !props.active ? '' : props.theme.primaryBackColorLight10};
    box-shadow: ${props =>
      !props.active
        ? ''
        : `1px 1px 30px 10px ${props.theme.primaryBackColorLight10}`};
  }
`;

export const BenefitTypeStatus = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  right: 12px;
  top: 12px;
  padding: 4px 12px;
  background-color: ${props => props.theme.gray0};
  border-radius: 20px;
`;

export const BenefitTypeStatusText = styled.div`
  color: ${props => props.theme.gray50};
  font-size: ${props => props.theme.sizeExtraSmall};
  line-height: ${props => props.theme.sizeMedium};
  letter-spacing: 0;

  &::before {
    content: '';
    display: inline-block;
    width: 8px;
    height: 8px;
    margin-right: 8px;
    background-color: #42c446;
    border-radius: 100%;
  }
`;

export const BenefitTypeName = styled.div`
  color: ${props => props.theme.primaryFrontColor};
  font-size: ${props => props.theme.sizeExtraExtraLarge};
  font-weight: ${props => props.theme.fontSemiBold};
  margin: 15px 0 35px;
`;
