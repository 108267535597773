import React from 'react';
import { Box } from 'edenred-ui';
import { useFormContext, useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { BenefitGroupTile, NotificationInfo } from '@epi-shared/components';
import { FormFields } from '@epi-forms/helpers';
import {
  IBenefitGroupSettings,
  IBenefits
} from '@epi-models/pages/Beneficiaries';

export const Benefits = ({ isAddMode = false }: IBenefits) => {
  const { control } = useFormContext();
  const { t } = useTranslation();

  const {
    field: { value: activeBenefits }
  } = useController({
    name: FormFields.Benefits,
    control
  });

  return (
    <Box mt={8} pb={6} id="employee-benefit-benefits-tab">
      {isAddMode && (
        <Box display="flex" mb={4}>
          <NotificationInfo
            text={t(
              'containers.beneficiaries.add_employee.benefits_notification_text'
            )}
            id="employee-benefit-benefits-tab-notification-info"
          />
        </Box>
      )}
      <Box display="grid" gridTemplateColumns="1fr 1fr">
        {activeBenefits.map((groupDetails: IBenefitGroupSettings, index) => (
          <BenefitGroupTile
            key={groupDetails.groupId}
            groupName={t(
              `components.manage_benefits.new.benefits.${groupDetails.benefitType.toLowerCase()}`
            )}
            maxAmountPerPeriod={groupDetails.maxAmountPerPeriod}
            index={index}
            noHover
            benefitType={groupDetails.benefitType.toLowerCase()}
          />
        ))}
      </Box>
    </Box>
  );
};
