import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  Benefit,
  setPayrollPillsData,
  setIncomePillsData,
  Download,
  dateRangeModes,
  setOtherReportsDataPills
} from '@epi-constants/downloadReports';
import { FormFields } from '@epi-forms/helpers';
import { setEndDate, setStartDate } from '@epi-helpers/reportsHelper';

export const useDownloadReportsForm = () => {
  const { t } = useTranslation();
  const form = useForm({
    mode: 'onChange',
    defaultValues: {
      [FormFields.Payroll]: {
        [FormFields.Benefit]: Benefit.Lounari,
        [FormFields.Columns]: setPayrollPillsData(t),
        [FormFields.DownloadAs]: Download.CSV,
        [FormFields.EndDate]: setEndDate(dateRangeModes.lastMonth),
        [FormFields.StartDate]: setStartDate(dateRangeModes.lastMonth),
        [FormFields.SelectedID]: dateRangeModes.lastMonth,
        [FormFields.WageType]: ''
      },
      [FormFields.Other]: {
        [FormFields.ActiveBenefits]: null,
        [FormFields.StartDate]: setStartDate(dateRangeModes.currentMonth),
        [FormFields.EndDate]: setEndDate(dateRangeModes.currentMonth),
        [FormFields.PickerStartDate]: null,
        [FormFields.SelectedID]: dateRangeModes.currentMonth,
        [FormFields.PickerEndDate]: null,
        [FormFields.LoadStatuses]: 'Fulfilled',
        [FormFields.Columns]: setOtherReportsDataPills(t),
        [FormFields.DataScope]: {
          [FormFields.EmployeeData]: false,
          [FormFields.Benefits]: false,
          [FormFields.Loads]: false
        },
        [FormFields.RequestDownloadReport]: false,
        [FormFields.Report]: null,
        [FormFields.DownloadAs]: Download.CSV
      },
      [FormFields.IncomeRegister]: {
        [FormFields.Benefit]: Benefit.Transport,
        [FormFields.Columns]: setIncomePillsData(t),
        [FormFields.DownloadAs]: Download.CSV,
        [FormFields.EndDate]: setEndDate(dateRangeModes.lastMonth),
        [FormFields.StartDate]: setStartDate(dateRangeModes.lastMonth),
        [FormFields.SelectedID]: dateRangeModes.lastMonth,
        [FormFields.WageType]: ''
      }
    }
  });
  return { form };
};
