import React from 'react';
import { Link } from 'react-router-dom';
import { nanoid } from 'nanoid';
import { BreadcrumbProps } from '@epi-models/components/Breadcrumb';

const Breadcrumb = ({ backIcon = false, linkSegments }: BreadcrumbProps) => {
  const breadcrumbSegments = linkSegments.map(s => (
    <Link key={nanoid()} to={s.link}>
      {s.label}
    </Link>
  ));

  return (
    <p style={{ fontWeight: '500' }}>
      {backIcon && '< '}
      {breadcrumbSegments}
    </p>
  );
};

export default Breadcrumb;
