import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Box, Button, ButtonMode, Link, TitleText, Text } from 'edenred-ui';
import { useTheme } from 'styled-components';

import supportImg from '@epi-assets/images/SupportImage.png';

export const SupportContact = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <Box
      display="grid"
      gridTemplateColumns="1fr 1fr"
      pt={6}
      px={25}
      pb={2}
      mx={-5}
      mt={8}
      sx={{ backgroundColor: theme.primaryBackColorLight10 }}
    >
      <Box>
        <TitleText>{t('containers.help.contact.title')}</TitleText>
        <Text paragraph>
          <Trans
            defaults={t('containers.help.contact.content')}
            components={{
              a: <Link href={t('containers.help.contact.content_link')} />
            }}
          />
        </Text>
        <Box display="flex" flexDirection="row" gap={3}>
          <Button
            mode={ButtonMode.Secondary}
            id="support-page-contact-button-one"
          >
            <Link
              target="_blank"
              href={t('containers.help.contact.button_one_link')}
            >
              {t('containers.help.contact.button_one')}
            </Link>
          </Button>
          <Button
            mode={ButtonMode.Secondary}
            id="support-page-contact-button-two"
          >
            <Link
              target="_blank"
              href={t('containers.help.contact.button_two_link')}
            >
              {t('containers.help.contact.button_two')}
            </Link>
          </Button>
        </Box>
      </Box>
      <Box display="flex" flexDirection="row-reverse">
        <img alt="support_image" src={supportImg} />
      </Box>
    </Box>
  );
};
