import React from 'react';
import { PagePanel } from 'edenred-ui';
import { connect } from 'react-redux';

import { LoadingPanelProps } from '@epi-models/components/LoadingPanel';

import { isLoading as isLoadingSelector } from '../../selectors/saga';
import { FixedPanel } from '../FixedPanel/FixedPanel';

import './LoadingPanel.scss';

const LoadingPanel = ({
  children,
  isLoading,
  fixedWidth = false,
  ...rest
}: LoadingPanelProps) => {
  const isFunction = typeof children === 'function';

  return (
    <div className="LoadingPanel">
      {fixedWidth ? (
        <FixedPanel className={isLoading ? 'isLoading' : ''}>
          {isFunction ? children({ isLoading }) : children}
        </FixedPanel>
      ) : (
        <PagePanel className={isLoading ? 'isLoading' : ''} {...rest}>
          {isFunction ? children({ isLoading }) : children}
        </PagePanel>
      )}
    </div>
  );
};

const mapStateToProps = (state, props) => ({
  isLoading: Array.isArray(props.isLoading)
    ? isLoadingSelector(props.isLoading)(state)
    : props.isLoading
});

const ConnectedLoadingPanel = connect(mapStateToProps)(LoadingPanel);

export { ConnectedLoadingPanel as LoadingPanel };
