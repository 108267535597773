import React from 'react';
import { useTranslation } from 'react-i18next';
import { SlideoutPanelComponent as Slideout, Tabs } from 'edenred-ui';
import { FormProvider } from 'react-hook-form';

import { CloseDetailDialog } from '@epi-shared/components';
import { IAddBeneficiarySlideout } from '@epi-models/pages/Beneficiaries';

import { AddBeneficiaryActions } from './components';
import { useAddBeneficiarySlideout, useAddBeneficiaryTab } from './hooks';

export const AddBeneficiarySlideout = (props: IAddBeneficiarySlideout) => {
  const { expanded, isProcessing } = props;
  const { t } = useTranslation();
  const { tabs } = useAddBeneficiaryTab();
  const {
    handleClose,
    addAnotherEmployee,
    toggleAddAnotherEmployee,
    handleSubmitFormExecute,
    disableSubmit,
    form,
    showCloseConfirmation,
    handleCloseConfirmation,
    confirmCloseWithoutSaving,
    confirmSaving
  } = useAddBeneficiarySlideout({ ...props });

  return (
    <Slideout
      title={t('containers.beneficiaries.add_employee.main_action')}
      expanded={expanded}
      onClose={handleClose}
      closeButtonId="close-beneficiary-slideout"
      actions={
        <AddBeneficiaryActions
          addAnotherEmployee={addAnotherEmployee}
          isProcessing={isProcessing}
          toggleAddAnotherEmployee={toggleAddAnotherEmployee}
          handleClose={handleClose}
          handleSubmitFormExecute={handleSubmitFormExecute}
          disableSubmit={disableSubmit}
        />
      }
    >
      <FormProvider {...form}>
        <Tabs tabs={tabs} defaultTabName={'Personal Data'} />
      </FormProvider>
      <CloseDetailDialog
        isOpen={showCloseConfirmation}
        title={t('containers.beneficiaries.close_detail_dialog.title')}
        handleCloseModal={handleCloseConfirmation}
        primaryAction={confirmCloseWithoutSaving}
        secondaryAction={confirmSaving}
        secondaryActionText={t(
          'containers.beneficiaries.close_add_employee_dialog.back'
        )}
        primaryActionText={t(
          'containers.beneficiaries.close_detail_dialog.close_without_saving'
        )}
        contentText={t('containers.beneficiaries.close_detail_dialog.content')}
      />
    </Slideout>
  );
};
