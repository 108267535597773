import React from 'react';
import { useParams } from 'react-router-dom';

import { BenefitGroupTile } from '@epi-shared/components';

export const BenefitGroupTiles = ({
  benefitGroups,
  handleViewDetail,
  handleEditDetail
}: any) => {
  const { benefitGroup } = useParams<{ benefitGroup: string }>();

  return benefitGroups.map((groupDetails, index) => (
    <BenefitGroupTile
      key={groupDetails.groupId}
      groupName={groupDetails.groupName}
      maxAmountPerPeriod={groupDetails.maxAmountPerPeriod}
      index={index}
      onTileClick={() => handleViewDetail(groupDetails.groupId)}
      onEditClick={() => handleEditDetail(groupDetails.groupId)}
      benefitType={benefitGroup}
    />
  ));
};
