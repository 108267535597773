import React from 'react';
import { PagePanel, Box } from 'edenred-ui';
import { useTranslation } from 'react-i18next';

import { ACTIVE } from '@epi-constants/benefitTypes';

import { BenefitTypeTile } from './components';
import { useManageBenefits } from './hooks';

export const ManageBenefits = () => {
  const { t } = useTranslation();
  const { availableBenefits } = useManageBenefits();

  return (
    <PagePanel title={t('components.manage_benefits.new.title')}>
      <Box display="flex" flexWrap="wrap">
        {availableBenefits.length > 0 &&
          availableBenefits.map(({ id, type, state }) => (
            <BenefitTypeTile
              key={id}
              benefit={type.toLowerCase()}
              active={state === ACTIVE}
            />
          ))}
      </Box>
    </PagePanel>
  );
};
