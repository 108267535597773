import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { fetchGet } from '@epi-helpers/FetchHelpers';
import { showNotification } from '@epi-actions/overlays';
import { genericErrorMessage } from '@epi-constants/downloadReports';
import { companyBenefitsGroupSetting } from '@epi-repositories/BenefitGroupSettingsRepository';
import { IAvailableBenefits } from '@epi-models/containers/ManageBenefits';
import { ACTIVE, INACTIVE } from '@epi-constants/benefitTypes';

export const useContractedBenefits = (activeFirst: boolean = true) => {
  const dispatch = useDispatch();
  const [contractedBenefits, setContractedBenefits] = useState<
    IAvailableBenefits[] | []
  >([]);

  const onSuccess = (data: IAvailableBenefits[]) =>
    setContractedBenefits(
      data
        .filter(elem => elem.state === ACTIVE || elem.state === INACTIVE)
        .sort((a, b) => {
          const stateComparison =
            (a.state === ACTIVE ? -1 : 1) * (activeFirst ? 1 : -1);
          if (a.state !== b.state) return stateComparison;
          return a.id - b.id;
        })
    );
  const onFailure = err => {
    dispatch(showNotification({ message: genericErrorMessage }, 'error'));
  };

  useEffect(() => {
    fetchGet(companyBenefitsGroupSetting(), {}, onSuccess, onFailure);
  }, []);

  return contractedBenefits;
};
