import React from 'react';
import {
  Box,
  TitleText,
  Button,
  ButtonMode,
  BasketIcon,
  BenefitTile
} from 'edenred-ui';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import { IReadyToActiveBenefits } from '@epi-models/pages/Home';

export const ReadyToActiveBenefits = ({
  benefits,
  handleOrderAllBenefits,
  handleOrderBenefit
}: IReadyToActiveBenefits) => {
  const { gray85, sizeExtraExtraLarge, fontSemiBold } = useTheme();
  const { t } = useTranslation();

  return benefits.length ? (
    <>
      <Box display="flex" alignItems="center" gap={3}>
        <TitleText
          fontSize={sizeExtraExtraLarge}
          fontWeight={fontSemiBold}
          noMargin
          color={gray85}
        >
          {t('home.benefits_tile.order_benefits_title')}
        </TitleText>
        <Button
          id="home-benefits-add-all-benefits"
          onClick={handleOrderAllBenefits}
          mode={ButtonMode.Secondary}
          icon={<BasketIcon />}
        >
          {' '}
          {t('home.benefits_tile.button_order_all_benefits')}
        </Button>
      </Box>
      <Box mt={1.5} mb={3} fontSize={16} fontWeight={400}>
        {t('home.benefits_tile.order_benefits_subtitle')}
      </Box>
      <Box
        display="grid"
        gridTemplateColumns="1fr 1fr"
        rowGap={3}
        columnGap={3}
      >
        {benefits.map(elem => (
          <BenefitTile
            key={elem.id}
            title={elem.title}
            status={elem.status}
            benefitType={elem.benefitType}
            description={elem.description}
            buttonContent={elem.buttonContent}
            onClick={() => handleOrderBenefit(elem.id)}
            isSelected={elem.isOrdered}
          />
        ))}
      </Box>
    </>
  ) : null;
};
