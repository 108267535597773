import React from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { TextField } from 'edenred-ui';

import { FormFields, createRules } from '@epi-forms/helpers';
import { required } from '@epi-helpers/formHelpers/validators';
import { FaceValueSelectProps } from '@epi-models/forms/Controls';

function FaceValueSelect({
  disabled = false,
  readOnly = false,
  filteredVouchers
}: FaceValueSelectProps) {
  const name = FormFields.VoucherId;
  const { t } = useTranslation();
  const { control } = useFormContext();
  const validators = [required];
  const rules = createRules(validators);

  const {
    field: { ref, value, onChange: setValue },
    fieldState: { error }
  } = useController({
    name,
    control,
    rules
  });

  return (
    <TextField
      label={t('forms.voucher_quantity.face_value')}
      id={name}
      select
      required
      value={value}
      disabled={disabled}
      readOnly={readOnly}
      error={!!error}
      helperText={error?.message}
      inputRef={ref}
      onChange={setValue}
      sx={{ '& .MuiSelect-select:focus': { backgroundColor: 'transparent' } }}
    >
      {(filteredVouchers.length > 0 ? filteredVouchers : []).map(voucher => (
        <TextField.Item key={voucher.value} value={voucher.value}>
          {voucher.label}
        </TextField.Item>
      ))}
    </TextField>
  );
}

const ConnectedFaceValueSelect = connect()(FaceValueSelect);

export { ConnectedFaceValueSelect as FaceValueSelect };
