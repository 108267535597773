import React from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import { Box, Table } from 'edenred-ui';

export const TableNoContent = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <Table.Row>
      <Table.Cell
        align="center"
        colSpan="7"
        padding="none"
        sx={{ '&.MuiTableCell-root.MuiTableCell-body': { padding: '0' } }}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          py={8}
          sx={{
            fontSize: theme.sizeLarge,
            backgroundColor: theme.gray5,
            color: theme.gray85
          }}
          id="table-no-content"
        >
          {t('containers.transactions_history.no_data')}
        </Box>
      </Table.Cell>
    </Table.Row>
  );
};
