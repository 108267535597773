import React from 'react';
import { Modal, Box, Button, ButtonMode } from 'edenred-ui';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

import { IOrderSummaryCancel } from '@epi-models/pages/Home';

export const OrderSummaryCancel = ({
  isOpen,
  handleClose,
  handleGoTo
}: IOrderSummaryCancel) => {
  const { t } = useTranslation();
  const { fontBold, sizeMedium } = useTheme();

  return (
    <Modal open={isOpen} onClose={handleClose} id="home-order-summary-success">
      <Box width={265} fontSize={sizeMedium}>
        <Box mb={3} fontWeight={fontBold}>
          {t('home.benefits_tile.summary_button')}
        </Box>
        <Box mb={3}>
          {t('home.benefits_tile.summary_text_modal_cancel_body')}
        </Box>
        <Box
          display="flex"
          justifyContent="center"
          flexDirection="column"
          gap={3}
        >
          <Button
            onClick={handleClose}
            id="home-order-summary-success-close-button"
          >
            {t('home.benefits_tile.summary_text_modal_cancel_continue')}
          </Button>
          <Button
            onClick={handleGoTo}
            id="home-order-summary-success-close-button"
            mode={ButtonMode.Secondary}
          >
            {t('home.benefits_tile.summary_text_modal_cancel_leave')}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
