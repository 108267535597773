import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { FormFields } from '@epi-forms/helpers';
import {
  IColumnsIncomePostRequest,
  IPillData
} from '@epi-models/pages/DownloadReports';

import { getIncomesRegister, hasPayrollSelected } from '../helpers';
import { showNotification } from '@epi-actions/overlays';
import {
  genericErrorMessage,
  reportDownloadSuccessMessage
} from '@epi-constants/downloadReports';
import { fetchPost } from '@epi-helpers/FetchHelpers';
import { downloadReport } from '@epi-helpers/reportsHelper';
import { arrayToObject } from '@epi-helpers/ArrayHelper';
import { incomeRegisterReportsApiUrl } from '@epi-repositories/DownloadReportsRepository';

export const useIncomeTabFooter = () => {
  const [isPosting, setIsPosting] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    formState: { errors },
    getValues,
    setValue,
    watch
  } = useFormContext();

  const isErrors = errors.incomeRegister;
  const columns: IPillData[] = watch(getIncomesRegister(FormFields.Columns));

  const disableSubmit =
    !watch(getIncomesRegister(FormFields.StartDate)) ||
    !watch(getIncomesRegister(FormFields.EndDate)) ||
    !hasPayrollSelected(columns) ||
    !!isErrors ||
    isPosting;

  const onSubmit = () => {
    const values = getValues(FormFields.IncomeRegister);
    if (values?.columns) {
      setIsPosting(true);

      const columns = arrayToObject<IColumnsIncomePostRequest>(
        values.columns,
        'type'
      );
      const isWageTypeActive =
        columns.wageType !== 'false' &&
        typeof values.wageType === 'string' &&
        values.wageType.length > 0;

      if (isWageTypeActive) {
        columns.wageType = values.wageType;
        delete values.wageType;
      } else {
        columns.wageType = 'false';
        delete values.wageType;
        setValue(getIncomesRegister(FormFields.WageType), '');
      }

      const dataToSend = {
        ...values,
        loadStatuses: ['Fulfilled'],
        columns
      };

      const onSuccess = data => {
        dispatch(
          showNotification({ message: reportDownloadSuccessMessage }, 'success')
        );
        downloadReport(dataToSend, data, true);
        setIsPosting(false);
      };
      const onFailure = () => {
        dispatch(showNotification({ message: genericErrorMessage }, 'error'));
        setIsPosting(false);
      };

      fetchPost(incomeRegisterReportsApiUrl, dataToSend, onSuccess, onFailure);
    } else {
      dispatch(showNotification({ message: genericErrorMessage }, 'error'));
    }
  };

  const incomeFooter = {
    submitText: t('download_reports.lunch_deduction.download_button'),
    onSubmit,
    disableSubmit,
    submitButtonWidth: '200px'
  };

  return { incomeFooter };
};
