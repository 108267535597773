import React from 'react';
import { Box, Button, ButtonMode, Text, Separator, Link } from 'edenred-ui';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

export function TowRedirect() {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <>
      <Separator text={t('containers.login.separator')} />
      <Box textAlign="center" px={3}>
        <Text small color={theme.gray50}>
          {t('containers.login.wrong_app')}
        </Text>
        <Box display="flex" flexDirection="column" alignContent="center" py={2}>
          <Button id="tow_redirect" mode={ButtonMode.Link}>
            <Link href={t('containers.login.tow_login_link')} id="tow_redirect">
              {t('containers.login.tow_login_button')}
            </Link>
          </Button>
        </Box>
      </Box>
    </>
  );
}
