import { slideoutMode } from '@epi-constants/slideoutMode';

export const ManageBenefitLunchFormFields = {
  BenefitGroupName: 'groupName',
  DailyLimit: 'maxAmountPerPeriod',
  FundsLoadingType: 'fundsLoadingType'
};

export const viewModeInitialState = {
  mode: slideoutMode.view,
  expanded: false,
  currentGroupId: null,
  createdGroupNotification: false,
  createdGroupSuggestion: false
};

export const createModeInitialState = {
  mode: slideoutMode.create,
  expanded: true,
  currentGroupId: 0,
  createdGroupNotification: false,
  createdGroupSuggestion: false
};

export function getDailyLimitSelectFieldOptions(
  limits: number[],
  selectedLimit?: number
) {
  const isSelectedObsoleteLimit =
    selectedLimit && !limits.includes(selectedLimit);

  return (isSelectedObsoleteLimit ? [...limits, selectedLimit] : limits).map(
    limit => ({
      id: limit,
      display: `${limit.toFixed(2)} €`,
      upToDate: limits.includes(limit)
    })
  );
}

export function mapFormToBenefitGroup(form, benefitGroup, benefitType) {
  return { ...benefitGroup, ...form, benefitType };
}
