import React from 'react';
import { Box } from 'edenred-ui';
import { useTranslation } from 'react-i18next';

import { currencyCommaConvert } from '@epi-helpers/numeral';
import { getBenefitIcon } from '@epi-constants/benefitTypes';
import { TopupReportsDetailsBoxProps } from '@epi-models/pages/Reports';

import {
  DetailsBox,
  DetailsBoxHeader,
  StatusDot
} from '../../TopupReports.styles';
import { reportStatusesColors, formatDate } from '../../../utils';

export const TopupReportsDetailsBox = ({
  reportDetails
}: TopupReportsDetailsBoxProps) => {
  const { t } = useTranslation();

  return (
    <DetailsBox id="topup-reports-single-detail">
      <Box>
        <DetailsBoxHeader id="topup-reports-single-detail-date-head">
          {t('containers.deposit_money.reports_tab.date')}
        </DetailsBoxHeader>
        <Box id="topup-reports-single-detail-date">
          {formatDate(reportDetails.date)}
        </Box>
      </Box>
      <Box>
        <DetailsBoxHeader id="topup-reports-single-detail-executed_by-head">
          {t('containers.deposit_money.reports_tab.executed_by')}
        </DetailsBoxHeader>
        <Box id="topup-reports-single-detail-executed_by">
          {reportDetails.firstName} {reportDetails.lastName}
        </Box>
      </Box>
      <Box>
        <DetailsBoxHeader id="topup-reports-single-detail-status-head">
          {t('containers.deposit_money.reports_tab.status')}
        </DetailsBoxHeader>
        <Box
          display="flex"
          alignItems="center"
          id="topup-reports-single-detail-status"
        >
          <StatusDot $dotColor={reportStatusesColors[reportDetails.status]} />
          {t(
            `containers.deposit_money.reports_tab.statuses.${reportDetails.status}`
          )}
        </Box>
      </Box>
      <Box>
        <DetailsBoxHeader id="topup-reports-single-detail-benefit-head">
          {t('containers.deposit_money.reports_tab.benefit')}
        </DetailsBoxHeader>
        <Box id="topup-reports-single-detail-benefit">
          <Box mr={0.5} display="inline">
            {getBenefitIcon(reportDetails.benefitType)}
          </Box>
          {t(
            `containers.deposit_money.reports_tab.benefitTypes.${reportDetails.benefitType}`
          )}
        </Box>
      </Box>
      <Box>
        <DetailsBoxHeader id="topup-reports-single-detail-load-head">
          {t('containers.deposit_money.reports_tab.load')}
        </DetailsBoxHeader>
        <Box id="topup-reports-single-detail-load">
          {currencyCommaConvert(reportDetails.amount)}
        </Box>
      </Box>
      <Box>
        <DetailsBoxHeader id="topup-reports-single-detail-sum-head">
          {t('containers.deposit_money.reports_tab.sum')}
        </DetailsBoxHeader>
        <Box id="topup-reports-single-detail-sum">
          {currencyCommaConvert(reportDetails.totalSum)}
        </Box>
      </Box>
      <Box>
        <DetailsBoxHeader id="topup-reports-single-detail-total-head">
          {t('containers.deposit_money.reports_tab.total')}
        </DetailsBoxHeader>
        <Box id="topup-reports-single-detail-total">
          {currencyCommaConvert(reportDetails.totalSumWithFees)}
        </Box>
      </Box>
    </DetailsBox>
  );
};
