import React from 'react';
import libphonenumber from 'google-libphonenumber';
import { FinnishSSN } from 'finnish-ssn';
import isEmail from 'validator/lib/isEmail';
import { get } from 'lodash';
import { getTranslatedCountries } from '@epi-selectors/api';
import { getNumber, decimal } from '../numeral';
import { TranslationResource } from '../TranslationResource';
import { isBlank } from '../StringHelper';
import { reduxStore } from '../../store-configuration';

const businessIdRegex = /^(ATU\d{8}|BE0\d{9}|BG\d{9,10}|HR\d{11}|CY\d{8}L|CZ\d{8,10}|DE\d{9}|DK\d{8}|EE\d{9}|EL\d{9}|ES[A-Z]\d{7}(?:\d|[A-Z])|(FI)?\d{8}|FR[0-9A-Z]{2}\d{9}|GB(\d{9}(\d{3})?|[A-Z]{2}\d{3})|HU\d{8}|IE\d{7}[A-Z]{1,2}|IE\d[A-Z]\d{5}[A-Z]|IT\d{11}|LT(\d{9}|\d{12})|LU\d{8}|LV\d{11}|MT\d{8}|NL\d{9}B\d{2}|NO\d{9}(MVA)|PL\d{10}|PT\d{9}|RO\d{2,10}|RU\d{10}|CHE(\d{9})(MWST|TVA|IVA)|SE\d{12}|SI\d{8}|SK\d{10})$/i;

export const CityRegex = /^[a-zA-Z\u0080-\u024F]([a-zA-Z\u0080-\u024F]+(?:. |-| |'))*[0-9a-zA-Z\u0080-\u024F]*$/;
export const NameRegex = /^[a-zA-Z\u0080-\u024F][a-zA-Z\u0080-\u024F ,."'-]*$/;
// NOSONAR
export const AddressLineRegex = /^[^;{}[\]<>~*|^]*$/;
export const NotContainsNumbersRegex = /^(\D*)$/;

export const Min10CharsRegex = /^.{10,50}$/i;
export const Min1LowercaseRegex = /([a-z])/;
export const Min1NumberRegex = /\d/;
export const isPasswordMin1Symbol = password =>
  /^(?=.*[^A-Z0-9]).{1,50}$/gi.test(password);
export const isPasswordMin1Uppercase = password =>
  /[A-Z].{0,50}/g.test(password);

const getTranslation = (message, parameters) => (
  <TranslationResource message={message} parameters={parameters} />
);

export const minAmountMoreAccurate = minVal => value =>
  value && getNumber(value) < minVal
    ? getTranslation('validation_messages.min_amount_more_accurate', { minVal })
    : undefined;

export const minValue = minVal => value =>
  value && getNumber(value) < minVal
    ? getTranslation('validation_messages.number_must_be_at_least', { minVal })
    : undefined;

export const maxValue = maxVal => value =>
  value && getNumber(value) > maxVal
    ? getTranslation('validation_messages.number_must_be_at_maximum', {
        maxVal
      })
    : undefined;

export const minLength = minLen => value =>
  value && value.length < minLen
    ? getTranslation('validation_messages.string_length_must_be_at_least', {
        minLen
      })
    : undefined;

export const maxLength = maxLen => value =>
  value && value.length > maxLen
    ? getTranslation('validation_messages.max_length', {
        maxLen
      })
    : undefined;

export const required = value =>
  !isBlank(value) ? undefined : getTranslation('validation_messages.required');

export const requiredCountry = value =>
  getTranslatedCountries(reduxStore.getState())
    .map(country => country.Code)
    .includes(value)
    ? undefined
    : getTranslation('validation_messages.required');

export const number = value => {
  const regex = /^\d+$/;
  return value && !regex.test(value)
    ? getTranslation('validation_messages.must_be_a_number')
    : undefined;
};

export const amount = value =>
  value && Number.isNaN(getNumber(value))
    ? getTranslation('validation_messages.must_be_a_number')
    : undefined;

export const minAmount = value =>
  value && getNumber(value) < 0.51
    ? getTranslation('validation_messages.min_amount')
    : undefined;

export const maxAmount = value =>
  value && getNumber(value) > 50000
    ? getTranslation('validation_messages.max_amount')
    : undefined;

export const numberBetween = (min, max) => value =>
  value &&
  (Number.isNaN(getNumber(value)) ||
    getNumber(value) < min ||
    getNumber(value) > max)
    ? getTranslation('validation_messages.number_must_be_between', {
        min,
        max
      })
    : undefined;

export const integer = value =>
  value && value % 1 !== 0
    ? getTranslation('validation_messages.must_be_an_integer')
    : undefined;

export const email = value =>
  value && !isEmail(value.trim())
    ? getTranslation('validation_messages.invalid_email')
    : undefined;

export const emailForbidden = value =>
  value && isEmail(value.trim())
    ? getTranslation('validation_messages.email_forbidden')
    : undefined;

export const businessId = value =>
  value && !businessIdRegex.test((value || '').replace(/[.\s-]/g, ''))
    ? getTranslation('validation_messages.invalid_business_id')
    : undefined;

export const phone = value => {
  try {
    const util = libphonenumber.PhoneNumberUtil.getInstance();
    const phoneNum = util.parse(value, '');

    return !util.isValidNumber(phoneNum)
      ? getTranslation('validation_messages.invalid_phone_number')
      : undefined;
  } catch {
    return getTranslation('validation_messages.invalid_phone_number');
  }
};

export const finnishZipCode = value =>
  value && !/^\d{5}$/.test((value || '').replace(/\s/g, ''))
    ? getTranslation('validation_messages.invalid_zip_code')
    : undefined;

export const internationalZipCode = value =>
  value && !/^[A-Z0-9.-]{2,7}$/i.test((value || '').replace(/\s/g, ''))
    ? getTranslation('validation_messages.invalid_zip_code')
    : undefined;

export const zipCode = relatedCountryCodeFieldName => (value, formValues) => {
  const isFinland =
    (get(formValues, relatedCountryCodeFieldName) || '').toUpperCase() === 'FI';
  return isFinland ? finnishZipCode(value) : internationalZipCode(value);
};

export const zipCodeBasedOnCountry = country => value =>
  country === 'FI' ? finnishZipCode(value) : internationalZipCode(value);

const withPOboxPattern =
  /^(((.{0,20}\s)|(^))(PL[ |.]{0,20}([1-9]\d{0,3}))(\s.{0,20}){0,20})$/i;

export const poBoxNotAllowed = value =>
  value && withPOboxPattern.test(value.trim())
    ? getTranslation('validation_messages.po_box_not_allowed')
    : undefined;

export const password = value => {
  const pattern = Min10CharsRegex.test(value) && Min1LowercaseRegex.test(value) && Min1NumberRegex.test(value) && isPasswordMin1Symbol(value) && isPasswordMin1Uppercase(value)
  const lengthValidation = value && maxLength(100)(value);
  const whiteSpacesPattern = /\s/g;
  const patternValidation =
    value && (!pattern || whiteSpacesPattern.test(value))
      ? getTranslation('validation_messages.invalid_password')
      : undefined;
  return lengthValidation || patternValidation;
};

export const termsAndConditionsRequired = value =>
  value
    ? undefined
    : getTranslation('validation_messages.termsAndConditionsRequired');

export const city = value => {
  const pattern =
    /^[a-z\u0080-\u024F]([a-z\u0080-\u024F]+(?:. |-| |'))*[0-9a-z\u0080-\u024F]*$/i;
  return value && !pattern.test(value)
    ? getTranslation('validation_messages.invalid_city')
    : undefined;
};

const nameRegex = /^["']?[a-z\u0080-\u024F][a-z\u0080-\u024F"'\-.\s]*$/i;

export const firstName = value =>
  value && !nameRegex.test(value)
    ? getTranslation('validation_messages.invalid_firstName')
    : undefined;

export const lastName = value =>
  value && !nameRegex.test(value)
    ? getTranslation('validation_messages.invalid_lastname')
    : undefined;

export const noWhitespaces = value =>
  // NOSONAR
  value && (/[\s]/.test(value))
    ? getTranslation('validation_messages.invalid_whitespaces')
    : undefined;

export const companyName = value => {
  // NOSONAR
  const pattern = /^[0-9a-z\u0080-\u024F@!#$%&"':+-.,/\\=?_`() —–]+$/i;
  return value && !pattern.test(value)
    ? getTranslation('validation_messages.invalid_company')
    : undefined;
};

export const username = value => {
  const pattern =
    /^([a-z0-9\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u017E+-._]@?){1,30}(\s?)([a-z0-9\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u017E+-._@]){1,30}$/i;
  return value && !pattern.test(value)
    ? getTranslation('validation_messages.invalid_username')
    : undefined;
};

export const socialSecurityNumber = value => {
  return FinnishSSN.validate(value)
    ? undefined
    : getTranslation('validation_messages.invalid_social_security_number');
};

const addressLinePattern = /^[^;{}[\]<>~*|^]*$/i;
export const addressLine = value =>
  value && !addressLinePattern.test(value)
    ? getTranslation('validation_messages.invalid_address_line')
    : undefined;

export const maxVouchersValue = maxVal => value =>
  value && Number(value) > maxVal
    ? getTranslation('validation_messages.max_voucher_qty', { maxValue: 50000 })
    : undefined;

export const yearlyLimit = maxVal => value =>
  value && getNumber(value) > maxVal
    ? getTranslation('validation_messages.yearly_limit')
    : undefined;

export const virikeMaxAmount = maxVal => value =>
  value && getNumber(value) > maxVal
    ? getTranslation('components.manage_benefit_recreational.custom_error', { maxVal })
    : undefined;

    export const virikeMinAmount = value =>
      value && getNumber(value) < 1
        ? getTranslation('components.manage_benefit_recreational.min_amount')
        : undefined;