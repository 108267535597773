import React, { useEffect } from 'react';
import { useFormContext, useController } from 'react-hook-form';
import { TextField } from 'edenred-ui';
import { useTranslation } from 'react-i18next';

import { createRules, FormFields } from '@epi-forms/helpers';
import { numberBetween, number } from '@epi-helpers/formHelpers/validators';
import { IContractPercentageField } from '@epi-models/pages/Beneficiaries';

export const DaysForLunariField = ({ disabled }: IContractPercentageField) => {
  const { control, trigger, watch } = useFormContext();
  const { t } = useTranslation();
  const rules = createRules([numberBetween(1, 23), number]);
  const name = FormFields.DaysForLunari;
  const employmentType = watch(FormFields.EmployeeType);

  const {
    field: { onChange, value },
    fieldState: { error }
  } = useController({
    name,
    control,
    rules
  });

  const setValue = () => {
    if (value) return value;
    return '';
  };

  useEffect(() => {
    trigger(name);
  }, [employmentType]);

  return (
    <TextField
      disabled={disabled}
      id={name}
      label={t('containers.beneficiaries.detail.lunch_benefit_per_month')}
      value={setValue()}
      onChange={onChange}
      readOnly={false}
      error={!!error}
      helperText={error?.message}
    />
  );
};
