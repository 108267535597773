import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  SlideoutPanelComponent as Slideout,
  Box,
  SimpleButton,
  EditIcon,
  Heading3,
  TextField,
  Text
} from 'edenred-ui';

import { slideoutMode } from '@epi-constants/slideoutMode';
import { BenefitType } from '@epi-constants/benefitTypes';
import { goToPage } from '@epi-actions/navigation';
import { routerPaths } from '@epi-constants/routerPaths';
import {
  TRANSFER_PAGE_QUERY_NAME,
  TransferPageType
} from '@epi-constants/transferPage';
import { IBenefitGroupDetail } from '@epi-models/containers/ManageBenefitGroups';
import { CloseDetailDialog } from '@epi-shared/components';
import { useGetDetails } from '@epi-shared/hooks';
import config from '@epi-config';

import {
  ManageBenefitLunchFormFields,
  mapFormToBenefitGroup
} from '../../helpers';
import { BenefitGroupDetailActions } from '../BenefitGroupDetailActions';
import { BenefitGroupNameField } from '../BenefitGroupNameField';
import { DailyLimitField } from '../DailyLimitField';
import { CloseDetailVirike } from '../CloseDetailVirike';
import { DetailDailyLimitStatement } from '../DetailDailyLimitStatement';
import { ConfirmationModal } from '../ConfirmationModal';
import { FundsLoadingTypeField } from '../FundsLoadingTypeField';

const { enableLoadingType } = config;

export function BenefitGroupDetail({
  detail,
  benefitGroup,
  benefitType,
  dailyLimitOptions,
  onClose,
  onSave,
  onCreate,
  onCancel,
  onEdit
}: IBenefitGroupDetail) {
  const [showCloseConfirmation, setShowCloseConfirmation] = useState(false);
  const [showRedirectConfirmation, setShowRedirectConfirmation] =
    useState(false);
  const [isSubmitOnRedirectConfirmation, setIsSubmitOnRedirectConfirmation] =
    useState(false);
  const [isExistingClients, setIsExistingClients] = useState(false);
  const [
    showConfirmationModalForExistingClients,
    setShowConfirmationModalForExistingClients
  ] = useState(false);
  const { isLoading, customerData } = useGetDetails();

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const form = useForm({
    mode: 'onChange',
    defaultValues: {
      [ManageBenefitLunchFormFields.BenefitGroupName]: benefitGroup
        ? benefitGroup.groupName
        : '',
      [ManageBenefitLunchFormFields.DailyLimit]: benefitGroup
        ? benefitGroup.maxAmountPerPeriod
        : dailyLimitOptions[0].id,
      [ManageBenefitLunchFormFields.FundsLoadingType]: benefitGroup
        ? benefitGroup.fundsLoadingType
        : ''
    }
  });

  const {
    formState: { isValid, isDirty, dirtyFields },
    watch,
    getValues,
    reset
  } = form;

  const selectedDailyLimit = watch(ManageBenefitLunchFormFields.DailyLimit);
  const isVirikeBenefit = benefitType === BenefitType.Recreational;
  const isSubmitAndShowRedirectConfirmation =
    showRedirectConfirmation && isSubmitOnRedirectConfirmation;

  const handleSaveButton = values => {
    const newBenefitGroup = mapFormToBenefitGroup(
      values,
      benefitGroup,
      benefitType
    );

    if (detail.mode === slideoutMode.edit) {
      onSave(newBenefitGroup);
    }
    if (detail.mode === slideoutMode.create) {
      onCreate(newBenefitGroup);
    }
  };

  const confirmCloseWithoutSaving = () => {
    setShowCloseConfirmation(false);
    onClose();
    reset();
  };

  const handleConfirmBenefitPopup = () => {
    confirmSaving();
    dispatch(
      goToPage(
        routerPaths.loadCompanyAccount +
          TRANSFER_PAGE_QUERY_NAME +
          TransferPageType.topupLoad
      )
    );
  };

  const handleCloseBenefitPopup = () => {
    confirmSaving();
    setShowCloseConfirmation(false);
  };

  const confirmSaving = () => {
    setShowCloseConfirmation(false);
    handleSaveButton(getValues());
  };

  const slideoutTitle =
    detail.mode === slideoutMode.create
      ? t('components.manage_benefit_lunch.detail_create_title')
      : t('components.manage_benefit_lunch.detail_view_title');

  const maxAmountPerPeriodDisplay = dailyLimitOptions
    ? Number(
        dailyLimitOptions.find(option => option.id === selectedDailyLimit)
          ?.display
      )
    : 0;

  const handleCloseBenefitGroupDetailActions = () =>
    isDirty && detail.mode !== slideoutMode.view
      ? setShowCloseConfirmation(true)
      : onCancel();

  const handleClickBenefitGroupDetailActions = () => {
    setShowConfirmationModalForExistingClients(false);
    if (isVirikeBenefit && showRedirectConfirmation) {
      setShowCloseConfirmation(true);
      setIsSubmitOnRedirectConfirmation(true);
    } else {
      confirmSaving();
    }
  };

  const handleShowConfirmationModalForExistingClients = () => {
    if (!isLoading && customerData?.isEmployeesSet) {
      setShowConfirmationModalForExistingClients(true);
    } else {
      handleClickBenefitGroupDetailActions();
    }
  };

  const handleCloseDetailVirike = () =>
    isSubmitAndShowRedirectConfirmation
      ? handleCloseBenefitPopup()
      : setShowCloseConfirmation(false);

  const handleSubmitDetailVirike = () => {
    if (isSubmitAndShowRedirectConfirmation) {
      return handleConfirmBenefitPopup();
    }

    confirmCloseWithoutSaving();
  };

  useEffect(() => {
    setShowRedirectConfirmation(!!dirtyFields?.maxAmountPerPeriod);
  }, [!!dirtyFields?.maxAmountPerPeriod]);

  useEffect(() => {
    if (
      (!isLoading && customerData?.isEmployeesSet) ||
      (!isLoading && customerData?.isBenefitsSet)
    ) {
      setIsExistingClients(true);
    } else {
      setIsExistingClients(false);
    }
  }, [isLoading]);

  return (
    <FormProvider {...form}>
      <Slideout
        title={slideoutTitle}
        expanded={detail.expanded}
        onClose={() =>
          isDirty && detail.mode !== slideoutMode.view
            ? setShowCloseConfirmation(true)
            : onClose()
        }
        actions={
          detail.mode !== slideoutMode.view ? (
            <BenefitGroupDetailActions
              saveDisabled={!isValid}
              onCancel={handleCloseBenefitGroupDetailActions}
              onClick={
                isExistingClients && detail.mode === slideoutMode.create
                  ? handleShowConfirmationModalForExistingClients
                  : handleClickBenefitGroupDetailActions
              }
            />
          ) : null
        }
        closeButtonId="close-benefit-group-slideout"
      >
        <Box position="relative" pl={8.25} pt={2.5}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Heading3 id="employee-benefits-group-title">
              {t(
                'components.manage_benefit_lunch.detail_benefit_settings_header'
              )}
            </Heading3>
            {detail.mode === slideoutMode.view && benefitGroup && (
              <SimpleButton
                id="edit-benefit-group"
                text={t('components.manage_benefit_lunch.detail_edit_btn_text')}
                icon={<EditIcon />}
                onClick={() => onEdit(benefitGroup.groupId)}
              />
            )}
          </Box>
          <Box width={457} my={3.5}>
            <Box pb={4}>
              <TextField
                id="employee-benefits-group-benefit-type"
                label={t(
                  'components.manage_benefit_lunch.detail_benefit_type_label'
                )}
                value={t(
                  `components.manage_benefits.new.benefits.${benefitType}`
                )}
                readOnly
              />
            </Box>
            <Box pb={4}>
              <BenefitGroupNameField
                label={t(
                  'components.manage_benefit_lunch.detail_group_name_label'
                )}
                readOnly={detail.mode === slideoutMode.view}
              />
            </Box>
            <Box pb={2}>
              <DailyLimitField
                label={t(
                  `components.manage_benefit_${benefitType}.detail_limit_label`
                )}
                readOnly={detail.mode === slideoutMode.view}
                options={dailyLimitOptions}
                helperText={t(
                  `components.manage_benefit_${benefitType}.helper_text`
                )}
                benefitType={benefitType}
              />
            </Box>
            {enableLoadingType &&
              (benefitType === BenefitType.Lunch ||
                benefitType === BenefitType.Recreational) && (
                <FundsLoadingTypeField
                  name={ManageBenefitLunchFormFields.FundsLoadingType}
                  disabled={detail.mode === slideoutMode.view}
                  benefitType={benefitType}
                />
              )}
            <DetailDailyLimitStatement
              benefitType={benefitType}
              maxAmountPerPeriodDisplay={maxAmountPerPeriodDisplay}
              isVirikeBenefit={isVirikeBenefit}
            />
          </Box>
        </Box>
        {isVirikeBenefit ? (
          <CloseDetailVirike
            isOpen={showCloseConfirmation}
            handleCloseModal={handleCloseDetailVirike}
            titleText={
              isSubmitAndShowRedirectConfirmation
                ? t(
                    'components.manage_benefit_recreational.load_virike_benefit_popup_title'
                  )
                : t('components.manage_benefit_recreational.exit_pop_up_title')
            }
            text={
              isSubmitAndShowRedirectConfirmation ? (
                <Text
                  paragraph
                  dangerouslySetInnerHTML={{
                    __html: t(
                      'components.manage_benefit_recreational.load_virike_benefit_popup_text'
                    )
                  }}
                />
              ) : (
                <Text paragraph>
                  {t('components.manage_benefit_recreational.exit_pop_up_text')}
                </Text>
              )
            }
            handleSubmit={handleSubmitDetailVirike}
            submitText={
              isSubmitAndShowRedirectConfirmation
                ? t(
                    'components.manage_benefit_recreational.load_virike_benefit_popup_submit'
                  )
                : t('components.manage_benefit_recreational.exit_pop_up_submit')
            }
            cancelText={
              isSubmitAndShowRedirectConfirmation
                ? t(
                    'components.manage_benefit_recreational.load_virike_benefit_popup_cancel'
                  )
                : t('components.manage_benefit_recreational.exit_pop_up_cancel')
            }
          />
        ) : (
          <CloseDetailDialog
            isOpen={showCloseConfirmation}
            title={t('containers.beneficiaries.close_detail_dialog.title')}
            handleCloseModal={() => setShowCloseConfirmation(false)}
            primaryAction={confirmCloseWithoutSaving}
            primaryActionText={t(
              'containers.beneficiaries.close_detail_dialog.close_without_saving'
            )}
            secondaryAction={confirmSaving}
            secondaryActionText={t(
              'containers.beneficiaries.close_detail_dialog.save'
            )}
            contentText={t(
              'containers.beneficiaries.close_detail_dialog.content'
            )}
          />
        )}
        {showConfirmationModalForExistingClients ? (
          <ConfirmationModal
            isOpen={showConfirmationModalForExistingClients}
            handleClose={() =>
              setShowConfirmationModalForExistingClients(false)
            }
            handleSuccess={handleClickBenefitGroupDetailActions}
            benefitType={benefitType}
          />
        ) : null}
      </Slideout>
    </FormProvider>
  );
}
