/* eslint-disable camelcase */
import { delay, put, call, select, take } from 'redux-saga/effects';
import { pick, get, omit } from 'lodash';
import { createOrder } from '../../repositories/VoucherOrdersRepository';
import { getLoginState } from '../../selectors/login';
import { DeliveryMethods } from '../../constants/deliveryMethods';
import { checkIfTokenExpires } from '../../helpers/AuthorizeHelper';
import { routerPaths } from '../../constants/routerPaths';
import { OrderType } from '../../constants/orderType';
import {
  ApiConstants,
  NavigationConstants,
  ApplicationConstants
} from '../../constants/actions';
import { NotificationMethods } from '../../constants/notificationMethods';
import { getVoucherById } from './apiHelpers';
import * as loginActions from '../../actions/login';
import { trackUserFinishOrderProcess } from '../tracking';
import { getPaymentData, isOnlineOrCreditCardPayment } from './getPaymentData';
import { createOnlinePayment } from './createOnlinePayment';

import { getAddressData } from '@epi-store/addressPicker/addressPicker.selectors';

function getOrderDetails({ calculator, voucher_details }) {
  const numberOfEmployees = get(calculator, 'employeesNumber') || null;
  const data = pick(voucher_details, ['quantity', 'voucherId']);
  return data ? [{ ...data, numberOfEmployees }] : [];
}

function getCompany({ company_info }) {
  return {
    name: company_info.name,
    sizeId: company_info.sizeId,
    businessIdentityCode: company_info.businessIdentityCode,
    customerNumber: company_info.customerNumber,
    addresses: [{ ...company_info.address, addressType: 'main' }]
  };
}

function getTermsAndConditions({ terms_and_conditions }) {
  return { ...terms_and_conditions };
}

function getOnlinePaymentCallbackUrl(voucherType) {
  return `${routerPaths.voucherOrder[voucherType][3]}`;
}

function getPickupInfo(deliveryInfo) {
  const isPickupDelivery =
    deliveryInfo.method === DeliveryMethods.ToPickupPoint;

  if (!isPickupDelivery) {
    return {};
  }

  const pickupNotificationMethod = get(
    deliveryInfo,
    'pickup_point_details.confirmation_method'
  );

  const pickupEmail =
    isPickupDelivery &&
    pickupNotificationMethod === NotificationMethods.SendEmail
      ? deliveryInfo.pickup_point_details.emailAddress
      : undefined;

  const pickupPhoneNumber =
    isPickupDelivery &&
    pickupNotificationMethod === NotificationMethods.SendSms &&
    !pickupEmail
      ? deliveryInfo.pickup_point_details.phoneNumber
      : undefined;

  return { pickupEmail, pickupPhoneNumber };
}

function getDeliveryData({ delivery_info }) {
  const toOtherAddressData =
    delivery_info.method === DeliveryMethods.ToOtherAddress
      ? { address: delivery_info.custom_address }
      : null;

  return {
    ...omit(delivery_info, 'pickup_point_details', 'custom_address'),
    ...toOtherAddressData,
    ...getPickupInfo(delivery_info)
  };
}

function getUser({ username_password, contact_person }) {
  /* eslint-disable camelcase */
  return {
    ...username_password,
    ...contact_person
  };
  /* eslint-enable camelcase */
}

export function* getOrderDeliveryFromPicker() {
  const deliveryAddress = yield select(getAddressData);
  const useSavedAddress =
    deliveryAddress &&
    deliveryAddress.method === DeliveryMethods.ToSavedAddress;
  const savedAddressMethod =
    get(deliveryAddress, 'address.addressType') === 'Main'
      ? DeliveryMethods.ToCompanyAddress
      : DeliveryMethods.ToOtherAddress;
  const address =
    savedAddressMethod === DeliveryMethods.ToOtherAddress
      ? deliveryAddress.custom_address
      : deliveryAddress.address;
  return {
    method: useSavedAddress ? savedAddressMethod : deliveryAddress.method,
    address: useSavedAddress
      ? { id: get(deliveryAddress, 'address.id') }
      : address,
    contactPerson: deliveryAddress.contactPerson,
    ...getPickupInfo(deliveryAddress)
  };
}

export function* callCreateOrderApi(login, request) {
  const [address] = request?.company?.addresses || [];
  if (request?.termsAndConditions) {
    request.termsAndConditions.validateCompanyInformation =
      !!address?.validateCompanyInformation;
  }

  try {
    const voucherOrder = yield call(createOrder, request);
    yield put({
      type: `${ApiConstants.CREATE_VOUCHER_ORDER}_SUCCESS`,
      payload: voucherOrder,
      isNewCustomer: !login.isAuthenticated
    });

    if (!login.isAuthenticated) {
      yield put(
        loginActions.getLoginAction(
          request.user.username,
          request.user.password,
          {
            isAutoLogin: true,
            autoRedirect: false
          }
        )
      );
      yield take(`${ApiConstants.LOGIN_ACTION}_SUCCESS`);
    }
    yield put({
      type: `${ApplicationConstants.CLEAR_FORMS}`
    });
    return voucherOrder;
  } catch (ex) {
    yield put({
      type: `${ApiConstants.CREATE_VOUCHER_ORDER}_FAILURE`,
      payload: ex
    });
    return null;
  }
}

export function* logoutAndStopSubmitting() {
  yield put({
    type: `${ApiConstants.LOGOUT_ACTION}_REQUEST`,
    sessionExpired: true
  });
  yield put({
    type: `${ApiConstants.CREATE_VOUCHER_ORDER}_ABORT`
  });
}

export default function* createVoucherOrder({ values }) {
  const login = yield select(getLoginState);
  if (login.isAuthenticated && checkIfTokenExpires(login.validTo)) {
    yield delay(1);
    yield call(logoutAndStopSubmitting);
    return;
  }

  const voucherOrder = yield call(callCreateOrderApi, login, values);

  if (!voucherOrder) {
    return;
  }

  const voucher = yield call(getVoucherById, values.details[0].voucherId);
  yield trackUserFinishOrderProcess(voucherOrder.orderId, voucher.type);

  const statusRoute = getOnlinePaymentCallbackUrl(voucher.type);

  if (isOnlineOrCreditCardPayment(values.payment)) {
    yield call(
      createOnlinePayment,
      voucherOrder.paymentPublicId,
      OrderType.voucher,
      statusRoute
    );
    yield take(`${ApiConstants.CREATE_NEW_PAYMENT}_SUCCESS`);
  } else {
    yield put({
      type: `${NavigationConstants.GO_TO_PAGE}`,
      url: statusRoute,
      params: { paymentId: voucherOrder.paymentPublicId }
    });
  }
}
