import React from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation, Trans } from 'react-i18next';
import { FormControlLabel, Radio, RadioGroup, Box } from 'edenred-ui';

import { createRules, FormFields } from '@epi-forms/helpers';
import { required } from '@epi-helpers/formHelpers/validators';
import { DeliveryMethods } from '@epi-constants/deliveryMethods';
import { Address } from '@epi-forms/Address/Address';
import { EditDeliveryInfoRadioProps } from '@epi-models/forms/Controls';

import { AddressesSelect } from '../AddressesSelect';

export function EditDeliveryInfoRadio({
  readOnly = false,
  onChange
}: EditDeliveryInfoRadioProps) {
  const name = FormFields.DeliveryMethod;
  const { control, watch } = useFormContext();
  const { t } = useTranslation();
  const validators = [required];
  const rules = createRules(validators);

  const {
    field: { value, onChange: setValue },
    fieldState: { error }
  } = useController({
    name,
    control,
    rules
  });

  const addressIdValue = watch(FormFields.AddressId);

  const onRadioChange = event => {
    setValue(event);

    if (onChange) {
      onChange(event.currentTarget.value);
    }
  };

  return (
    <RadioGroup
      sx={{
        '& .MuiAccordion-root:before': { backgroundColor: 'transparent' },
        '& .MuiAccordionSummary-expandIconWrapper': { display: 'none' }
      }}
      value={value}
      onChange={onRadioChange}
      id={name}
    >
      <FormControlLabel
        value={DeliveryMethods.ToSavedAddress}
        control={<Radio />}
        label={t(
          'forms.delivery_info.delivery_method.existing_delivery_to_label'
        )}
        readOnly={readOnly}
        id="delivery-info_existing"
      />
      <Box margin="25px">
        <AddressesSelect key={addressIdValue} />
      </Box>
      <FormControlLabel
        value={DeliveryMethods.ToOtherAddress}
        control={<Radio />}
        label={t('forms.delivery_info.delivery_method.new_delivery_to_label')}
        readOnly={readOnly}
        id="delivery-info_new"
      />
      <div className="street_sublabel caption3">
        {t(
          'forms.delivery_info.delivery_method.different_than_company_address.sublabel'
        )}
      </div>
      <Box margin="25px">
        <Address disabled={value !== DeliveryMethods.ToOtherAddress} />
      </Box>
      <FormControlLabel
        value={DeliveryMethods.ToPickupPoint}
        control={<Radio />}
        label={
          <span>
            {t(
              'forms.delivery_info.delivery_method.nearest_pickup_point.label'
            )}
          </span>
        }
        disabled={readOnly}
        id="delivery-info_service"
      />
      <div className="explanatory-text caption3">
        <Trans
          defaults={t(
            'forms.delivery_info.delivery_method.nearest_pickup_point.explanatory_text'
          )}
          components={{
            a: (
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={t(
                  'forms.delivery_info.delivery_method.nearest_pickup_point.link_url'
                )}
              />
            )
          }}
        />
      </div>
      <span>{error?.message}</span>
    </RadioGroup>
  );
}
