import React from 'react';
import { Modal, Box, Button, ButtonMode, BenefitType } from 'edenred-ui';
import { Trans, useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

export const ConfirmationModal = ({
  isOpen,
  handleClose,
  handleSuccess,
  benefitType
}: any) => {
  const { t } = useTranslation();
  const { fontBold, sizeMedium } = useTheme();
  const type = t(`home.benefits_tile.${BenefitType[benefitType]}.title`);

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      id="benefit-group-details-confirmation-modal"
    >
      <Box
        width={310}
        fontSize={sizeMedium}
        justifyContent="center"
        alignItems="center"
        display="flex"
        flexDirection="column"
      >
        <Box
          mb={3}
          fontWeight={fontBold}
          id="benefit-group-details-confirmation-modal-title"
        >
          {t('containers.beneficiaries.confirmation_modal.title')}
        </Box>
        <Box mb={3} id="benefit-group-details-confirmation-modal-content">
          <Trans
            defaults={t('containers.beneficiaries.confirmation_modal.body', {
              type
            })}
            components={{
              b: <b />
            }}
          />
        </Box>
        <Box
          display="flex"
          justifyContent="center"
          flexDirection="column"
          gap={3}
          width={163}
        >
          <Button
            onClick={handleSuccess}
            id="benefit-group-details-confirmation-modal-confirm-button"
          >
            {t('containers.beneficiaries.confirmation_modal.confirm_button')}
          </Button>
          <Button
            onClick={handleClose}
            id="benefit-group-details-confirmation-modal-close-button"
            mode={ButtonMode.Secondary}
          >
            {t('containers.beneficiaries.confirmation_modal.cancel_button')}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
