import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { goToPage } from '@epi-actions/navigation';
import { routerPaths } from '@epi-constants/routerPaths';
import { loadCompanyAccounts, getActiveBenefits } from '@epi-actions/api';
import { isSignatorySelector } from '@epi-selectors/login';
import { getMainCompanyAccount } from '@epi-selectors/companyAccounts';
import { useEmailConfirmation } from '@epi-shared/hooks/useEmailConfirmation';
import { useGetDetails } from '@epi-shared/hooks';

export const useHome = () => {
  useEmailConfirmation();
  const { companyAccountId } = useSelector(getMainCompanyAccount);
  const { isLoading, customerData } = useGetDetails();
  const dispatch = useDispatch();
  const isSignatory = useSelector(isSignatorySelector);
  const isNewCustomer =
    customerData &&
    Object.values(customerData).find(elem => !elem) !== undefined;

  useEffect(() => {
    if (!isSignatory) {
      dispatch(goToPage(routerPaths.orderHistory));
      return;
    }
  }, []);

  useEffect(() => {
    dispatch(loadCompanyAccounts());
    dispatch(getActiveBenefits());
  }, []);

  return {
    isLoading: isLoading || Boolean(!companyAccountId),
    isNewCustomer,
    customerData
  };
};
