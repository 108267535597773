import React from 'react';
import {
  ButtonComponent as Button,
  ButtonMode,
  DialogComponent,
  Box,
  CloseIcon,
  TitleText,
  ButtonSize
} from 'edenred-ui';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

import { ITerminationDateDialog } from '@epi-models/pages/Beneficiaries';

import { useTerminationDateDialog } from '../hooks';
import { TerminationDatePicker } from './TerminationDatePicker';

export const TerminationDateDialog = ({
  isOpen,
  handleClose
}: ITerminationDateDialog) => {
  const { gray10, sizeExtraExtraLarge } = useTheme();
  const { handleSetDate, handleSave, endOfBenefit, disableSave } =
    useTerminationDateDialog(handleClose);
  const { t } = useTranslation();

  return (
    <DialogComponent open={isOpen} onClose={handleClose}>
      <Box pt={2} pl={5} borderBottom={1} borderColor={gray10} minWidth={400}>
        <Box position="absolute" right={16}>
          <Button
            id="date-range-dialog-cancel-btn"
            mode={ButtonMode.Link}
            onClick={handleClose}
          >
            <CloseIcon />
          </Button>
        </Box>
        <Box pt={3} maxWidth={250}>
          <TitleText fontWeight={600} fontSize={sizeExtraExtraLarge}>
            {t('containers.beneficiaries.detail.termination_modal_title')}
          </TitleText>
        </Box>
      </Box>
      <Box
        height={400}
        pt={2.5}
        pl={5}
        pr={5}
        borderBottom={1}
        borderColor={gray10}
        fontSize={16}
      >
        <TerminationDatePicker
          dayValue={endOfBenefit}
          setTerminationDate={handleSetDate}
          isError={disableSave}
          title={t('containers.beneficiaries.detail.termination_modal_modal')}
        />
      </Box>
      <Box
        display="flex"
        pt={2}
        justifyContent="flex-end"
        pr={5}
        gap={8}
        pb={2}
      >
        <Button
          id="date-range-dialog-cancel-btn"
          mode={ButtonMode.Link}
          onClick={handleClose}
        >
          {t('download_reports.lunch_deduction.time_period.dialog_cancel')}
        </Button>
        <Button
          id="date-range-dialog-save-btn"
          size={ButtonSize.Large}
          minWidth={135}
          mode={ButtonMode.Primary}
          disabled={disableSave}
          onClick={handleSave}
        >
          {t('download_reports.lunch_deduction.time_period.dialog_save')}
        </Button>
      </Box>
    </DialogComponent>
  );
};
