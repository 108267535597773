import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import NotificationSystem from 'react-notification-system';
import { bindActionCreators } from 'redux';
import { withTranslation, WithTranslation } from 'react-i18next';

import { getNotifications } from '../../selectors/overlays';
import * as overlaysActions from '../../actions/overlays';

interface Notification {
  uid: string;
  message: string;
  translated?: boolean;
  onRemove?: () => void;
  [key: string]: any;
}

interface NotifierProps extends WithTranslation {
  hideNotification: (uid: string) => void;
  notifications: Notification[];
}

interface NotifierState {
  notificationSystem: NotificationSystem | null;
}

class Notifier extends PureComponent<NotifierProps, NotifierState> {
  private style = {
    Containers: {
      DefaultStyle: {
        zIndex: 1010
      },
      tl: {
        top: 73
      },
      tr: {
        top: 73,
        right: 17
      },
      tc: {
        top: 73
      }
    }
  };

  private notificationSystem: NotificationSystem | null = null;

  componentDidUpdate(): void {
    const { t, notifications, hideNotification } = this.props;
    const notificationIds = notifications.map(notification => notification.uid);
    const systemNotifications =
      this.notificationSystem?.state.notifications || [];

    if (notifications.length > 0) {
      systemNotifications.forEach(notification => {
        if (notificationIds.indexOf(notification.uid) < 0) {
          this.notificationSystem?.removeNotification(notification.uid);
        }
      });

      notifications.forEach(notification => {
        const message = notification.translated
          ? notification.message
          : t(notification.message);
        this.notificationSystem?.addNotification({
          ...notification,
          message,
          onRemove: () => {
            hideNotification(notification.uid);
            if (notification.onRemove) {
              notification.onRemove();
            }
          }
        });
      });
    }
  }

  render() {
    return (
      <div>
        <NotificationSystem
          ref={(r: NotificationSystem | null) => {
            this.notificationSystem = r;
          }}
          style={this.style}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  notifications: getNotifications(state)
});

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(overlaysActions, dispatch);

const ConnectedNotifier = connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(Notifier) as any);

export { ConnectedNotifier as Notifier };
