import React from 'react';
import { Box, Table, LoadingSpinner, ChevronRightIcon } from 'edenred-ui';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { routerPaths } from '@epi-constants/routerPaths';
import { goToPage } from '@epi-actions/navigation';
import { currencyCommaConvert } from '@epi-helpers/numeral';
import { getBenefitIcon } from '@epi-constants/benefitTypes';
import { TopupReportsTableProps } from '@epi-models/pages/Reports';

import { StatusDot, ButtonContainer } from '../TopupReports.styles';
import { reportStatusesColors, formatDate } from '../../utils';
import { TableNoContent } from '../../TableNoContent';

import { TableHeaderTooltip } from './TableHeaderTooltip';

export const TopupReportsTable = ({
  visibleReports,
  isLoading
}: TopupReportsTableProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const goToDetails = reportId => {
    dispatch(goToPage(`${routerPaths.topupDetails}/${reportId}`));
  };

  if (isLoading) return <LoadingSpinner />;

  return (
    <Table id="topup-reports-table">
      <Table.Head id="topup-reports-table-head">
        <Table.Row>
          <Table.Cell width="15%" id="topup-reports-table-date">
            {t('containers.deposit_money.reports_tab.date')}
          </Table.Cell>
          <Table.Cell width="18%" id="topup-reports-table-executed_by">
            {t('containers.deposit_money.reports_tab.executed_by')}
          </Table.Cell>
          <Table.Cell width="20%" id="topup-reports-table-status">
            {t('containers.deposit_money.reports_tab.status')}
          </Table.Cell>
          <Table.Cell width="5%" id="topup-reports-table-benefit">
            {t('containers.deposit_money.reports_tab.benefit')}
          </Table.Cell>
          <Table.Cell width="9%" align="right" id="topup-reports-table-load">
            <Box display="flex" alignItems="center" justifyContent="flex-end">
              {t('containers.deposit_money.reports_tab.load')}
              <TableHeaderTooltip
                tooltipText={t(
                  'containers.deposit_money.reports_tab.load_info'
                )}
              />
            </Box>
          </Table.Cell>
          <Table.Cell width="14%" id="topup-reports-table-sum">
            <Box
              display="flex"
              alignItems="center"
              justifyContent="flex-end"
              textAlign="right"
            >
              {t('containers.deposit_money.reports_tab.sum')}
              <TableHeaderTooltip
                tooltipText={t('containers.deposit_money.reports_tab.sum_info')}
              />
            </Box>
          </Table.Cell>
          <Table.Cell width="14%" align="right" id="topup-reports-table-total">
            <Box display="flex" alignItems="center" justifyContent="flex-end">
              {t('containers.deposit_money.reports_tab.total')}
              <TableHeaderTooltip
                tooltipText={t(
                  'containers.deposit_money.reports_tab.total_info'
                )}
              />
            </Box>
          </Table.Cell>
          <Table.Cell width="5%" align="right" id="topup-reports-table-actions">
            {t('containers.deposit_money.reports_tab.actions')}
          </Table.Cell>
        </Table.Row>
      </Table.Head>
      <Table.Body>
        {visibleReports.length ? (
          visibleReports.map(report => (
            <Table.Row key={report.id}>
              <Table.Cell id="topup-reports-table-cell-date">
                {formatDate(report.date)}
              </Table.Cell>
              <Table.Cell id="topup-reports-table-cell-executed_by">
                {report.firstName} {report.lastName}
              </Table.Cell>
              <Table.Cell id="topup-reports-table-cell-status">
                <Box display="flex" alignItems="center">
                  <StatusDot $dotColor={reportStatusesColors[report.status]} />
                  {t(
                    `containers.deposit_money.reports_tab.statuses.${report.status}`
                  )}
                </Box>
              </Table.Cell>
              <Table.Cell id="topup-reports-table-cell-benefit">
                {getBenefitIcon(report.benefitType)}
              </Table.Cell>
              <Table.Cell align="right" id="topup-reports-table-cell-load">
                {currencyCommaConvert(report.amount)}
              </Table.Cell>
              <Table.Cell align="right" id="topup-reports-table-cell-sum">
                {currencyCommaConvert(report.totalSum)}
              </Table.Cell>
              <Table.Cell align="right" id="topup-reports-table-cell-total">
                {currencyCommaConvert(report.totalSumWithFees)}
              </Table.Cell>
              <Table.Cell align="right" id="topup-reports-table-cell-actions">
                <Box onClick={() => goToDetails(report.id)}>
                  <ButtonContainer>
                    <ChevronRightIcon />
                  </ButtonContainer>
                </Box>
              </Table.Cell>
            </Table.Row>
          ))
        ) : (
          <TableNoContent />
        )}
      </Table.Body>
    </Table>
  );
};
