import React from 'react';
import { useTheme } from 'styled-components';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Box, TextField, InputAdornment } from 'edenred-ui';
import PropTypes from 'prop-types';

import { createRules, FormFields } from '@epi-forms/helpers';
import {
  required,
  maxAmount,
  minAmount
} from '@epi-helpers/formHelpers/validators';
import { MoneyAmountFieldProps } from '@epi-models/forms/Controls';

export function MoneyAmountField({
  labelKey = 'transfer_money.amount_field_label',
  name = FormFields.MoneyAmount,
  validators
}: MoneyAmountFieldProps) {
  const { control } = useFormContext();
  const { t } = useTranslation();
  const theme = useTheme();
  const defaultValidators = [required, minAmount, maxAmount];
  const rules = createRules(validators || defaultValidators);

  const {
    field: { ref, value, onChange: setValue },
    fieldState: { error }
  } = useController({
    name,
    control,
    rules
  });

  const onChange = event => {
    const formattedValue = (event.target.value || '').replace('.', ',');
    const decimalRegex = /^\d*,?\d{0,2}$/;

    if (formattedValue === '' || decimalRegex.test(formattedValue)) {
      setValue(formattedValue);
    }
  };

  return (
    <TextField
      label={t(labelKey)}
      value={value}
      id={name}
      error={!!error}
      helperText={error?.message}
      inputRef={ref}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <Box fontSize={theme.sizeMedium}>&euro;</Box>
          </InputAdornment>
        )
      }}
      onChange={onChange}
    />
  );
}
