import React from 'react';

import { DisplayAddressProps } from '@epi-models/components/DisplayAddress';

function DisplayAddress({ address }: DisplayAddressProps) {
  if (!address) {
    return null;
  }

  return (
    <div className="header3 small">
      {address && `${address.addressLine},`}
      <br />
      {address && `${address.postCode} ${address.city}`}
    </div>
  );
}

export default DisplayAddress;
