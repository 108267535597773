import React from 'react';
import { SwitchElement, SwitchSlider } from './Switch.styles';

import { ISwitchProps } from '@epi-models/pages/Transfer';

export const Switch = ({ checked, onChange, id }: ISwitchProps) => (
  <SwitchElement id={id}>
    <SwitchSlider onClick={onChange} checked={checked} />
  </SwitchElement>
);
