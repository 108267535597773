import { put, select } from 'redux-saga/effects';
import {
  getApplicationLocale,
  getApplicationLanguageId
} from '@epi-store/application/application.selectors';

import { ApiConstants } from '../../constants/actions';

export function* createOnlinePayment(paymentPublicId, orderType, statusRoute) {
  const locale = yield select(getApplicationLocale);
  const languageId = yield select(getApplicationLanguageId);
  yield put({
    type: `${ApiConstants.CREATE_NEW_PAYMENT}_REQUEST`,
    payload: {
      paymentPublicId,
      orderType,
      locale,
      languageId,
      statusRoute
    }
  });
}
