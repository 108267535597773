import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { Card } from 'react-bootstrap';
import { get, isEmpty } from 'lodash';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import { Button, ButtonSize } from 'edenred-ui';
import { FormProvider, useForm } from 'react-hook-form';

import { registeredVoucherOrderAction } from '@epi-store/registeredVoucherOrder/registeredVoucherOrder.slice';
import { FormArrays, FormFields } from '@epi-forms/helpers';
import { getOrderData } from '@epi-store/registeredVoucherOrder/registeredVoucherOrder.selectors';
import { getAddressData } from '@epi-store/addressPicker/addressPicker.selectors';
import { IDeliveryAddressPicker } from '@epi-models/containers/DeliveryAddressPicker';

import { addresses as addressesLoadingSelector } from '../../selectors/loading';
import { getFinnishAddresses } from '../../selectors/api';
import { DeliveryMethods } from '../../constants/deliveryMethods';
import { NotificationMethods } from '../../constants/notificationMethods';
import { EditDeliveryInfo } from '../../forms/DeliveryInfo/EditDeliveryInfo/EditDeliveryInfo';
import { NavigationHeader } from '../../components/NavigationHeader/NavigationHeader';
import * as apiActions from '../../actions/api';
import * as addressPickerActions from '../../actions/addressPicker';
import * as navigationActions from '../../actions/navigation';

import './DeliveryAddressPicker.scss';

function DeliveryAddressPicker({
  isLoadingAddresses,
  setDeliveryPickedAddress,
  goToReturnPage,
  getAddresses
}: IDeliveryAddressPicker) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const order = useSelector(getOrderData);
  const form = useForm({
    mode: 'onChange',
    defaultValues: {
      [FormFields.DeliveryMethod]: DeliveryMethods.ToSavedAddress,
      [FormArrays.ContactPersons]: '',
      [FormFields.DeliveryCompanyName]: '',
      [FormFields.DeliveryAddress]: '',
      [FormFields.DeliveryZipCode]: '',
      [FormFields.DeliveryCity]: ''
    }
  });
  const {
    formState: { isValid, errors },
    handleSubmit,
    setValue
  } = form;

  const onSubmit = values => {
    setDeliveryPickedAddress();
    goToReturnPage();

    dispatch(registeredVoucherOrderAction.addOrderInformation(values));
  };

  useEffect(() => {
    getAddresses();

    if (order.deliveryMethod) {
      setValue(FormFields.DeliveryMethod, order.deliveryMethod);
      setValue(FormArrays.ContactPersons, order.contactPersons);
    }

    if (order.deliveryMethod === DeliveryMethods.ToOtherAddress) {
      setValue(FormFields.DeliveryCompanyName, order.deliveryCompanyName);
      setValue(FormFields.DeliveryAddress, order.deliveryAddress);
      setValue(FormFields.DeliveryZipCode, order.deliveryZipCode);
      setValue(FormFields.DeliveryCity, order.deliveryCity);
    }
  }, []);

  return (
    <NavigationHeader
      className="DeliveryAddressPicker"
      withBackButton
      title={t('containers.edit_delivery.page_title')}
    >
      <FormProvider {...form}>
        <form noValidate onSubmit={handleSubmit(onSubmit)}>
          <Card>
            <Card.Body>
              <Card.Title as="h1">
                <Trans
                  defaults={t('containers.edit_delivery.delivery_info_header')}
                  components={{
                    span: <span className="small" />
                  }}
                />
              </Card.Title>
              <EditDeliveryInfo isLoading={isLoadingAddresses} />
            </Card.Body>
          </Card>
          <Button
            id="delivery-address-picker-update-btn"
            type="submit"
            size={ButtonSize.Medium}
            className="pull-right btn-edit"
            disabled={!(isValid || isEmpty(errors))}
          >
            {t('containers.edit_delivery.update_button')}
          </Button>
        </form>
      </FormProvider>
    </NavigationHeader>
  );
}

const initAddress = deliveryAddress => {
  const defaultData = {
    method: DeliveryMethods.ToSavedAddress,
    pickup_point_details: {
      confirmation_method: NotificationMethods.SendEmail,
      ...deliveryAddress.pickup_point_details
    },
    contactPerson: deliveryAddress.contactPerson
  };

  const savedAddressId =
    DeliveryMethods.ToSavedAddress === deliveryAddress.method &&
    (deliveryAddress.selectedAddress || get(deliveryAddress, 'address.id'));

  const otherAddressId =
    DeliveryMethods.ToOtherAddress === deliveryAddress.method &&
    get(deliveryAddress, 'address.id');

  if (savedAddressId || otherAddressId) {
    return {
      delivery_info: {
        ...defaultData,
        selectedAddress: savedAddressId || otherAddressId,
        address: {}
      }
    };
  }

  return {
    delivery_info: {
      ...defaultData,
      ...deliveryAddress
    }
  };
};

const mapStateToProps = state => ({
  addresses: getFinnishAddresses(state),
  isLoadingAddresses: addressesLoadingSelector(state),
  initialValues: initAddress(getAddressData(state))
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    { ...navigationActions, ...addressPickerActions, ...apiActions, dispatch },
    dispatch
  );

const ConnectedDeliveryAddressPicker = connect(
  mapStateToProps,
  mapDispatchToProps
)(DeliveryAddressPicker);

export { ConnectedDeliveryAddressPicker as DeliveryAddressPicker };
