import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { ErrorPopupProps } from '@epi-models/components/ErrorPopup';

import { ErrorDetails } from '../../containers/ErrorData/ErrorDetails';
import { getErrorPopup } from '../../selectors/overlays';
import * as profileActions from '../../actions/profile';

const ErrorPopup = ({ errorPopup }: ErrorPopupProps) => {
  const { t } = useTranslation();
  return (
    <Modal show={errorPopup.display || false}>
      <Modal.Header closeButton={errorPopup.closable}>
        <Modal.Title>{t('components.error_popup.title')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {t('components.error_popup.message')}
        {errorPopup.showDetails && errorPopup.error && (
          <ErrorDetails error={errorPopup.error} />
        )}
      </Modal.Body>
      {errorPopup.closable ? (
        <Modal.Footer>
          <Button
            id="error-modal-close-btn"
            onClick={() => errorPopup.closable}
          >
            {t('components.error_popup.close_button')}
          </Button>
        </Modal.Footer>
      ) : null}
    </Modal>
  );
};

const mapStateToProps = state => ({
  errorPopup: getErrorPopup(state)
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(profileActions, dispatch);

const ConnectedErrorPopup = connect(
  mapStateToProps,
  mapDispatchToProps
)(ErrorPopup);

export { ConnectedErrorPopup as ErrorPopup };
