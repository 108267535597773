import { combineReducers } from 'redux';
import { contractReducer } from '@epi-pages/Contract/store/reducers';
import { reducer as registeredVoucherOrder } from '../store/registeredVoucherOrder/registeredVoucherOrder.slice';
import { reducer as companyMetadata } from '../store/companyMetadata/companyMetadata.slice';
import { reducer as addressPicker } from '../store/addressPicker/addressPicker.slice';
import { reducer as application } from '../store/application/application.slice';
import voucherReorder from './voucherReorder';
import login from './api/login';
import navigation from './navigation';
import profile from './api/profile';
import apiCountries from './api/countries';
import apiVouchers from './api/vouchers';
import apiOrderFees from './api/orderFees';
import apiPaymentMethods from './api/paymentMethods';
import apiPayments from './api/payments';
import apiPaymentStatus from './api/paymentStatus';
import apiVoucherOrder from './api/voucherOrder';
import apiCompanies from './api/companies';
import orderHistory from './orderHistory';
import saga from './api/saga';
import apiCompany from './api/company';
import apiVoucherOrdersHistory from './api/voucherOrdersHistory';
import apiVoucherOrderDetails from './api/voucherOrderDetails';
import apiTransactionsHistory from './api/transactionsHistory';
import signUp from './api/signUp';
import apiErrors from './api/errors';
import overlays from './overlays';
import usernameAvailability from './api/usernameAvailability';
import apiAddresses from './api/addresses';
import apiVoucherOrderByPayment from './api/voucherOrderByPayment';
import apiDownloadInvoice from './api/downloadInvoice';
import apiDownloadReceiptForDepositOrder from './api/downloadReceiptForDepositOrder';
import apiDownloadReceiptForTransaction from './api/downloadReceiptForTransaction';
import staticFiles from './staticFiles';
import companyInfo from './companyInfo';
import apiContacts from './api/contacts';
import apiSettings from './api/settings';
import apiContract from './api/contract';
import apiCompanyAccounts from './api/companyAccounts';
import addSignatoryRoleToUser from './api/addSignatoryRoleToUser';
import apiDeposit from './api/deposit';
import apiBenefitGroups from './api/benefitGroups';
import apiBenefitGroupSettings from './api/benefitGroupSettings';
import loadingActions from './loadingActions';
import apiTransactionEstimation from './api/transactionEstimation';
import getHasOrders from './api/getHasOrders';
import { reCaptchaReducer } from './reCaptchaReducer';

export default combineReducers({
  contract: contractReducer,
  reCaptcha: reCaptchaReducer,
  login,
  application,
  navigation,
  profile,
  apiCountries,
  apiVouchers,
  apiOrderFees,
  apiPaymentMethods,
  apiPayments,
  apiPaymentStatus,
  apiVoucherOrder,
  apiCompanies,
  apiBenefitGroups,
  apiBenefitGroupSettings,
  saga,
  orderHistory,
  apiCompany,
  apiVoucherOrdersHistory,
  apiVoucherOrderDetails,
  apiTransactionsHistory,
  apiAddresses,
  signUp,
  overlays,
  apiErrors,
  apiDownloadInvoice,
  usernameAvailability,
  voucherReorder,
  apiVoucherOrderByPayment,
  staticFiles,
  companyInfo,
  apiContacts,
  apiSettings,
  apiContract,
  apiCompanyAccounts,
  addSignatoryRoleToUser,
  apiDownloadReceiptForDepositOrder,
  apiDownloadReceiptForTransaction,
  apiDeposit,
  apiTransactionEstimation,
  loadingActions,
  getHasOrders,
  registeredVoucherOrder,
  companyMetadata,
  addressPicker
});
