import React from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { TextField } from 'edenred-ui';

import { createRules, FormFields } from '@epi-forms/helpers';
import { required } from '@epi-helpers/formHelpers/validators';
import { BenefitSelectProps } from '@epi-models/forms/Controls';

export function BenefitSelect({
  disabled = false,
  readOnly = false,
  name = FormFields.Benefit,
  options
}: BenefitSelectProps) {
  const { control } = useFormContext();
  const { t } = useTranslation();
  const validators = [required];
  const rules = disabled ? undefined : createRules(validators);

  const {
    field: { ref, value, onChange: setValue },
    fieldState: { error }
  } = useController({
    name,
    control,
    rules
  });

  return (
    <TextField
      id="deposit_money_topup_step_one_input"
      label={t('containers.deposit_money.topup_tab.form.benefit.input')}
      select
      value={value}
      disabled={disabled}
      readOnly={readOnly}
      error={!!error}
      helperText={error?.message}
      inputRef={ref}
      onChange={setValue}
      inputProps={{ id: name }}
    >
      {options?.map(benefit => (
        <TextField.Item key={benefit} value={benefit}>
          {t(`components.manage_benefits.new.benefits.${benefit}`)}
        </TextField.Item>
      ))}
    </TextField>
  );
}
