import React from 'react';
import { useTranslation } from 'react-i18next';
import { Table, Button, ButtonMode } from 'edenred-ui';
import styled from 'styled-components';

import { useMenuDimensions } from '@epi-shared/hooks';
import { contactsOrderBy } from '@epi-constants/contactsOrderBy';
import { SortingIcon } from '@epi-shared/components';
import { IContactsTableHead } from '@epi-models/pages/CompanyInfo';

const SortingHeaderContent = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.gray85};
`;

export function ContactsTableHead({
  areData,
  orderBy,
  sortingBy,
  ascending
}: IContactsTableHead) {
  const { t } = useTranslation();
  const { isMobile } = useMenuDimensions();

  return isMobile ? (
    <Table.Head>
      <Table.Row>
        <Table.Cell width="90%">{t('company_info.contacts.name')}</Table.Cell>
        <Table.Cell width="10%" />
      </Table.Row>
    </Table.Head>
  ) : (
    <Table.Head>
      <Table.Row>
        <Table.Cell width="15%">
          <Button
            id="contacts-table-order-by-firstname-btn"
            mode={ButtonMode.None}
            disabled={!areData}
            onClick={() => orderBy(contactsOrderBy.FirstName)}
          >
            <SortingHeaderContent>
              {t('company_info.contacts.first_name')}
              {sortingBy === contactsOrderBy.FirstName && (
                <SortingIcon isAscending={ascending} />
              )}
            </SortingHeaderContent>
          </Button>
        </Table.Cell>
        <Table.Cell width="22%">
          <Button
            id="contacts-table-order-by-lastname-btn"
            mode={ButtonMode.None}
            disabled={!areData}
            onClick={() => orderBy(contactsOrderBy.LastName)}
          >
            <SortingHeaderContent>
              {t('company_info.contacts.last_name')}
              {sortingBy === contactsOrderBy.LastName && (
                <SortingIcon isAscending={ascending} />
              )}
            </SortingHeaderContent>
          </Button>
        </Table.Cell>
        <Table.Cell width="25%">
          <Button
            id="contacts-table-order-by-email-btn"
            mode={ButtonMode.None}
            disabled={!areData}
            onClick={() => orderBy(contactsOrderBy.Email)}
          >
            <SortingHeaderContent>
              {t('company_info.contacts.email')}
              {sortingBy === contactsOrderBy.Email && (
                <SortingIcon isAscending={ascending} />
              )}
            </SortingHeaderContent>
          </Button>
        </Table.Cell>
        <Table.Cell width="20%">
          <Button
            id="contacts-table-order-by-phone-btn"
            mode={ButtonMode.None}
            disabled={!areData}
            onClick={() => orderBy(contactsOrderBy.PhoneNumber)}
          >
            <SortingHeaderContent>
              {t('company_info.contacts.phone_number')}
              {sortingBy === contactsOrderBy.PhoneNumber && (
                <SortingIcon isAscending={ascending} />
              )}
            </SortingHeaderContent>
          </Button>
        </Table.Cell>
        <Table.Cell width="13%">
          <Button
            id="contacts-table-order-by-active-btn"
            mode={ButtonMode.None}
            disabled={!areData}
            onClick={() => orderBy(contactsOrderBy.Active)}
          >
            <SortingHeaderContent>
              {t('company_info.contacts.status_header')}
              {sortingBy === contactsOrderBy.Active && (
                <SortingIcon isAscending={ascending} />
              )}
            </SortingHeaderContent>
          </Button>
        </Table.Cell>
        <Table.Cell width="5%">
          {t('company_info.contacts.actions_header')}
        </Table.Cell>
      </Table.Row>
    </Table.Head>
  );
}
