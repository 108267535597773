import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';

import { StepsProps } from '@epi-models/components/Steps';

import './Steps.scss';

export const Steps = ({
  pages,
  activePageId,
  navigationClick,
  isStepClickable = () => true
}: StepsProps) => {
  const activePageIndex = activePageId
    ? pages.findIndex(page => page.id === activePageId)
    : 0;

  return (
    <Container fluid className="Steps showgrid">
      <Row>
        <div className="Content">
          {pages.map((page, index) => (
            <div
              key={page.id}
              className={`col- ${activePageIndex === index ? 'active' : ''} ${
                activePageIndex > index ? 'visited' : ''
              } text-center step-button-container`}
            >
              <span className="step-button">
                <Button
                  id={`navigate-to-step-btn-${index + 1}`}
                  aria-hidden
                  onClick={() => navigationClick(activePageIndex, index)}
                  className={
                    isStepClickable(activePageIndex, index)
                      ? ''
                      : 'not-clickable'
                  }
                >
                  {index + 1}
                </Button>
              </span>
              <span className="desktop">{page.name}</span>
            </div>
          ))}
        </div>
      </Row>
      <Row className="mobile">
        <Col xs={12} className="text-center active">
          <span>{pages[activePageIndex].name}</span>
        </Col>
      </Row>
    </Container>
  );
};
