import React from 'react';
import {
  BasketIcon,
  BenefitTileStatus,
  BenefitType,
  SuccessCheckIcon
} from 'edenred-ui';
import { useTheme } from 'styled-components';

import { useTranslation, Trans } from 'react-i18next';
import {
  ACTIVE,
  INACTIVE,
  LUNCH,
  ORDERED,
  READY_TO_ORDER
} from '@epi-constants/benefitTypes';

export const useMapBenefits = () => {
  const { t } = useTranslation();
  const { primaryFrontColor } = useTheme();

  const extendBenefitObject = benefit => {
    switch (benefit.state) {
      case ACTIVE:
        return extendBenefitObjectBaseOnTitle({
          ...benefit,
          status: BenefitTileStatus.active,
          labelText: t('home.benefits_tile.active'),
          buttonContent: t('home.benefits_tile.button_manage_benefit'),
          benefitType: BenefitType[benefit.type.toLowerCase()]
        });
      case INACTIVE:
        return extendBenefitObjectBaseOnTitle({
          ...benefit,
          status: BenefitTileStatus.inactive,
          labelText: t('home.benefits_tile.inactive'),
          buttonContent: t('home.benefits_tile.button_active'),
          benefitType: BenefitType[benefit.type.toLowerCase()]
        });
      case ORDERED:
        return extendBenefitObjectBaseOnTitle({
          ...benefit,
          status: BenefitTileStatus.waiting,
          labelText: t('home.benefits_tile.waiting_for_confirm'),
          buttonContent: '',
          benefitType: BenefitType[benefit.type.toLowerCase()]
        });
      case READY_TO_ORDER:
        if (benefit.type === LUNCH) {
          return extendBenefitObjectBaseOnTitle({
            ...benefit,
            status: BenefitTileStatus.available,
            benefitType: BenefitType.duo,
            labelText: '',
            buttonContent: benefit.isOrdered ? (
              <>
                <SuccessCheckIcon color={primaryFrontColor} />{' '}
                {t('home.benefits_tile.button_ordered')}
              </>
            ) : (
              <>
                <BasketIcon />
                {t('home.benefits_tile.button_ready_to_order')}
              </>
            )
          });
        } else {
          return extendBenefitObjectBaseOnTitle({
            ...benefit,
            status: BenefitTileStatus.available,
            labelText: '',
            benefitType: BenefitType[benefit.type.toLowerCase()],
            buttonContent: benefit.isOrdered ? (
              <>
                <SuccessCheckIcon color={primaryFrontColor} />{' '}
                {t('home.benefits_tile.button_ordered')}
              </>
            ) : (
              <>
                <BasketIcon /> {t('home.benefits_tile.button_ready_to_order')}
              </>
            )
          });
        }
      default:
        return extendBenefitObjectBaseOnTitle({
          ...benefit,
          status: BenefitTileStatus.inactive,
          labelText: '',
          buttonContent: '',
          benefitType: BenefitType[benefit.type.toLowerCase()]
        });
    }
  };

  const extendBenefitObjectBaseOnTitle = benefit => {
    const isLounchAndVirike =
      benefit.type === LUNCH && benefit.state === READY_TO_ORDER
        ? 'lunch_and_virike'
        : benefit.type.toLowerCase();

    return {
      ...benefit,
      description: (
        <div id={`benefit-tile-description`}>
          <Trans
            defaults={t(`home.benefits_tile.${isLounchAndVirike}.description`)}
            components={{
              a: (
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={t(`home.benefits_tile.${isLounchAndVirike}.link_url`)}
                />
              ),
              b: (
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={t(`home.benefits_tile.${isLounchAndVirike}.link_url_2`)}
                />
              )
            }}
          />
        </div>
      ),
      title: t(`home.benefits_tile.${isLounchAndVirike}.title`)
    };
  };

  return { extendBenefitObject };
};
