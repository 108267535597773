import React, { useEffect, useState } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { TextField } from 'edenred-ui';
import { useTranslation } from 'react-i18next';
import { isToday, isBefore, isAfter } from 'date-fns';

import { createRules, FormFields } from '@epi-forms/helpers';
import { EmployeeTypeModes } from '@epi-constants/beneficiary';
import { IEmployeeTypeDataField } from '@epi-models/pages/Beneficiaries';
import { required } from '@epi-helpers/formHelpers/validators';
import { TODAY } from '@epi-constants/downloadReports';

export const EmployeeTypeDataField = ({
  isAddMode = false
}: IEmployeeTypeDataField) => {
  const { t } = useTranslation();
  const { control, setError, watch, setValue } = useFormContext();
  const [prevValue, setPrevValue] = useState('');
  const isEditMode = watch(FormFields.EditMode);
  const EoB = watch(FormFields.EndOfBenefit);
  const percentedField = FormFields.EmployeePercentage;
  const daysForLunari = FormFields.DaysForLunari;
  const readOnly = isAddMode ? false : !isEditMode;
  const validators = [required];
  const rules = createRules(validators);

  const fullTime = {
    display: t('containers.beneficiaries.detail.full_time'),
    id: EmployeeTypeModes.FullTime
  };

  const partTime = {
    display: t('containers.beneficiaries.detail.part_time'),
    id: EmployeeTypeModes.PartTime
  };

  const temporaryEmployed = {
    display: t('containers.beneficiaries.detail.temporary_employed'),
    id: EmployeeTypeModes.TemporaryEmployed
  };

  const onHold = {
    display: t('containers.beneficiaries.detail.on_hold'),
    id: EmployeeTypeModes.Onhold
  };

  const options = isAddMode
    ? [fullTime, partTime, temporaryEmployed]
    : [fullTime, partTime, temporaryEmployed, onHold];

  const {
    field: { onChange, value },
    fieldState: { error }
  } = useController({
    name: FormFields.EmployeeType,
    control,
    rules
  });
  const isDropped = value === EmployeeTypeModes.Dropped;

  const handleOnChange = e => {
    setValue(FormFields.EditingType, true);
    onChange(e);
  };

  useEffect(() => {
    if (value === EmployeeTypeModes.FullTime) {
      setError(percentedField, {});
    }
  }, [value]);

  useEffect(() => {
    if (!EoB) {
      setPrevValue('');
      setValue(daysForLunari, '');
      return setValue(FormFields.EmployeeType, '');
    }

    if (
      prevValue === EmployeeTypeModes.Dropped &&
      isAfter(new Date(EoB), TODAY)
    ) {
      setPrevValue('');
      setValue(daysForLunari, '');
      return setValue(FormFields.EmployeeType, '');
    }

    if (isToday(new Date(EoB)) || isBefore(new Date(EoB), TODAY)) {
      setValue(FormFields.EmployeeType, EmployeeTypeModes.Dropped);
      return setPrevValue(EmployeeTypeModes.Dropped);
    }

    setPrevValue('');
    setValue(FormFields.EmployeeType, value);
  }, [EoB]);

  return (
    <TextField
      label={t('containers.beneficiaries.detail.employee_type')}
      select={!isDropped}
      required
      value={
        isDropped
          ? t('containers.beneficiaries.detail.dropped')
          : EmployeeTypeModes[value] ?? ''
      }
      readOnly={!isDropped ? readOnly : true}
      id={'employee-type-select'}
      onChange={handleOnChange}
      error={!!error}
      helperText={error?.message}
      sx={{ '& .MuiSelect-select:focus': { backgroundColor: 'transparent' } }}
    >
      {options.map(option => (
        <TextField.Item key={option.id} value={option.id}>
          {option.display}
        </TextField.Item>
      ))}
    </TextField>
  );
};
