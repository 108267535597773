import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FormFields } from '@epi-forms/helpers';
import { isEmptyObj } from '@epi-helpers/isEmptyObj';
import { getBeneficiaryPayload } from '@epi-pages/Beneficiaries/helpers/getBeneficiaryPayload';
import { IAddBeneficiarySlideout } from '@epi-models/pages/Beneficiaries';
import {
  UNIQUE,
  FIELDS_TO_WATCH,
  EmployeeTypeModes
} from '@epi-constants/beneficiary';
import {
  addBenefitsDataMapper,
  setBenefitsDataForCreateEmployee
} from '@epi-helpers/BeneficiaryHelper';

export const useAddBeneficiarySlideout = ({
  expanded,
  isProcessing,
  setExpanded,
  addEmployeeAction,
  currentlySetBenefitValues,
  isLunchBenefitActive
}: IAddBeneficiarySlideout) => {
  const { t } = useTranslation();
  const [addAnotherEmployee, setAddAnotherEmployee] = useState(false);
  const [showCloseConfirmation, setShowCloseConfirmation] = useState(false);

  const form = useForm({
    mode: 'onChange',
    defaultValues: addBenefitsDataMapper(
      currentlySetBenefitValues,
      isLunchBenefitActive
    )
  });

  const {
    getValues,
    reset,
    watch,
    trigger,
    setError,
    setValue,
    formState: { errors, isDirty, dirtyFields }
  } = form;

  const isEmptyField = watch(FIELDS_TO_WATCH);
  const employeeType = watch(FormFields.EmployeeType);
  const isPartTimeOrTemporaryType =
    employeeType === EmployeeTypeModes.PartTime ||
    employeeType === EmployeeTypeModes.TemporaryEmployed;

  const toggleAddAnotherEmployee = () =>
    setAddAnotherEmployee(prevState => !prevState);

  const handleClose = () => {
    if (isEmptyObj(dirtyFields)) {
      setExpanded(false);
      setShowCloseConfirmation(false);
      reset();
    } else {
      setShowCloseConfirmation(true);
    }
  };

  const handleSubmitFormExecute = () => {
    if (isEmptyField.includes('')) {
      trigger();
    } else {
      addEmployeeAction(
        getBeneficiaryPayload(getValues()),
        addAnotherEmployee,
        reset
      )
        .then(() => setExpanded(false))
        .catch(err => {
          const uniqueEmployerNumber =
            err.content && err.content.errors[0].message === UNIQUE;
          if (uniqueEmployerNumber) {
            setError(FormFields.EmployeeNumber, {
              type: 'custom',
              message: t('messages.employee_number')
            });
          }
        });
    }
  };

  const handleCloseConfirmation = () => setShowCloseConfirmation(false);

  const confirmCloseWithoutSaving = () => {
    setShowCloseConfirmation(false);
    reset();
    setExpanded(false);
  };

  const confirmSaving = () => {
    setShowCloseConfirmation(false);
  };

  const { [FormFields.EmployeePercentage]: EmployeePercentage, ...rest } =
    errors;

  const specificErrors = isPartTimeOrTemporaryType ? errors : rest;

  const disableSubmit =
    !isEmptyObj(specificErrors) ||
    !isDirty ||
    isEmptyObj(dirtyFields) ||
    isProcessing ||
    isEmptyField.includes('');

  useEffect(() => {
    if (!expanded && currentlySetBenefitValues) {
      reset();
      setValue(
        FormFields.Benefits,
        setBenefitsDataForCreateEmployee(currentlySetBenefitValues)
      );
      setValue(FormFields.LunchBenefitActive, isLunchBenefitActive);
    }
  }, [expanded]);

  useEffect(() => {
    if (currentlySetBenefitValues) {
      setValue(
        FormFields.Benefits,
        setBenefitsDataForCreateEmployee(currentlySetBenefitValues)
      );
    }
  }, [currentlySetBenefitValues]);

  useEffect(() => {
    if (isLunchBenefitActive) {
      setValue(FormFields.LunchBenefitActive, isLunchBenefitActive);
    }
  }, [isLunchBenefitActive]);

  return {
    handleClose,
    addAnotherEmployee,
    toggleAddAnotherEmployee,
    handleSubmitFormExecute,
    disableSubmit,
    form,
    showCloseConfirmation,
    handleCloseConfirmation,
    confirmCloseWithoutSaving,
    confirmSaving
  };
};
