import { BeneficiaryConstants } from '@epi-constants/actions';

export const initializeBenefitSettingsContainer = () => ({
  type: `${BeneficiaryConstants.INITIALIZE_BENEFIT_SETTINGS_CONTAINER}`
});

export const startOrderProcess = () => ({
  type: `${BeneficiaryConstants.START_ORDER_PROCESS}`
});

export const stopOrderProcess = () => ({
  type: BeneficiaryConstants.STOP_ORDER_PROCESS
});

export const setGoToPageLink = payload => ({
  type: BeneficiaryConstants.GO_TO_PAGE_LINK,
  payload
});
