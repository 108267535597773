import { desiredOrder } from '@epi-constants/beneficiary';
import { TODAY } from '@epi-constants/downloadReports';
import { FormFields } from '@epi-forms/helpers';
import {
  IBeneficiaryData,
  ICurrentlySetBenefitValues
} from '@epi-models/pages/Beneficiaries';

const sortBenefitsByCustomOrder = (arr, desiredOrder) => {
  const orderForIndexVals = desiredOrder.slice(0).reverse();
  arr.sort((a, b) => {
    const aIndex = -orderForIndexVals.indexOf(a.benefitType);
    const bIndex = -orderForIndexVals.indexOf(b.benefitType);
    return aIndex - bIndex;
  });
  return arr;
};

export const benefitsDataMapper = (
  beneficiary: IBeneficiaryData,
  editingMode: boolean,
  isLunchBenefitActive?: boolean
) => {
  const {
    personId,
    firstName,
    lastName,
    emailAddress,
    personalId,
    endOfBenefit,
    endOfFunds,
    createdAt,
    employmentType,
    benefitGroupSettings,
    costCenter,
    employeeNumber,
    startOfBenefit,
    benefitPercentage,
    benefitLunchMaxWorkingDays
  } = beneficiary;

  return {
    [FormFields.FirstName]: firstName,
    [FormFields.LastName]: lastName,
    [FormFields.PersonID]: personId,
    [FormFields.EmailAddress]: emailAddress,
    [FormFields.PersonalID]: personalId,
    [FormFields.EndOfBenefit]: endOfBenefit,
    [FormFields.EndOfFunds]: endOfFunds,
    [FormFields.CreatedAt]: createdAt,
    [FormFields.EditMode]: editingMode,
    [FormFields.EditingType]: false,
    [FormFields.Entitled]: startOfBenefit,
    [FormFields.Benefits]: sortBenefitsByCustomOrder(
      benefitGroupSettings,
      desiredOrder
    ),
    [FormFields.EmployeeType]: employmentType || '',
    [FormFields.EmployeeNumber]: employeeNumber || '',
    [FormFields.CostCenterNumber]: costCenter || '',
    [FormFields.DaysForLunari]: benefitLunchMaxWorkingDays,
    [FormFields.EmployeePercentage]: benefitPercentage,
    [FormFields.LunchBenefitActive]: isLunchBenefitActive
  };
};

export const setBenefitsDataForCreateEmployee = (
  currentlySetBenefitValues: ICurrentlySetBenefitValues
) => {
  const arrValues = Object.values(currentlySetBenefitValues);
  const arrKeys = Object.keys(currentlySetBenefitValues).map(key =>
    key.replace('card', '')
  );

  return arrKeys
    .map((key, index) => {
      if (!arrValues[index]) return;
      return {
        groupId: key + index,
        benefitType: key,
        maxAmountPerPeriod: arrValues[index] / 100,
        endDate: '',
        deductionType: '',
        frequency: '',
        mobilePaymentEnabled: false,
        name: key,
        startDate: ''
      };
    })
    .filter(obj => obj);
};

export const addBenefitsDataMapper = (
  currentlySetBenefitValues: ICurrentlySetBenefitValues | null,
  isLunchBenefitActive?: boolean
) => {
  return {
    [FormFields.FirstName]: '',
    [FormFields.LastName]: '',
    [FormFields.PersonalID]: '',
    [FormFields.EmailAddress]: '',
    [FormFields.EmployeeNumber]: '',
    [FormFields.CostCenterNumber]: '',
    [FormFields.EndOfBenefit]: '',
    [FormFields.EndOfFunds]: '',
    [FormFields.EmployeeType]: '',
    [FormFields.EditingType]: false,
    [FormFields.LunchBenefitActive]: isLunchBenefitActive,
    [FormFields.Entitled]: '',
    [FormFields.Benefits]: currentlySetBenefitValues?.cardLunch
      ? setBenefitsDataForCreateEmployee(currentlySetBenefitValues)
      : []
  };
};

export const disableSaveButtonForBeneficiariesDataModal = (
  date: null | Date,
  isEntitledDate = false
) => {
  if (date === null) {
    return isEntitledDate;
  }

  if (date) {
    return date?.getTime() < TODAY.setHours(0, 0, 0, 0);
  }

  return true;
};
