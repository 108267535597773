import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { Notification, NotificationType } from 'edenred-ui';

export function AccountCreatedNotification() {
  const { t } = useTranslation();
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const shouldShowNotification = params.get('created-account') === 'true';

  const [isVisible, setVisible] = useState(shouldShowNotification);

  return isVisible ? (
    <Notification
      text={t('login.created_account_notification')}
      type={NotificationType.Success}
      onClose={() => setVisible(false)}
    />
  ) : null;
}
