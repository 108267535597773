import React from 'react';
import { useFormContext } from 'react-hook-form';

import { FormFields } from '@epi-forms/helpers';

import { getOther } from '../helpers';

export const useReportsTab = () => {
  const { watch } = useFormContext();

  const dataScope = watch(getOther(FormFields.DataScope));

  const displayColumnPills =
    dataScope.employeeData || dataScope.benefits || dataScope.loads;

  return { displayColumnPills };
};
