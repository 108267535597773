import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Login from '@epi-pages/Login';
import CreateAccount, {
  ResendMail,
  ResendMailSummary
} from '@epi-pages/CreateAccount';
import CompanyInfo from '@epi-pages/CompanyInfo';
import PaperVouchers from '@epi-pages/PaperVouchers';
import {
  ContractCompleted,
  TokenInvalid
} from '@epi-pages/CreateAccount/components';
import { Home } from '@epi-pages/Home';
import { DownloadReports } from '@epi-pages/DownloadReports';
import { Support } from '@epi-pages/Support';
import { TransferPage } from '@epi-pages/TransferPage/TransferPage';
import { OrderAmountLoadingData } from '@epi-containers/OrderAmount/OrderAmountLoadingData';
import { NewPassword } from '@epi-pages/NewPassword';
import { TopupReportsDetails } from '@epi-pages/Reports/TopupReports/TopupReportsDetails';
import { ContactDeleted } from '@epi-pages/CreateAccount/components/ContactDeleted';
import { EmailConfirmation } from '@epi-pages/EmailConfirmation';
import { ManageBenefits } from '@epi-containers/ManageBenefits';

import { ManageBenefitGroups } from '../containers/ManageBenefitGroups';
import { join } from '../helpers/UrlHelpers';
import { routerPaths } from '../constants/routerPaths';
import { OrderType } from '../constants/orderType';
import { VoucherTypes, getVoucherTypeKey } from '../constants/voucherTypes';
import { FormConstants as forms } from '../constants/forms';
import { OrderStatus } from '../containers/OrderStatus/OrderStatus';
import { OrderDetails } from '../containers/OrderDetails/OrderDetails';
import ExternalReports from '../containers/ExternalReports/ExternalReports';
import { Profile } from '../containers/Profile/Profile';
import { MaksturvaNewPayment } from '../containers/MaksturvaNewPayment/MaksturvaNewPayment';
import { NotFound } from '../containers/NotFound/NotFound';
import { DeliveryAddressPicker } from '../containers/DeliveryAddressPicker/DeliveryAddressPicker';
import { VoucherOrderPayment } from '../containers/VoucherOrderPayment/VoucherOrderPayment';
import CardOrderPayment from '../containers/CardOrderPayment/CardOrderPayment';
import { ResetPassword } from '../containers/ResetPassword/ResetPassword';
import { ResetPasswordRequested } from '../containers/ResetPasswordRequested/ResetPasswordRequested';
import { RegisteredUser as VoucherOrderRegisteredUser } from '../containers/VoucherOrder/RegisteredUser';
import { StrictMode } from '../components/StrictMode/StrictMode';
import BeneficiariesList from '../pages/Beneficiaries/BeneficiariesList';
import { ReorderRoute } from './ReorderRoute';
import * as navigationActions from '../actions/navigation';
import { SystemRoles } from '../constants/roles';
import RequestedLoadsPage from '../containers/RequestedLoads/RequestedLoadsPage';

import { AnonymousOnly } from './AnonymousOnly';
import { Authorize } from './Authorize';
import { AuthorizeRole } from './AuthorizeRole';
import { TwoWays } from './TwoWays';

class DefaultRoutes extends Component {
  constructor(props) {
    super(props);
    const {
      openPageEventStart,
      location: { pathname }
    } = this.props;
    openPageEventStart(pathname);
  }

  componentDidMount() {
    const {
      openPageEventEnd,
      location: { pathname }
    } = this.props;
    setTimeout(() => openPageEventEnd(pathname), 0); // Run after rendering
  }

  shouldComponentUpdate(nextProps) {
    const {
      path,
      openPageEventStart,
      location: { pathname }
    } = this.props;
    const shouldUpdate =
      nextProps.path !== path || nextProps.location.pathname !== pathname;
    if (shouldUpdate) {
      openPageEventStart(nextProps.location.pathname);
    }
    return shouldUpdate;
  }

  componentDidUpdate(prevProps) {
    const {
      openPageEventEnd,
      location,
      location: { pathname },
      saveLocationHistory
    } = this.props;
    if (prevProps.location.pathname !== pathname) {
      saveLocationHistory(location);
    }
    setTimeout(() => openPageEventEnd(pathname), 0); // Run after rendering
  }

  render() {
    const { path } = this.props;
    const lunchType = getVoucherTypeKey(VoucherTypes.Lunch);
    const recreationalType = getVoucherTypeKey(VoucherTypes.Recreational);
    return (
      <StrictMode>
        <Switch>
          <TwoWays
            exact
            path={`${join(path, routerPaths.home)}`}
            authorized={<Home />}
            unauthorized={<Login />}
            redirect={`${join(path, routerPaths.login)}`}
          />

          <Route
            exact
            path={`${join(path, routerPaths.notFound)}`}
            component={NotFound}
          />

          {/* Start - Lunch vouchers routing */}
          <TwoWays
            exact
            path={`${join(path, routerPaths.voucherOrder[lunchType][0])}`}
            unauthorized={<NotFound />}
            authorized={
              <VoucherOrderRegisteredUser
                voucherTypeKey={lunchType}
                useCalculator
                form={forms.LUNCH_VOUCHER_ORDER_FORM}
                page={1}
              />
            }
            redirect={`${join(path, routerPaths.login)}`}
          />
          <TwoWays
            path={`${join(path, routerPaths.voucherOrder[lunchType][1])}`}
            unauthorized={<NotFound />}
            authorized={
              <VoucherOrderRegisteredUser
                voucherTypeKey={lunchType}
                form={forms.LUNCH_VOUCHER_ORDER_FORM}
                page={2}
              />
            }
            redirect={`${join(path, routerPaths.login)}`}
          />

          <Authorize
            path={`${join(path, routerPaths.voucherOrder[lunchType][3])}`}
            redirect={`${join(path, routerPaths.login)}`}
          >
            <OrderStatus orderType={OrderType.voucher} />
          </Authorize>
          {/* End - Lunch vouchers routing */}

          {/* Start - Recreational vouchers routing */}
          <TwoWays
            exact
            path={`${join(
              path,
              routerPaths.voucherOrder[recreationalType][0]
            )}`}
            unauthorized={<NotFound />}
            authorized={
              <VoucherOrderRegisteredUser
                voucherTypeKey={recreationalType}
                form={forms.RECREATIONAL_VOUCHER_ORDER_FORM}
                page={1}
              />
            }
            redirect={`${join(path, routerPaths.login)}`}
          />
          <TwoWays
            path={`${join(
              path,
              routerPaths.voucherOrder[recreationalType][1]
            )}`}
            unauthorized={<NotFound />}
            authorized={
              <VoucherOrderRegisteredUser
                voucherTypeKey={recreationalType}
                form={forms.RECREATIONAL_VOUCHER_ORDER_FORM}
                page={2}
              />
            }
            redirect={`${join(path, routerPaths.login)}`}
          />
          <Authorize
            path={`${join(
              path,
              routerPaths.voucherOrder[recreationalType][3]
            )}`}
            redirect={`${join(path, routerPaths.login)}`}
          >
            <OrderStatus orderType={OrderType.voucher} />
          </Authorize>
          {/* End - Recreational vouchers routing */}

          {/* Load deposit order routing */}
          <Authorize
            path={`${join(path, routerPaths.loadCompanyAccountStatus)}`}
            redirect={`${join(path, routerPaths.login)}`}
          >
            <OrderStatus orderType={OrderType.digital} isDeposit />
          </Authorize>

          {/* End - Load deposit order routing */}

          <AnonymousOnly
            path={`${join(path, routerPaths.login)}`}
            redirect={`${join(path, routerPaths.home)}`}
          >
            <Login />
          </AnonymousOnly>

          <Route
            path={`${join(path, routerPaths.voucherOrderPayment)}`}
            component={VoucherOrderPayment}
          />
          <Authorize
            path={`${join(path, routerPaths.cardOrderPayment)}`}
            redirect={`${join(path, routerPaths.login)}`}
          >
            <CardOrderPayment />
          </Authorize>
          <Route
            path={`${join(path, routerPaths.orderPaymentStatus)}`}
            component={props => (
              <OrderStatus
                {...props}
                orderType={OrderType.digital}
                paymentOnlyFlow
              />
            )}
            redirect={`${join(path, routerPaths.login)}`}
          />
          <AnonymousOnly
            path={`${join(path, routerPaths.resetPasswordRequested)}`}
            redirect={`${join(path, routerPaths.home)}`}
          >
            <ResetPasswordRequested />
          </AnonymousOnly>
          <AnonymousOnly
            path={`${join(path, routerPaths.resetPassword)}`}
            redirect={`${join(path, routerPaths.home)}`}
          >
            <ResetPassword />
          </AnonymousOnly>
          <AnonymousOnly
            path={`${join(path, routerPaths.createAccount)}`}
            redirect={`${join(path, routerPaths.home)}`}
          >
            <CreateAccount />
          </AnonymousOnly>
          <AnonymousOnly
            path={`${join(path, routerPaths.resendMail)}`}
            redirect={`${join(path, routerPaths.home)}`}
          >
            <ResendMail />
          </AnonymousOnly>
          <AnonymousOnly
            path={`${join(path, routerPaths.resendMailSummary)}`}
            redirect={`${join(path, routerPaths.home)}`}
          >
            <ResendMailSummary />
          </AnonymousOnly>
          <AnonymousOnly
            path={`${join(path, routerPaths.resendMailCompleted)}`}
            redirect={`${join(path, routerPaths.home)}`}
          >
            <ContractCompleted />
          </AnonymousOnly>
          <AnonymousOnly
            path={`${join(path, routerPaths.resendMailInvalid)}`}
            redirect={`${join(path, routerPaths.home)}`}
          >
            <TokenInvalid />
          </AnonymousOnly>
          <AnonymousOnly
            path={`${join(path, routerPaths.resendMailContactDeleted)}`}
            redirect={`${join(path, routerPaths.home)}`}
          >
            <ContactDeleted />
          </AnonymousOnly>
          <AnonymousOnly
            path={`${join(path, routerPaths.confirmEmail)}`}
            redirect={`${join(path, routerPaths.home)}`}
          >
            <EmailConfirmation />
          </AnonymousOnly>
          <AnonymousOnly
            path={`${join(path, routerPaths.changeEmail)}`}
            redirect={`${join(path, routerPaths.home)}`}
          >
            <EmailConfirmation isChange />
          </AnonymousOnly>
          <Route
            path={`${join(path, routerPaths.newPassword)}`}
            component={NewPassword}
          />
          <Route
            path={`${join(path, routerPaths.maksuturvaPayment)}`}
            component={MaksturvaNewPayment}
          />
          <Authorize
            path={`${join(path, routerPaths.voucherReorderEditAmount)}`}
            redirect={`${join(path, routerPaths.login)}`}
          >
            <OrderAmountLoadingData />
          </Authorize>

          <Authorize
            path={`${join(path, routerPaths.voucherReorderEditCompany)}`}
            redirect={`${join(path, routerPaths.login)}`}
          >
            <CompanyInfo autoRedirect />
          </Authorize>
          <Authorize
            path={`${join(path, routerPaths.cardOrderEditCompany)}`}
            redirect={`${join(path, routerPaths.login)}`}
          >
            <CompanyInfo autoRedirect />
          </Authorize>
          <Authorize
            path={`${join(path, routerPaths.voucherReorderEditDelivery)}`}
            redirect={`${join(path, routerPaths.login)}`}
          >
            <DeliveryAddressPicker />
          </Authorize>
          <Authorize
            path={`${join(path, routerPaths.voucherReorder)}`}
            redirect={`${join(path, routerPaths.login)}`}
          >
            <ReorderRoute path={path} />
          </Authorize>
          <Authorize
            path={`${join(path, routerPaths.voucherOrderEditDelivery)}`}
            redirect={`${join(path, routerPaths.login)}`}
          >
            <DeliveryAddressPicker />
          </Authorize>
          <Authorize
            path={`${join(path, routerPaths.voucherOrderEditCompany)}`}
            redirect={`${join(path, routerPaths.login)}`}
          >
            <CompanyInfo autoRedirect />
          </Authorize>
          <Authorize
            path={`${join(path, routerPaths.profile)}`}
            redirect={`${join(path, routerPaths.login)}`}
          >
            <Profile />
          </Authorize>
          <AuthorizeRole
            roles={[SystemRoles.VoucherManager]}
            path={`${join(path, routerPaths.orderHistory)}`}
            redirect={`${join(path, routerPaths.login)}`}
          >
            <PaperVouchers />
          </AuthorizeRole>
          <AuthorizeRole
            roles={[SystemRoles.Signatory]}
            path={`${join(path, routerPaths.requestedLoads)}`}
            redirect={`${join(path, routerPaths.login)}`}
          >
            <RequestedLoadsPage />
          </AuthorizeRole>
          <AuthorizeRole
            roles={[SystemRoles.Signatory]}
            path={`${join(path, routerPaths.transactionsHistory)}`}
            redirect={`${join(path, routerPaths.login)}`}
          >
            <Redirect
              to={{
                pathname: `${join(path, routerPaths.reports)}`
              }}
            />
          </AuthorizeRole>
          <AuthorizeRole
            exact
            roles={[SystemRoles.Signatory]}
            path={`${join(path, routerPaths.reports)}`}
            redirect={`${join(path, routerPaths.login)}`}
          >
            <ExternalReports />
          </AuthorizeRole>
          <AuthorizeRole
            roles={[SystemRoles.Signatory]}
            path={`${join(path, routerPaths.downloadReports)}`}
            redirect={`${join(path, routerPaths.login)}`}
          >
            <DownloadReports />
          </AuthorizeRole>
          <AuthorizeRole
            roles={[SystemRoles.VoucherManager]}
            path={`${join(path, routerPaths.companyInformation)}`}
            redirect={`${join(path, routerPaths.login)}`}
          >
            <CompanyInfo />
          </AuthorizeRole>
          <Authorize
            path={`${join(path, routerPaths.orderDetails)}`}
            redirect={`${join(path, routerPaths.login)}`}
          >
            <OrderDetails />
          </Authorize>
          <AuthorizeRole
            exact
            roles={[SystemRoles.Signatory]}
            path={`${join(path, routerPaths.loadCompanyAccount)}`}
            redirect={`${join(path, routerPaths.login)}`}
          >
            <TransferPage />
          </AuthorizeRole>
          <AuthorizeRole
            roles={[SystemRoles.Signatory]}
            path={`${join(path, routerPaths.topupDetails)}`}
            redirect={`${join(path, routerPaths.login)}`}
          >
            <TopupReportsDetails />
          </AuthorizeRole>
          <AuthorizeRole
            roles={[SystemRoles.Signatory]}
            path={`${join(path, routerPaths.manageBenefitGroup)}`}
            redirect={`${join(path, routerPaths.login)}`}
          >
            <ManageBenefitGroups />
          </AuthorizeRole>
          <AuthorizeRole
            roles={[SystemRoles.Signatory]}
            path={`${join(path, routerPaths.manageBenefits)}`}
            redirect={`${join(path, routerPaths.login)}`}
          >
            <ManageBenefits />
          </AuthorizeRole>
          <Route path={`${join(path, routerPaths.help)}`} component={Support} />
          <AuthorizeRole
            roles={[SystemRoles.Signatory]}
            path={`${join(path, routerPaths.beneficiaries)}`}
            redirect={`${join(path, routerPaths.login)}`}
          >
            <BeneficiariesList />
          </AuthorizeRole>
          <Route component={NotFound} />
        </Switch>
      </StrictMode>
    );
  }
}

DefaultRoutes.propTypes = {
  path: PropTypes.string.isRequired,
  location: PropTypes.object,
  lastLocation: PropTypes.object,
  saveLocationHistory: PropTypes.func.isRequired,
  openPageEventStart: PropTypes.func.isRequired,
  openPageEventEnd: PropTypes.func.isRequired
};

DefaultRoutes.defaultProps = {
  location: {},
  lastLocation: null
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(navigationActions, dispatch);

const ConnectedDefaultRoutes = connect(null, mapDispatchToProps)(DefaultRoutes);

export { ConnectedDefaultRoutes as DefaultRoutes };
