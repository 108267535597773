import numeral from 'numeral';

const euroCurrencyFormat = '0,0[.]00';
const euroDecimalCurrencyFormat = '0,0.00';

export const currency = value =>
  `${numeral(value).format(euroCurrencyFormat)} €`;

export const currencyDecimal = value =>
  `${numeral(value).format(euroDecimalCurrencyFormat)}€`;

export const currencyDecimalConvert = value =>
  `${numeral(value / 100).format(euroDecimalCurrencyFormat)} €`;

export const currencyCommaConvert = value =>
  `${numeral(value / 100).format(euroCurrencyFormat)}€`;

export const currencyBalance = value => {
  let prefix = '';
  if (value !== 0) prefix = '+';
  const balance = numeral(value).format(`${prefix}${euroCurrencyFormat}`);
  return `${balance} €`;
};

export const integer = value => numeral(value).format('0,0');

export const decimal = value => numeral(value).format('0,0.00');

export const format = (value, pattern) => numeral(value).format(pattern);

export const getNumber = value => {
  if (value && typeof value === 'string')
    return Number(value.replace(',', '.'));
  if (value && !Number.isNaN(value)) return value;
  return NaN;
};

export const parseDecimal = value => {
  if (value && typeof value === 'string') {
    const decimalSeparator = numeral.localeData().delimiters.decimal;
    return parseFloat(value ? `${value}`.replace(decimalSeparator, '.') : 0);
  }
  return getNumber(value);
};

export const value = v => numeral(v).value();

export {
  currencyDecimal as formatCurrencyDecimal,
  currencyDecimalConvert as formatCurrencyDecimalConvert,
  currency as formatCurrency,
  currencyBalance as formatCurrencyBalance,
  format as formatNumeric,
  decimal as formatDecimal,
  integer as formatInteger
};
