import React, { useEffect, useState } from 'react';

export const useOrderSummaryTerms = (showOrderSummary: boolean) => {
  const [isSign, setIsSign] = useState(false);
  const [isTerms, setIsTerms] = useState(false);
  const [isFirstOrder, setIsFirstOrder] = useState(true);

  const handleIsSingIn = () => setIsSign(prevState => !prevState);
  const handleIsTerms = () => setIsTerms(prevState => !prevState);

  useEffect(() => {
    if (isFirstOrder && showOrderSummary) {
      setIsFirstOrder(false);
      document.querySelector('#home-order-summary')!.scrollIntoView({
        behavior: 'smooth'
      });
    }
  }, [showOrderSummary]);

  return { isSign, isTerms, handleIsSingIn, handleIsTerms };
};
