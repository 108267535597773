import React from 'react';
import PropTypes from 'prop-types';
import { useController, useFormContext } from 'react-hook-form';
import { FormControlLabel, Radio, Box } from 'edenred-ui';

import { createRules } from '@epi-forms/helpers';
import { required } from '@epi-helpers/formHelpers/validators';
import { PaymentMethodFieldProps } from '@epi-models/forms/PaymentMethodField';

export function PaymentMethodField({
  value,
  validators,
  name,
  label,
  disabled,
  onChangeMethod
}: PaymentMethodFieldProps) {
  const { control } = useFormContext();
  const defaultValidators = [required];
  const rules = createRules(validators || defaultValidators);

  const {
    field: { ref, onChange: setValue }
  } = useController({
    name,
    control,
    rules
  });

  return (
    <Box>
      <FormControlLabel
        inputRef={ref}
        disabled={disabled}
        value={value}
        control={<Radio />}
        label={label}
        onChange={() => {
          onChangeMethod(value);
          setValue(value);
        }}
      />
    </Box>
  );
}
