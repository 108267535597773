import React, { forwardRef, useImperativeHandle } from 'react';
import { connect } from 'react-redux';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { TextField } from 'edenred-ui';

import { validateEmailRequest } from '@epi-api/email';
import * as Validators from '@epi-helpers/formHelpers/validators';
import { getRecaptchaToken } from '@epi-helpers/ReCaptchaHelper';
import {
  createRules,
  FormFields,
  useOnBlurTrim,
  withFormNameContext,
  useAsyncValidator,
  useAsyncValidatorController
} from '@epi-forms/helpers';
import { getReCaptchaSiteKey } from '@epi-selectors/settings';
import { EmailAddressFieldProps } from '@epi-models/forms/Controls';

function EmailAddressField({
  reCaptchaSiteKey,
  context = '',
  disabled = false,
  readOnly = false,
  forwardedRef,
  fieldName,
  label = '',
  unverifiedInfo
}: EmailAddressFieldProps) {
  const name = withFormNameContext(
    context || '',
    fieldName || FormFields.EmailAddress
  );
  const { t } = useTranslation();
  const { control } = useFormContext();
  const onBlurTrim = useOnBlurTrim(name);
  const validators = [
    Validators.required,
    Validators.email,
    Validators.maxLength(50)
  ];
  const rules = createRules(validators);

  const {
    field: { ref: inputRef, value, onChange: setValue },
    fieldState: { error }
  } = useController({
    name,
    control,
    rules
  });

  const emailDNSValidator = async abortController => {
    const reCaptchaToken = await getRecaptchaToken(
      reCaptchaSiteKey,
      'email_dns_validation'
    );

    await validateEmailRequest(
      { emailAddress: value, reCaptchaToken },
      abortController
    );
  };

  const validateEmailDNS = useAsyncValidator({
    fieldName: name,
    validator: emailDNSValidator,
    validatorName: FormFields.EmailAddress
  });

  useImperativeHandle(forwardedRef, () => ({
    validateEmailDNS
  }));

  const {
    isError,
    isInfo,
    helperText,
    inputProps,
    setAsyncUnverified,
    clearAsyncUnverified
  } = useAsyncValidatorController({
    value,
    error,
    name,
    invalidMsg: t('validation_messages.email_invalid_domain'),
    processingMsg: t('validation_messages.email_domain_processing')
  });

  const onChange = event => {
    setAsyncUnverified();
    setValue(event);
  };

  const onBlur = async () => {
    onBlurTrim();
    await validateEmailDNS();
    clearAsyncUnverified();
  };

  return (
    <TextField
      label={label}
      required
      value={value}
      readOnly={readOnly}
      disabled={disabled}
      id={name}
      error={isError || !!unverifiedInfo}
      info={isInfo}
      helperText={helperText || unverifiedInfo}
      InputProps={readOnly ? undefined : inputProps}
      inputRef={inputRef}
      onBlur={onBlur}
      onChange={onChange}
    />
  );
}

const mapStateToProps = state => ({
  reCaptchaSiteKey: getReCaptchaSiteKey(state)
});

const ConnectedEmailAddressField = connect(mapStateToProps)(EmailAddressField);

const ForwardedRefEmailAddressField = forwardRef<any, EmailAddressFieldProps>(
  (props, ref) => <ConnectedEmailAddressField {...props} forwardedRef={ref} />
);

ForwardedRefEmailAddressField.displayName = 'ForwardedRefEmailAddressField';

export { ForwardedRefEmailAddressField as EmailAddressField };
