import { useCallback, useState } from 'react';

export function useModal(defaultIsOpen = false) {
  const [isModalOpen, setModalIsOpen] = useState(defaultIsOpen);

  const openModal = useCallback(() => {
    setModalIsOpen(true);
  }, [setModalIsOpen]);

  const closeModal = useCallback(() => {
    setModalIsOpen(false);
  }, [setModalIsOpen]);

  return { isModalOpen, openModal, closeModal };
}
