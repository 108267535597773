import { NavigationConstants } from '../constants/actions';

export const replacePage = url => ({
  type: NavigationConstants.REPLACE_PAGE,
  url
});

export const goToPage = (url, { target, params } = {}) => ({
  type: `${NavigationConstants.GO_TO_PAGE}`,
  url,
  target,
  params
});

export const saveLocationHistory = currentLocation => ({
  type: NavigationConstants.SAVE_LOCATION_HISTORY,
  currentLocation
});

export const goToReturnPage = () => ({
  type: NavigationConstants.GO_RETURN_URL
});

export const openPageEventStart = path => ({
  type: NavigationConstants.OPEN_PAGE_EVENT_START,
  path
});

export const openPageEventEnd = path => ({
  type: NavigationConstants.OPEN_PAGE_EVENT_END,
  path
});
