import config from '@epi-config';
import { get } from '@epi-helpers/FetchHelpers';

export const USER_API_URL = `${config.apiHost}/api/user`;
export const IMPERSONATION_API_URL = `${config.apiHost}/api/admin-tool/impersonation/token`;

export const getDetails = () => {
  return get(`${USER_API_URL}/details`);
};

export const getImpersonationToken = guid => {
  return get(`${IMPERSONATION_API_URL}/${guid}`);
};
