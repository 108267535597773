import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useController, useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { TextField } from 'edenred-ui';

import { createRules, FormFields } from '@epi-forms/helpers';
import { required } from '@epi-helpers/formHelpers/validators';
import { getFinnishAddresses } from '@epi-selectors/api';
import { AddressesSelectProps } from '@epi-models/forms/Controls';

export function AddressesSelect({ disabled, readOnly }: AddressesSelectProps) {
  const name = FormFields.AddressId;
  const { control } = useFormContext();
  const validators = [required];
  const rules = createRules(validators);
  const addresses = useSelector(getFinnishAddresses);

  const {
    field: { ref, value, onChange: setValue },
    fieldState: { error }
  } = useController({
    name,
    control,
    rules
  });

  useEffect(() => {
    if (addresses?.length && !value) {
      setValue(addresses[0]?.value);
    }
  }, [addresses]);

  return (
    <TextField
      select
      required
      id={name}
      value={value}
      disabled={disabled}
      readOnly={readOnly}
      error={!!error}
      helperText={error?.message}
      inputRef={ref}
      onChange={setValue}
      sx={{ '& .MuiSelect-select:focus': { backgroundColor: 'transparent' } }}
    >
      {(addresses?.length > 0 ? addresses : []).map(address => (
        <TextField.Item key={address.value} value={address.value}>
          {address.label}
        </TextField.Item>
      ))}
    </TextField>
  );
}

AddressesSelect.propTypes = {
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool
};

AddressesSelect.defaultProps = {
  disabled: false,
  readOnly: false
};
