import { isEqual } from 'lodash';

const cachedResults = {};

export function getAsyncValidatorCache(validatorName: string) {
  return {
    add(value) {
      if (!cachedResults[validatorName]) {
        cachedResults[validatorName] = [];
      }

      cachedResults[validatorName].push(value);
    },
    has(value) {
      return !!cachedResults[validatorName]?.some(cachedValue =>
        isEqual(cachedValue, value)
      );
    }
  };
}
