import styled from 'styled-components';

export const DateRangePickerStyles = styled.div`
  .InputFromTo
    .DayPicker-Day--selected:not(.DayPicker-Day--start):not(
      .DayPicker-Day--end
    ):not(.DayPicker-Day--outside) {
    background-color: #e7e8ee;
    color: #252525;
  }

  .InputFromTo .DayPicker-Day {
    font-weight: 400;
    border-radius: 0;
  }
  .InputFromTo .DayPicker-Day--start {
    border-top-left-radius: 50%;
    border-bottom-left-radius: 50%;
    background-color: #162056;
    color: #fff;
    &:hover {
      background-color: hsl(231, 59%, 30%);
    }
  }
  .InputFromTo .DayPicker-Day--end {
    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%;
    background-color: #162056;
    color: #fff;
    &:hover {
      background-color: hsl(231, 59%, 30%);
    }
  }
  .InputFromTo .DayPickerInput-Overlay {
    display: block;
    margin-top: 24px;
    width: 296px;
    height: 329px;
    box-shadow: none;
    border: 1px solid #bfbfbf;

    @media only screen and (max-width: 600px) {
      height: 300px;
    }
  }
  input {
    width: 296px;
    padding: 1rem 2rem 1rem 0;
    border: none;
    border-bottom: 1px solid #bfbfbf;
    &:first-of-type {
      margin-right: 2rem;
    }

    ::placeholder {
      color: #bfbfbf;
      opacity: 1;
    }

    :-ms-input-placeholder {
      color: #bfbfbf;
    }

    ::-ms-input-placeholder {
      color: #bfbfbf;
    }

    &:focus {
      outline: none;
      border-bottom: 2px solid #162056;
    }
  }

  .input-label-group {
    display: flex;
    flex-flow: column;
    @media only screen and (max-width: 600px) {
      margin-bottom: 50px;
    }
  }

  .InputFromTo {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;

    @media only screen and (max-width: 600px) {
      flex-direction: column;
    }
  }

  .DayPicker {
    font-size: 16px;
    width: 296px;
    &-Caption {
      width: 270px;
      padding-bottom: 12px;
      > div {
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        font-size: 16px;
        font-weight: 600;
      }
    }
    .DayPicker-NavButton--prev {
      position: absolute;
      left: 16px;
      top: 18px;
      background-image: none;
      color: #162056;
      &::before {
        border-style: solid;
        border-width: 2px 2px 0 0;
        content: '';
        display: inline-block;
        height: 10px;
        position: relative;
        transform: rotate(-45deg);
        vertical-align: top;
        width: 10px;
        transform: rotate(-135deg);
      }
    }
    .DayPicker-NavButton--next {
      position: absolute;
      right: 10px;
      top: 18px;
      background-image: none;
      color: #162056;
      &::before {
        border-style: solid;
        border-width: 2px 2px 0 0;
        content: '';
        display: inline-block;
        height: 10px;
        left: 0;
        position: relative;
        top: 0;
        transform: rotate(-45deg);
        vertical-align: top;
        width: 10px;
        transform: rotate(45deg);
      }
    }
  }
  .DayPicker-WeekdaysRow {
    position: relative;
    top: 12px;
    border-top: 1px solid grey;
    color: #7f7f7f;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
  }
  .DayPicker-Body {
    position: relative;
    top: 8px;
  }
`;
