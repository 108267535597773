import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box } from 'edenred-ui';
import { useFormContext } from 'react-hook-form';

import { FormFields } from '@epi-forms/helpers';

import { Header, Sum, Remainder } from './TopupSummary.styles';
import { useTopupSummary } from './useTopupSummary';

export const TopupSummary = ({
  employeesCount,
  employeesToTopup,
  showTable
}) => {
  const { t } = useTranslation();
  const { watch } = useFormContext();
  const topupPerPerson = watch(FormFields.MoneyAmount);

  const {
    formattedTopup,
    formattedFee,
    formattedVat,
    formattedTopupWithFees,
    formattedRemainingBalance,
    fee,
    vat,
    remainingBalance
  } = useTopupSummary(
    employeesCount,
    topupPerPerson,
    employeesToTopup,
    showTable
  );

  return (
    <Box minWidth="260px" id="deposit_money_topup_step_three_summary">
      <Box display="flex" justifyContent="space-between" marginBottom="10px">
        <Header id="deposit_money_topup_step_three_summary_load_amount_title">
          {t('containers.deposit_money.topup_tab.form.employees.total_amount')}
        </Header>
        <Sum id="deposit_money_topup_step_three_summary_load_amount_value">
          {formattedTopup}
        </Sum>
      </Box>
      <Box display="flex" justifyContent="space-between" margin="0 0 10px 10px">
        <Box id="deposit_money_topup_step_three_summary_fee_title">
          {t('containers.deposit_money.topup_tab.form.employees.fee', {
            fee
          })}
        </Box>
        <Box id="deposit_money_topup_step_three_summary_fee_value">
          {formattedFee}
        </Box>
      </Box>
      <Box display="flex" justifyContent="space-between" margin="0 0 10px 10px">
        <Box id="deposit_money_topup_step_three_summary_vat_title">
          {t('containers.deposit_money.topup_tab.form.employees.vat', {
            vat
          })}
        </Box>
        <Box id="deposit_money_topup_step_three_summary_vat_value">
          {formattedVat}
        </Box>
      </Box>
      <Box display="flex" justifyContent="space-between" marginLeft="10px">
        <Sum id="deposit_money_topup_step_three_summary_total_title">
          {t('containers.deposit_money.topup_tab.form.employees.total')}
        </Sum>
        <Sum id="deposit_money_topup_step_three_summary_total_value">
          {formattedTopupWithFees}
        </Sum>
      </Box>
      <Remainder
        hasError={remainingBalance < 0}
        id="deposit_money_topup_step_three_summary_remaining"
      >
        {t('containers.deposit_money.topup_tab.form.employees.remaining', {
          remaining: formattedRemainingBalance
        })}
      </Remainder>
    </Box>
  );
};

TopupSummary.propTypes = {
  employeesCount: PropTypes.number,
  employeesToTopup: PropTypes.array,
  showTable: PropTypes.bool
};
