import { put, call, select } from 'redux-saga/effects';
import {
  getApplicationLocale,
  getApplicationLanguageId
} from '@epi-store/application/application.selectors';

import { addNewPaymentToDepositOrder } from '../../repositories/DepositOrderPaymentsRepository';
import { PaymentMethods } from '../../constants/paymentMethods';
import { OrderType } from '../../constants/orderType';
import { routerPaths } from '../../constants/routerPaths';
import { UnauthorizedErrorName } from '../../constants/errorName';
import { HandleUnauthorizedErrorAndLogout } from '../../helpers/AuthorizeHelper';
import { ApiConstants, ApplicationConstants } from '../../constants/actions';
import { goToPage } from '../../actions/navigation';
import { getPaymentData } from './getPaymentData';

const isOnlineOrCreditCardPayment = paymentMethod =>
  paymentMethod === PaymentMethods.Online ||
  paymentMethod === PaymentMethods.CreditCard;

export default function* addNewPaymentToDeposit({ values }) {
  try {
    const data = getPaymentData({ payment: values });
    const newPaymentId = yield call(addNewPaymentToDepositOrder, data);

    if (isOnlineOrCreditCardPayment(values.method)) {
      const locale = yield select(getApplicationLocale);
      const languageId = yield select(getApplicationLanguageId);
      yield put({
        type: `${ApiConstants.CREATE_NEW_PAYMENT}_REQUEST`,
        payload: {
          paymentPublicId: newPaymentId,
          orderType: OrderType.digital,
          locale,
          languageId,
          isPaymentOnlyFlow: false,
          statusRoute: routerPaths.loadCompanyAccountStatus
        }
      });
    } else {
      yield put(
        goToPage(routerPaths.orderPaymentStatus, {
          params: { paymentId: newPaymentId }
        })
      );
    }
    yield put({
      type: `${ApiConstants.ADD_NEW_PAYMENT_TO_DEPOSIT}_SUCCESS`
    });
    yield put({
      type: `${ApplicationConstants.CLEAR_FORMS}`
    });
  } catch (ex) {
    if (ex.name === UnauthorizedErrorName) {
      yield call(HandleUnauthorizedErrorAndLogout);
    }
    yield put({
      type: `${ApiConstants.ADD_NEW_PAYMENT_TO_DEPOSIT}_FAILURE`,
      payload: ex
    });
  }
}
