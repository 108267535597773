import React, { useEffect, useState } from 'react';
import { Box, PagePanel, LoadingSpinner } from 'edenred-ui';

import { getTopupReportDetailsRequest } from '@epi-api/topupLoad';
import { Pagination } from '@epi-shared/components';
import { routerPaths } from '@epi-constants/routerPaths';
import {
  ReportTypeVirike,
  REPORTS_QUERY_NAME
} from '@epi-constants/externalReport';
import {
  ReportDetails,
  TopupLoadItem,
  TopupReportsDetailsProps
} from '@epi-models/pages/Reports';

import { TopupReportsItemsTable } from './TopupReportsItemsTable';
import { TopupReportsDetailsBox } from './TopupReportsDetailsBox';

const itemsPerPage = 7;

export const TopupReportsDetails = ({
  match: {
    params: { reportId }
  }
}: TopupReportsDetailsProps) => {
  const [reportDetails, setReportDetails] = useState<ReportDetails>();
  const [visibleReportItems, setVisibleReportItems] = useState<TopupLoadItem[]>(
    []
  );
  const [page, setPage] = useState(1);

  const paginationCount = reportDetails?.topUpLoadItems?.length
    ? Math.ceil(reportDetails.topUpLoadItems.length / itemsPerPage)
    : 0;

  const handleChangePage = (newPage: number) => {
    setPage(newPage);
  };

  const getReports = async () => {
    const reportDetails = await getTopupReportDetailsRequest(reportId);
    setReportDetails(reportDetails);
  };

  useEffect(() => {
    getReports();
  }, []);

  useEffect(() => {
    if (reportDetails?.topUpLoadItems.length) {
      setVisibleReportItems(
        reportDetails?.topUpLoadItems.slice(
          page * itemsPerPage - itemsPerPage,
          page * itemsPerPage
        )
      );
    }
  }, [reportDetails?.topUpLoadItems, page]);

  return (
    <PagePanel
      title="Topup load"
      backIcon
      backIconLink={
        routerPaths.reports + REPORTS_QUERY_NAME + ReportTypeVirike.topups
      }
    >
      {reportDetails ? (
        <>
          <TopupReportsDetailsBox reportDetails={reportDetails} />
          <Box marginTop="40px">
            <TopupReportsItemsTable visibleReportItems={visibleReportItems} />
            <Box mt={3}>
              <Pagination
                page={page}
                count={paginationCount}
                defaultRowsPerPage={itemsPerPage}
                onChangePage={handleChangePage}
              />
            </Box>
          </Box>
        </>
      ) : (
        <LoadingSpinner />
      )}
    </PagePanel>
  );
};
