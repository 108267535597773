import React from 'react';
import { Box, Tooltip, Text, InfoIcon } from 'edenred-ui';
import { useTheme } from 'styled-components';

import { TableHeaderTooltipProps } from '@epi-models/pages/Reports';

export const TableHeaderTooltip = ({
  tooltipText
}: TableHeaderTooltipProps) => {
  const theme = useTheme();

  return (
    <Tooltip
      arrow
      placement="top"
      title={
        <Box maxWidth={274} p={2}>
          <Text small>{tooltipText}</Text>
        </Box>
      }
    >
      <Box paddingLeft="4px">
        <InfoIcon color={theme.primaryFrontColor} />
      </Box>
    </Tooltip>
  );
};
