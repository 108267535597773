import config from '@epi-config';
import { get } from '@epi-helpers/FetchHelpers';
import { BalanceCorrectionsResponse } from '@epi-models/pages/Reports';

export const getBalanceCorrectionsCheckRequest = (
  abortController: AbortController | null = null
) => {
  return get(
    `${config.apiHost}/api/company-accounts/balance-corrections/check`,
    null,
    null,
    abortController?.signal
  );
};

export const getBalanceCorrectionsRequest = (
  page: number,
  from: string,
  to: string,
  abortController: AbortController | null = null
): Promise<BalanceCorrectionsResponse> => {
  return get(
    `${config.apiHost}/api/company-accounts/balance-corrections?Page=${page}&RowsPerPage=10&From=${from}&To=${to}`,
    null,
    null,
    abortController?.signal
  );
};
