import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Row, Col } from 'react-bootstrap';
import { PagePanel, Button, ButtonSize, EdenredIcon } from 'edenred-ui';
import { useTranslation } from 'react-i18next';

import { BenefitSummaryProps } from '@epi-models/components/BenefitSummary';

import { BenefitSummaryContent } from '../BenefitSummaryContent/BenefitSummaryContent';
import { RoundButton } from '../RoundButton/RoundButton';
import { LoadingContainer } from '../LoadingContainer/LoadingContainer';
import * as navigationActions from '../../actions/navigation';

import './BenefitSummary.scss';

const LOCALE_PREFIX = 'components.benefit_summary';

const BenefitSummary = ({
  goToEditPage,
  showTitle = false,
  showCreditCardFee = false,
  voucherTypeKey,
  isLoading = false,
  orderSummary,
  voucherOrderPayment
}: BenefitSummaryProps) => {
  const { t } = useTranslation();

  return (
    <PagePanel
      title={
        showTitle
          ? t('containers.voucher_order.benefit_summary_header')
          : undefined
      }
      className="BenefitSummary px-10"
    >
      {showTitle && goToEditPage && (
        <span className="visible-xs-inline">
          <RoundButton
            id="benefit-summary-edit-icon-btn"
            icon={<EdenredIcon />}
            className="pull-right"
            onClick={goToEditPage}
          />
        </span>
      )}
      <LoadingContainer isLoading={isLoading}>
        <Row>
          <Col className="px-45" lg={11}>
            <BenefitSummaryContent
              showCreditCardFee={showCreditCardFee}
              voucherTypeKey={voucherTypeKey}
              orderSummary={orderSummary}
              voucherOrderPayment={voucherOrderPayment}
            />
          </Col>
        </Row>
        {goToEditPage && (
          <Row className="hidden-xs">
            <Col>
              <Button
                id="benefit-summary-edit-btn"
                className="btn-edit pull-right"
                onClick={goToEditPage}
                size={ButtonSize.Medium}
              >
                {t(`${LOCALE_PREFIX}.edit`)}
              </Button>
            </Col>
          </Row>
        )}
      </LoadingContainer>
    </PagePanel>
  );
};

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...navigationActions }, dispatch);

const ConnectedBenefitSummary = connect(
  null,
  mapDispatchToProps
)(BenefitSummary);

export {
  ConnectedBenefitSummary as BenefitSummary,
  BenefitSummary as BenefitSummaryTest
};
