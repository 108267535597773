import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { READY_TO_ORDER, RECREATIONAL } from '@epi-constants/benefitTypes';
import { fetchGet } from '@epi-helpers/FetchHelpers';
import { showNotification } from '@epi-actions/overlays';
import { genericErrorMessage } from '@epi-constants/downloadReports';
import { companyBenefitsGroupSetting } from '@epi-repositories/BenefitGroupSettingsRepository';
import {
  IActive,
  IReadyToActive,
  IResponseBenefits
} from '@epi-models/pages/Home';
import { startOrderProcess, stopOrderProcess } from '@epi-actions/beneficiary';

import { useMapBenefits } from './useMapBenefits';

export const useBenefits = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { extendBenefitObject } = useMapBenefits();

  const [activeBenefits, setActiveBenefits] = useState<IActive[]>([]);
  const [readyToActiveBenefits, setReadyToActiveBenefits] = useState<
    IReadyToActive[]
  >([]);

  const onSuccess = (data: IResponseBenefits[]) => {
    setActiveBenefits(
      data
        .filter(elem => elem.state !== READY_TO_ORDER)
        .map(elem => extendBenefitObject(elem))
    );
    setReadyToActiveBenefits(
      data
        .filter(elem => elem.state === READY_TO_ORDER)
        .map(elem => ({ ...elem, isOrdered: false }))
        .map(elem => extendBenefitObject(elem))
        .filter(
          elem => !(elem.type === RECREATIONAL && elem.state === READY_TO_ORDER)
        )
    );
  };

  const onFailure = err => {
    dispatch(showNotification({ message: genericErrorMessage }, 'error'));
  };

  const handleOrderBenefit = id => {
    setReadyToActiveBenefits(prevState =>
      prevState
        .map(elem =>
          elem.id === id ? { ...elem, isOrdered: !elem.isOrdered } : elem
        )
        .map(elem => extendBenefitObject(elem))
    );
  };

  const showOrderSummary = readyToActiveBenefits.some(
    elem => elem.isOrdered === true
  );

  const benefitsForOrder = readyToActiveBenefits.filter(
    elem => elem.isOrdered === true
  );

  const handleOrderAllBenefits = () =>
    setReadyToActiveBenefits(prevState =>
      prevState.map(elem =>
        elem.state === READY_TO_ORDER
          ? extendBenefitObject({ ...elem, isOrdered: true })
          : elem
      )
    );

  const getBenefits = () =>
    fetchGet(companyBenefitsGroupSetting(), {}, onSuccess, onFailure);

  useEffect(() => {
    getBenefits();
  }, []);

  useEffect(() => {
    if (showOrderSummary) {
      dispatch(startOrderProcess());
    } else {
      dispatch(stopOrderProcess());
    }
  }, [showOrderSummary]);

  useEffect(() => {
    setActiveBenefits(prevState =>
      prevState.map(elem => extendBenefitObject(elem))
    );
    setReadyToActiveBenefits(prevState =>
      prevState.map(elem => extendBenefitObject(elem))
    );
  }, [t]);

  return {
    activeBenefits,
    readyToActiveBenefits,
    showOrderSummary,
    benefitsForOrder,
    handleOrderAllBenefits,
    handleOrderBenefit,
    getBenefits
  };
};
