import { put, select, call, take } from 'redux-saga/effects';
import { createUser } from '../../repositories/SignUpRepository';
import { getSignUpActiveState } from '../../selectors/signUp';
import { routerPaths } from '../../constants/routerPaths';
import {
  NavigationConstants as navConstants,
  ApiConstants
} from '../../constants/actions';
import * as loginActions from '../../actions/login';
import * as companyActions from '../../actions/company';
import * as overlaysActions from '../../actions/overlays';

export function* createNewUser({ values }) {
  try {
    const checkCompanyPayload = {
      name: values.company.name,
      businessIdentityCode: values.company.businessIdentityCode,
      address: values.company.addresses[0]
    };
    if (!values.company.customerNumber) {
      yield put(companyActions.checkIfCompanyExists(checkCompanyPayload));

      const companyExistsAction = yield take(
        `${ApiConstants.CHECK_IF_COMPANY_EXISTS}_FINISHED`
      );

      if (companyExistsAction.cancel) {
        yield put({
          type: `${ApiConstants.SIGN_UP}_ABORT`
        });
        return;
      }
    }
    const signUpIsActive = yield select(getSignUpActiveState);
    if (signUpIsActive) {
      yield call(createUser, values);
      yield put(
        overlaysActions.showNotification(
          { message: 'messages.successful_sign_up' },
          'success'
        )
      );
      yield put(
        loginActions.getLoginAction(
          values.user.username,
          values.user.password,
          {
            isAutoLogin: true,
            autoRedirect: false
          }
        )
      );
    }
    yield put({
      type: `${ApiConstants.SIGN_UP}_SUCCESS`
    });
    if (values.returnUrl) {
      yield take(`${ApiConstants.LOGIN_ACTION}_SUCCESS`);
    }
    yield put({
      type: `${navConstants.GO_TO_PAGE}`,
      url: values.returnUrl || routerPaths.home
    });
  } catch (ex) {
    yield put({
      type: `${ApiConstants.SIGN_UP}_FAILURE`,
      payload: ex
    });
  }
}

export function* goToSignUpPage() {
  yield put({
    type: `${navConstants.GO_TO_PAGE}`,
    url: routerPaths.signUp
  });
}
