import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { fetchGet } from '@epi-helpers/FetchHelpers';
import { showNotification } from '@epi-actions/overlays';
import { genericErrorMessage } from '@epi-constants/downloadReports';
import { companyBenefitsGroupSetting } from '@epi-repositories/BenefitGroupSettingsRepository';
import { IAvailableBenefits } from '@epi-models/containers/ManageBenefits';
import { ACTIVE, INACTIVE } from '@epi-constants/benefitTypes';

export const useManageBenefits = () => {
  const dispatch = useDispatch();
  const [availableBenefits, setAvailableBenefits] = useState<
    IAvailableBenefits[] | []
  >([]);
  const [allBenefits, setAllBenefits] = useState<IAvailableBenefits[] | []>([]);

  const onSuccess = (data: IAvailableBenefits[]) => {
    const active = data.filter(elem => elem.state === ACTIVE);
    const inActive = data.filter(elem => elem.state === INACTIVE);
    setAvailableBenefits([...inActive, ...active]);
    setAllBenefits([...data]);
  };
  const onFailure = err => {
    dispatch(showNotification({ message: genericErrorMessage }, 'error'));
  };

  useEffect(() => {
    fetchGet(companyBenefitsGroupSetting(), {}, onSuccess, onFailure);
  }, []);

  return { availableBenefits, allBenefits };
};
