import { get } from 'lodash';
import createReducer from '../../helpers/CreateReducer';
import { ApiConstants } from '../../constants/actions';

export const getInitialState = () => ({
  creditCardFee: 0,
  voucherMinimumQuantity: 0,
  voucherOrderMaxAmount: 0,
  lunchMinAmount: 0,
  lunchMaxAmount: 0,
  lunchMidAmounts: [],
  recreationalMinAmount: 0,
  recreationalMaxAmount: 0,
  massageMinAmount: 0,
  massageMaxAmount: 0,
  wellbeingMinAmount: 1,
  wellbeingMaxAmount: 0,
  transportMinAmount: 0,
  transportMaxAmount: 0,
  isLoading: false,
  loaded: false,
  noPersistance: true,
  feesVatPercentage: 0,
  feePercentage: 0
});

export default createReducer(getInitialState, {
  [`${ApiConstants.LOAD_SETTINGS}_REQUEST`]: () => ({ isLoading: true }),

  [`${ApiConstants.LOAD_SETTINGS}_SUCCESS`]: (state, { payload }) => ({
    creditCardFee: get(payload, 'creditCardFee') || 0,
    voucherMinimumQuantity: get(payload, 'voucherMinimumQuantity') || 0,
    voucherOrderMaxAmount: get(payload, 'voucherOrderMaxAmount') || 0,
    lunchMinAmount: get(payload, 'lunchMinAmount') || 0,
    lunchMaxAmount: get(payload, 'lunchMaxAmount') || 0,
    lunchMidAmounts: get(payload, 'lunchMidAmounts') || [],
    lunchAmountApplicableYear:
      get(payload, 'lunchAmountApplicableYear') || new Date().getFullYear(),
    recreationalMinAmount: get(payload, 'recreationalMinAmount') || 0,
    recreationalMaxAmount: get(payload, 'recreationalMaxAmount') || 0,
    recreationalMaxAmountForTopUpRequest:
      get(payload, 'recreationalMaxAmountForTopUpRequest') || 0,
    transportMinAmount: get(payload, 'transportMinAmount') || 0,
    transportMaxAmount: get(payload, 'transportMaxAmount') || 0,
    massageMinAmount: get(payload, 'massageMinAmount') || 0,
    massageMaxAmount: get(payload, 'massageMaxAmount') || 0,
    wellbeingMinAmount: get(payload, 'wellbeingMinAmount') || 0,
    wellbeingMaxAmount: get(payload, 'wellbeingMaxAmount') || 0,
    reCaptchaSiteKey: get(payload, 'reCaptchaSiteKey') || '',
    onlinePaymentMinAmount: get(payload, 'onlinePaymentMinAmount') || 0,
    offlinePaymentMinAmount: get(payload, 'offlinePaymentMinAmount') || 0,
    companyBeneficiariesTotalAllowedForCompany:
      get(payload, 'companyBeneficiariesTotalAllowedForCompany') || 100,
    loaded: true,
    isLoading: false,
    feesVatPercentage: get(payload, 'feesVatPercentage') || 0
  }),

  [`${ApiConstants.LOAD_SETTINGS}_FAILURE`]: state =>
    !state.isError
      ? {
          isLoading: false,
          loaded: true,
          isError: true
        }
      : state,

  [`${ApiConstants.GET_FEE}`]: (state, { feePercentage }) => ({
    feePercentage
  }),

  [`${ApiConstants.CLEAR_FEE}`]: state => {
    state.feePercentage = 0;
  }
});
