import { select, call } from 'redux-saga/effects';

import { getLoginState } from '../selectors/login';
import { UnauthorizedError } from '../helpers/UnauthorizedError';
import { get, post, put, del, patch } from '../helpers/FetchHelpers';
import { Authorize } from '../constants/authorize';

export function* getAuthorizationHeader(authorize) {
  const login = yield select(getLoginState);
  if (!login.isAuthenticated) {
    if (authorize === Authorize.IfLogged) {
      return undefined;
    }
    throw new UnauthorizedError(`messages.unauthorized_user`);
  }
  const header = {
    Authorization: `${login.tokenType || ''} ${login.accessToken || ''}`
  };

  return header;
}

export function shouldGetAuthorizationHeader(authorize) {
  return authorize === Authorize.Always || authorize === Authorize.IfLogged;
}

export function* getHeader(authorize, refreshToken = true) {
  if (shouldGetAuthorizationHeader(authorize)) {
    return yield call(getAuthorizationHeader, authorize, refreshToken);
  }

  return undefined;
}

export function* getRequest(
  path,
  authorize,
  { data, refreshToken = true } = {}
) {
  const header = yield call(getHeader, authorize, refreshToken);
  return yield call(get, path, data, header);
}

export function* putRequest(path, authorize, data) {
  const header = yield call(getHeader, authorize);
  return yield call(put, path, data, header);
}

export function* postRequest(path, authorize, data) {
  const header = yield call(getHeader, authorize);
  return yield call(post, path, data, header);
}
