import React from 'react';
import { bindActionCreators } from 'redux';
import { Card } from 'react-bootstrap';
import { connect } from 'react-redux';

import { FixedPanelProps } from '@epi-models/components/FixedPanel';

import * as navigationActions from '../../actions/navigation';

import './FixedPanel.scss';

const FixedPanel = ({ children, className = '' }: FixedPanelProps) => (
  <Card className={`FixedPanel ${className}`}>
    <Card.Body>
      <>{children}</>
    </Card.Body>
  </Card>
);

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...navigationActions }, dispatch);

const ConnectedFixedPanel = connect(null, mapDispatchToProps)(FixedPanel);

export { ConnectedFixedPanel as FixedPanel };
