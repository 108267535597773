import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Container, Row, Col, Modal } from 'react-bootstrap';
import {
  Button,
  ButtonSize,
  ButtonMode,
  Notification,
  NotificationType
} from 'edenred-ui';
import { useTranslation } from 'react-i18next';
import { snakeCase } from 'lodash';
import { FormProvider, useForm } from 'react-hook-form';

import { PasswordField } from '@epi-forms/controls';
import { FormFields } from '@epi-forms/helpers';
import { ENTER_KEYCODE } from '@epi-constants/popup';
import { ConfirmPasswordPopupProps } from '@epi-models/components/ConfirmPasswordPopup';

import {
  getPasswordPopup,
  getPasswordPopupErrorMessage
} from '../../selectors/overlays';
import { required } from '../../helpers/formHelpers/validators';
import * as loginActions from '../../actions/login';
import * as navigationActions from '../../actions/navigation';
import * as overlaysActions from '../../actions/overlays';
import * as profileActions from '../../actions/profile';

import './ConfirmPasswordPopup.scss';

function ConfirmPasswordPopup({
  show,
  message = '',
  passwordPopupAction,
  errorMessage = '',
  isLoading
}: ConfirmPasswordPopupProps) {
  const { t } = useTranslation();

  const translate = value => {
    return t(value) || t(`api_errors.${snakeCase(value)}`) || value;
  };

  const form = useForm({
    mode: 'onChange',
    defaultValues: {
      [FormFields.Password]: ''
    }
  });
  const { watch, reset, handleSubmit } = form;
  const passwordValue = watch(FormFields.Password);

  const onSubmit = () => {
    passwordPopupAction('submit', passwordValue);
  };

  const onChange = e => {
    if (e.keyCode === ENTER_KEYCODE) {
      passwordPopupAction('submit', passwordValue);
    }
  };

  useEffect(() => {
    reset();
  }, [show]);

  return (
    <Modal className="ConfirmPasswordPopup" show={show} animation={false}>
      <FormProvider {...form}>
        <form onSubmit={handleSubmit(onSubmit)} onKeyDown={onChange}>
          <fieldset disabled={isLoading}>
            <Modal.Body>
              <Container fluid>
                {message && (
                  <Row>
                    <Col xs={12}>
                      <h2 className="mt-10">{t(message)}</h2>
                    </Col>
                  </Row>
                )}
                {errorMessage && (
                  <Row>
                    <Col xs={12}>
                      <Notification
                        type={NotificationType.Error}
                        text={translate(errorMessage)}
                      />
                    </Col>
                  </Row>
                )}
                <Row className="password-row">
                  <Col xs={12}>
                    <PasswordField
                      name={FormFields.Password}
                      label={t('forms.confirm_password_popup.password')}
                      validators={[required]}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={12}>
                    <Button
                      id="confirm-password-modal-forgot-credentials-btn"
                      mode={ButtonMode.Link}
                      onClick={() => passwordPopupAction('forgot_password')}
                    >
                      {t('forms.confirm_password_popup.forgot_credentials')}
                    </Button>
                  </Col>
                </Row>
              </Container>
            </Modal.Body>
            <Modal.Footer>
              <Button
                id="confirm-password-modal-cancel-btn"
                onClick={() => passwordPopupAction('cancel')}
                mode={ButtonMode.Secondary}
                size={ButtonSize.Medium}
              >
                {t('forms.confirm_password_popup.cancel_button')}
              </Button>
              <Button
                id="confirm-password-modal-submit-btn"
                size={ButtonSize.Medium}
                type="submit"
                aria-hidden
                disabled={isLoading}
              >
                {t('forms.confirm_password_popup.ok_button')}
              </Button>
            </Modal.Footer>
          </fieldset>
        </form>
      </FormProvider>
    </Modal>
  );
}

const mapStateToProps = state => ({
  ...getPasswordPopup(state),
  errorMessage: getPasswordPopupErrorMessage(state)
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...profileActions,
      ...overlaysActions,
      ...navigationActions,
      ...loginActions
    },
    dispatch
  );

const ConnectedConfirmPasswordPopup = connect(
  mapStateToProps,
  mapDispatchToProps
)(ConfirmPasswordPopup);

export { ConnectedConfirmPasswordPopup as ConfirmPasswordPopup };
