import tokeys from 'tokeys';

export const AzureVariable = tokeys(['string', 'number', 'boolean']);

export const envVariablesSchema = {
  environment: AzureVariable.string,
  apiHost: AzureVariable.string,
  enablePersistanceOnLocalStorage: AzureVariable.boolean,
  disablePersistanceCompression: AzureVariable.boolean,
  disableReCaptcha: AzureVariable.boolean,
  enableVirikeBenefit: AzureVariable.boolean,
  googleAnalyticsKey: AzureVariable.string,
  onetrustKey: AzureVariable.string,
  enableNewContracts: AzureVariable.boolean,
  showNotificationBanner: AzureVariable.boolean,
  notificationBannerStartDate: AzureVariable.string,
  notificationBannerEndDate: AzureVariable.string,
  notificationBannerTextEng: AzureVariable.string,
  notificationBannerTextFin: AzureVariable.string,
  enableLoadingType: AzureVariable.boolean
};
