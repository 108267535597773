import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Box } from 'edenred-ui';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { closeNotification } from '@epi-actions/companyAccounts';
import { ErrorPanel } from '@epi-components/ErrorPanel/ErrorPanel';
import { ApiConstants } from '@epi-constants/actions';
import {
  ILoadCompanyAccount,
  IEstimate
} from '@epi-models/containers/LoadCompanyAccount';
import { getCompanyNotification } from '@epi-selectors/companyAccounts';
import { estimationSelector } from '@epi-selectors/transactionEstimationSelectors';
import { NotificationInfo } from '@epi-shared/components';

import { useLoadCompanyAccount } from './hooks';
import {
  TransferMoneyEnterAmount,
  ReviewRequestedLoads,
  PaymentMethodOptions
} from './components';

const LoadCompanyAccount = ({
  updateFooter
}: ILoadCompanyAccount & RouteComponentProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const showNotification: boolean = useSelector(getCompanyNotification);
  const estimation: IEstimate | null = useSelector(estimationSelector);

  const {
    form,
    submitCompleteForm,
    disabled,
    isPaymentProcessing,
    handleSubmitForm
  } = useLoadCompanyAccount(updateFooter);

  const showReviewRequestedLoads =
    estimation?.requestedLoads &&
    estimation.requestedLoads.numberOfRequests > 0;

  return (
    <>
      <FormProvider {...form}>
        <form noValidate onSubmit={handleSubmitForm}>
          <Box pt={4} display={showNotification ? 'block' : 'none'}>
            <NotificationInfo
              text={t('containers.deposit_money.page_description')}
              onClose={() => dispatch(closeNotification())}
            />
          </Box>
          {showReviewRequestedLoads && (
            <ReviewRequestedLoads estimation={estimation} />
          )}
          <Box my={2}>
            <TransferMoneyEnterAmount />
          </Box>
          <PaymentMethodOptions
            submitCompleteForm={submitCompleteForm}
            disabled={disabled}
            isPaymentProcessing={isPaymentProcessing}
          />
        </form>
      </FormProvider>
      <ErrorPanel failedActionName={ApiConstants.DEPOSIT_COMPANY_ACCOUNT} />
    </>
  );
};

const ConnectedLoadCompanyAccountWithRouter = withRouter(LoadCompanyAccount);
export { ConnectedLoadCompanyAccountWithRouter as LoadCompanyAccount };
