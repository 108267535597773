import React from 'react';
import { Button, ButtonSize } from 'edenred-ui';

import { RoundButtonProps } from '@epi-models/components/RoundButton';

import './RoundButton.scss';

export const RoundButton = ({
  icon,
  className = '',
  onClick = () => {},
  id = ''
}: RoundButtonProps) => {
  return (
    <Button
      type="button"
      id={id}
      size={ButtonSize.Medium}
      className={`RoundButton ${className}`}
      onClick={onClick}
    >
      {icon}
    </Button>
  );
};
