import { put, call, select } from 'redux-saga/effects';
import { getIsAuthenticated } from '../selectors/login';
import { ApiConstants } from '../constants/actions';
import Moment from './MomentExtensions';
import { Notify } from './NotificationHelper';

export function* SetAuthorizationData(loginResponse) {
  const validTo = Moment().unix() + loginResponse.expiresIn;

  yield put({
    type: `${ApiConstants.LOGIN_ACTION}_SUCCESS`,
    payload: {
      ...loginResponse,
      validTo
    }
  });
}

export const checkIfTokenExpires = validTo =>
  validTo && Moment().isAfter(Moment.unix(validTo));

export const shouldRefreshToken = (validTo, expiresIn) => {
  const refreshTokenDate = Moment.unix(validTo - expiresIn / 2);
  return validTo && Moment().isAfter(refreshTokenDate);
};

export const userIsAuthorizedAndTokenIsValid = loginState =>
  loginState.validTo &&
  loginState.isAuthenticated &&
  Moment().isBefore(Moment.unix(loginState.validTo));

export default {
  checkIfTokenExpires,
  shouldRefreshToken,
  userIsAuthorizedAndTokenIsValid
};

export function* HandleUnauthorizedError(login) {
  if (login.isAuthenticated && checkIfTokenExpires(login.validTo)) {
    yield put({
      type: `${ApiConstants.LOGOUT_ACTION}_REQUEST`,
      sessionExpired: true
    });
  } else {
    yield call(Notify, 'forms.user_information.incorrect_password', 'error');
  }
}

export function* HandleUnauthorizedErrorAndLogout() {
  const isAuthenticated = yield select(getIsAuthenticated);
  if (isAuthenticated) {
    yield put({
      type: `${ApiConstants.LOGOUT_ACTION}_REQUEST`,
      sessionExpired: true
    });
  }
}
