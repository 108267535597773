import { createSelector } from 'reselect';
import { get, sortBy, deburr } from 'lodash';
import { getApplicationLanguageId } from '@epi-store/application/application.selectors';

import { addressLine } from '../helpers/Formatters';

export const getVouchers = state => state.apiVouchers.vouchers;

export const getContacts = state => state.apiContacts.contacts;

export const getOrderFees = state => state.apiOrderFees;

export const getAddresses = state => state.apiAddresses.addresses || [];

export const getApiPayments = state => state.apiPayments;

export const getApiPaymentStatus = state => state.apiPaymentStatus;

export const isNewCustomer = state => state.apiVoucherOrder.isNewCustomer;

export const getApiErrors = state => state.apiErrors;

export const getApiPaymentsMethods = state => state.apiPaymentMethods;

export const getPaymentMethods = state =>
  state.apiPaymentMethods.paymentMethods;

export const getAvailableVouchersByType = type =>
  createSelector(getVouchers, vouchers =>
    (vouchers || []).filter(
      voucher =>
        voucher.type.toLowerCase() === type.toLowerCase() && voucher.available
    )
  );

export const isCompanyLoaded = state =>
  !!(
    (state.apiCompanies.userCompany &&
      state.apiCompanies.userCompany.address &&
      state.apiCompanies.userCompany.address.addressLine) ||
    state.apiCompanies.isLoaded
  );

export const isProcessingCompanyUpdateSelector = state =>
  state.apiCompanies.isProcessingUpdate;

export const getUserCompany = createSelector(
  state => get(state, 'apiCompanies.userCompany'),
  userCompany => ({
    address: { country: 'FI' },
    ...userCompany
  })
);

export const getCustomerNumber = state =>
  state.apiCompanies.userCompany.customerNumber;

const formatAddress = adr => ({
  id: adr.id,
  value: adr.id,
  label: addressLine(adr)
});

export const getFormattedAddresses = createSelector(getAddresses, addresses =>
  addresses.map(formatAddress)
);

export const getFinnishAddresses = createSelector(getAddresses, addresses =>
  addresses?.length
    ? addresses.filter(adr => adr.country === 'FI').map(formatAddress)
    : null
);

export const isLoadingInvoice = (idType, id) => state =>
  (state.apiDownloadInvoice.isLoading || []).includes(`${idType}-${id}`);

export const isLoadingReceiptForDeposit = id => state => {
  const array = state.apiDownloadReceiptForDepositOrder.isLoading || [];
  return array.includes(`depositOrder-${id}`);
};

export const isLoadingReceiptForTransaction = id => state => {
  const array = state.apiDownloadReceiptForTransaction.isLoading || [];
  return array.includes(`transaction-${id}`);
};

export const getTranslatedCountries = state => {
  const finnish =
    (getApplicationLanguageId(state) || '').toLowerCase() === 'fi';

  const countries = (state?.apiCountries?.countries || []).map(item => ({
    ...item,
    Name: finnish ? item.FinnishName : item.EnglishName
  }));
  const sortedCountries = sortBy(countries, item => deburr(item.Name));
  return sortedCountries;
};

export const arePaymentMethodsLoaded = (locale, amount) => state => {
  if (state.apiPaymentMethods.isError) {
    return true;
  }
  const areMethodsLoaded = getPaymentMethods(state).length !== 0;
  const areMethodsLoading = state.apiPaymentMethods.isLoading;
  const isSameAmount = state.apiPaymentMethods.amount === amount;
  const isSameLocale = state.apiPaymentMethods.locale === locale;
  return (
    (areMethodsLoaded || areMethodsLoading) && isSameAmount && isSameLocale
  );
};

export const getCompanyName = state => state.apiCompanies.userCompany.name;
