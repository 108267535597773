import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { AppSetupProps } from '@epi-models/components/AppSetup';

import { isLoaded as isLoadedSelector } from '../../selectors/saga';
import { ApiConstants } from '../../constants/actions';
import * as apiActions from '../../actions/api';
import { refreshRememberedUser } from '../../helpers/refreshTokenHelper';

const AppSetup = ({
  loadSettings,
  getHasOrders,
  children,
  isLoaded
}: AppSetupProps) => {
  useEffect(() => {
    refreshRememberedUser().then(() => {
      loadSettings();
      getHasOrders();
    });
  }, []);

  if (!isLoaded) {
    return <div>Loading...</div>;
  }

  return children;
};

const isLoaded = isLoadedSelector([
  ApiConstants.LOAD_SETTINGS,
  ApiConstants.GET_HAS_ORDERS
]);
const mapStateToProps = state => ({
  isLoaded: isLoaded(state)
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...apiActions
    },
    dispatch
  );

const ConnectedAppSetup = connect(
  mapStateToProps,
  mapDispatchToProps
)(AppSetup);

export { ConnectedAppSetup as AppSetup };
