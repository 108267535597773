import React, { useState } from 'react';
import { Table, SimpleButton, MoreIcon } from 'edenred-ui';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';

import { fetchPut as Put } from '@epi-helpers/FetchHelpers';
import { useMenuDimensions } from '@epi-shared/hooks';
import {
  openSlideout,
  disableSlideoutEditMode,
  disableEditMode
} from '@epi-actions/company';
import { contactsApiUrl } from '@epi-repositories/ContactsRepository';
import { showNotification } from '@epi-actions/overlays';
import { SignupTokenErrors } from '@epi-constants/signupTokenErrors';
import { loadContacts } from '@epi-actions/api';
import { IContactRow } from '@epi-models/pages/CompanyInfo';

import { ContactActions } from '../ContactActions';
import { formatPhoneNumber } from '../../../../helpers/formHelpers/formatters';

const TableRow = styled(Table.Row)`
  cursor: pointer;
`;

export function ContactRow({ row, handleDeleteContact }: IContactRow) {
  const { t } = useTranslation();
  const { isMobile } = useMenuDimensions();
  const dispatch = useDispatch();
  const { setValue } = useFormContext();
  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLButtonElement | null>(
    null
  );

  const openMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setMenuAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setMenuAnchorEl(null);
  };

  const showError = (errorKey: string) => {
    dispatch(showNotification({ message: errorKey }, 'error'));
  };

  const openModalAndFillForm = () => {
    setValue('firstName', row.firstName);
    setValue('lastName', row.lastName);
    setValue('emailAddress', row.emailAddress);
    setValue('phoneNumber', row.phoneNumber);
    setValue('userName', row.userName);
    if (row.personId) setValue('personId', row.personId);
    if (row.dataContactId) setValue('dataContactId', row.dataContactId);
    dispatch(openSlideout());
  };

  const onResendError = error => {
    const errorMessage = error.content?.errors[0]?.message;

    if (errorMessage === SignupTokenErrors.CreationCompleted) {
      dispatch(loadContacts());
      return showError('messages.account_activated');
    }
    if (errorMessage === SignupTokenErrors.ContactDeleted) {
      dispatch(loadContacts());
      return showError('messages.contact_already_deleted');
    }
    return showError('messages.error');
  };

  const resendActivationEmail = () => {
    closeMenu();
    return Put(
      `${contactsApiUrl}/resend-email`,
      { dataContactId: row.dataContactId },
      dispatch(
        showNotification(
          { message: 'messages.resend_activation_successful' },
          'success'
        )
      )
    ).catch(onResendError);
  };

  const handleRowClick = () => {
    openModalAndFillForm();
    dispatch(disableSlideoutEditMode());
    dispatch(disableEditMode());
  };

  const handleDeleteAction = () => {
    handleDeleteContact(row);
    closeMenu();
  };

  return (
    <>
      {isMobile ? (
        <TableRow onClick={handleRowClick}>
          <Table.Cell>
            {row.firstName} {row.lastName}
          </Table.Cell>
          <Table.Cell align="center">
            <SimpleButton icon={<MoreIcon />} onClick={openMenu} />
          </Table.Cell>
        </TableRow>
      ) : (
        <TableRow onClick={handleRowClick}>
          <Table.Cell>{row.firstName}</Table.Cell>
          <Table.Cell>{row.lastName}</Table.Cell>
          <Table.Cell>{row.emailAddress}</Table.Cell>
          <Table.Cell>{formatPhoneNumber(row.phoneNumber)}</Table.Cell>
          <Table.Cell>
            {row.active
              ? t('company_info.contacts.status.active')
              : t('company_info.contacts.status.inactive')}
          </Table.Cell>
          <Table.Cell align="center">
            <SimpleButton icon={<MoreIcon />} onClick={openMenu} />
          </Table.Cell>
        </TableRow>
      )}
      <ContactActions
        anchorEl={menuAnchorEl}
        closeMenu={closeMenu}
        userActive={!!row.active}
        openModalAndFillForm={openModalAndFillForm}
        resendActivationEmail={resendActivationEmail}
        handleDeleteAction={handleDeleteAction}
      />
    </>
  );
}
