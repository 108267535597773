import { FormFields } from '@epi-forms/helpers';

export const BeneficiaryItemsPerPage = 20;
export const NumberOfBeneficiariesLimitReached =
  'NumberOfBeneficiariesLimitReached';

export const BenefitsTabName = {
  personalData: 'Personal Data',
  benefits: 'Benefits'
};

export const EmployeeTypeModes = {
  FullTime: 'FullTime',
  PartTime: 'PartTime',
  TemporaryEmployed: 'TemporaryEmployed',
  Dropped: 'Dropped',
  Onhold: 'Onhold'
};

export const UNIQUE = 'must_be_unique';

export const FIELDS_TO_WATCH = [
  FormFields.FirstName,
  FormFields.LastName,
  FormFields.PersonalID,
  FormFields.EmailAddress,
  FormFields.Entitled,
  FormFields.EmployeeType,
  FormFields.EmployeePercentage
];

export const desiredOrder = [
  'Lunch',
  'Recreational',
  'Transport',
  'Massage',
  'Wellbeing'
];
