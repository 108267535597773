import React from 'react';
import { useTranslation } from 'react-i18next';

import { DisplayBillingInformationProps } from '@epi-models/components/DisplayBillingInformation';

import DisplayAddress from '../Address/DisplayAddress';
import Panel from '../Panel';

function DisplayBillingInformation({
  address
}: DisplayBillingInformationProps) {
  const { t } = useTranslation();

  if (!address) {
    return null;
  }
  const translationPrefix = 'containers.card_order.summary_and_payment.';

  return (
    <Panel
      title={t(`${translationPrefix}billing_information_header`)}
      subtitle={t(`${translationPrefix}billing_information_subheader`)}
    >
      <div className="content">
        <div className="pl-60 row">
          <div className="col-sm-12 col-xs-10">
            <DisplayAddress address={address} />
          </div>
        </div>
      </div>
    </Panel>
  );
}

export default DisplayBillingInformation;
