import React, { useState } from 'react';
import {
  TagComponent as Tag,
  Table,
  SimpleButton,
  MoreIcon,
  Box
} from 'edenred-ui';
import { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';

import { getBenefitIcon } from '@epi-constants/benefitTypes';
import { hasDatePassed, parseStringToDate } from '@epi-helpers/dateHelper';
import { IBeneficiariesRow } from '@epi-models/pages/Beneficiaries';

import { BeneficiaryActions } from './BeneficiaryActions';

export function BeneficiariesRow({
  showDetail,
  beneficiary,
  setBeneficiaryEditingMode,
  contractedBenefits
}: IBeneficiariesRow) {
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const { t } = useTranslation();
  const theme = useTheme();

  const openMenu = event => {
    event.stopPropagation();
    setMenuAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setMenuAnchorEl(null);
  };

  const openBeneficiary = () => {
    showDetail(beneficiary.personId);
    closeMenu();
  };

  const editBeneficiary = () => {
    openBeneficiary();
    setBeneficiaryEditingMode(true);
  };

  const endOfFundsDatePassed = !!hasDatePassed(
    parseStringToDate(beneficiary.endOfFunds)
  );

  const getBenefitIconByState = type => {
    const isBenefitActiveForUser = beneficiary.benefitTypes.find(
      benefit => benefit === type
    );
    const color =
      !endOfFundsDatePassed && isBenefitActiveForUser
        ? theme.primaryFrontColor
        : theme.altGray1;

    return getBenefitIcon(type, color);
  };

  return (
    <>
      <Table.Row
        sx={{ cursor: 'pointer' }}
        key={beneficiary.personId}
        onClick={endOfFundsDatePassed ? openBeneficiary : editBeneficiary}
        disabled={endOfFundsDatePassed}
      >
        <Table.Cell>
          <Box
            display="flex"
            alignItems="center"
            id="manage-employees-table-employee"
          >
            {beneficiary.lastName} {beneficiary.firstName}
            {beneficiary.isNew && (
              <Box
                lineHeight="11px"
                ml={2}
                id="manage-employees-table-employee-new-label"
              >
                <Tag label={t('containers.beneficiaries.table.new')} size="s" />
              </Box>
            )}
            {endOfFundsDatePassed && (
              <Box
                lineHeight="11px"
                ml={2}
                id="manage-employees-table-employee-remove-label"
              >
                <Tag
                  label={t('containers.beneficiaries.table.remove')}
                  size="s"
                  color={theme.gray70}
                  borderColor={theme.gray70}
                  fontWeight={theme.fontSemiBold}
                />
              </Box>
            )}
          </Box>
        </Table.Cell>
        <Table.Cell id="manage-employees-table-email">
          {beneficiary.emailAddress}
        </Table.Cell>
        <Table.Cell>
          {contractedBenefits?.map(benefit => (
            <Box display="inline" key={benefit.type} mr={1}>
              {getBenefitIconByState(benefit.type)}
            </Box>
          ))}
        </Table.Cell>
        <Table.Cell align="right">
          <SimpleButton icon={<MoreIcon />} onClick={openMenu} />
        </Table.Cell>
      </Table.Row>
      <BeneficiaryActions
        anchorEl={menuAnchorEl}
        closeMenu={closeMenu}
        openModalAndFillForm={editBeneficiary}
        endOfFundsDatePassed={endOfFundsDatePassed}
        openBeneficiary={openBeneficiary}
      />
    </>
  );
}
