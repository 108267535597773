import { useFormContext } from 'react-hook-form';

export function useOnBlurTrim(name: string) {
  const { getValues, setValue } = useFormContext();
  return () => {
    const value = getValues(name);
    const trimmedValue = value?.trim();

    if (value !== trimmedValue) {
      setValue(name, trimmedValue);
    }
  };
}
