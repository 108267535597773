import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { PagePanel, Tabs } from 'edenred-ui';

import { ReportTypeVirike } from '@epi-constants/externalReport';
import { TopupReports } from '@epi-pages/Reports/TopupReports';
import { BalanceCorrections } from '@epi-pages/Reports/BalanceCorrections';
import { TransactionsHistory } from '@epi-containers/TransactionsHistory/TransactionsHistory';
import { getBalanceCorrectionsCheckRequest } from '@epi-api/balanceCorrections';
import { getApplicationLocale } from '@epi-store/application/application.selectors';
import config from '@epi-config';

import { useExternalReportsTabs } from './hooks/useExternalReportsTabs';

const { enableVirikeBenefit } = config;

const ExternalReportsTabs = () => {
  const { t } = useTranslation();
  const locale = useSelector(getApplicationLocale);
  const [showBalanceCorrections, setShowBalanceCorrections] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { handleOnTabChange, defaultTabName } = useExternalReportsTabs();

  const checkBalanceCorrections = async () => {
    setIsLoading(true);
    const response = await getBalanceCorrectionsCheckRequest();
    setShowBalanceCorrections(response);
    setIsLoading(false);
  };

  useEffect(() => {
    checkBalanceCorrections();
  }, [locale]);

  const tabTransactions = {
    name: ReportTypeVirike.transactions,
    label: t('containers.reports.tabs.transactions'),
    id: 'tab-transactions',
    body: <TransactionsHistory />
  };

  const topUpLoads = {
    name: ReportTypeVirike.topups,
    label: t('containers.reports.tabs.topup'),
    id: 'tab-topup',
    body: <TopupReports />
  };

  const balanceCorrections = {
    name: ReportTypeVirike.corrections,
    label: t('containers.deposit_money.corrections_tab.title'),
    id: 'tab-balance-corrections',
    body: <BalanceCorrections />
  };

  const getTabs = () => {
    if (enableVirikeBenefit && showBalanceCorrections)
      return [tabTransactions, topUpLoads, balanceCorrections];
    if (enableVirikeBenefit) return [tabTransactions, topUpLoads];
    if (showBalanceCorrections) return [tabTransactions, balanceCorrections];
    return [tabTransactions];
  };

  return (
    <PagePanel title={t('containers.reports.title')}>
      {!isLoading && (
        <Tabs
          tabs={getTabs()}
          onTabChange={handleOnTabChange}
          defaultTabName={defaultTabName}
        />
      )}
    </PagePanel>
  );
};

export default ExternalReportsTabs;
