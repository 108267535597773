export const getBeneficiaryPayload = values => {
  const {
    personId,
    personalId,
    firstName,
    lastName,
    emailAddress,
    endOfBenefit,
    EmploymentType,
    employeeNumber,
    costCenter,
    entitled,
    EmployeePercentage,
    DaysForLunari
  } = values;
  return {
    personId,
    personalId,
    firstName,
    lastName,
    emailAddress,
    endOfBenefit,
    EmploymentType,
    employeeNumber,
    costCenter,
    startOfBenefit: entitled,
    BenefitPercentage: EmployeePercentage,
    BenefitLunchMaxWorkingDays: DaysForLunari
  };
};
