import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Notification, NotificationType, Box } from 'edenred-ui';

import config from '@epi-config';
import { getIsAuthenticated } from '@epi-selectors/login';
import { getApplicationLanguageId } from '@epi-store/application/application.selectors';

const {
  notificationBannerStartDate,
  notificationBannerEndDate,
  showNotificationBanner,
  notificationBannerTextEng,
  notificationBannerTextFin
} = config;

export const NotificationInfoBanner = () => {
  const language = useSelector(getApplicationLanguageId);
  const [showNotification, setShowNotification] = useState(true);
  const isAuthenticated = useSelector(getIsAuthenticated);

  useEffect(() => {
    const todayTime = new Date().getTime();
    const startDate = new Date(notificationBannerStartDate).getTime();
    const endDate = new Date(notificationBannerEndDate).getTime();

    if (!!notificationBannerStartDate && !!notificationBannerEndDate) {
      setShowNotification(todayTime > startDate && todayTime < endDate);
    }
  }, []);

  return isAuthenticated &&
    Boolean(showNotificationBanner) &&
    showNotification ? (
    <Box m={4} mb={-1}>
      <Notification
        type={NotificationType.Important}
        text={
          language == 'en'
            ? notificationBannerTextEng
            : notificationBannerTextFin
        }
        onClose={() => setShowNotification(false)}
      />
    </Box>
  ) : null;
};
