import React, { useEffect } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { TextField } from 'edenred-ui';

import {
  createRules,
  FormFields,
  useOnBlurTrim,
  htmlDecode
} from '@epi-forms/helpers';
import {
  companyName,
  maxLength,
  required as requiredValidation
} from '@epi-helpers/formHelpers/validators';
import { CompanyNameFieldProps } from '@epi-models/forms/Controls';

export function CompanyNameField({
  disabled = false,
  readOnly = false,
  name = FormFields.CompanyName,
  required = true
}: CompanyNameFieldProps) {
  const { control, unregister } = useFormContext();
  const { t } = useTranslation();
  const onBlurTrim = useOnBlurTrim(name);
  const validators = [requiredValidation, maxLength(50), companyName];
  const rules = disabled || readOnly ? undefined : createRules(validators);

  const {
    field: { ref, value, onChange: setValue },
    fieldState: { error }
  } = useController({
    name,
    control,
    rules
  });

  useEffect(() => {
    if (readOnly) {
      unregister(name);
    }
  }, [readOnly]);

  return (
    <TextField
      label={t('controls.company_name_label')}
      required={required}
      disabled={disabled}
      readOnly={readOnly}
      value={htmlDecode(value)}
      id={name}
      error={!!error}
      helperText={error?.message}
      inputRef={ref}
      onBlur={onBlurTrim}
      onChange={setValue}
    />
  );
}
