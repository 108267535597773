import { formatCurrencyDecimal } from '@epi-helpers/numeral';
import React from 'react';
import { Checkbox } from 'edenred-ui';

import { EstimationDetailsRowProps } from '@epi-models/components/EstimationDetails';

function EstimationDetails({
  amount,
  text,
  message = null,
  hideIfZero = false,
  toggable = false,
  checked = false,
  setChecked = () => {},
  id = '',
  action = null,
  month = null
}: EstimationDetailsRowProps) {
  if (amount <= 0 && hideIfZero) {
    return null;
  }
  const dataTestSuffix = month ? `-${month}` : '';
  return (
    <div
      className="estimation-details-row"
      id={`estimation-details-row-${id}`}
      onClick={() => {
        if (action !== null) {
          action();
        }
      }}
    >
      <div
        className="estimation-details-row-text"
        id={`estimation-details-row-text-${id}`}
      >
        {toggable && (
          <Checkbox
            inputProps={
              {
                'data-testid': `${text}-checkbox${dataTestSuffix}`
              } as React.InputHTMLAttributes<HTMLInputElement>
            }
            checked={checked}
            onChange={() => setChecked(id)}
          />
        )}
        {text}
      </div>
      <div
        className="estimation-details-row-message"
        id={`estimation-details-row-message-${id}`}
      >
        {message}
      </div>
      <div
        className={`estimation-details-row-amount ${
          toggable && !checked && 'estimation-details-row-amount--linethrough'
        }`}
        id={`estimation-details-row-amount-${id}`}
      >
        {formatCurrencyDecimal(amount)}
      </div>
    </div>
  );
}

export default EstimationDetails;
