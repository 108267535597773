import React from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { TextField } from 'edenred-ui';

import { FormFields, createRules } from '@epi-forms/helpers';
import {
  businessId,
  required as requiredValidation
} from '@epi-helpers/formHelpers/validators';
import { CustomerNumberFieldProps } from '@epi-models/forms/Controls';

export function CustomerNumberField({
  readOnly = false,
  disabled = false,
  required = true
}: CustomerNumberFieldProps) {
  const name = FormFields.CustomerNumber;
  const { t } = useTranslation();
  const { control } = useFormContext();
  const validators = [requiredValidation, businessId];
  const rules = createRules(validators);

  const {
    field: { ref, value, onChange: setValue },
    fieldState: { error }
  } = useController({
    name,
    control,
    rules
  });

  return (
    <TextField
      label={t('controls.customer_number_label')}
      onChange={setValue}
      readOnly={readOnly}
      value={value}
      error={!!error}
      helperText={error?.message}
      id={name}
      inputRef={ref}
      required={required}
      disabled={disabled}
    />
  );
}
