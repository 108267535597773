import React, { useEffect } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { TextField } from 'edenred-ui';
import { useSelector } from 'react-redux';

import { IDailyLimitField } from '@epi-models/containers/ManageBenefitGroups';
import { BenefitType, FundsLoadingTypes } from '@epi-constants/benefitTypes';
import { createRules } from '@epi-forms/helpers';
import { getSettings } from '@epi-selectors/settings';
import {
  numberBetween,
  required,
  number,
  virikeMaxAmount,
  virikeMinAmount
} from '@epi-helpers/formHelpers/validators';

import { ManageBenefitLunchFormFields } from '../../helpers';
import { useGetAmounts } from '../../hooks';

export function DailyLimitField({
  readOnly = false,
  options,
  label = '',
  benefitType,
  helperText = ''
}: IDailyLimitField) {
  const name = ManageBenefitLunchFormFields.DailyLimit;
  const { control, watch, trigger } = useFormContext();
  const settings = useSelector(getSettings);
  const amount = useGetAmounts();
  const fundsLoadingType = watch(ManageBenefitLunchFormFields.FundsLoadingType);
  const isLounari = benefitType === BenefitType.Lunch;
  const isVirike = benefitType === BenefitType.Recreational;
  const validators = [
    required,
    number,
    numberBetween(amount[amount.length - 1], amount[0])
  ];
  const lounariValidators = [required];
  const virikeValidators = [
    required,
    number,
    virikeMinAmount,
    virikeMaxAmount(
      fundsLoadingType === FundsLoadingTypes.DirectLoad
        ? settings.recreationalMaxAmount
        : settings.recreationalMaxAmountForTopUpRequest
    )
  ];
  const getRules = () => {
    if (isVirike) return createRules(virikeValidators);
    if (isLounari) return createRules(lounariValidators);
    return createRules(validators);
  };
  const rules = getRules();

  useEffect(() => {
    if (isVirike) {
      trigger(name);
    }
  }, [fundsLoadingType, isVirike, name, trigger]);

  const {
    field: { ref, value, onChange: setValue },
    fieldState: { error }
  } = useController({
    name,
    control,
    rules
  });

  return (
    <TextField
      label={label}
      select={isLounari}
      value={value}
      readOnly={readOnly}
      id="daily-limit-select"
      error={!!error}
      helperText={error?.message ? error?.message : helperText}
      inputRef={ref}
      onChange={setValue}
      SelectProps={{
        MenuProps: {
          BackdropProps: {
            sx: {
              backgroundColor: 'transparent'
            }
          }
        }
      }}
    >
      {options
        .filter(option => option.upToDate)
        .map(option => (
          <TextField.Item key={option.id} value={option.id}>
            {option.display}
          </TextField.Item>
        ))}
    </TextField>
  );
}
