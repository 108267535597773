import styled from 'styled-components';

export const SwitchElement = styled.div`
  position: relative;
  display: inline-block;
  width: 48px;
  height: 24px;
`;

export const SwitchSlider = styled.span<{ checked: boolean }>`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${({ theme, checked }) =>
    checked ? theme.primaryFrontColor : theme.gray0};
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 16px;
  border: ${({ theme }) => `2px solid ${theme.primaryFrontColor}`};

  &::before {
    position: absolute;
    content: '';
    height: 16px;
    width: 16px;
    left: 4px;
    bottom: 2px;
    background-color: white;
    border: ${({ theme }) => `2px solid ${theme.primaryFrontColor}`};
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 50%;
    transform: ${props =>
      props.checked ? 'translateX(23px)' : 'translateX(0)'};
  }
`;
