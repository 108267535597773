import React, { useState } from 'react';
import styled from 'styled-components';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Box } from 'edenred-ui';

import { goToPage } from '@epi-actions/navigation';
import { routerPaths } from '@epi-constants/routerPaths';
import { getLogoutAction } from '@epi-actions/login';
import { getApplicationLanguageId } from '@epi-store/application/application.selectors';
import { INavbarDropdownActions } from '@epi-models/core/Navbar';
import { useToastContext } from '@epi-core/components/Toast/useToastContext';
import { setGoToPageLink } from '@epi-actions/beneficiary';
import { selectIsOrderInProcess } from '@epi-selectors/benefitGroupSettings';

import { NavbarLanguagesList } from '../NavbarLanguagesList';
import { NavbarLanguageLabelWithChevron } from '../NavbarLanguageLabel';

const DropdownActionButton = styled.button.attrs({ type: 'button' })`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 64px;
  padding: 0 20px;
  background: none;
  border: none;
  text-align: left;
  color: ${({ theme }) => theme.primaryFrontColor};
  font-size: ${({ theme }) => theme.sizeMedium};
  font-family: ${({ theme }) => theme.fontFamily};

  &:not(:first-child) {
    border-top: 1px solid ${({ theme }) => theme.altGray2};
  }

  &:hover {
    background: ${({ theme }) => theme.primaryBackColorLight10};
  }
`;

function NavbarDropdownActions({
  selectedLanguageId,
  closeDropdown
}: INavbarDropdownActions) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [languageItemOpen, setLanguageItemOpen] = useState(false);
  const { closeToast } = useToastContext();
  const isOrderInProgress = useSelector(selectIsOrderInProcess);

  const close = () => {
    setLanguageItemOpen(false);
    closeDropdown();
  };

  const goToProfile = () => {
    if (isOrderInProgress) {
      dispatch(setGoToPageLink(routerPaths.profile));
    } else {
      dispatch(goToPage(routerPaths.profile));
    }
    close();
  };

  const toggleLanguage = () => {
    setLanguageItemOpen(!languageItemOpen);
  };

  const logout = () => {
    dispatch(getLogoutAction());
    close();
    closeToast();
  };

  return (
    <>
      <DropdownActionButton
        id="dropdown-action-btn-profile"
        onClick={goToProfile}
      >
        {t('navbar.profile')}
      </DropdownActionButton>
      <DropdownActionButton
        id="dropdown-action-btn-language"
        onClick={toggleLanguage}
      >
        <Box>{t('navbar.language')}</Box>
        <Box display="flex" alignItems="center">
          <NavbarLanguageLabelWithChevron
            languageId={selectedLanguageId}
            open={languageItemOpen}
          />
        </Box>
      </DropdownActionButton>
      {languageItemOpen && <NavbarLanguagesList onLanguageChanged={close} />}
      <DropdownActionButton id="dropdown-action-btn-logout" onClick={logout}>
        {t('navbar.logout')}
      </DropdownActionButton>
    </>
  );
}

const mapStateToProps = state => ({
  selectedLanguageId: getApplicationLanguageId(state)
});

const ConnectedNavbarDropdownActions = connect(mapStateToProps)(
  NavbarDropdownActions
);

export { ConnectedNavbarDropdownActions as NavbarDropdownActions };
