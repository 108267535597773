import React from 'react';
import { useTranslation } from 'react-i18next';
import { Menu, EditIcon, Box, SearchIcon } from 'edenred-ui';

import { IBeneficiaryActions } from '@epi-models/pages/Beneficiaries';

import { StyledButton } from './BeneficiariesTable.styled';

export function BeneficiaryActions({
  anchorEl,
  closeMenu,
  openModalAndFillForm,
  endOfFundsDatePassed,
  openBeneficiary
}: IBeneficiaryActions) {
  const { t } = useTranslation();

  const handleEditAction = () => {
    closeMenu();
    openModalAndFillForm();
  };

  return (
    <Menu
      open={!!anchorEl}
      anchorEl={anchorEl}
      onClose={closeMenu}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
    >
      {!endOfFundsDatePassed ? (
        <div>
          <Menu.Item onClick={null}>
            <StyledButton
              onClick={handleEditAction}
              icon={
                <Box mr="10px">
                  <EditIcon />
                </Box>
              }
              text={t('company_info.contacts.actions.edit')}
            />
          </Menu.Item>
        </div>
      ) : (
        <Menu.Item>
          <StyledButton
            onClick={openBeneficiary}
            icon={
              <Box mr="10px">
                <SearchIcon />
              </Box>
            }
            text={t('containers.beneficiaries.table.show_action')}
          />
        </Menu.Item>
      )}
    </Menu>
  );
}
