import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { PagePanel, Button, ButtonSize, EditIcon } from 'edenred-ui';

import * as navigationActions from '@epi-actions/navigation';
import DisplayAddress from '@epi-components/Address/DisplayAddress';
import { CompanyProps } from '@epi-models/components/Company';

import { businessId as businessIdFormatter } from '../../helpers/formHelpers/formatters';
import { RoundButton } from '../RoundButton/RoundButton';
import { LoadingContainer } from '../LoadingContainer/LoadingContainer';

import './Company.scss';

const Company = ({
  userCompany,
  editPage = '',
  goToPage = () => null,
  isLoading = false
}: CompanyProps) => {
  const { t } = useTranslation();
  const { businessIdentityCode, name, address } = userCompany;

  return (
    <div className="Company">
      <PagePanel
        title={t('containers.voucher_order.company_information_header')}
        className="px-10"
      >
        {editPage && (
          <span className="visible-xs-inline">
            <RoundButton
              id="company-edit-icon-btn"
              icon={<EditIcon />}
              className="pull-right"
              onClick={() => goToPage(editPage)}
            />
          </span>
        )}
        <LoadingContainer isLoading={isLoading}>
          <Row className="pl-60">
            <Col xs={10} sm={12}>
              <div className="header3 small">
                {`${name} ${businessIdFormatter(businessIdentityCode)}`}
              </div>
              <DisplayAddress address={address} />
            </Col>
          </Row>
          {editPage && (
            <Row className="hidden-xs">
              <Col>
                <Button
                  id="company-edit-btn"
                  className="btn-edit pull-right"
                  onClick={() => goToPage(editPage)}
                  size={ButtonSize.Medium}
                >
                  {t('components.benefit_summary.edit')}
                </Button>
              </Col>
            </Row>
          )}
        </LoadingContainer>
      </PagePanel>
    </div>
  );
};

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...navigationActions }, dispatch);

const ConnectedCompany = connect(null, mapDispatchToProps)(Company);

export { ConnectedCompany as Company };
