import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { useGetDetails } from '@epi-shared/hooks';
import { useManageBenefits } from '@epi-containers/ManageBenefits/hooks';
import { ACTIVE, INACTIVE, READY_TO_ORDER } from '@epi-constants/benefitTypes';
import { goToPage } from '@epi-actions/navigation';
import { routerPaths } from '@epi-constants/routerPaths';

export const useCreatedGroupNotifications = () => {
  const { isLoading, customerData } = useGetDetails();
  const { allBenefits } = useManageBenefits();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const isEmployeeSet = customerData?.isEmployeesSet;
  const isNewCustomer =
    customerData &&
    Object.values(customerData).find(elem => !elem) !== undefined;
  const activeBenefits = allBenefits.filter(b => b.state === ACTIVE);
  const inactiveBenefits = allBenefits.filter(b => b.state === INACTIVE);
  const readyToOrderBenefits = allBenefits.filter(
    b => b.state === READY_TO_ORDER
  );
  const isOneInactiveBenefit = inactiveBenefits.length > 1;
  const isOneActiveBenefit = activeBenefits.length >= 0;

  const variantOne =
    isOneActiveBenefit && isOneInactiveBenefit && !isEmployeeSet;
  const variantTwo =
    isOneActiveBenefit &&
    !isOneInactiveBenefit &&
    !isEmployeeSet &&
    readyToOrderBenefits.length < 1;
  const variantThree =
    isOneActiveBenefit && isOneInactiveBenefit && isEmployeeSet;
  const variantFour =
    isOneActiveBenefit &&
    !isOneInactiveBenefit &&
    isEmployeeSet &&
    readyToOrderBenefits.length < 1;
  const variantFive =
    isOneActiveBenefit &&
    !isOneInactiveBenefit &&
    !isEmployeeSet &&
    readyToOrderBenefits.length >= 1;
  const variantSix =
    isOneActiveBenefit &&
    !isOneInactiveBenefit &&
    isEmployeeSet &&
    readyToOrderBenefits.length >= 1;

  const handleChangePageToManageBenefits = () => {
    dispatch(goToPage(routerPaths.manageBenefits));
  };

  const handleChangePageToBeneficiaries = () => {
    dispatch(goToPage(routerPaths.beneficiaries));
  };

  const handleChangePageToHome = () => {
    dispatch(goToPage(routerPaths.home));
  };

  const notificationGroupArr = [
    {
      id: 0,
      showNotification: variantOne,
      successNotification: {
        text: t(
          'components.manage_benefits.notification.text_variant_success_a'
        )
      },
      infoNotification: {
        text: t('components.manage_benefits.notification.text_variant_info_a'),
        handleFirstBtn: handleChangePageToManageBenefits,
        textForFirstBtn: t(
          'components.manage_benefits.notification.active_benefit'
        ),
        handleSecondBtn: handleChangePageToBeneficiaries,
        textForSecondBtn: t(
          'components.manage_benefits.notification.add_employee_second'
        ),
        textBetweenBtns: t(
          'components.manage_benefits.notification.text_between_variant_a'
        )
      }
    },
    {
      id: 1,
      showNotification: variantTwo,
      successNotification: {
        text: t(
          'components.manage_benefits.notification.text_variant_success_a'
        ),
        handleFirstBtn: handleChangePageToBeneficiaries,
        textForFirstBtn: t(
          'components.manage_benefits.notification.add_employee'
        )
      }
    },
    {
      id: 2,
      showNotification: variantThree,
      successNotification: {
        text: t(
          'components.manage_benefits.notification.text_variant_success_a'
        )
      },
      infoNotification: {
        text: t('components.manage_benefits.notification.text_variant_info_a'),
        handleFirstBtn: handleChangePageToManageBenefits,
        textForFirstBtn: t(
          'components.manage_benefits.notification.active_benefit'
        )
      }
    },
    {
      id: 3,
      showNotification: variantFour,
      successNotification: {
        text: t(
          'components.manage_benefits.notification.text_variant_success_a'
        )
      }
    },
    {
      id: 4,
      showNotification: variantFive,
      successNotification: {
        text: t(
          'components.manage_benefits.notification.text_variant_success_b'
        ),
        handleFirstBtn: handleChangePageToBeneficiaries,
        textForFirstBtn: t(
          'components.manage_benefits.notification.add_employee'
        ),
        handleSecondBtn: isNewCustomer ? undefined : handleChangePageToHome,
        textForSecondBtn: isNewCustomer
          ? undefined
          : t('components.manage_benefits.notification.buy_benefits_variant_b')
      }
    },
    {
      id: 5,
      showNotification: variantSix,
      successNotification: {
        text: t(
          'components.manage_benefits.notification.text_variant_success_b'
        ),
        handleFirstBtn: isNewCustomer ? undefined : handleChangePageToHome,
        textForFirstBtn: isNewCustomer
          ? undefined
          : t('components.manage_benefits.notification.buy_benefits_variant_a')
      }
    }
  ];

  return { isLoading, notificationGroupArr };
};
