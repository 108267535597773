import React from 'react';

import { NavigationHeaderProps } from '@epi-models/components/NavigationHeader';
import history from '../../routes/history';
import { BackButton } from '../BackButton/BackButton';

import './NavigationHeader.scss';

export const NavigationHeader = ({
  children,
  withBackButton = false,
  onClickBack = () => history.goBack(),
  title,
  className = ''
}: NavigationHeaderProps) => (
  <div className={`NavigationHeader ${className}`}>
    <div
      className={`NavigationHeader-header mb-30 ${
        !withBackButton ? 'with-back-button' : ''
      }`}
    >
      {withBackButton && <BackButton onClick={onClickBack} />}
      <h2 className="title">{title}</h2>
    </div>
    {children && <div className="NavigationHeader-body">{children}</div>}
  </div>
);
