import React, { PureComponent } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { throttle } from 'lodash';
import { InfoIcon, Box } from 'edenred-ui';

import './TooltipInfo.scss';

interface TooltipInfoProps {
  info?: string;
  placement?: 'left' | 'right' | 'bottom' | 'top';
  className?: string;
  clickable?: boolean;
}

interface TooltipInfoState {
  show: boolean;
}

export class TooltipInfo extends PureComponent<
  TooltipInfoProps,
  TooltipInfoState
> {
  private throttledOnResize: ReturnType<typeof throttle>;

  static defaultProps = {
    info: undefined,
    placement: 'right',
    className: '',
    clickable: false
  };

  constructor(props: TooltipInfoProps) {
    super(props);
    this.handleToggle = this.handleToggle.bind(this);
    this.getTrigger = this.getTrigger.bind(this);
    this.state = {
      show: false
    };

    const onResize = () => {
      // HACK: to make open popover move when resizing the window
      const { show } = this.state;
      if (show) {
        this.handleToggle();
        this.handleToggle();
      }
    };
    this.throttledOnResize = throttle(onResize, 100);
  }

  componentDidMount() {
    window.addEventListener('resize', this.throttledOnResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.throttledOnResize);
  }

  getToggleHandler(shouldAddHandler: boolean) {
    return shouldAddHandler ? this.handleToggle : undefined;
  }

  getTrigger = (clickable: boolean) =>
    clickable ? ['click'] : ['hover', 'focus'];

  handleToggle() {
    const { show } = this.state;
    this.setState({ show: !show });
  }

  render() {
    const { info, placement, className, clickable } = this.props;
    const tooltip = info && (
      <Tooltip
        className="TooltipInfo tooltip-info"
        id="tooltip"
        placement={placement}
      >
        <div
          dangerouslySetInnerHTML={{
            __html: info
          }}
        />
      </Tooltip>
    );

    return tooltip ? (
      <span className="icon">
        <OverlayTrigger
          shouldUpdatePosition
          //@ts-ignore
          container={document.getElementsByClassName('sidebar-content')[0]}
          placement={placement}
          //@ts-ignore
          trigger={this.getTrigger(clickable)}
          overlay={tooltip}
        >
          <Box
            onClick={this.getToggleHandler(!!clickable)}
            onFocus={this.getToggleHandler(!clickable)}
            onBlur={this.getToggleHandler(!clickable)}
            onMouseOut={this.getToggleHandler(!clickable)}
            onMouseOver={this.getToggleHandler(!clickable)}
          >
            <InfoIcon />
          </Box>
        </OverlayTrigger>
      </span>
    ) : (
      <div />
    );
  }
}
