import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ButtonComponent as Button, ButtonMode, Box } from 'edenred-ui';

import { IBenefitTypeTile } from '@epi-models/containers/ManageBenefits';

import { BenefitTypeIcon } from '../BenefitTypeIcon';

import * as Style from './BenefitTypeTile.styles';

export const BenefitTypeTile = ({ benefit, active }: IBenefitTypeTile) => {
  const { t } = useTranslation();
  const { push } = useHistory();
  const { pathname } = useLocation();

  const benefitName = t(`components.manage_benefits.new.benefits.${benefit}`);

  const buttonText = active
    ? t('components.manage_benefits.new.view_groups')
    : t('components.manage_benefits.new.activate');

  const handleClick = () => {
    push(`${pathname}/${benefit}`);
  };

  return (
    <Style.BenefitTypeTileContainer
      active={active}
      id={`benefit-tile_${benefitName}`}
    >
      {active && (
        <Style.BenefitTypeStatus id={`status-active-${benefit}`}>
          <Style.BenefitTypeStatusText>
            {t('components.manage_benefits.new.active')}
          </Style.BenefitTypeStatusText>
        </Style.BenefitTypeStatus>
      )}
      <BenefitTypeIcon benefit={benefit} />
      <Style.BenefitTypeName id={`benefit-tile_${benefitName}-title`}>
        {benefitName}
      </Style.BenefitTypeName>
      <Box maxWidth={248} width="100%">
        <Button
          fullWidth
          id={`view-groups-${benefit}`}
          onClick={handleClick}
          mode={active ? ButtonMode.Secondary : ButtonMode.Primary}
        >
          {buttonText}
        </Button>
      </Box>
    </Style.BenefitTypeTileContainer>
  );
};
