import React from 'react';
import { connect } from 'react-redux';
import { useTranslation, Trans } from 'react-i18next';
import { bindActionCreators } from 'redux';
import { Row, Col, Collapse } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { Box } from 'edenred-ui';

import { FormFields } from '@epi-forms/helpers';

import { formatNumeric, formatCurrency } from '../../helpers/numeral';
import { VoucherTypes } from '../../constants/voucherTypes';
import { TooltipInfo } from '../TooltipInfo/TooltipInfo';
import * as navigationActions from '../../actions/navigation';

import './BenefitSummaryContent.scss';
import { BenefitSummaryContentProps } from '@epi-models/components/BenefitSummary';

const LOCALE_PREFIX = 'components.benefit_summary';

function BenefitSummaryContent({
  showCreditCardFee,
  voucherTypeKey,
  orderSummary: {
    creditCardFeePercent,
    creditCardFee,
    withoutReference = false,
    vatFeePercent,
    vatFeeAmount,
    handlingFee
  },
  voucherOrderPayment
}: BenefitSummaryContentProps) {
  const { watch } = useFormContext();
  const { t } = useTranslation();

  const quantityValue =
    watch(FormFields.VoucherQuantity) || voucherOrderPayment?.totalQuantity;
  const voucherWorthValue =
    watch(FormFields.VoucherWorth) ||
    voucherOrderPayment?.faceValue ||
    (voucherOrderPayment?.totalAmount ?? 0) /
      (voucherOrderPayment?.totalQuantity ?? 1);
  const sumValue = quantityValue * voucherWorthValue;
  const orderTotalWithoutVat = sumValue + handlingFee + creditCardFee;
  const totalAmountWithFees = orderTotalWithoutVat + vatFeeAmount;
  const formattedQuantity = `${formatNumeric(quantityValue, '0,0')}`;
  const formattedFaceValue = `${formatCurrency(voucherWorthValue)}`;
  const formattedSumVouchers = `${formatCurrency(sumValue)}`;

  return (
    <div
      className="BenefitSummaryContent benefit-summary-content"
      id="BenefitSummaryContent"
    >
      <Row>
        <Col xs={8}>
          <div className="header3 small m-0">
            {t(
              `${LOCALE_PREFIX}.vouchers_total[${voucherTypeKey.toLowerCase()}]`,
              { quantity: formattedQuantity, faceValue: formattedFaceValue }
            )}
          </div>
        </Col>
        <Col className="text-right" xs={2}>
          <div className="header3 small m-0">
            {t(`${LOCALE_PREFIX}.vat`, { vatFeePercent: 0 })}
          </div>
        </Col>
        <Col className="text-right" xs={2}>
          <div className="header3 small m-0">{formattedSumVouchers}</div>
        </Col>
      </Row>
      <Row>
        <Col xs={8}>
          <div
            className={`${
              withoutReference ? 'blue-text' : ''
            } header3 small m-0`}
          >
            {t(`${LOCALE_PREFIX}.handling_fee`)}{' '}
          </div>
          <Box fontSize={12.5}>
            <Trans
              defaults={t(
                `${LOCALE_PREFIX}.${
                  VoucherTypes[voucherTypeKey] === VoucherTypes.Lunch
                    ? 'lunch_price_list_and_fee'
                    : 'recreational_price_list_and_fee'
                }`
              )}
              components={{
                a: (
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={t(
                      `${LOCALE_PREFIX}.${
                        VoucherTypes[voucherTypeKey] === VoucherTypes.Lunch
                          ? 'lunch_price_list_and_fee_url'
                          : 'recreational_price_list_and_fee_url'
                      }`
                    )}
                  />
                )
              }}
            />
          </Box>
        </Col>
        <Col className="text-right" xs={2}>
          <div className="header3 small m-0">
            {t(`${LOCALE_PREFIX}.vat`, { vatFeePercent })}
          </div>
        </Col>
        <Col className="text-right" xs={2}>
          <div
            className={`${
              withoutReference ? 'blue-text' : ''
            } header3 small internal-subheader m-0`}
          >
            {handlingFee || '-'} €
          </div>
        </Col>
      </Row>
      <hr />

      <Row>
        <Col xs={6} sm={7}>
          <div className="header3 small m-0">
            {t(`${LOCALE_PREFIX}.total_without_vat`)}
          </div>
        </Col>
        <Col className="text-right" xs={6} sm={5}>
          <div className="header3 small m-0">
            {formatCurrency(orderTotalWithoutVat)}
          </div>
        </Col>
      </Row>

      <Row>
        <Col xs={6} sm={7}>
          <div className="header3 small m-0">
            {t(`${LOCALE_PREFIX}.total_vat`, { vatFeePercent })}
          </div>
        </Col>
        <Col className="text-right" xs={6} sm={5}>
          <div className="header3 small m-0">
            {formatCurrency(vatFeeAmount)}
          </div>
        </Col>
      </Row>

      <Collapse unmountOnExit in={showCreditCardFee}>
        <div>
          <hr />
          <Row>
            <Col xs={7} sm={8}>
              <div className="blue-text header3 small internal-subheader m-0">
                {t(`${LOCALE_PREFIX}.credit_card_fee`)}{' '}
              </div>
              {creditCardFeePercent > 0 && (
                <TooltipInfo
                  info={t(`${LOCALE_PREFIX}.credit_card_fee_desc`, {
                    value: formatNumeric(creditCardFeePercent, '0.00')
                  })}
                  placement="right"
                />
              )}
            </Col>
            <Col className="text-right" xs={5} sm={4}>
              <div className="blue-text header3 small internal-subheader m-0">
                {totalAmountWithFees ? formatCurrency(creditCardFee) : '?'}
              </div>
            </Col>
          </Row>
        </div>
      </Collapse>
      <hr className="gray" />
      <Row>
        <Col xs={7} sm={8}>
          <div className="header2 small internal-subheader m-0">
            {t(`${LOCALE_PREFIX}.order_total`)}
          </div>
        </Col>
        <Col className="text-right total" xs={5} sm={4}>
          <div className="header2 small internal-subheader m-0">
            {formatCurrency(totalAmountWithFees)}
          </div>
        </Col>
      </Row>
    </div>
  );
}

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...navigationActions }, dispatch);

const ConnectedBenefitSummaryContent = connect(
  null,
  mapDispatchToProps
)(BenefitSummaryContent);

export {
  ConnectedBenefitSummaryContent as BenefitSummaryContent,
  BenefitSummaryContent as BenefitSummaryContentTest
};
