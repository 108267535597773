import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table, LoadingSpinner, ButtonMode, Box } from 'edenred-ui';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ApiConstants } from '@epi-constants/actions';
import { ErrorPanel } from '@epi-components/ErrorPanel/ErrorPanel';
import { getShowSlideout } from '@epi-selectors/company';
import { useMenuDimensions } from '@epi-shared/hooks';
import { contactsApiUrl } from '@epi-repositories/ContactsRepository';
import { fetchDelete as Delete } from '@epi-helpers/FetchHelpers';
import { showNotification } from '@epi-actions/overlays';
import { getUserInformation } from '@epi-actions/profile';
import { getCustomerNumber } from '@epi-selectors/api';
import {
  closeSlideout,
  disableEditMode,
  disableSlideoutEditMode,
  enableSlideoutEditMode,
  openSlideout,
  setContactsOrder
} from '@epi-actions/company';
import { IContact, IContactsData } from '@epi-models/pages/CompanyInfo';

import {
  getOrderedContacts,
  getContactsOrder
} from '../../../../selectors/company';
import { contacts as contactsLoadingSelector } from '../../../../selectors/loading';
import { ContactRow } from '../ContactRow';
import { ContactsTableHead } from '../ContactsTableHead';
import { ContactPersonSlideout } from '../ContactPersonSlideout';
import { ContactDeletionModal } from '../ContactDeletionModal';
import { loadContacts } from '../../../../actions/api';

export function ContactsData({ updateFooter }: IContactsData) {
  const { t } = useTranslation();
  const { isMobile } = useMenuDimensions();
  const dispatch = useDispatch();
  const companyId = useSelector(getCustomerNumber);
  const contacts = useSelector(getOrderedContacts);
  const isLoading = useSelector(contactsLoadingSelector);
  const { sortingBy, ascending } = useSelector(getContactsOrder);
  const showSlideout = useSelector(getShowSlideout);
  const closedModalState = {
    showModal: false,
    modalError: ''
  };
  const [showModalByError, setShowModalByError] = useState(closedModalState);
  const [contactToDelete, setContactToDelete] = useState<IContact>();
  const form = useForm({
    mode: 'onChange',
    defaultValues: {
      userName: '',
      firstName: '',
      lastName: '',
      emailAddress: '',
      phoneNumber: ''
    }
  });

  const handleOpenSlideout = () => {
    dispatch(openSlideout());
    dispatch(enableSlideoutEditMode());
  };

  const handleCloseSlideout = () => {
    form.reset();
    dispatch(closeSlideout());
  };

  const handleCloseModal = () => {
    form.reset();
    setShowModalByError(closedModalState);
  };

  const onDeleteError = error => {
    setShowModalByError({
      showModal: true,
      modalError: error?.content?.errors[0]?.message || 'general_error'
    });
    dispatch(loadContacts());
  };

  const handleDeleteContact = contact => {
    dispatch(closeSlideout());
    setContactToDelete(contact);
    setShowModalByError({
      showModal: true,
      modalError: ''
    });
  };

  const deleteContact = () => {
    handleCloseModal();

    const onSuccess = () => {
      dispatch(
        showNotification(
          { message: 'messages.company_info_updated' },
          'success'
        )
      );
      dispatch(loadContacts());
    };

    const url = new URL(contactsApiUrl);

    if (contactToDelete?.active) {
      url.searchParams.set('personIdToDelete', contactToDelete?.personId || '');
      url.searchParams.set('companyId', companyId);
    } else {
      url.searchParams.set(
        'dataContactIdToDelete',
        contactToDelete?.dataContactId || ''
      );
    }

    return Delete(url.toString(), null).then(onSuccess).catch(onDeleteError);
  };

  useEffect(() => {
    dispatch(getUserInformation());
    dispatch(loadContacts());
    dispatch(closeSlideout());
    dispatch(disableSlideoutEditMode());
    dispatch(disableEditMode());
    if (isMobile)
      updateFooter({
        submitText: t('company_info.add_contact'),
        onSubmit: handleOpenSlideout,
        submitButtonMode: ButtonMode.Secondary
      });
  }, []);

  const orderBy = sortingBy => {
    dispatch(setContactsOrder(sortingBy, !ascending));
  };

  const areData = contacts && contacts.length > 0;

  return (
    <Box mt={4}>
      <FormProvider {...form}>
        <ErrorPanel failedActionName={ApiConstants.ADD_NEW_CONTACT} />
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <Table size="small">
            <ContactsTableHead
              orderBy={orderBy}
              areData={areData}
              sortingBy={sortingBy}
              ascending={ascending}
            />
            <Table.Body>
              {contacts.map(row => (
                <ContactRow
                  key={`${row.userName}-${row.dataContactId}`}
                  row={row}
                  handleDeleteContact={handleDeleteContact}
                />
              ))}
            </Table.Body>
          </Table>
        )}
        <ContactPersonSlideout
          isSlideoutExpanded={showSlideout}
          closeSlideout={handleCloseSlideout}
          handleDeleteContact={handleDeleteContact}
        />
      </FormProvider>
      {contactToDelete && (
        <ContactDeletionModal
          showModalByError={showModalByError}
          contactToDelete={contactToDelete}
          deleteContact={deleteContact}
          handleCloseModal={handleCloseModal}
        />
      )}
    </Box>
  );
}
