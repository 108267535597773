import { format } from 'date-fns';

export const formatDate = (date: string) => {
  const day = new Date(date);
  return format(day, 'yyyy-MM-dd');
};

export const isNew = (dateString: string): boolean => {
  const transactionDate = new Date(dateString);
  const now = new Date();
  const hoursDiff =
    (now.getTime() - transactionDate.getTime()) / (1000 * 60 * 60);
  return hoursDiff <= 24 * 7;
};
