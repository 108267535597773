import React from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { TextField } from 'edenred-ui';

import { companySizes } from '@epi-constants/companySizes';
import { createRules, FormFields } from '@epi-forms/helpers';
import { required as requiredValidation } from '@epi-helpers/formHelpers/validators';
import { NumberOfEmployeesFieldProps } from '@epi-models/forms/Controls';

export function NumberOfEmployeesField({
  readOnly = false,
  disabled = false,
  required = true
}: NumberOfEmployeesFieldProps) {
  const name = FormFields.NumberOfEmployees;
  const { t } = useTranslation();
  const { control } = useFormContext();
  const validators = [requiredValidation];
  const rules = createRules(validators);

  const {
    field: { ref, value, onChange: setValue },
    fieldState: { error }
  } = useController({
    name,
    control,
    rules
  });

  return (
    <TextField
      label={t('controls.number_of_employees_label')}
      select
      required={required}
      value={value}
      readOnly={readOnly}
      id={name}
      error={!!error}
      helperText={error?.message}
      inputRef={ref}
      onChange={setValue}
      disabled={disabled}
      sx={{ '& .MuiSelect-select:focus': { backgroundColor: 'transparent' } }}
    >
      {companySizes.map(option => (
        <TextField.Item key={option.value} value={option.value}>
          {option.label}
        </TextField.Item>
      ))}
    </TextField>
  );
}
