import React, { useState } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router';

import { useMenuDimensions } from '@epi-shared/hooks';
import { getIsAuthenticated } from '@epi-selectors/login';
import { isLocationWithMenu } from '@epi-helpers/Menu/MenuLocationHelper';
import {
  NavbarDropdown,
  NavbarMobileMenuButton,
  NavbarHeading,
  NavbarDropdownButton
} from './components';

const NavbarContainer = styled.div`
  display: flex;
  align-items: center;
  height: ${({ isMobile }) => (isMobile ? '64px' : '80px')};
  border-bottom: 1px solid ${({ theme }) => theme.altGray2};
  justify-content: space-between;
`;

interface NavbarProps extends RouteComponentProps {
  isAuthenticated: boolean;
  sideMenuOpen: boolean;
  toggleSideMenu: (open: boolean) => void;
}

function Navbar({
  isAuthenticated,
  location,
  sideMenuOpen,
  toggleSideMenu
}: NavbarProps) {
  const [dropdownBtnEl, setDropdownBtnEl] = useState(null);

  const { isMobile } = useMenuDimensions();
  const hasMenuInCurrentLocation = isLocationWithMenu(location);

  const onClickMobileMenuBtn = () => toggleSideMenu(!sideMenuOpen);
  const openDropdown = event => setDropdownBtnEl(event.currentTarget);
  const closeDropdown = () => setDropdownBtnEl(null);

  const showMobileMenuButton =
    isMobile && hasMenuInCurrentLocation && isAuthenticated;

  return (
    <NavbarContainer isMobile={isMobile}>
      {showMobileMenuButton && (
        <NavbarMobileMenuButton
          isMenuOpen={sideMenuOpen}
          onClick={onClickMobileMenuBtn}
        />
      )}
      <NavbarHeading />
      <NavbarDropdownButton
        isDropdownOpen={!!dropdownBtnEl}
        onClick={openDropdown}
      />
      <NavbarDropdown anchorEl={dropdownBtnEl} onClose={closeDropdown} />
    </NavbarContainer>
  );
}

const mapStateToProps = state => ({
  isAuthenticated: getIsAuthenticated(state)
});

const ConnectedNavbar = connect(mapStateToProps)(withRouter(Navbar));

export { ConnectedNavbar as Navbar };
