import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setGoToPageLink, stopOrderProcess } from '@epi-actions/beneficiary';
import { goToPage } from '@epi-actions/navigation';
import { selectGoToPageLink } from '@epi-selectors/benefitGroupSettings';

export const useOrderSummaryCancel = () => {
  const [isOpenLeave, setIsOpenLeave] = useState(false);

  const goToPageLink = useSelector(selectGoToPageLink);
  const dispatch = useDispatch();

  const handleCloseLeaveModal = () => {
    dispatch(setGoToPageLink(''));
    setIsOpenLeave(false);
  };

  const handleGoTo = () => {
    dispatch(setGoToPageLink(''));
    dispatch(stopOrderProcess());
    dispatch(goToPage(goToPageLink));
  };

  useEffect(() => {
    if (goToPageLink) {
      setIsOpenLeave(true);
    }
  }, [goToPageLink]);
  return {
    handleCloseLeaveModal,
    handleGoTo,
    isOpenLeave
  };
};
