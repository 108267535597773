import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Image,
  Container,
  Row,
  Col,
  FormGroup,
  FormLabel
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import memoize from 'memoize-one';
import {
  Notification,
  NotificationType,
  IconTooltip,
  InfoIcon
} from 'edenred-ui';
import { useFormContext } from 'react-hook-form';

import { VoucherQuantityField } from '@epi-forms/controls/VoucherQuantityField';
import { FaceValueSelect } from '@epi-forms/controls/FaceValueSelect';
import { FormFields } from '@epi-forms/helpers';
import {
  IFilteredVouchers,
  VoucherQuantityProps
} from '@epi-models/forms/VoucherQuantity';
import { IVouchers } from '@epi-models/containers/OrderAmount';

import {
  maxValue,
  maxVouchersValue
} from '../../helpers/formHelpers/validators';
import { formatCurrency } from '../../helpers/numeral';
import { VoucherTypes } from '../../constants/voucherTypes';
import { BenefitCalculator } from '../BenefitCalculator/BenefitCalculator';
import voucherLunchImage from '../../../assets/images/LunchVoucher.png';
import voucherRecreationalImage from '../../../assets/images/RecreationalVoucher.png';

import './VoucherQuantity.scss';

function VoucherQuantity({
  voucherMinimumQuantity: minQtyValue,
  vouchers,
  voucherTypeKey,
  locale,
  useCalculator = false,
  newFaceValueMessage,
  maxOrderTotalAmount
}: VoucherQuantityProps) {
  const { t } = useTranslation();
  const [showAdvice, setShowAdvice] = useState(false);
  const [isRounded, setIsRounded] = useState(false);
  const [filteredVouchers, setFilteredVouchers] = useState<IFilteredVouchers[]>(
    []
  );
  const [maxValueTotalAmountValidator, setMaxValueTotalAmountValidator] =
    useState(() => maxValue(50000));

  const { watch, setValue, trigger } = useFormContext();
  const quantityValue = watch(FormFields.VoucherQuantity);
  const voucherIdValue = watch(FormFields.VoucherId);
  const voucherWorthValue = watch(FormFields.VoucherWorth);

  const calculateVoucherValues = memoize((vouchers: IVouchers[]) => {
    if (!vouchers || vouchers.length === 0) {
      return;
    }

    const getFilteredVouchers = vouchers.map(item => ({
      ...item,
      value: item.id,
      faceValue: item.value,
      label: `${formatCurrency(item.value)}`
    }));

    setFilteredVouchers(getFilteredVouchers);

    if (!voucherIdValue)
      setValue(FormFields.VoucherId, getFilteredVouchers[0].value);
  });

  const getOrderSum = () => {
    return quantityValue * voucherWorthValue;
  };

  const setVoucherRoundedQuantity = value => {
    const valueAsNumber = Number(value);
    const roundedValue = Number(roundVoucherQty(value));
    setIsRounded(roundedValue !== valueAsNumber);
    if (roundedValue !== valueAsNumber || quantityValue !== valueAsNumber) {
      setValue(FormFields.VoucherQuantity, roundedValue);
    }
    trigger();
  };

  const setVoucherQuantity = voucherQty => {
    setShowAdvice(true);
    setVoucherRoundedQuantity(voucherQty);
  };

  const handleQuantityOnFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    setShowAdvice(false);
    setIsRounded(false);
    e.preventDefault();
  };

  const maxAllowedQuantity = () => {
    if (
      !quantityValue ||
      !voucherIdValue ||
      !vouchers ||
      vouchers.length === 0
    ) {
      return;
    }
    const maxVoucherQty =
      Math.floor(maxOrderTotalAmount / voucherWorthValue / 10) * 10;

    setMaxValueTotalAmountValidator(() => maxVouchersValue(maxVoucherQty));
  };

  const roundVoucherQty = value => {
    const maxQtyValue = 50000;

    if (isNaN(value)) {
      return minQtyValue;
    }
    if (value >= maxQtyValue) {
      return maxQtyValue;
    }
    if (value <= minQtyValue) {
      return minQtyValue;
    }
    if (value % 10 !== 0) {
      return Math.ceil(value / 10) * 10;
    }
    return value;
  };

  const handleQuantityOnBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    setShowAdvice(false);
    setVoucherRoundedQuantity(e.target.value);
    e.preventDefault();
  };

  const closeRoundedNotification = () => {
    setIsRounded(false);
  };

  const closeAdviceNotification = () => {
    setShowAdvice(false);
  };

  useEffect(() => {
    calculateVoucherValues(vouchers);
    maxAllowedQuantity();
  }, []);

  useEffect(() => {
    maxAllowedQuantity();
    if (quantityValue && voucherIdValue) trigger();
  }, [quantityValue, voucherIdValue]);

  useEffect(() => {
    calculateVoucherValues(vouchers);

    if (vouchers.length > 0 && !voucherIdValue) {
      setValue(FormFields.VoucherId, vouchers[0].id);
    }
  }, [vouchers]);

  useEffect(() => {
    setValue(
      FormFields.VoucherWorth,
      (vouchers || []).find(v => v.id === voucherIdValue)?.value
    );
  }, [vouchers, voucherIdValue]);

  const sumVouchers = getOrderSum();
  const tooltipText =
    t(
      `forms.benefit_calculator.info_tooltip[${voucherTypeKey.toLowerCase()}]`
    ) || '';
  return (
    <div className="VoucherQuantity">
      <Container fluid>
        {(useCalculator && (
          <Row>
            <BenefitCalculator
              tooltip={tooltipText}
              locale={locale}
              handleSetQuantityValue={setVoucherQuantity}
              maxOrderTotalAmount={maxOrderTotalAmount}
            />
          </Row>
        )) || (
          <Row className="flex-row-reverse">
            <IconTooltip title={tooltipText}>
              <InfoIcon />
            </IconTooltip>
          </Row>
        )}
        <Row className="container-fluid">
          <Col xs={12} md={3} className="content-paddings">
            <FormGroup>
              <VoucherQuantityField
                label={t(
                  `forms.voucher_quantity.voucher_name[${voucherTypeKey.toLowerCase()}]`
                )}
                onFocus={handleQuantityOnFocus}
                onBlur={handleQuantityOnBlur}
                validate={[maxValueTotalAmountValidator]}
              />
            </FormGroup>
          </Col>
          <Col xs={12} md={3} className="content-paddings">
            <FormGroup>
              <FaceValueSelect filteredVouchers={filteredVouchers} />
            </FormGroup>
          </Col>
          <Col className="d-none d-md-block" md={3}>
            <Image
              className="vertical-center voucher-image"
              src={
                VoucherTypes[voucherTypeKey] !== VoucherTypes.Lunch
                  ? voucherRecreationalImage
                  : voucherLunchImage
              }
              fluid
            />
          </Col>
          <Col className="d-none d-md-block" md={3}>
            <FormGroup>
              <FormLabel />
              <p className="amount">
                <span className="header3 small text-right pull-right">
                  = {formatCurrency(sumVouchers)}
                </span>
              </p>
            </FormGroup>
          </Col>
        </Row>
        {isRounded && (
          <Row>
            <Col className="pl-20">
              <Notification
                text={t('forms.voucher_quantity.rounded_value')}
                type={NotificationType.Error}
                onClose={closeRoundedNotification}
              />
            </Col>
          </Row>
        )}
        {newFaceValueMessage && (
          <Row>
            <Col className="pl-20">
              <Notification
                text={newFaceValueMessage}
                type={NotificationType.Important}
              />
            </Col>
          </Row>
        )}
        {showAdvice && (
          <Row>
            <Col className="pl-20">
              <Notification
                text={t('forms.voucher_quantity.adviced_value')}
                type={NotificationType.Info}
                onClose={closeAdviceNotification}
              />
            </Col>
          </Row>
        )}
        <Row className="container-fluid">
          <Col xs={12} className="amount container-fluid d-md-none">
            <span className="text-right pull-right">
              = {formatCurrency(sumVouchers)}
            </span>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

const VoucherQuantityWithI18n = connect()(VoucherQuantity);

export { VoucherQuantityWithI18n as VoucherQuantity };
