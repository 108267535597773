import React from 'react';
import { Button } from 'react-bootstrap';
import { SortDownIcon, SortUpIcon } from 'edenred-ui';

import {
  SortIndicatorProps,
  SortingButtonProps
} from '@epi-models/components/SortingButtonComponent';

function SortIndicator({ descending }: SortIndicatorProps) {
  return descending ? <SortDownIcon /> : <SortUpIcon />;
}

export function SortingButton({
  text = '',
  sortBy,
  sortedBy,
  sortDirection,
  onSortingChange,
  ...otherProps
}: SortingButtonProps) {
  return (
    <Button
      variant="link"
      onClick={() => {
        onSortingChange(sortBy, !sortDirection);
      }}
      {...otherProps}
    >
      {text}
      {sortBy === sortedBy ? (
        <SortIndicator descending={sortDirection} />
      ) : null}
    </Button>
  );
}
