export function getUnverifiedErrorName(fieldName: string) {
  return `${fieldName}_UNVERIFIED_ASYNC_VALIDATOR`;
}

export function getProcessingErrorName(fieldName: string) {
  return `${fieldName}_PROCESSING_ASYNC_VALIDATOR`;
}

export function getInvalidErrorName(fieldName: string) {
  return `${fieldName}_INVALID_ASYNC_VALIDATOR`;
}
