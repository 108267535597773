import React from 'react';
import { Button } from 'react-bootstrap';
import { BackIcon } from 'edenred-ui';

import { BackButtonProps } from '@epi-models/components/BackButton';

import history from '../../routes/history';

import './BackButton.scss';

export const BackButton = ({
  onClick = () => history.goBack()
}: BackButtonProps) => (
  <Button
    id="back-btn"
    variant="link"
    className="BackButton btn-back p-0 mr-3"
    onClick={onClick}
  >
    <BackIcon />
  </Button>
);
