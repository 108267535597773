import { useEffect, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { get, isEqual } from 'lodash';
import {
  getProcessingErrorName,
  getInvalidErrorName
} from './asyncValidatorErrorNames';
import { getAsyncValidatorCache } from './asyncValidatorCache';

export function useAsyncValidator({
  fieldName,
  validator,
  validatorName,
  getValueFn,
  canValidateFn
}: {
  fieldName: string;
  validator: (abortController: AbortController) => Promise<void>;
  validatorName: string;
  getValueFn?: () => string | { [key: string]: any };
  canValidateFn?: () => boolean;
}) {
  const {
    formState: { errors },
    trigger,
    getValues,
    setError,
    clearErrors
  } = useFormContext();

  function defaultGetValue() {
    return getValues(fieldName);
  }

  const getValue = getValueFn || defaultGetValue;

  function defaultCanValidate() {
    return !!getValue() && !get(errors, fieldName);
  }

  const canValidate = canValidateFn || defaultCanValidate;

  const cache = getAsyncValidatorCache(validatorName);
  const lastProcessedValue = useRef({});
  const abortControllerRef = useRef(new AbortController());

  const processingName = getProcessingErrorName(fieldName);
  const invalidName = getInvalidErrorName(fieldName);

  function cancelPendingRequest() {
    abortControllerRef.current.abort();
    abortControllerRef.current = new AbortController();

    clearErrors(invalidName);
    clearErrors(processingName);
    trigger(processingName);
  }

  useEffect(() => cancelPendingRequest, []);

  return async () => {
    const value = getValue();

    if (isEqual(lastProcessedValue.current, value)) {
      return;
    }

    lastProcessedValue.current = value;

    cancelPendingRequest();

    const abortController = abortControllerRef.current;

    try {
      if (canValidate()) {
        if (cache.has(value)) {
          return;
        }

        setError(processingName, {});

        await validator(abortController);

        clearErrors(processingName);
        trigger(processingName);

        cache.add(value);
      }
    } catch (e) {
      if (!abortController.signal.aborted) {
        clearErrors(processingName);
        setError(invalidName, {});
        trigger(processingName);
      }
    }
  };
}
