import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col } from 'react-bootstrap';

import { IErrorResponse } from '@epi-models/containers/ErrorData';

import { ErrorDataItem } from './ErrorDataItem';
import './ErrorDetails.scss';

export const ErrorDetails = ({ error }: IErrorResponse) => {
  const { t } = useTranslation();

  return (
    <div>
      <Col className="error-details xs-12">
        <div className="error-details-row-no-padding">
          <span>{t('components.error_popup.details')}:</span>
        </div>
        {error.content?.errors.map(ed => (
          <ErrorDataItem
            errorData={ed}
            key={ed.key}
            className="error-details-row-no-padding"
          />
        ))}
      </Col>
    </div>
  );
};
