import React from 'react';
import config from '@epi-config';
import { IBenefitType } from '@epi-models/containers/ManageBenefits';
import {
  LunchIcon,
  RecreationalIcon,
  TransportIcon,
  MassageIcon,
  WellbeingIcon
} from 'edenred-ui';

export const BenefitType: IBenefitType = {
  Lunch: 'lunch',
  Transport: 'transport',
  Massage: 'massage',
  Wellbeing: 'wellbeing',
  ...(config.enableVirikeBenefit && { Recreational: 'recreational' })
};

export const LUNCH = 'Lunch';
export const RECREATIONAL = 'Recreational';

export const ACTIVE = 'Active';
export const INACTIVE = 'Contracted';
export const READY_TO_ORDER = 'NotContracted';
export const ORDERED = 'Ordered';
export const ON_HOLD = 'Onhold';

export const getBenefitIcon = (type: string, color?: string) => {
  switch (type.toLowerCase()) {
    case BenefitType.Lunch:
      return <LunchIcon color={color} />;
    case BenefitType.Recreational:
      return <RecreationalIcon color={color} />;
    case BenefitType.Transport:
      return <TransportIcon color={color} />;
    case BenefitType.Massage:
      return <MassageIcon color={color} />;
    case BenefitType.Wellbeing:
      return <WellbeingIcon color={color} />;
    default:
      return;
  }
};

export const FundsLoadingTypes = {
  TopUpRequest: 'TopUpRequest',
  DirectLoad: 'DirectLoad'
};
