import React from 'react';
import {
  SlideoutPanelComponent as Slideout,
  Box,
  ButtonMode,
  Button,
  ButtonSize,
  Tabs
} from 'edenred-ui';
import { useTranslation } from 'react-i18next';
import { FormProvider } from 'react-hook-form';

import { IBeneficiaryDetailSlideout } from '@epi-models/pages/Beneficiaries';
import { CloseDetailDialog } from '@epi-shared/components';

import { useBeneficiaryDetailSlideout, useBeneficiaryTab } from './hooks';

export const BeneficiaryDetailSlideout = (
  props: IBeneficiaryDetailSlideout
) => {
  const { expanded, editingMode, isProcessing } = props;
  const {
    confirmCloseWithoutSaving,
    confirmSaving,
    handleClose,
    handleSubmitFormExecute,
    form,
    showCloseConfirmation,
    handleCloseConfirmation,
    disableSubmit
  } = useBeneficiaryDetailSlideout({ ...props });
  const { tabs } = useBeneficiaryTab();
  const { t } = useTranslation();

  return (
    <Slideout
      title={
        editingMode
          ? t('containers.beneficiaries.detail.edit_title')
          : t('containers.beneficiaries.detail.title')
      }
      expanded={expanded}
      onClose={handleClose}
      closeButtonId="close-beneficiary-slideout"
      actions={
        editingMode && (
          <Box display="flex" justifyContent="flex-end" mr={5} gap={5}>
            <Button
              id="beneficiary-detail-cancel-btn"
              onClick={handleClose}
              mode={ButtonMode.Link}
            >
              {t('containers.beneficiaries.detail.cancel')}
            </Button>
            <Button
              id="beneficiary-detail-submit-btn"
              onClick={handleSubmitFormExecute}
              mode={ButtonMode.Primary}
              size={ButtonSize.Large}
              disabled={disableSubmit() || isProcessing}
              minWidth={130}
              isProcessing={isProcessing}
            >
              {t('containers.beneficiaries.detail.save')}
            </Button>
          </Box>
        )
      }
    >
      <FormProvider {...form}>
        <Tabs tabs={tabs} defaultTabName={'Personal Data'} />
      </FormProvider>
      <CloseDetailDialog
        isOpen={showCloseConfirmation}
        title={t('containers.beneficiaries.close_detail_dialog.title')}
        handleCloseModal={handleCloseConfirmation}
        primaryAction={confirmCloseWithoutSaving}
        secondaryAction={confirmSaving}
        secondaryActionText={t(
          'containers.beneficiaries.close_detail_dialog.save'
        )}
        primaryActionText={t(
          'containers.beneficiaries.close_detail_dialog.close_without_saving'
        )}
        contentText={t('containers.beneficiaries.close_detail_dialog.content')}
      />
    </Slideout>
  );
};
