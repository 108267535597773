import React from 'react';
import { Box, Table, LoadingSpinner, TagComponent as Tag } from 'edenred-ui';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

import { currencyCommaConvert } from '@epi-helpers/numeral';
import { BalanceCorrectionsTableProps } from '@epi-models/pages/Reports';

import { TableNoContent } from '../../TableNoContent';
import { formatDate, isNew } from '../../utils';

export const BalanceCorrectionsTable = ({
  correctionReports,
  isLoading
}: BalanceCorrectionsTableProps) => {
  const { t } = useTranslation();
  const { warningColor } = useTheme();

  if (isLoading) return <LoadingSpinner />;

  return (
    <Table>
      <Table.Head>
        <Table.Row>
          <Table.Cell width="15%" id="balance-corrections-date">
            {t('containers.deposit_money.corrections_tab.date')}
          </Table.Cell>
          <Table.Cell width="28%" id="balance-corrections-reason">
            {t('containers.deposit_money.corrections_tab.reason')}
          </Table.Cell>
          <Table.Cell width="11%" id="balance-corrections-document-id">
            {t('containers.deposit_money.corrections_tab.document_id')}
          </Table.Cell>
          <Table.Cell width="15%" id="balance-corrections-executed-by">
            {t('containers.deposit_money.corrections_tab.executed_by')}
          </Table.Cell>
          <Table.Cell
            width="17%"
            align="right"
            id="balance-corrections-balance"
          >
            {t('containers.deposit_money.corrections_tab.balance')}
          </Table.Cell>
          <Table.Cell width="14%" align="right" id="balance-corrections-amount">
            {t('containers.deposit_money.corrections_tab.amount')}
          </Table.Cell>
        </Table.Row>
      </Table.Head>
      <Table.Body>
        {correctionReports.length ? (
          correctionReports.map(report => (
            <Table.Row key={report.id}>
              <Table.Cell>
                <Box display="flex" alignItems="center" lineHeight="25px">
                  {formatDate(report.transactionDateTime)}
                  {isNew(report.transactionDateTime) && (
                    <Box lineHeight="11px" ml={2}>
                      <Tag
                        label={t('containers.beneficiaries.table.new')}
                        size="s"
                      />
                    </Box>
                  )}
                </Box>
              </Table.Cell>
              <Table.Cell>
                {t(
                  `containers.deposit_money.corrections_tab.reason_type.${report.reasonType}`
                ) || report.reason}
              </Table.Cell>
              <Table.Cell>
                <Box display="flex" alignItems="center">
                  {report.documentId || '-'}
                </Box>
              </Table.Cell>
              <Table.Cell>{report.executedBy}</Table.Cell>
              <Table.Cell align="right">
                {currencyCommaConvert(report.balanceAfterCorrection)}
              </Table.Cell>
              <Table.Cell align="right">
                <Box color={report.amount < 0 ? warningColor : 'inherit'}>
                  {currencyCommaConvert(report.amount)}
                </Box>
              </Table.Cell>
            </Table.Row>
          ))
        ) : (
          <TableNoContent />
        )}
      </Table.Body>
    </Table>
  );
};
