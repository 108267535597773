import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';

import { ScrollToTopProps } from '@epi-models/components/ScrollToTop';

const ScrollToTop = ({ children, pathname }: ScrollToTopProps) => {
  useEffect(() => {
    window.scrollTo(0, 0);
    const container = document.getElementsByClassName('sidebar-content');
    Array.from(container).forEach(elt => (elt as HTMLElement).scrollTo?.(0, 0));
  }, [pathname]);

  return <>{children}</>;
};

const ScrollToTopWithRouter = withRouter(ScrollToTop);
export { ScrollToTopWithRouter as ScrollToTop };
