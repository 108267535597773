import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Box, Button } from 'edenred-ui';
import { useTheme } from 'styled-components';

import {
  UsernameField,
  PasswordField,
  KeepLoggedInCheckbox
} from '@epi-forms/controls';
import { FormFields } from '@epi-forms/helpers';
import { isEmptyObj } from '@epi-helpers/isEmptyObj';
import { routerPaths } from '@epi-constants/routerPaths';
import { getLoginAction } from '@epi-actions/login';
import { isFetching } from '@epi-selectors/login';
import { required, username } from '@epi-helpers/formHelpers/validators';
import { ApiConstants } from '@epi-constants/actions';
import { ErrorPanel } from '@epi-components/ErrorPanel/ErrorPanel';
import { useEmailConfirmation } from '@epi-shared/hooks/useEmailConfirmation';

export function LoginForm() {
  useEmailConfirmation();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const theme = useTheme();
  const isProcessingLogin = useSelector(isFetching);

  const form = useForm({
    mode: 'onChange',
    defaultValues: {
      [FormFields.Username]: '',
      [FormFields.Password]: '',
      [FormFields.KeepLoggedIn]: false
    }
  });

  const {
    formState: { errors, isValid },
    handleSubmit
  } = form;

  const isSubmitBtnDisabled =
    !isValid || !isEmptyObj(errors) || isProcessingLogin;

  const onSubmit = handleSubmit(data =>
    dispatch(
      getLoginAction(data[FormFields.Username], data[FormFields.Password], {
        rememberMe: !!data[FormFields.KeepLoggedIn],
        autoRedirect: false
      })
    )
  );

  return (
    <FormProvider {...form}>
      <form noValidate onSubmit={onSubmit}>
        <Box
          fontSize={theme.sizeExtraExtraLarge}
          fontWeight={theme.fontSemiBold}
          textAlign="center"
          px={5}
          pb={1}
        >
          {t('login.login_heading')}
        </Box>
        <Box fontSize={theme.sizeMedium} textAlign="center">
          {t('login.login_subheading')}
        </Box>
        <Box my={3}>
          <ErrorPanel failedActionName={ApiConstants.LOGIN_ACTION} />
        </Box>
        <Box>
          <UsernameField
            validators={[required, username]}
            validateAvailability={false}
            label={'containers.login.username'}
          />
        </Box>
        <Box mt={3}>
          <PasswordField
            name={FormFields.Password}
            label={t('controls.password_label')}
            validators={[required]}
          />
        </Box>
        <Box mt={2}>
          <KeepLoggedInCheckbox />
        </Box>
        <Box mt={2}>
          <Button fullWidth id="login-btn" disabled={isSubmitBtnDisabled}>
            {t('login.login_btn')}
          </Button>
        </Box>
        <Box mt={2}>
          <Box textAlign="center" fontWeight={theme.fontRegularBold}>
            <RouterLink
              to={routerPaths.resetPassword.substring(1)}
              className="link"
              id="forgotten-password_btn"
            >
              {t('login.forgot_password_link')}
            </RouterLink>
          </Box>
        </Box>
      </form>
    </FormProvider>
  );
}
