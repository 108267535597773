import React, { useEffect, useState } from 'react';
import { BenefitType, NotificationType } from 'edenred-ui';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { useToastContext } from '@epi-core/components/Toast/useToastContext';
import { fetchPost } from '@epi-helpers/FetchHelpers';
import { crossSellingOrderApiUrl } from '@epi-repositories/CrossSellingRepository';
import { stopOrderProcess } from '@epi-actions/beneficiary';

export const useOrderSummary = (benefits, getBenefits) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isError, setIsError] = useState(false);

  const { showToast, closeToast } = useToastContext();
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const onFailure = () => {
    setIsError(true);
    showToast(
      NotificationType.Error,
      t('home.benefits_tile.summary_error_text')
    );
  };
  const onSuccess = () => {
    handleOpen();
  };
  const handleClose = () => {
    getBenefits();
    setIsOpen(false);
  };
  const handleOpen = () => setIsOpen(true);
  const handleOrderBenefits = () => {
    const data: string[] = benefits.flatMap(elem =>
      elem.benefitType === BenefitType.duo
        ? [BenefitType.lunch, BenefitType.recreational]
        : elem.benefitType
    );

    fetchPost(
      crossSellingOrderApiUrl,
      { benefits: data },
      onSuccess,
      onFailure
    );
  };

  useEffect(() => {
    return () => {
      dispatch(stopOrderProcess());
    };
  }, []);

  useEffect(() => {
    if (isError) {
      const timeout = setTimeout(() => {
        closeToast();
        setIsError(false);
      }, 5000);

      return () => clearTimeout(timeout);
    }
  }, [isError]);

  return {
    handleClose,
    handleOrderBenefits,
    isOpen
  };
};
