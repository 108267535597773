import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useTranslation } from 'react-i18next';
import { snakeCase } from 'lodash';
import { Notification, NotificationType } from 'edenred-ui';

import { ErrorSeverity } from '@epi-constants/errorSeverity';
import { ErrorPanelProps } from '@epi-models/components/ErrorPanel';

import { getApiErrors } from '../../selectors/api';
import * as apiActions from '../../actions/api';

function ErrorPanel({
  failedActionName,
  dismissApiErrors,
  errorData: { actionName, isError, errors, message }
}: ErrorPanelProps) {
  const { t } = useTranslation();
  const isNotNullErrorValue = !!errors && errors.find(v => v.value);
  const displayPanel = `${failedActionName}_FAILURE` === actionName && isError;
  const isOnlyWarnings =
    errors && !errors.find(e => e.severity !== ErrorSeverity.Warning);
  const translate = value => {
    const translation = t(`api_errors.${snakeCase(value)}`);
    return translation === '' ? undefined : translation || value;
  };
  const getErrorKey = error =>
    (Array.isArray(error.value) &&
      error.value.reduce(
        (acc, val) => `${acc}${acc ? ', ' : ''}${translate(val)}`,
        ''
      )) ||
    translate(error.value);

  return (
    <>
      {displayPanel &&
        (isNotNullErrorValue && errors ? (
          errors.map(error => (
            <Notification
              key={error.key}
              type={
                isOnlyWarnings ? NotificationType.Info : NotificationType.Error
              }
              text={getErrorKey(error)}
              onClose={dismissApiErrors}
            />
          ))
        ) : (
          <Notification
            type={isError ? NotificationType.Error : NotificationType.Info}
            text={t(message) || t('messages.error')}
            onClose={dismissApiErrors}
          />
        ))}
    </>
  );
}

const mapStateToProps = state => ({
  errorData: getApiErrors(state)
});

const mapDispatchToProps = dispatch => bindActionCreators(apiActions, dispatch);

const ConnectedErrorPanel = connect(
  mapStateToProps,
  mapDispatchToProps
)(ErrorPanel);

export { ConnectedErrorPanel as ErrorPanel };
