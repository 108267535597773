import { get } from 'lodash';
import history from '../../routes/history';
import { REHYDRATE } from 'redux-persist/constants';
import { createSlice } from '@reduxjs/toolkit';

import {
  findLanguageById,
  getDefaultLanguage
} from '@epi-helpers/LanguageHelpers';
import i18n from '@epi-helpers/I18n';
import { removeLocale, getLocale } from '@epi-helpers/UrlHelpers';
import { IsExternalUrl } from '@epi-sagas/navigation';
import { goToPage } from '@epi-actions/navigation';

const defaultLanguage = getDefaultLanguage();
const initialState = {
  language: defaultLanguage
};

export const changeUrlIfNedded = () => dispatch => {
  const {
    location: { pathname, search }
  } = history;
  const url = removeLocale(pathname + search);

  dispatch(goToPage(IsExternalUrl(url) ? `/${search}` : url));
};

export const applicationChangeLanguage = () => dispatch => {
  const {
    location: { pathname }
  } = history;
  const locale = getLocale(pathname);
  const language = locale ? findLanguageById(locale) : getDefaultLanguage();

  i18n.changeLanguage(locale);
  dispatch(applicationAction.changeLanguageState(language));
  dispatch(changeUrlIfNedded());
};

export const switchLanguage = payload => dispatch => {
  const newLanguage = findLanguageById(payload);
  i18n.changeLanguage(payload);

  dispatch(applicationAction.changeLanguageState(newLanguage));
  dispatch(changeUrlIfNedded());
};

const applicationSlice = createSlice({
  name: 'application',
  initialState,
  reducers: {
    changeLanguageState: (state, { payload }) => {
      state.language = payload;
    }
  },
  extraReducers: builder => {
    builder.addCase(REHYDRATE, (state, action) => {
      if (action.payload?.application) {
        const newState = {
          ...action.payload.application,
          rehydrated: true
        };

        const languageId = get(action, 'payload.application.language.id');
        if (languageId) {
          newState.language = { id: languageId };
        }

        return newState;
      }
      return state;
    });
  }
});

export const languageRehydrationMiddleware = store => next => action => {
  const result = next(action);
  if (action.type === REHYDRATE) {
    store.dispatch(applicationChangeLanguage());
    return result;
  } else if (action.payload?.application?.language?.id) {
    store.dispatch(switchLanguage(action.payload.application.language.id));
    return result;
  }
};

export const { reducer, actions: applicationAction } = applicationSlice;
