import React from 'react';
import styled, { css } from 'styled-components';
import { connect, useDispatch } from 'react-redux';
import { Box } from 'edenred-ui';

import { availableLanguages } from '@epi-constants/languages';
import { getApplicationLanguageId } from '@epi-store/application/application.selectors';
import { INavbarLanguagesList } from '@epi-models/core/Navbar';

import { NavbarLanguageLabel } from '../NavbarLanguageLabel';
import { switchLanguage } from '@epi-store/application/application.slice';

const LanguageButton = styled.button.attrs({ type: 'button' })`
  display: flex;
  align-items: center;
  background: none;
  border: none;
  width: 100%;
  padding: 12px 20px;
  margin: 2px 0;
  font-size: ${({ theme }) => theme.sizeExtraSmall};

  &:hover {
    background: ${({ theme }) => theme.primaryBackColorLight10};
  }

  ${({ selected }) =>
    selected &&
    css`
      border-left: 2px solid ${({ theme }) => theme.warningColor};
      background: ${({ theme }) => theme.primaryBackColorLight10};
    `}
`;

function NavbarLanguagesList({
  selectedLanguageId,
  onLanguageChanged
}: INavbarLanguagesList) {
  const dispatch = useDispatch();
  const changeLanguage = languageId => {
    dispatch(switchLanguage(languageId));
    onLanguageChanged();
  };

  return (
    <Box bgcolor="white" px={2} py={1}>
      {availableLanguages.map(languageOption => (
        <LanguageButton
          key={languageOption.id}
          id={`select-language-${languageOption.id}`}
          selected={languageOption.id === selectedLanguageId}
          onClick={() => changeLanguage(languageOption.id)}
        >
          <NavbarLanguageLabel languageId={languageOption.id} />
        </LanguageButton>
      ))}
    </Box>
  );
}

const mapStateToProps = state => ({
  selectedLanguageId: getApplicationLanguageId(state)
});

const ConnectedNavbarLanguagesList =
  connect(mapStateToProps)(NavbarLanguagesList);

export { ConnectedNavbarLanguagesList as NavbarLanguagesList };
