import React from 'react';
import { Modal, Box, Button } from 'edenred-ui';
import { useTheme } from 'styled-components';
import { useTranslation, Trans } from 'react-i18next';

import { IOrderSummarySuccess } from '@epi-models/pages/Home';

export const OrderSummarySuccess = ({
  isOpen,
  handleClose
}: IOrderSummarySuccess) => {
  const { t } = useTranslation();
  const { fontBold, sizeMedium } = useTheme();

  return (
    <Modal open={isOpen} onClose={handleClose} id="home-order-summary-success">
      <Box width={265} fontSize={sizeMedium}>
        <Box mb={3} fontWeight={fontBold}>
          {t('home.benefits_tile.summary_button')}
        </Box>
        <Box mb={3}>
          <Trans
            defaults={t('home.benefits_tile.summary_text_modal_body')}
            components={{
              br: <br />
            }}
          />
        </Box>
        <Box display="flex" justifyContent="center">
          <Button
            onClick={handleClose}
            id="home-order-summary-success-close-button"
          >
            {t('components.error_popup.close_button')}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
