import React, { useEffect } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useForm, FormProvider } from 'react-hook-form';
import { isEmpty } from 'lodash';

import {
  countries as countriesLoadingSelector,
  reorderData as reorderDataLoadingSelector,
  createVoucherReorder as createVoucherReorderLoadingSelector,
  disablePayment as disablePaymentLoadingSelector
} from '@epi-selectors/loading';
import {
  getVoucherTypeKey,
  isError as isErrorSelector,
  getVoucherReorder
} from '@epi-selectors/voucherReorder';
import { orderSummary as orderSummarySelector } from '@epi-selectors/voucherOrder';
import { getApplicationLocale } from '@epi-store/application/application.selectors';
import { getUserCompany } from '@epi-selectors/api';
import { getRecaptchaToken } from '@epi-helpers/ReCaptchaHelper';
import { FormFields } from '@epi-forms/helpers';
import { getOrderData } from '@epi-store/registeredVoucherOrder/registeredVoucherOrder.selectors';
import { registeredVoucherOrderAction } from '@epi-store/registeredVoucherOrder/registeredVoucherOrder.slice';
import { IVoucherReOrder } from '@epi-models/containers/VoucherReOrder';

import { getReCaptchaSiteKey } from '../../selectors/settings';
import { OrderType } from '../../constants/orderType';
import { routerPaths } from '../../constants/routerPaths';
import { replaceParams } from '../../helpers/UrlHelpers';
import { FormConstants as forms } from '../../constants/forms';
import { ApiConstants } from '../../constants/actions';
import { PaymentMethods } from '../../constants/paymentMethods';
import { scrollToInvalidField } from '../../helpers/DocumentHelper';
import { ErrorPanel } from '../../components/ErrorPanel/ErrorPanel';
import { DeliveryAndPayment } from '../../components/DeliveryAndPayment/DeliveryAndPayment';
import { NavigationHeader } from '../../components/NavigationHeader/NavigationHeader';
import { OrderButton } from '../../components/OrderButton/OrderButton';
import { VoucherTypes } from '../../constants/voucherTypes';
import * as voucherReorderActions from '../../actions/voucherReorder';
import * as apiActions from '../../actions/api';

import { useVoucherOrder } from './hooks/useVoucherOrder';
import './VoucherOrder.scss';

function VoucherReOrder({
  loadReorderData,
  voucherTypeKey,
  isLoadingReorderData,
  isCreatingVoucherReorder,
  userCompany,
  apiPaymentMethods,
  disablePayment = false,
  locale,
  reCaptchaSiteKey,
  createVoucherReorder,
  loadPaymentMethods
}: IVoucherReOrder) {
  const { orderId } = useParams<{ orderId: string }>();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { composeReorder } = useVoucherOrder();
  const voucherReorderDetails = useSelector(getVoucherReorder);
  const orderData = useSelector(getOrderData);
  const form = useForm({
    mode: 'onChange',
    defaultValues: {
      [FormFields.PaymentMethod]: '',
      [FormFields.PaymentMethodCode]: '',
      [FormFields.AcceptTerms]: false
    }
  });
  const {
    formState: { isValid, errors },
    handleSubmit,
    trigger,
    getValues
  } = form;
  const paymentMethodValue = getValues(FormFields.PaymentMethod);
  const voucherWorthValue = voucherReorderDetails.faceValue;
  const voucherQuantityValue = voucherReorderDetails.totalQuantity;
  const orderSummary = useSelector(
    orderSummarySelector(
      paymentMethodValue,
      voucherQuantityValue,
      voucherWorthValue
    )
  );
  const handleLoadingPaymentMethods = () => {
    if (voucherReorderDetails?.totalAmount) {
      loadPaymentMethods(
        OrderType.voucher,
        voucherReorderDetails?.totalAmount,
        locale
      );
    }
  };

  const onSubmit = async values => {
    trigger();

    if (!isValid) {
      return;
    }

    const reCaptchaToken = await getRecaptchaToken(
      reCaptchaSiteKey,
      forms.REORDER_FORM
    );
    const payload = composeReorder({ ...orderData, ...values, reCaptchaToken });
    createVoucherReorder(payload);
  };

  const submitExternalPayment = async () => {
    onSubmit(getValues());
  };

  const isPaidOnline = [
    PaymentMethods.CreditCard,
    PaymentMethods.Online
  ].includes(paymentMethodValue);

  useEffect(() => {
    handleLoadingPaymentMethods();
    loadReorderData(orderId);
  }, []);

  useEffect(() => {
    dispatch(
      registeredVoucherOrderAction.addOrderInformation(voucherReorderDetails)
    );
  }, [voucherReorderDetails]);

  return (
    <NavigationHeader title={t('containers.voucher_reorder.title')}>
      <div className="VoucherOrder reorder">
        <FormProvider {...form}>
          <form noValidate onSubmit={handleSubmit(onSubmit)}>
            <DeliveryAndPayment
              voucherTypeKey={voucherTypeKey}
              editDeliveryPage={replaceParams(
                routerPaths.voucherReorderEditDelivery,
                {
                  voucherType: VoucherTypes[voucherTypeKey],
                  orderId
                }
              )}
              editCompanyPage={replaceParams(
                routerPaths.voucherReorderEditCompany,
                {
                  voucherType: VoucherTypes[voucherTypeKey],
                  orderId
                }
              )}
              isLoading={isLoadingReorderData}
              orderSummary={orderSummary}
              apiPaymentMethods={apiPaymentMethods}
              onClickPaymentMethod={submitExternalPayment}
              userCompany={userCompany}
              paymentMethod={paymentMethodValue.toString()}
              disabled={disablePayment}
              voucherReorderDetails={orderData}
            />
            <ErrorPanel failedActionName={ApiConstants.CREATE_VOUCHER_ORDER} />
            {!isPaidOnline && (
              <OrderButton
                disabled={!isValid || !isEmpty(errors)}
                currentPage={1}
                totalPages={1}
                isLoading={isCreatingVoucherReorder}
                onClick={() => {
                  if (!isValid) {
                    scrollToInvalidField();
                  }
                }}
              />
            )}
          </form>
        </FormProvider>
      </div>
    </NavigationHeader>
  );
}

const mapStateToProps = state => ({
  apiPaymentMethods: state.apiPaymentMethods,
  reCaptchaSiteKey: getReCaptchaSiteKey(state),
  locale: getApplicationLocale(state),
  isLoadingCountries: countriesLoadingSelector(state),
  orderSummary: orderSummarySelector(state),
  voucherTypeKey: getVoucherTypeKey(state),
  userCompany: getUserCompany(state),
  isLoadingReorderData: reorderDataLoadingSelector(state),
  isCreatingVoucherReorder: createVoucherReorderLoadingSelector(state),
  isError: isErrorSelector(state),
  disablePayment: disablePaymentLoadingSelector(state)
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...apiActions,
      ...voucherReorderActions
    },
    dispatch
  );

const ConnectVoucherReOrder = connect(
  mapStateToProps,
  mapDispatchToProps
)(VoucherReOrder);

export { ConnectVoucherReOrder as VoucherReOrder };
