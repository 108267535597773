import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  Button,
  ButtonMode,
  ButtonSize,
  DownloadIcon,
  InProgressIcon
} from 'edenred-ui';
import styled from 'styled-components';

import { DownloadInvoiceButtonProps } from '@epi-models/components/DownloadInvoiceButton';

import { isLoadingInvoice } from '../../selectors/api';
import * as apiActions from '../../actions/api';

const ButtonContent = styled.span`
  display: flex;
  align-items: center;
`;

const ButtonIcon = styled.span`
  width: 28px;
  display: flex;
  justify-content: center;
`;

const DownloadInvoiceButton = ({
  isLoading,
  idType,
  downloadInvoice,
  id,
  children,
  mode = ButtonMode.Secondary,
  className
}: DownloadInvoiceButtonProps) => {
  const showSpinner = isLoading(idType, id);
  const params =
    idType === 'order' ? { voucherOrderId: id } : { paymentId: id };

  return (
    <div>
      <Button
        id="download-invoice-btn"
        mode={mode}
        size={ButtonSize.Medium}
        className={className}
        disabled={showSpinner}
        onClick={() => downloadInvoice(params)}
      >
        <ButtonContent>
          {showSpinner ? (
            <InProgressIcon />
          ) : (
            <ButtonIcon>
              <DownloadIcon
                color={mode === ButtonMode.Primary ? 'white' : 'inherit'}
              />
            </ButtonIcon>
          )}
          {children && ' '}
          {children}
        </ButtonContent>
      </Button>
    </div>
  );
};

const mapStateToProps = state => ({
  isLoading: (idType, id) => isLoadingInvoice(idType, id)(state)
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...apiActions }, dispatch);

const ConnectedDownloadInvoiceButton = connect(
  mapStateToProps,
  mapDispatchToProps
)(DownloadInvoiceButton);

export { ConnectedDownloadInvoiceButton as DownloadInvoiceButton };
