import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import {
  Menu,
  SimpleButton,
  EditIcon,
  DeleteIcon,
  MessageIcon,
  Box
} from 'edenred-ui';

import { enableSlideoutEditMode, enableEditMode } from '@epi-actions/company';
import { IContactActions } from '@epi-models/pages/CompanyInfo';

const StyledButton = styled(SimpleButton)`
  flex: 1;
  display: flex;
  font-size: ${({ theme }) => theme.sizeMedium};
`;

export function ContactActions({
  anchorEl = null,
  closeMenu,
  userActive,
  openModalAndFillForm,
  resendActivationEmail,
  handleDeleteAction
}: IContactActions) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleEditAction = () => {
    closeMenu();
    dispatch(enableSlideoutEditMode());
    dispatch(enableEditMode());
    openModalAndFillForm();
  };

  return (
    <Menu
      open={!!anchorEl}
      anchorEl={anchorEl}
      onClose={closeMenu}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
    >
      <Menu.Item onClick={null}>
        {userActive ? (
          <StyledButton
            onClick={handleEditAction}
            icon={
              <Box mr="10px">
                <EditIcon />
              </Box>
            }
            text={t('company_info.contacts.actions.edit')}
            sx={{ width: '100%' }}
            id="contact-action_edit"
          />
        ) : (
          <StyledButton
            onClick={resendActivationEmail}
            icon={
              <Box mr="10px">
                <MessageIcon />
              </Box>
            }
            text={t('company_info.contacts.actions.resend_email')}
            sx={{ width: '100%' }}
            id="contact-action_resend-email"
          />
        )}
      </Menu.Item>
      <Menu.Item onClick={null}>
        <StyledButton
          onClick={handleDeleteAction}
          icon={
            <Box mr="10px">
              <DeleteIcon />
            </Box>
          }
          text={t('company_info.contacts.actions.delete')}
          sx={{ width: '100%' }}
          id="contact-action_delete"
        />
      </Menu.Item>
    </Menu>
  );
}
