import React from 'react';
import { DownloadIcon, InProgressIcon } from 'edenred-ui';
import { connect } from 'react-redux';

import { SingleClickButton } from '@epi-components/SingleClickButton/SingleClickButton';
import {
  isLoadingReceiptForDeposit,
  isLoadingReceiptForTransaction
} from '@epi-selectors/api';
import {
  downloadReceiptForDepositOrder,
  downloadReceiptForTransaction
} from '@epi-actions/api';
import { DocumentSourceTypes } from '@epi-constants/documentSourceTypes';
import { DownloadReceiptButtonProps } from '@epi-models/components/DownloadReceiptButton';

function downloadReceiptBySource(sourceType: string) {
  if (sourceType === DocumentSourceTypes.Transaction) {
    return downloadReceiptForTransaction;
  }
  return downloadReceiptForDepositOrder;
}

function isLoadingReceiptBySource(sourceType: string) {
  if (sourceType === DocumentSourceTypes.Transaction) {
    return isLoadingReceiptForTransaction;
  }
  return isLoadingReceiptForDeposit;
}

function DownloadReceiptButton({
  isLoading,
  downloadReceipt,
  sourceId,
  sourceType = DocumentSourceTypes.Order,
  children = null
}: DownloadReceiptButtonProps) {
  const showSpinner = isLoading(sourceId, sourceType);

  return (
    <div>
      <SingleClickButton
        disabled={showSpinner}
        onClick={() => {
          if (!showSpinner) {
            downloadReceipt(sourceId, sourceType);
          }

          return false;
        }}
      >
        {showSpinner ? <InProgressIcon /> : <DownloadIcon />}
        {children}
      </SingleClickButton>
    </div>
  );
}

const mapStateToProps = state => ({
  isLoading: (sourceId, sourceType) =>
    isLoadingReceiptBySource(sourceType)(sourceId)(state)
});

const mapDispatchToProps = dispatch => ({
  downloadReceipt: (sourceId, sourceType) =>
    dispatch(downloadReceiptBySource(sourceType)(sourceId))
});

const ConnectedDownloadReceiptButton = connect(
  mapStateToProps,
  mapDispatchToProps
)(DownloadReceiptButton);

export { ConnectedDownloadReceiptButton as DownloadReceiptButton };
