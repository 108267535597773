import React from 'react';
import {
  ButtonComponent as Button,
  ButtonMode,
  DialogComponent,
  Box,
  CloseIcon,
  TitleText,
  ButtonSize
} from 'edenred-ui';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

import DateRangePicker from '@epi-components/DateRangePicker/DateRangePicker';
import { useTimePeriodDialog } from '@epi-pages/DownloadReports/hooks';
import { ITimePeriodDialog } from '@epi-models/pages/DownloadReports';
import { useMenuDimensions } from '@epi-shared/hooks';

export const TimePeriodDialog = ({
  isOpen,
  closeAction,
  disabledCustomDates,
  type,
  getFieldName
}: ITimePeriodDialog) => {
  const { t } = useTranslation();
  const { gray10, sizeExtraExtraLarge } = useTheme();
  const { from, to, setFrom, setTo, handleCancel, handleSave } =
    useTimePeriodDialog({ closeAction, type, getFieldName });
  const { isMobile } = useMenuDimensions();

  return (
    <DialogComponent open={isOpen} onClose={closeAction} overflow>
      <Box pt={2} pl={5} borderBottom={1} borderColor={gray10}>
        <Box position="absolute" right={16}>
          <Button
            id="date-range-dialog-cancel-btn"
            mode={ButtonMode.Link}
            onClick={handleCancel}
          >
            <CloseIcon />
          </Button>
        </Box>
        <Box pt={3}>
          <TitleText fontWeight={600} fontSize={sizeExtraExtraLarge}>
            {t('download_reports.lunch_deduction.time_period.dialog_title')}
          </TitleText>
        </Box>
      </Box>
      <Box
        height={isMobile ? 550 : 500}
        pt={5}
        pl={5}
        pr={5}
        borderBottom={1}
        borderColor={gray10}
        fontSize={16}
      >
        <DateRangePicker
          from={from}
          to={to}
          setFrom={setFrom}
          setTo={setTo}
          disabledCustomDates={disabledCustomDates}
        />
      </Box>
      <Box
        display="flex"
        pt={2}
        justifyContent="flex-end"
        pr={5}
        gap={8}
        pb={2}
      >
        <Button
          id="date-range-dialog-cancel-btn"
          mode={ButtonMode.Link}
          onClick={handleCancel}
        >
          {t('download_reports.lunch_deduction.time_period.dialog_cancel')}
        </Button>
        <Button
          id="date-range-dialog-save-btn"
          size={ButtonSize.Large}
          minWidth={135}
          mode={ButtonMode.Primary}
          disabled={!(from && to)}
          onClick={() => {
            handleSave();
          }}
        >
          {t('download_reports.lunch_deduction.time_period.dialog_save')}
        </Button>
      </Box>
    </DialogComponent>
  );
};
