import React from 'react';
import PropTypes from 'prop-types';

import { FormStepStyles } from './FormStep.styles';

export const FormStep = ({ mode, children, stepNumber }) => {
  return (
    <FormStepStyles.Container
      id={`deposit_money_topup_step_${stepNumber}`}
      mode={mode}
    >
      {children}
    </FormStepStyles.Container>
  );
};

export const FormStepMode = { Contained: 'contained', Outlined: 'outlined' };

FormStep.propTypes = {
  children: PropTypes.node.isRequired,
  mode: PropTypes.string.isRequired,
  stepNumber: PropTypes.number.isRequired
};

FormStep.defaultProps = {
  mode: FormStepMode.Contained
};
