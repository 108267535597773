import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import {
  selectBenefitGroups,
  selectIsBenefitGroupsLoaded,
  selectIsBenefitGroupsError
} from '@epi-selectors/benefitGroups';
import config from '@epi-config';
import { IBenefitGroup } from '@epi-models/containers/ManageBenefitGroups';
import { BenefitType } from '@epi-constants/benefitTypes';
import { getBenefitGroups, getActiveBenefits } from '@epi-actions/api';
import { goToPage } from '@epi-actions/navigation';
import { routerPaths } from '@epi-constants/routerPaths';

export const useManageBenefitGroups = () => {
  const dispatch = useDispatch();
  const { benefitGroup } = useParams<{ benefitGroup: string }>();
  const benefitGroups: IBenefitGroup[] = useSelector(selectBenefitGroups);
  const isBenefitGroupsLoaded: boolean = useSelector(
    selectIsBenefitGroupsLoaded
  );
  const isBenefitGroupsError: boolean = useSelector(selectIsBenefitGroupsError);

  const benefitGroupFirstLetterUpperCase =
    benefitGroup[0].toUpperCase() + benefitGroup.slice(1);

  const isBenefitAvailable =
    benefitGroup === BenefitType[benefitGroupFirstLetterUpperCase] ||
    (benefitGroup === BenefitType.Recreational && config.enableVirikeBenefit);

  const isMassageOrWellbeing =
    benefitGroup === BenefitType.Massage ||
    benefitGroup === BenefitType.Wellbeing;

  const shouldShowAddBenefitTile = benefitGroups.length === 0;

  useEffect(() => {
    dispatch(getActiveBenefits());
  }, [benefitGroups]);

  useEffect(() => {
    if (!isBenefitAvailable) {
      dispatch(goToPage(routerPaths.notFound));
    } else {
      dispatch(getBenefitGroups(benefitGroup));
    }
  }, []);

  return {
    benefitGroups,
    isBenefitGroupsLoaded,
    isBenefitGroupsError,
    isMassageOrWellbeing,
    isBenefitAvailable,
    shouldShowAddBenefitTile
  };
};
