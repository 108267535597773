import { useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { FormFields, FormArrays } from '@epi-forms/helpers';
import { DeliveryMethods } from '@epi-constants/deliveryMethods';
import { PaymentMethods } from '@epi-constants/paymentMethods';
import { getIsAuthenticated } from '@epi-selectors/login';
import { getAddresses } from '@epi-selectors/api';

export const useVoucherOrder = () => {
  const isAuthenticated = useSelector(getIsAuthenticated);
  const voucherOrderForm = useForm({
    mode: 'onChange',
    defaultValues: {
      [FormFields.VoucherQuantity]: 50,
      [FormFields.VoucherId]: '',
      [FormFields.VoucherWorth]: 0,
      [FormFields.BusinessId]: '',
      [FormFields.CustomerNumber]: '',
      [FormFields.CompanyName]: '',
      [FormFields.NumberOfEmployees]: '',
      [FormFields.Address]: '',
      [FormFields.AddressId]: '',
      [FormFields.ZipCode]: '',
      [FormFields.City]: '',
      [FormFields.Country]: 'FI',
      [FormFields.ValidateCompanyInformation]: false,
      [FormFields.FirstName]: '',
      [FormFields.LastName]: '',
      [FormFields.JobTitle]: '',
      [FormFields.EmailAddress]: '',
      [FormFields.PhoneNumber]: '',
      [FormFields.Username]: '',
      [FormFields.Password]: '',
      [FormFields.ConfirmPassword]: '',
      [FormFields.DeliveryMethod]: DeliveryMethods.ToCompanyAddress,
      [FormArrays.ContactPersons]: '',
      [FormFields.DeliveryCompanyName]: '',
      [FormFields.DeliveryAddress]: '',
      [FormFields.DeliveryZipCode]: '',
      [FormFields.DeliveryCity]: '',
      [FormFields.DeliveryCountry]: '',
      [FormFields.AcceptTerms]: false,
      [FormFields.FromMonth]: '',
      [FormFields.ToMonth]: '',
      [FormFields.BeneficiariesAmount]: '',
      [FormFields.PaymentMethod]: '',
      [FormFields.PaymentMethodCode]: '',
      [FormFields.InvoicingEmailAddress]: '',
      [FormFields.InvoicingCostCenter]: '',
      [FormFields.InvoicingContactPerson]: '',
      [FormFields.WithoutReferenceNumber]: false
    }
  });
  const addresses = useSelector(getAddresses);
  const getDeliveryMethod = addressId =>
    addresses.find(a => a.id === addressId)?.addressType === 'Main'
      ? DeliveryMethods.ToCompanyAddress
      : DeliveryMethods.ToOtherAddress;

  const getDeliveryAddress = values => {
    if (values[FormFields.DeliveryMethod] === DeliveryMethods.ToSavedAddress) {
      return {
        contactPerson: values[FormArrays.ContactPersons],
        address: {
          id: values[FormFields.AddressId]
        },
        method: getDeliveryMethod(
          voucherOrderForm.watch(FormFields.AddressId) || values.addressId
        )
      };
    }
    if (
      values[FormFields.DeliveryMethod] === DeliveryMethods.ToCompanyAddress
    ) {
      return isAuthenticated
        ? {
            contactPerson: values[FormArrays.ContactPersons],
            address: {
              id: values[FormFields.AddressId]
            },
            method: DeliveryMethods.ToCompanyAddress
          }
        : {
            contactPerson: values[FormArrays.ContactPersons],
            address: {
              addressLine: values[FormFields.Address],
              postCode: values[FormFields.ZipCode],
              city: values[FormFields.City],
              country: values[FormFields.Country],
              companyName: values[FormFields.CompanyName]
            },
            method: DeliveryMethods.ToCompanyAddress
          };
    }
    if (values[FormFields.DeliveryMethod] === DeliveryMethods.ToOtherAddress) {
      return {
        contactPerson: values[FormArrays.ContactPersons],
        address: {
          addressLine: values[FormFields.DeliveryAddress],
          postCode: values[FormFields.DeliveryZipCode],
          city: values[FormFields.DeliveryCity],
          country: values[FormFields.DeliveryCountry],
          companyName: values[FormFields.DeliveryCompanyName]
        },
        method: DeliveryMethods.ToOtherAddress
      };
    }
    if (values[FormFields.DeliveryMethod] === DeliveryMethods.ToPickupPoint) {
      return {
        contactPerson: values[FormArrays.ContactPersons],
        method: DeliveryMethods.ToPickupPoint
      };
    }
  };

  const getPayment = values => {
    if (values[FormFields.PaymentMethod] === PaymentMethods.LinkToOtherPerson) {
      return {
        method: values[FormFields.PaymentMethod],
        emailAddress: values[FormFields.InvoicingEmailAddress],
        additionalMessage: values[FormFields.AdditionalMessage]
      };
    }
    if (values[FormFields.PaymentMethod] === PaymentMethods.PrintInvoicePdf) {
      return {
        method: values[FormFields.PaymentMethod],
        invoiceCostCenter: values[FormFields.InvoicingCostCenter],
        invoiceContactPerson: values[FormFields.InvoicingContactPerson],
        withoutReferenceNumber: values[FormFields.WithoutReferenceNumber]
      };
    }
    if (
      values[FormFields.PaymentMethod] === PaymentMethods.Online ||
      values[FormFields.PaymentMethod] === PaymentMethods.CreditCard
    ) {
      return {
        method: values[FormFields.PaymentMethod],
        methodCode: values[FormFields.PaymentMethodCode]
      };
    }
  };

  const composeVoucherOrder = ({ values, reCaptchaToken }) => {
    return {
      user: isAuthenticated
        ? null
        : {
            username: values[FormFields.Username],
            firstName: values[FormFields.FirstName],
            lastName: values[FormFields.LastName],
            emailAddress: values[FormFields.EmailAddress],
            phoneNumber: values[FormFields.PhoneNumber],
            confirmPassword: values[FormFields.ConfirmPassword],
            password: values[FormFields.Password]
          },
      company: isAuthenticated
        ? null
        : {
            name: values[FormFields.CompanyName],
            businessIdentityCode: values[FormFields.BusinessId],
            customerNumber: values[FormFields.CustomerNumber],
            addresses: [
              {
                addressLine: values[FormFields.Address],
                city: values[FormFields.City],
                companyName: values[FormFields.CompanyName],
                country: values[FormFields.Country],
                postCode: values[FormFields.ZipCode],
                validateCompanyInformation:
                  values[FormFields.ValidateCompanyInformation],
                addressType: 'main'
              }
            ],
            sizeId: values[FormFields.NumberOfEmployees]
          },
      payment: getPayment(values),
      delivery: getDeliveryAddress(values),
      termsAndConditions: {
        acceptTermsAndConditions: values[FormFields.AcceptTerms],
        validateCompanyInformation:
          values[FormFields.ValidateCompanyInformation]
      },
      details: [
        {
          voucherId: values[FormFields.VoucherId],
          quantity: values[FormFields.VoucherQuantity],
          numberOfEmployees: values[FormFields.BeneficiariesAmount]
        }
      ],
      ReCaptchaToken: reCaptchaToken
    };
  };

  const composeReorder = values => {
    return {
      payment: getPayment(values),
      delivery: getDeliveryAddress(values),
      // delivery: values.delivery,
      termsAndConditions: {
        acceptTermsAndConditions: values[FormFields.AcceptTerms],
        validateCompanyInformation:
          values[FormFields.ValidateCompanyInformation]
      },
      details: [
        {
          voucherId: values[FormFields.VoucherId],
          quantity: values.totalQuantity,
          numberOfEmployees: values[FormFields.BeneficiariesAmount]
        }
      ],
      ReCaptchaToken: values.reCaptchaToken
    };
  };

  const keyList = Object.keys;

  const isEqual = (a, b) => {
    if (a === b) return true;
    if (!(a instanceof Object) || !(b instanceof Object)) return false;

    const keys = keyList(a);
    const length = keys.length;

    for (let i = 0; i < length; i++) if (!(keys[i] in b)) return false;

    for (let j = 0; j < length; j++)
      if (a[keys[j]] !== b[keys[j]]) return false;

    return length === keyList(b).length;
  };

  const shallowEqualDepsList = (prevDeps, nextDeps) =>
    prevDeps.every((dep, index) => isEqual(dep, nextDeps[index]));

  const useCompare = (effect, deps) => {
    const ref = useRef(undefined);

    if (!ref.current || !shallowEqualDepsList(deps, ref.current)) {
      ref.current = deps;
    }

    useEffect(effect, ref.current);
  };

  const useFormUpdater = (book, setValue) => {
    useCompare(() => {
      const setValueToKey = ([key, value]) => {
        setValue(key, value, { shouldDirty: false });
      };
      Object.entries(book).forEach(setValueToKey);
    }, [book, setValue]);
  };

  return {
    voucherOrderForm,
    composeVoucherOrder,
    useFormUpdater,
    composeReorder
  };
};
