import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Button, ButtonSize, PagePanel } from 'edenred-ui';
import { useForm, FormProvider } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { FormFields } from '@epi-forms/helpers';
import { registeredVoucherOrderAction } from '@epi-store/registeredVoucherOrder/registeredVoucherOrder.slice';
import { getOrderData } from '@epi-store/registeredVoucherOrder/registeredVoucherOrder.selectors';
import { goToPage } from '@epi-actions/navigation';
import { IOrderAmount } from '@epi-models/containers/OrderAmount';
import { getApplicationLocale } from '@epi-store/application/application.selectors';

import { formatCurrency } from '../../helpers/numeral';
import { orderSummary as orderSummarySelector } from '../../selectors/voucherOrder';
import { getAvailableVouchersByType } from '../../selectors/api';
import {
  getVoucherTypeKey,
  getFaceValueChangedData,
  getVoucherReorderDetails
} from '../../selectors/voucherReorder';
import {
  getMaxOrderTotalAmount,
  getVoucherMinimumQuantity
} from '../../selectors/settings';
import { VoucherTypes } from '../../constants/voucherTypes';
import { routerPaths } from '../../constants/routerPaths';
import { FormConstants as forms } from '../../constants/forms';
import { VoucherQuantity } from '../../forms/VoucherQuantity/VoucherQuantity';
import { NavigationHeader } from '../../components/NavigationHeader/NavigationHeader';
import { BenefitSummaryContent } from '../../components/BenefitSummaryContent/BenefitSummaryContent';
import * as voucherReorderActions from '../../actions/voucherReorder';
import * as apiActions from '../../actions/api';
import * as navigationActions from '../../actions/navigation';

import './OrderAmount.scss';

function OrderAmount({
  vouchers,
  orderSummary,
  voucherTypeKey,
  locale,
  faceValueChangingInfo,
  maxOrderTotalAmount,
  voucherMinimumQuantity,
  anyTouched = false
}: IOrderAmount) {
  const { orderId } = useParams<{ orderId: string }>();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const orderData = useSelector(getOrderData);

  const form = useForm({
    mode: 'onChange',
    defaultValues: {
      [FormFields.VoucherQuantity]: 0,
      [FormFields.VoucherId]: '',
      [FormFields.VoucherWorth]: 0
    }
  });
  const { handleSubmit, watch, setValue } = form;
  const voucherIdValue = watch(FormFields.VoucherId);

  useEffect(() => {
    setValue(FormFields.VoucherQuantity, orderData.totalQuantity);
    setValue(FormFields.VoucherId, orderData.voucherId);
    setValue(FormFields.VoucherWorth, orderData.faceValue);
  }, []);

  useEffect(() => {
    const vouchersValue = (vouchers || []).find(v => v.id === voucherIdValue);
    if (vouchersValue) {
      setValue(FormFields.VoucherWorth, vouchersValue.value);
    }
  }, [voucherIdValue]);

  const onSubmit = values => {
    dispatch(
      registeredVoucherOrderAction.addOrderInformation({
        totalQuantity: Number(values[FormFields.VoucherQuantity]),
        voucherId: values[FormFields.VoucherId],
        faceValue: values[FormFields.VoucherWorth]
      })
    );
    dispatch(
      goToPage(routerPaths.voucherReorder, {
        params: {
          voucherType: VoucherTypes[voucherTypeKey],
          orderId
        },
        target: null
      })
    );
  };

  const useCalculator = VoucherTypes[voucherTypeKey] === VoucherTypes.Lunch;

  return (
    <NavigationHeader
      withBackButton
      title={t('containers.edit_order_amount.title')}
    >
      <FormProvider {...form}>
        <form noValidate onSubmit={handleSubmit(onSubmit)}>
          <PagePanel
            className="OrderAmount"
            title={t(`containers.voucher_order.header`)}
          >
            <Col md={12} className="order-amount-order-calculator-container">
              <VoucherQuantity
                voucherTypeKey={voucherTypeKey}
                vouchers={vouchers}
                useCalculator={useCalculator}
                locale={locale}
                maxOrderTotalAmount={maxOrderTotalAmount}
                voucherMinimumQuantity={voucherMinimumQuantity}
                newFaceValueMessage={
                  (faceValueChangingInfo &&
                    !anyTouched &&
                    t('forms.voucher_quantity.voucher_face_value_changed', {
                      oldValue: formatCurrency(faceValueChangingInfo.oldValue),
                      newValue: formatCurrency(faceValueChangingInfo.newValue)
                    })) ||
                  undefined
                }
              />
            </Col>
            <Col md={12}>
              <hr />
            </Col>
            <Col md={12} className="mb-10 order-amount-order-details-container">
              <BenefitSummaryContent
                voucherTypeKey={voucherTypeKey}
                orderSummary={orderSummary}
              />
            </Col>
          </PagePanel>
          <Button
            id="order-amount-submit-btn"
            type="submit"
            size={ButtonSize.Medium}
            className="pull-right"
          >
            {t('containers.edit_order_amount.update_amount')}
          </Button>
        </form>
      </FormProvider>
    </NavigationHeader>
  );
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    { ...voucherReorderActions, ...apiActions, ...navigationActions },
    dispatch
  );

const mapStateToProps = state => ({
  locale: getApplicationLocale(state),
  voucherTypeKey: getVoucherTypeKey(state),
  vouchers: getAvailableVouchersByType(getVoucherTypeKey(state))(state),
  faceValueChangingInfo: getFaceValueChangedData(state),
  orderSummary: orderSummarySelector(forms.EDIT_VOUCHER_QUANTITY_FORM)(state),
  initialValues: {
    voucher_details: getVoucherReorderDetails(state)
  },
  maxOrderTotalAmount: getMaxOrderTotalAmount(state),
  voucherMinimumQuantity: getVoucherMinimumQuantity(state)
});

const ConnectedOrderAmount = connect(
  mapStateToProps,
  mapDispatchToProps
)(OrderAmount);

export { ConnectedOrderAmount as OrderAmount };
