import React from 'react';
import { Box, TitleText, Text, Link, SimpleList } from 'edenred-ui';

import { SupportLinksProps } from '@epi-models/pages/Support';

const linksMapper = data =>
  data.map(elem => {
    if (elem?.links) {
      const links = elem.links.map(({ url, name, id }) => (
        <Link
          target="_blank"
          href={url}
          key={url}
          isSizeMedium
          isFontRegularBold
          id={`support-page-link-element-${id}`}
        >
          {name}
        </Link>
      ));
      return { ...elem, links };
    }
    return elem;
  });

export const SupportLinks = ({ data }: SupportLinksProps) => {
  const mappedData = linksMapper(data);

  return mappedData.map(
    ({ title, description, links, description_id, title_id }) => (
      <Box mt={6} mx={20} mb={2} key={title}>
        <Box id={`support-page-link-title-${title_id}`}>
          <TitleText>{title}</TitleText>
        </Box>
        {description && (
          <Text paragraph id={`support-page-link-desc-${description_id}`}>
            {description}
          </Text>
        )}
        {links && <SimpleList data={links} />}
      </Box>
    )
  );
};
