import React from 'react';
import {
  Modal,
  PopupPanelComponent as Panel,
  ButtonMode,
  Button,
  Box
} from 'edenred-ui';
import { useTranslation } from 'react-i18next';
import { ICloseDetailDialog } from '@epi-models/pages/Beneficiaries';

export const CloseDetailDialog = ({
  isOpen,
  title,
  handleCloseModal,
  primaryAction,
  primaryActionText,
  secondaryAction,
  secondaryActionText,
  contentText
}: ICloseDetailDialog) => {
  const { t } = useTranslation();

  return (
    <Modal
      open={isOpen}
      hideCloseIcon
      onClose={handleCloseModal}
      modalBodySx={{
        padding: '0',
        left: '35%'
      }}
    >
      <Panel
        onClose={handleCloseModal}
        title={title}
        primaryActionButton={
          <Button
            id="dialog-close-without-saving"
            onClick={primaryAction}
            mode={ButtonMode.Primary}
            minWidth={200}
          >
            {primaryActionText}
          </Button>
        }
        secondaryActionButton={
          <Button
            id="dialog-save-first"
            onClick={secondaryAction}
            mode={ButtonMode.Secondary}
            minWidth={200}
          >
            {secondaryActionText}
          </Button>
        }
      >
        <Box height={100} display="flex" paddingTop={4} paddingLeft={4}>
          {contentText}
        </Box>
      </Panel>
    </Modal>
  );
};
