import React from 'react';
import { useTranslation } from 'react-i18next';
import { OrderType } from '@epi-constants/orderType';
import { PagePanel } from 'edenred-ui';

import { DeliveryAndPaymentProps } from '@epi-models/components/DeliveryAndPayment';

import { ApiConstants } from '../../constants/actions';
import { PaymentMethods } from '../../constants/paymentMethods';
import { TermsAndConditions } from '../../forms/TermsAndConditions/TermsAndConditions';
import { PaymentMethod } from '../../forms/PaymentMethod/PaymentMethod';
import { DeliveryPicker } from '../../forms/DeliveryPicker/DeliveryPicker';
import { Company } from '../Company/Company';
import { BenefitSummary } from '../BenefitSummary/BenefitSummary';
import { LoadingPanel } from '../LoadingPanel/LoadingPanel';

export function DeliveryAndPayment({
  voucherTypeKey,
  orderSummary,
  paymentMethod = '',
  apiPaymentMethods,
  onClickPaymentMethod,
  userCompany,
  isLoading = false,
  disabled = false,
  goToEditPage,
  editDeliveryPage,
  editCompanyPage,
  voucherReorderDetails
}: DeliveryAndPaymentProps) {
  const { t } = useTranslation();

  return (
    <div>
      <Company
        voucherTypeKey={voucherTypeKey}
        userCompany={userCompany}
        isLoading={isLoading}
        editPage={editCompanyPage}
      />
      <DeliveryPicker
        editPage={editDeliveryPage}
        isLoading={isLoading}
        companyAdress={userCompany.address}
      />
      <BenefitSummary
        goToEditPage={goToEditPage}
        voucherTypeKey={voucherTypeKey}
        orderSummary={orderSummary}
        isLoading={isLoading}
        showCreditCardFee={paymentMethod === PaymentMethods.CreditCard}
        showTitle
        voucherOrderPayment={voucherReorderDetails}
      />
      <PagePanel
        title={t('containers.voucher_order.terms_and_conditions_header')}
        className="px-10"
      >
        <TermsAndConditions />
      </PagePanel>
      <LoadingPanel
        title={t('containers.voucher_order.payment_methods_header')}
        className="px-10 payment-method"
        isLoading={[
          ApiConstants.CREATE_VOUCHER_ORDER,
          ApiConstants.CREATE_NEW_PAYMENT
        ]}
      >
        <PaymentMethod
          paymentMethods={apiPaymentMethods}
          onClickPaymentMethod={() => onClickPaymentMethod()}
          disabled={disabled}
          creditCardFeePercent={orderSummary.creditCardFeePercent}
          amountValue={orderSummary.totalAmountWithFees}
          orderType={OrderType.voucher}
          hiddenMethods={[PaymentMethods.PrintInvoicePdfForTransferMoney]}
        />
      </LoadingPanel>
    </div>
  );
}
