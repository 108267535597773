import React, { useEffect, useState } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { TextField } from 'edenred-ui';
import memoize from 'memoize-one';

import { createRules, FormFields } from '@epi-forms/helpers';
import { required } from '@epi-helpers/formHelpers/validators';
import Moment from '@epi-helpers/MomentExtensions';
import {
  ToMonthSelectOption,
  ToMonthSelectProps
} from '@epi-models/forms/Controls';

function ToMonthSelect({
  disabled = false,
  readOnly = false,
  name = FormFields.ToMonth,
  locale,
  startFrom = ''
}: ToMonthSelectProps) {
  const { t } = useTranslation();
  const { control } = useFormContext();
  const [toPeriods, setToPeriods] = useState<ToMonthSelectOption[]>([]);
  const validators = [required];
  const rules = createRules(validators);

  const {
    field: { ref, value, onChange: setValue },
    fieldState: { error }
  } = useController({
    name,
    control,
    rules
  });

  const getNextFullMonth = memoize(increment => {
    const today = Moment();
    if (today.date() === 1) {
      return today.format('MM/YYYY');
    }
    return today.add(increment || 1, 'months').format('MM/YYYY');
  });

  const getLastMonthOfNextYear = () => {
    const endOfNextYear = Moment().endOf('year').add(1, 'year');
    return endOfNextYear.format('MM/YYYY');
  };

  const generateDateOptions = (locale, from, to) => {
    Moment.locale(locale);
    const start = Moment(from, 'MM/YYYY');
    const end = to || getLastMonthOfNextYear();
    const totalMonths = Moment(end, 'MM/YYYY').diff(start, 'months') + 1;
    const options = Array.from({ length: totalMonths }, (_, i) => {
      const option = {
        id: start.toISOString(),
        label: start.format('MMMM YYYY'),
        value: start.format('MM/YYYY')
      };
      start.add(1, 'months');
      return option;
    });
    return options;
  };

  const getToPeriods = memoize(from => {
    const start = from || getNextFullMonth(undefined);
    return generateDateOptions(locale, start, undefined);
  });

  useEffect(() => {
    const newPeriods = getToPeriods(undefined);
    setToPeriods(newPeriods);
    setValue(newPeriods[1].value);
  }, [locale]);

  useEffect(() => {
    const newPeriods = getToPeriods(startFrom);
    setToPeriods(newPeriods);
    if (Moment(startFrom, 'MM/YYYY') > Moment(value, 'MM/YYYY')) {
      setValue(newPeriods[0].value);
    }
  }, [startFrom]);

  return (
    <TextField
      label={t('forms.benefit_calculator.period_to')}
      select
      required
      value={value}
      disabled={disabled}
      readOnly={readOnly}
      id={name}
      error={!!error}
      helperText={error?.message}
      inputRef={ref}
      onChange={setValue}
      sx={{ '& .MuiSelect-select:focus': { backgroundColor: 'transparent' } }}
    >
      {(toPeriods?.length > 0 ? toPeriods : []).map(period => (
        <TextField.Item key={period.id} value={period.value}>
          {period.label}
        </TextField.Item>
      ))}
    </TextField>
  );
}

const ConnectedToMonthSelect = connect()(ToMonthSelect);

export { ConnectedToMonthSelect as ToMonthSelect };
